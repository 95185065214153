const $ = window.$;
const _ = window._;

$(function () {

    const $user_name = $(".form_password_login #loginUserName");
    // 一時的に無効化します (focus当てたくない)
    // if ($user_name) {
    //     $user_name.focus();
    // }

    const show_password_toggle_func = function ($password, $show_password) {
        const password_str = $password.val();

        let type;
        if ( $password.attr("type") === "text" ) {
            type = "password";
        } else {
            type = "text";
        }

        const $new_password = $("<input>");
        $new_password.attr("type", type);
        $new_password.attr("value", password_str);
        _.each(["id", "class", "name", "size", "maxlength", "tabindex", "autocomplete", "placeholder"], function (value, key) {
            const attr = $password.attr(value);
            if (attr) {
                $new_password.attr(value, $password.attr(value));
            }
        });

        $password.before($new_password);
        $password.remove();

        return $new_password;
    };

    $(".form_password_login #show-password").change( function () {
        const $show_password = $(this);

        const $password = $(".form_password_login #login-password");
        if ($password) {
            show_password_toggle_func($password, $show_password);
        }
    });

});
