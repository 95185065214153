var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShown,
                expression: "isShown"
              }
            ],
            staticClass: "modal-content"
          },
          [
            _c("div", { staticClass: "modal-mask", on: { click: _vm.cancel } }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-wrapper" }, [
              _c("div", { staticClass: "modal-container" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("div", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.title))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-table" }, [
                    _c("div", { staticClass: "modal-price-wrap" }, [
                      _c("ul", [
                        _c("li", [
                          _c("div", [_vm._v("Price")]),
                          _vm._v(
                            _vm._s(
                              _vm.currency.formatSymbolAndPrice(
                                _vm.totalPriceWithoutTax
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("div", [_vm._v("Tax")]),
                          _vm._v(
                            _vm._s(
                              _vm.currency.formatSymbolAndPrice(_vm.totalTax)
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "modal-price" }, [
                          _c("div", [_vm._v("Total")]),
                          _vm._v(_vm._s(_vm.currency.symbol)),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.currency.formatPrice(_vm.totalPrice))
                            )
                          ]),
                          _vm._v(
                            _vm._s(_vm.currency.name) + "\n                  "
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-button-set-wrap" },
                      [
                        !_vm.isRequesting
                          ? [
                              _c("div", { staticClass: "modal-button-wrap" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "modal-cancel-button",
                                    on: { click: _vm.cancel }
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.isRequesting,
                                        expression: "!isRequesting"
                                      }
                                    ],
                                    staticClass:
                                      "modal-confirm-button margin-l10",
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("confirm")
                                      }
                                    }
                                  },
                                  [_vm._v("Confirm Purchase")]
                                )
                              ])
                            ]
                          : [
                              _c("div", { staticClass: "modal-button-wrap" }, [
                                _c("span", {
                                  staticClass: "modal-button-loading"
                                })
                              ])
                            ],
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.error,
                                expression: "error"
                              }
                            ],
                            staticClass: "modal-error"
                          },
                          [_vm._v(_vm._s(_vm.error))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "modal-payment-method-button-wrap" },
                          [
                            !_vm.isRequesting
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "modal-payment-method-button",
                                    attrs: { href: "/account/payment" }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa-solid fa-gear",
                                      attrs: { "aria-hidden": "true" }
                                    }),
                                    _vm._v(
                                      " Edit Payment Method\n                  "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c("br")
                          ]
                        )
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-list" }, [
                  _c(
                    "div",
                    _vm._l(_vm.items, function(item) {
                      return _c("div", { staticClass: "modal-list-item" }, [
                        _c("div", [
                          _c("img", {
                            staticClass: "modal-list-cover margin-r10",
                            attrs: {
                              src: _vm.coverImageUrl(item.coverImageBaseUrl),
                              width: "46",
                              height: "auto"
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "modal-list-info" }, [
                          _c(
                            "div",
                            {
                              staticClass: "modal-list-volume",
                              class: { "icon-new-item": item.isNew }
                            },
                            [_vm._v(_vm._s(item.numberingText))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal-list-price" },
                            [
                              item.salePrice
                                ? [
                                    _c("span", { staticClass: "is-sale" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.currency.formatSymbolAndPrice(
                                            item.price
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" Sale\n                      "),
                                    _c(
                                      "span",
                                      { staticClass: "modal-price-sale" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.currency.formatSymbolAndPrice(
                                              item.salePrice
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                : [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          _vm.currency.formatSymbolAndPrice(
                                            item.price
                                          )
                                        ) +
                                        "\n                    "
                                    )
                                  ]
                            ],
                            2
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "modal-close", on: { click: _vm.cancel } },
                  [_vm._v("Close")]
                )
              ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }