"use strict";

let isSupported = null;
function isLocalStorageSupported() {
  if (isSupported === null) {
    const _salt = `EIK20${(Math.random() + 1).toString(36).substring(7)}`;
    try {
      localStorage.setItem(_salt, true);
      localStorage.removeItem(_salt);
      isSupported = true;
    } catch(e) {
      isSupported = false;
      console.log("Warning: localStorage is not supported on your browser.");
    }
  }
  return isSupported;
}

module.exports = isLocalStorageSupported() ? window.localStorage : {
  setItem: function(key, value) {
    this[key] = value;
  },
  getItem: function(key) {
    if (!this.hasOwnProperty(key)) {
      return null;
    }
    return this[key];
  },
  removeItem: function(key) {
    delete this[key];
  },
};
