const $ = window.$;

$(() => {
  if ($(".js-reset-colors").exists()) {
    $(".js-reset-colors").on("click", (e) => {
      e.preventDefault()
      const type = $(e.currentTarget.attributes["data-type"]).val()
      $(`input[name^="${type}"]`).each((i, el) => {
        const $el = $(el)
        $el.val($el.data("default"))
      })
    })
  }
})
