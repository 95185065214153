const $ = window.$;
import incrementalSearch from "../../../common/modules/incrementalSearch";

$(function() {
  // PM送信先ユーザのIncrementalSearch
  const $pmSearch = $("#pmessage-to");
  if ($pmSearch.exists()) {
    incrementalSearch.initializeVueModel("#pmessage-to", {
      type           : "user",
      query          : "toname",
      resultPosition : {
        left : 0,
        top  : $(".js-pmessage-to").outerHeight(),
        width: $(".js-pmessage-to").outerWidth(),
      },
      generateItemPageUrl (item) {
        return `/mymessages.php?go=send&toname=${encodeURIComponent(item.name)}`;
      }
    });
  }

  // btn filter event
  if ($(".js-mym-checkbox-pulldown-button")[0]) {
    $(".js-mym-checkbox-pulldown-button").on("click", function() {
      $(".js-pmessage-select").toggle();
      $(this).toggleClass("on");
    });
  }

  //submit change status
  if ($(".js-pmessage-checked-action-button")[0]) {
    $(".js-pmessage-checked-action-button").on("click", function() {

      const submitMessageForm = function() {
        $(".js-pmessage-checkSelector").val(actionVal);
        document.messageForm.submit();
      }

      const actionVal = $(this).attr("id");
      if (actionVal === "delete") {
        if (!confirm("Are you sure you want to delete?")) {
          return;
        }
      }
      submitMessageForm();
    });
  }


  //send Message link
  if ($(".js-pmessage-send-message")[0]) {
    const $toBox = $(".js-pmessage-to")
      , $inputUser = $("#inputUser")
      , $sendmsg = $("#sendmsg")
      ;
    $(".js-pmessage-send-message").on("click", function(e) {
      e.preventDefault();
      $inputUser.show();
      $sendmsg.hide();
      $toBox.focus();
    });
  }

  //confirm delete
  if ($(".js-pmessage-delete-message")[0]) {
    $(".js-pmessage-delete-message").on("click", function() {
      const data = $(this).data("json");
      let type = data.type;
      const pm_id = data.pm_id;
      if (!type) {
        type = 1;
      }
      if (confirm("Are you sure you want to delete?")) {
        $.post("/includes/ajax.inc.php?t=75", {id:pm_id,box:type}, () => {
          $(`#message_${pm_id}`).hide("slow");
        });
      }
    });
  }


  if ($(".js-pmessage-checkboxesAll")[0]) {

    //checkall icon func
    const inputCheckedAllicon = function () {
      const inputCheckedCountTotal = $(".mym_checkboxes input[type=\"checkbox\"]:checked").length;
      const inputAllSelect = $("input.js-pmessage-checkboxesAll[type=\"checkbox\"]").prop("checked");
      $("input.js-pmessage-checkboxesAll[type=\"checkbox\"]").removeClass("part");
      if (inputCheckedCountTotal > 0 && !inputAllSelect) {
        $("input.js-pmessage-checkboxesAll[type=\"checkbox\"]").addClass("part");
      }
    }

    //checked func
    const inputChecked = function () {
      const inputCountTotal = $(".mym_checkboxes input[type=\"checkbox\"]").length;
      const inputCheckedCountTotal = $(".mym_checkboxes input[type=\"checkbox\"]:checked").length;
      const inputAllSelect = $("input.js-pmessage-checkboxesAll[type=\"checkbox\"]").prop("checked");

      if (inputCountTotal - 1 === inputCheckedCountTotal) {
        if (inputAllSelect) {
          $("input.js-pmessage-checkboxesAll[type=\"checkbox\"]").prop("checked",false);
        } else {
          $("input.js-pmessage-checkboxesAll[type=\"checkbox\"]").prop("checked",true);
        }
      } else if (inputCheckedCountTotal === 1 && inputAllSelect) {
        $("input.js-pmessage-checkboxesAll[type=\"checkbox\"]").prop("checked",false);
      } else if (inputCheckedCountTotal > 0 && !inputAllSelect) {
        $("input.js-pmessage-checkboxesAll[type=\"checkbox\"]").prop("checked",false);
      }

      if (inputCheckedCountTotal > 0) {
        $(".js-pmessage-checked-action").show();
      } else {
        $(".js-pmessage-checked-action").hide();
      }
      //adding background color
      $(".mym_checkboxes").find("input[type=\"checkbox\"]").parents(".message").removeClass("selected");
      $(".mym_checkboxes").find("input[type=\"checkbox\"]:checked").parents(".message").addClass("selected");

      //check allのchekcboxにハイフン入れる
      inputCheckedAllicon();
    }


    // check box
    $(".mym_checkboxes input[type=\"checkbox\"]").on("click", function() {
      inputChecked();
    });

    // CheckUnCheckAll
    $(".js-pmessage-checkboxesAll").on("click", function() {
      let checkType = $(this).data("status");
      if (!checkType) {
        checkType = document.getElementById("checkSelector").value;
      }

      //select box
      $(".js-pmessage-select").hide();
      $(".js-mym-checkbox-pulldown-button").removeClass("on");

      //cleckbox
      switch (checkType){
        case "none":
          $(".mym_checkboxes").find("input[type=\"checkbox\"]").prop("checked",false);
          break;
        case "all":
          const checkVal = $("#checkall").prop("checked");
          if (checkVal) {
            $(".mym_checkboxes").find("input[type=\"checkbox\"]").prop("checked",false);
          } else {
            $(".mym_checkboxes").find("input[type=\"checkbox\"]").prop("checked",true);
          }
          break;
        case "selectall":
          $(".mym_checkboxes").find("input[type=\"checkbox\"]").prop("checked",true);
          break;
        case "read":
          $(".mym_checkboxes").find("input[type=\"checkbox\"]").prop("checked",false);
          $(".mym_checkboxes.read").find("input[type=\"checkbox\"]").prop("checked",true);
          break;
        case "unread":
          $(".mym_checkboxes").find("input[type=\"checkbox\"]").prop("checked",false);
          $(".mym_checkboxes.unread").find("input[type=\"checkbox\"]").prop("checked",true);
          break;
      }

      inputChecked();
    });
  }

});
