const $ = window.$;

$(function() {

  if ($(".js-settings-notification")[0]) {

    // AllがOffならその列は全グレーアウト、WebがOffなら同じ行のEmailはグレーアウト
    const reflectSettingsFunc = function() {
      // All行のボタン
      const $getAllNotifications = $(".js-settings-notification .js-get-all-notifications input[type='checkbox']");
      const $getAllEmails = $(".js-settings-notification .js-get-all-emails input[type='checkbox']");

      // Web列のボタンは、All WebがOffならグレーアウト
      $(".js-settings-notification .js-notification").each(function(index, element) {
        if ($getAllNotifications.prop("checked")) {
          $(element).removeClass("off");
        } else {
          $(element).addClass("off");
        }
      });
      // Email列のボタンは、All EmailがOffまたは同じ行のWebがOffならグレーアウト
      $(".js-settings-notification .js-email").each(function(index, element) {
        const $notificationCheckbox = $(element).siblings(".js-notification").find("input[type='checkbox']");
        if ($getAllNotifications.prop("checked") && $getAllEmails.prop("checked") && (!$notificationCheckbox[0] || $notificationCheckbox.prop("checked"))) {
          $(element).removeClass("off");
        } else {
          $(element).addClass("off");
        }
      });
      // All Emailは、All WebがOffならグレーアウト
      if ($getAllNotifications.prop("checked")) {
        $(".js-settings-notification .js-get-all-emails").removeClass("off");
      } else {
        $(".js-settings-notification .js-get-all-emails").addClass("off");
      }
    };

    // グレーアウト状態の初期化
    reflectSettingsFunc();
    // 何らかのチェックボックスの状態が変わったらグレーアウト状態を再計算
    $(".js-settings-notification input[type='checkbox']").on("change", reflectSettingsFunc);

    // グレーアウト状態のチェックボックスは値を変更不可
    $(".js-settings-notification input[type='checkbox']").on("click", function() {
        return !$(this).parent("td").hasClass("off");
    });
  }
});
