"use strict";

//汎用UA判定
module.exports = function getUA() {
  const ua = window.navigator.userAgent.toLowerCase();
  const version = window.navigator.appVersion.toLowerCase();
  let browser = "";
  if (ua.indexOf("msie") !== -1) {
    if (version.indexOf("msie 6.") !== -1) {
      browser = "ie6";
    } else if (version.indexOf("msie 7.") !== -1) {
      browser = "ie7";
    } else if (version.indexOf("msie 8.") !== -1) {
      browser = "ie8";
    } else if (version.indexOf("msie 9.") !== -1) {
      browser = "ie9";
    } else if (version.indexOf("msie 10.") !== -1) {
      browser = "ie10";
    } else {
      browser = "ie";
    }
  } else if (ua.indexOf("trident/7") !== -1) {
    browser = "ie11";
  } else if (ua.indexOf("chrome") !== -1) {
    browser = "chrome";
  } else if (ua.indexOf("safari") !== -1) {
    browser = "safari";
  } else if (ua.indexOf("opera") !== -1) {
    browser = "opera";
  } else if (ua.indexOf("firefox") !== -1) {
    browser = "firefox";
  }
  return {
    browser: browser,
    ua: ua,
    version: version
  };
};
