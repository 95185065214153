
export const TYPE_COMIC = "TYPE_COMIC"
export const TYPE_COMIC_VOLUME = "TYPE_COMIC_VOLUME"
export const TYPE_COMIC_CHAPTER = "TYPE_COMIC_CHAPTER"

export const ITEM_TYPE_STRING = {
  0: TYPE_COMIC,
  1: TYPE_COMIC_VOLUME,
  2: TYPE_COMIC_CHAPTER,
}

export const COVER_IMAGE_SIZE_S = "s.jpg"
export const COVER_IMAGE_SIZE_M = "m.jpg"
export const COVER_IMAGE_SIZE_L = "l.jpg"
export const COVER_IMAGE_SIZE_S_2X = "s@2x.jpg"
export const COVER_IMAGE_SIZE_M_2X = "m@2x.jpg"
export const COVER_IMAGE_SIZE_L_2X = "l@2x.jpg"

export function createBook(json) {
  return {
    id: json.id,
    itemType: json.item_type,
    title: json.title,
    numberingText: json.numbering_text,
    url: json.url,
    coverImageBaseUrl: json.cover_image_base_url,
    viewerUrl: json.viewer_url,
    previewUrl: json.preview_url,
    isPossessed: json.is_possessed,
    isPreviewable: json.is_previewable,
    onPreorderAt: json.on_preorder_at,
    price: json.price,
    salePrice: json.sale_price,
    tax: json.tax,
    totalAmount: json.total_amount,
    isNew: json.is_new,
    score: json.score,
    isFree: json.is_free,
    useFreeManga: json.use_free_manga,
    accessLimit: json.access_limit,
    saleBookId: json.sale_book_id,
    saleBookTitle: json.sale_book_title,
    onSaleAt: json.on_sale_at,
    closeSaleAt: json.close_sale_at,
    currentTimestamp: json.current_timestamp
  }
}

export function generateCoverImageUrl(baseUrl, size = COVER_IMAGE_SIZE_L) {
  return `${baseUrl}/${size}`
}

