require("../common")
require("../pages/account/login")
require("../pages/account/deletion")
require("../pages/apiconfig/request_form")
require("../pages/message/message")
require("../pages/myfriend/myfriend")
require("../pages/news/news")
require("../pages/news/edit")
require("../pages/about/support")
require("../pages/about/advertising")
require("../pages/list/setting")
require("../pages/anime/anime")
require("../pages/anime/season")
require("../pages/anime/stream")
require("../pages/anime/video")
require("../pages/manga/adapted")
require("../pages/manga/manga")
require("../pages/index/index")
require("../pages/featured/featured")
require("../pages/forum/forum")
require("../pages/forum/search")
require("../pages/forum/topic")
require("../pages/featured/draft")
require("../pages/watch/watch")
require("../pages/ranking/ranking")
require("../pages/review/review")
require("../pages/recommendation/recommendation")
require("../pages/rescue/rescue")
require("../pages/work/search")
require("../pages/work/misc")
require("../pages/notification/notification")
require("../pages/notification/setting")
require("../pages/profile/comment")
require("../pages/profile/personal-profile-edit")
require("../pages/profile/profile")
require("../pages/quickadd/quickadd")
require("../pages/search/search")
require("../pages/stacks/stacks")
require("../pages/store/store")
require("../pages/test/ui")
require("../pages/ownlist/ownlist")
require("../pages/ownlist/style")
require("../pages/ownlist/anime/update.js")
require("../pages/ownlist/manga/update.js")
require("../pages/membership/membership")
require("../pages/membership/paypal")
require("../pages/legacy/forum/topic.js")
require("../pages/legacy/forum/settings.js")
require("../pages/legacy/clubs")
require("../pages/legacy/clubs/new_forum_topic")
require("../pages/legacy/clubs/subscribe_mass_message")
require("../pages/legacy/users")
require("../pages/legacy/profile_setting")
require("../pages/legacy/people")
require("../pages/fansub/fansub")
require("../pages/admin/episode")
require("../pages/admin/ad_whitelist")
require("../pages/admin/panel")
require("../pages/admin/terms")
require("../modules/ad/merchandise")
require("../modules/ad/side_sticky")
require("../../common/pages/account/payment-settings")
require("../pages/dbchanges/dbchanges.js")
require("../pages/club/club.js")
require("../../common/text_ribbon")
require("../pages/history/updates")
require('../pages/admin/company_diff')
require("../../common/bbcode")
require("../../common/ui")
require("../../common/register_link")
require('../pages/company/company')
require("../pages/poll/20th_anniversary.js")

require("../../../vue/pages/manga_store/viewer/pc/mount")
require("../../../vue/components/gdpr/pc/mount")
require("../../../vue/components/manga_store/pc/organisms/slider/mount")
require("../../../vue/components/manga_store/pc/organisms/all_books/mount")
require("../../../vue/components/manga_store/pc/organisms/purchase_single_button/mount")
require("../../../vue/components/manga_store/pc/organisms/purchase_bulk_button/mount")
require("../../../vue/components/manga_store/pc/organisms/related_book_button/mount")
require("../../common/jquery.sceditor.bbcode")
require("../../common/jquery.sceditor.dragdroptext")
require("../../common/jquery.scrollintoview")
require("../../../vue/components/mal_support/mount")
require("../../../vue/components/affiliate/amazon/mount")
