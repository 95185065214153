<template>
  <div class="sp pl12 pr12 pt8 pb8" v-if="sp">
    <div class="manga-store-preview ga-impression" v-if="is_buy"
         :data-ga-impression-type="ga_type + '_preview'"
         :data-ga-impression-param="gaParam()">
      <a :href="link" class="manga-store-preview-thumb ga-click" target="_blank"
         :data-ga-click-type="ga_type + '_cover'"
         :data-ga-click-param="gaParam()"><img :src="image_url" :alt="title"/></a>
      <div class="manga-store-preview-info">
        <a :href="link" class="di-b ga-click" target="_blank"
           :data-ga-click-type="ga_type + '_title'"
           :data-ga-click-param="gaParam()"><span class="title fw14 fw-b">{{ title }}</span>
          <br>
          <span class="volume">{{ volume }}</span>
        </a>
        <a class="mal-btn outline button-preview ga-click" :href="link"
           target="_blank"
           :data-ga-click-type="ga_type + '_preview'"
           :data-ga-click-param="gaParam()">{{ is_manga ? 'Preview on Amazon' : 'Preview Manga on Amazon' }}</a>
      </div>
    </div>
    <div class="ac" v-if="is_wish">
      <affiliate-amazon-button :id="id" :type="type" :is_wish="is_wish" :link="link" :sp="sp"></affiliate-amazon-button>
    </div>
  </div>
  <div class="manga-store-preview pc ga-impression" v-else
       :data-ga-impression-type="ga_type + '_preview'"
       :data-ga-impression-param="gaParam()">
    <a :href="link" class="manga-store-preview-thumb ga-click" target="_blank"
       :data-ga-click-type="ga_type + '_cover'"
       :data-ga-click-param="gaParam()"><img :src="image_url" :alt="title"/></a>
    <div class="manga-store-preview-info">
      <span class="manga-store-preview-info-header"><i class="fa-solid fa-cart-shopping" aria-hidden="true"></i>
        Available on Amazon</span><br>
      <a :href="link" class="di-ib ga-click" target="_blank"
         :data-ga-click-type="ga_type + '_title'"
         :data-ga-click-param="gaParam()"><span
      class="title fw14 fw-b">{{ title }}</span>
      <br>
      <span class="volume">{{ volume }}</span>
    </a>
      <a class="mal-btn button-preview ga-click" :href="link" target="_blank"
         :data-ga-click-type="ga_type + '_preview'"
         :data-ga-click-param="gaParam()">Preview on Amazon</a>
    </div>
  </div>
</template>

<script>
import AffiliateAmazonButton from "./AffiliateAmazonButton.vue";

export default {
  name: "AffiliateAmazonBox",
  components: {
    AffiliateAmazonButton
  },
  props: {
    type: String,
    id: Number,
    link: String,
    image_url: String,
    title: String,
    volume: String,
    is_wish: Boolean,
    is_buy: Boolean,
    sp: Boolean,
    ga_type: String
  },
  methods: {
    gaParam: function () {
      return (this.is_manga ? 'mid' : 'aid') + ':' + this.id;
    }
  },
  data() {
    return {
      is_manga: this.type === 'manga',
      is_anime: this.type === 'anime',
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../../../../css/_variables.scss";

.title {
  word-break: normal;
}

.sp {

  .manga-store-preview {
    display: table;
  }

  .manga-store-preview-thumb {
    display: table-cell;

    img {
      height: 70px;
      width: 50px;
    }
  }

  .manga-store-preview-info {
    width: 100%;
    box-sizing: border-box;
    display: table-cell;
    padding-left: 8px;
    position: relative;
    text-align: left;
    vertical-align: top;

    .title {
      font-size: 1.5rem;
    }

    .volume {
      margin-top: 0.75rem;
      display: inline-block;
      color: $color-sp-text-888888;
    }

    .mal-btn {
      position: absolute;
      bottom: 0;
      border-radius: 2px;
      margin: 0;
    }
  }
}

.pc {
  .mal-btn {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .volume {
    display: inline-block;
    color: $color-pc-text-888888;
  }
}

</style>
