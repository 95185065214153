if ('customElements' in window) {
  customElements.define('mal-score-selector', class extends HTMLElement {
    constructor() {
      super();
      this.isOpen = false;
      this.selectValue = this.dataset.filterValue;
      this.disabled = this.dataset.disabled ? this.dataset.disabled : false;
      this.outFocusFunction = (e) => {
        if (this.isOpen && !e.target.closest('mal-score-selector')) {
          this.isOpen = false;
          this.render();
        }
      }

      this.render();

      $(this).on('click', 'button', (ev) => {
        ev.stopImmediatePropagation();
        this.isOpen = !this.isOpen;
        this.render();
      })
      $(this).on('click', 'p.selector', ({currentTarget}) => {
        const value = currentTarget.dataset.value;
        const isUpdate = this.selectValue !== value;
        this.selectValue = value;
        this.close();
        this.render();
        if (isUpdate) {
          $('button', this).trigger('mal-select-value');
        }
      })
    }

    render() {
      if ($('#mal-score-selector-tmpl').length === 0) {
        throw new DOMException("#mal-score-selector-tmplが存在しません。全体に読み込みを行っていないため個別にtwig読み込みをしてください。");
      }
      this.innerHTML = $.templates('#mal-score-selector-tmpl').render({
        isOpen: this.isOpen,
        selectValue: this.selectValue,
        active: this.selectValue > 0, ...this.dataset,
        disabled: this.disabled
      });
    }

    close() {
      this.isOpen = false;
    }

    /**
     * Runs each time the element is appended to or moved in the DOM
     */
    connectedCallback() {
      document.addEventListener('click', this.outFocusFunction);
    }

    /**
     * Runs when the element is removed from the DOM
     */
    disconnectedCallback() {
      document.removeEventListener('click', this.outFocusFunction);
    }
  });
}
