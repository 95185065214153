<template>
  <div class="text-payment-policy-container">
    <div class="text-payment-policy" v-html="termsHtml"></div>

    <input v-model="checked" type="checkbox" id="agreement" :class="{ checked: checked}">
    <label tabindex="7" for="agreement" class="btn-supporter-policy mt12">
      I have read and agree to the Terms of Use Agreement.
    </label>

    <ul class="text-payment-notice">
      <li>All payments are non-refundable.</li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script type="text/babel">
  export default {
    props: {
      termsHtml: String,
      checkedAgreement: Boolean,
    },
    data() {
      return {
        checked: this.checkedAgreement,
      }
    },
    watch: {
      checked() {
        this.$emit('update:checkedAgreement', this.checked)
      }
    },
  }
</script>
