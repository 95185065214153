import qs from 'qs'
const $ = window.$


$(function () {
  $('#submit').off("click").on('click', function () {
    const $result = $('#result')
    const $submit = $('#submit')

    // button is disabled
    $submit.prop('disabled', true)

    // input values
    let issueType = $('select[name="issue_type"] option:selected').val()
    const username = $('input[name="user_name"]').val()
    const password = $('input[name="password"]').val()
    const emailAddress = $('input[name="email_address"]').val()
    const emailConfirm = $('input[name="email_address_confirmation"]').val()

    // check required values
    if (!issueType || issueType === 'select' || !username || !password || !emailAddress || !emailConfirm) {
      $result.text('Please ensure that all required fields are completed.')
      $result.removeClass('rescue-result--is-hidden')
      $result.addClass('rescue-result--is-visible')
      $submit.prop('disabled', false)
      return false
    }

    // mail address
    if (emailAddress !== emailConfirm) {
      $result.text('Your email addresses don’t match.')
      $result.removeClass('rescue-result--is-hidden')
      $result.addClass('rescue-result--is-visible')
      $submit.prop('disabled', false)
      return false
    }

    grecaptcha.ready(function(){
      grecaptcha.execute(window.GRECAPTCHA_SITE_KEY, {action: 'homepage'}).then(function(token){
        $.ajax({
          url: './rescue/submit',
          type:'POST',
          cache: false,
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          dataType: 'json',
          data: qs.stringify({
            'g-recaptcha-response': token,
            'issue_type': issueType | 0,
            'user_name': username,
            'password': password,
            'email_address': emailAddress
          })
        })
        .done((data) => {
          location.href = './rescue/thanks'
        })
        .fail((data) => {
          const json = $.parseJSON(data.responseText)
          $result.text(json.errors[0].message)
          $result.removeClass('rescue-result--is-hidden')
          $result.addClass('rescue-result--is-visible')
          $submit.prop('disabled', false)
        });
      });
    });

    return false
  })
})
