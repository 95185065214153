const COOKIE_KEY = "affiliate_amazon_list"
export default ($cookie) => {
  const o = {
    $cookie,
    getWishList: () => {
      try {
        return JSON.parse($cookie.get(COOKIE_KEY) || "{}")
      } catch (e) {
        return {}
      }
    },
    saveWishList: (list) => {
      $cookie.set(COOKIE_KEY, JSON.stringify(list))
    }
  }

  return {
    isWishList: (id) => {
      return o.getWishList()[id]
    },
    addWishList: (id) => {
      const v = o.getWishList()
      v[id] = 1
      o.saveWishList(v)
    }
  }
}
