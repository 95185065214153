import elVis from "../../../common/element-visibility"

const $ = window.$
const sVue = window.sVue

$(() => {

  // Recommendationのread more, show lessのボタン
  if ($(".js-toggle-recommendation-button").exists()) {
    $(".js-toggle-recommendation-button").on("click", function () {
      const id = $(this).data("id")
      const $text = $(`#recommend${id}`)
      $text.toggle()
      $(this).html($text.is(":visible") ? "show less" : "read more")
    })
  }

  // Auto recommendation
  $(".js-auto-recommendation").each(
    function () {

      const id = this.id
      const placement = this.getAttribute("data-placement")
      const initialData = this.getAttribute("data-initial-data")

      var timeoutId = null


      const width = 124
      const margin = 7
      const perPage = Math.round(this.offsetWidth / (width + margin))


      const autoRecs = new sVue({
        el: `#${id}`,
        data: {
          width: width,
          margin: margin,
          perPage: perPage,
          currentPage: 0,
          isFirstPage: true,
          isLastPage: false,
          isLoading: true,
          recs: [],
        },
        mounted: function() {
          let data = JSON.parse(initialData)
          if (data === null) {
            $.ajax({
              url: "auto_recommendation/personalized_suggestions.json",
              data: {
                placement: placement
              },
              success: response => {
                this.recs = response
                this.isLoading = false
              }
            })
          } else {
            this.recs = data
            this.isLoading = false
          }
        },
        methods: {
          onClick: function (index) {
            this.logClick(index)
            const url = this.recs[index].path
            location.href = url
          },
          onContextMenu: function (index) {
            this.logClick(index)
          },
          logClick: function(index) {
          },
          scroll: function (direction) {
            if ((direction === "left" && this.isFirstPage) || (direction === "right" && this.isLastPage)) {
              return
            }

            this.currentPage = this.currentPage + ((direction === "right") ? 1 : -1)
            this.isFirstPage = this.currentPage === 0 ? true : false
            this.isLastPage  = this.currentPage === (Math.ceil(this.recs.length / this.perPage) - 1) ? true : false
          }
        }
      })

    }
  )

})
