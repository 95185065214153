const $ = window.$

$(() => {
  const $submit = $(".js-submit-forum-search")
  const $keyword = $(`input[name="q"]`)
  const $userName = $(`input[name="u"]`)
  if ($submit.exists()) {
    const changeSubmit = function () {
      // userNameが指定されてないならkeywordが二文字上必須
      if ($userName.val().length === 0 && $keyword.val().length < 2) {
        $submit.addClass("notActive")
        $submit.prop("disabled", true)
        return
      }
      // userNameが指定されているならkeywordは何も指定しないか二文字以上必須
      if ($userName.val().length > 0 && $keyword.val().length === 1) {
        $submit.addClass("notActive")
        $submit.prop("disabled", true)
        return
      }
      $submit.removeClass("notActive");
      $submit.prop("disabled", false)
    }

    changeSubmit()

    $keyword.on("input", changeSubmit)
    $userName.on("input", changeSubmit)
  }
})
