(function (sceditor) {
    'use strict';

    sceditor.plugins.dragdroptext = function () {
        if (typeof window.FileReader === 'undefined') {
            return;
        }

        const base = this;
        let opts;
        let editor;
        let $container;

        function isAllowed(file) {
            // FF sets type to application/x-moz-file until it has been dropped
            if (file.type !== 'application/x-moz-file' && opts.allowedTypes &&
                opts.allowedTypes.indexOf(file.type) < 0) {
                return false;
            }
            return opts.isAllowed ? opts.isAllowed(file) : true;
        }

        function handleDragEnter(e) {
            const dt = e.originalEvent.dataTransfer;
            const files = dt.files.length || !dt.items ? dt.files : dt.items;
            const targets = {success:[], error:[]};
            for (let i = 0; i < files.length; i++) {
                // URLがドロップされたらブラウザ標準挙動に任せる
                if (files[i].kind === 'string') {
                    e.preventDefault();
                    return;
                }
                if (isAllowed(files[i])) {
                    targets.success.push(files[i]);
                } else {
                    targets.error.push(files[i]);
                }
            }
            if (opts.handleDragEnter) {
                opts.handleDragEnter(targets, $container);
            }
            e.stopPropagation();
            e.preventDefault();
        }

        function handleDragLeave(e) {
            if (opts.handleDragLeave) {
                opts.handleDragLeave($container);
            }
            e.stopPropagation();
            e.preventDefault();
        }

        function handleDrop(e) {
            const dt = e.originalEvent.dataTransfer;
            const files = dt.files.length || !dt.items ? dt.files : dt.items;
            const targets = {success:[], error:[]};
            for (let i = 0; i < files.length; i++) {
                // URLがドロップされたらブラウザ標準挙動に任せる
                if (files[i].kind === 'string') {
                    e.preventDefault();
                    return;
                }
                if (isAllowed(files[i])) {
                    targets.success.push(files[i]);
                } else {
                    targets.error.push(files[i]);
                }
            }
            if (opts.handleFiles) {
                opts.handleFiles(targets, $container, editor);
            }
            e.stopPropagation();
            e.preventDefault();
        }

        base.signalReady = function () {
            editor = this;
            opts = editor.opts.dragdroptext || {};
            $container = $(editor.getContentAreaContainer().parentNode);
            $container.on('dragover', handleDragEnter);
            $container.on('dragleave', handleDragLeave);
            $container.on('dragend', handleDragLeave);
            $container.on('drop', handleDrop);
        };
    };
})(sceditor);
