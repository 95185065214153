import Viewer from "./Viewer.vue"
import store from "./store/index"
import * as types from "./store/mutation-types"

const SVue = window.sVue

const $ = window.$
const selector = ".v-manga-store-viewer"

$(() => {
  const el = document.querySelector(selector)
  if (el) {
    const initialState = JSON.parse(el.getAttribute("data-state"))
    const banned = JSON.parse(el.getAttribute("data-banned"))
    const storeUrl = el.getAttribute("data-storeUrl")
    const paymentSettings = JSON.parse(el.getAttribute("data-settings"))
    const termsHtml = el.getAttribute("data-terms-html")
    const supportUrl = el.getAttribute("data-support-url")

    new SVue({
      el,
      store,
      render: h => h(Viewer),
      mounted() {
        // state の初期化
        this.$store.commit(types.INITIALIZE, {initialState, banned, storeUrl, paymentSettings, termsHtml, supportUrl})

        // チュートリアルを表示
        this.$store.dispatch(types.SHOW_TUTORIAL)

        // 画像を読み込む
        this.$store.dispatch(types.LOAD_IMAGES)
      },
    })
  }
})
