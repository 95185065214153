import * as book from "../../../common/modules/book"
import {getCurrency} from "../../../common/modules/currency"
import Slider from "./Slider.vue"

const $ = window.$
const SVue = window.sVue

$(() => {
  const _initSlider = (el, items, type, currency, options) => {
    new SVue({
      el,
      data: {
        items: [],
        type: null,
        currency: {},
        options: {},
      },
      render(h) {
        return h(Slider, {
          props: {
            items: this.items,
            type: this.type,
            currency: this.currency,
            options: this.options,
          }
        })
      },
      created() {
        this.items = items.map(i => {
          return {
            id: i.id,
            title: i.title,
            numberingText: i.numbering_text,
            url: i.url,
            viewerUrl: i.viewer_url,
            previewUrl: i.preview_url,
            isPossessed: i.is_possessed,
            isPreviewable: i.is_previewable,
            coverImageBaseUrl: i.cover_image_base_url,
            price: i.price,
            salePrice: i.sale_price,
            onPreorderAt: i.on_preorder_at,
            isNew: i.is_new,
            score: i.score,
            isFree: i.is_free,
            accessLimit: i.access_limit,
            saleBookId: i.sale_book_id,
            saleBookTitle: i.sale_book_title,
            reflowable: i.reflowable,
            onSaleAt: i.on_sale_at,
            currentTimestamp: i.current_timestamp
          }
        })
        this.type = type
        this.currency = currency || {}
        this.options = options || {}
      },
    })
  }

  // Volume
  Array.from(document.querySelectorAll(".v-manga-store-book-slider")).forEach(el => {
    const items    = JSON.parse(el.getAttribute("data-items"))
    const currency = getCurrency(JSON.parse(el.getAttribute("data-currency")))
    const options  = JSON.parse(el.getAttribute("data-options"))
    const type     = null
    _initSlider(el, items, type, currency, options)
  })

  // comics
  Array.from(document.querySelectorAll(".v-manga-store-comic-slider")).forEach(el => {
    const items   = JSON.parse(el.getAttribute("data-items"))
    const options = JSON.parse(el.getAttribute("data-options"))
    _initSlider(el, items, book.TYPE_COMIC, null, options)
  })

})
