import Log from "../../common/modules/log";

// anime/manga詳細からのクリックログを出す
if ($(".js-shop-anime")) {
  $(".js-shop-anime").on("click", function () {
    const data = $(this).data("shop")
    Log.actionLog(data.shop, {
      user_id : data.user_id,
      type : data.type,
      id: data.id,
    })
  })
}
