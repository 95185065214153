"use struct";

export default {
  send: (type, param) => {
    const data = {'event':'ga-js-event'};
    if (typeof type !== 'undefined') {
      data['ga-js-event-type'] = type;
    }
    if (typeof param !== 'undefined') {
      data['ga-js-event-param'] = param;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  },
  impression: (type, param) => {
    const data = {'event':'ga-impression'};
    if (typeof type !== 'undefined') {
      data['ga-impression-type'] = type;
    }
    if (typeof param !== 'undefined') {
      data['ga-impression-param'] = param;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  },
  loadready: (url) => {
    const data = {
      'event':'loadready',
      'trackPageview':url
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  },
}
