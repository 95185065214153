<template>
  <div class="root">
    <transition name="modal">
      <div v-show="show" class="modal-content">
        <div class="modal-mask" @click="hide"></div>
        <div class="modal-wrapper">
          <div v-if="isPreview">
            <div class="modal-container">
              <p class="modal-head">Purchase this manga to continue reading</p>
              <div class="modal-cover"
                :style="{backgroundImage: `url(${coverImageUrl})`}"
                :class="{iconNew: !nextBook.isPossessed && nextBook.isNew}"
              ></div>
              <p class="modal-title">{{ nextBook.title }}</p>
              <p class="modal-volume">{{ nextBook.numberingText }}</p>

              <div v-if="nextBook.isPossessed || nextBook.isFree">
                <a class="modal-button-read" @click="$emit('read')">Read {{ nextBook.numberingText }}</a>
              </div>
              <div v-else>
                <div v-if="nextBook.salePrice >= 0" class="modal-price-wrap">
                  <span class="modal-price is-sale">{{ currency.formatSymbolAndPrice(nextBook.price) }}</span>
                  <span class="modal-price-sale">Sale {{ currency.formatSymbolAndPrice(nextBook.salePrice) }}</span>
                </div>
                <div v-if="!(nextBook.salePrice >= 0)" class="modal-price-wrap">
                  <span class="modal-price">{{ currency.formatSymbolAndPrice(nextBook.price) }}</span>
                </div>
                <div class="modal-button-container">
                  <a class="modal-button-back-store" @click="$emit('backstore')">Buy on Store</a>
                  <a class="modal-button-back-manga" @click="$emit('backmanga')">Back to Manga page</a>
                </div>
              </div>

              <a class="modal-close" @click="hide">Close</a>
            </div>
          </div>
          <div v-else-if="isFree">
            <div v-if="customFinish.useFreeManga && customFinish.productId">
              <div class="modal-container-rectangle">
                <p class="modal-head-small">Tap the read button for <br />next chapter</p>
                <div style="height:170px;">
                    <div style="float:left;margin-right:10px;margin-left: 30px;">
                        <img :src="nextBookCoverImageUrl" width="156px">
                    </div>
                    <div style="padding-top:10px;padding-left:20px;overflow: hidden;"> 
                        <span class="modal-chapter">{{ nextBook.numberingText }}</span>
                    </div>
                    <div style="padding-top:10px;padding-left: 20px;overflow: hidden;">
                        <div>
                          <div v-if="nextBook.onSaleAt <= currentTimestamp && (nextBook.closeSaleAt >=currentTimestamp || nextBook.closeSaleAt == 0) ">
                            <a class="modal-button-read-special-small" @click="$emit('read')">Read</a>
                          </div>
                          <div v-else-if="nextBook.onSaleAt > 0 && nextBook.onSaleAt > currentTimestamp">
                            <span style="color:#9b9b9b;font-size:12px;">coming soon</span>
                          </div>
                          <div v-else-if="nextBook.closeSaleAt > 0 && nextBook.closeSaleAt < currentTimestamp">
                            <span style="color:#9b9b9b;font-size:12px;">not longer free</span>
                          </div>
                        </div>
                    </div>
                </div>
                <div v-if="isAdShown">
                    <div class="fb-page" style="text-align:center;">
                      <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FOfficialMyAnimeList&tabs&width=320&height=214&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=true&appId" width="320" height="214" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true"></iframe>
                    </div>
                </div>
                <div v-else>
                    <div class="fb-page" style="text-align:center;">
                      <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FOfficialMyAnimeList&tabs&width=320&height=214&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=true&appId" width="320" height="214" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true"></iframe>
                    </div>
                </div>
                <a class="modal-close" @click="hide">Close</a>
              </div>
            </div>
            <div v-else>
                <div v-if="customFinish.url">
                  <div class="modal-container">
                    <iframe width="100%" height="409px" frameborder="0" :scrolling="customFinish.scroll ? 'auto' : 'no'" :src="customFinish.url"></iframe>
                    <a class="modal-close" @click="hide">Close</a>
                  </div>
                </div>
                <div v-else-if="customFinish.productId">
                  <div class="modal-container">
                    <p class="modal-head">Purchase this manga to continue reading</p>
                    <div class="modal-cover"
                         :style="{backgroundImage: `url(${nextBookCoverImageUrl})`}"
                         :class="{iconNew: !nextBook.isPossessed && nextBook.isNew}"
                    ></div>
                    <p class="modal-title">{{ nextBook.title }}</p>
                    <p class="modal-volume">{{ nextBook.numberingText }}</p>

                    <div v-if="nextBook.isPossessed || nextBook.isFree">
                      <a class="modal-button-read" @click="$emit('read')">Read {{ nextBook.numberingText }}</a>
                    </div>
                    <div v-else>
                      <div v-if="nextBook.salePrice >= 0" class="modal-price-wrap">
                        <span class="modal-price is-sale">{{ currency.formatSymbolAndPrice(nextBook.price) }}</span>
                        <span class="modal-price-sale">Sale {{ currency.formatSymbolAndPrice(nextBook.salePrice) }}</span>
                      </div>
                      <div v-if="!(nextBook.salePrice >= 0)" class="modal-price-wrap">
                        <span class="modal-price">{{ currency.formatSymbolAndPrice(nextBook.price) }}</span>
                      </div>
                      <div class="modal-button-container">
                        <a class="modal-button-back-store" @click="$emit('backstore')">Buy on Store</a>
                        <a class="modal-button-back-manga" @click="$emit('backmanga')">Back to Manga page</a>
                      </div>
                    </div>

                    <a class="modal-close" @click="hide">Close</a>
                  </div>
                </div>
            </div>
          </div>
          <div v-else>
            <div v-if="nextBook">
              <div class="modal-container">
                <p class="modal-head">{{ book.numberingText }} Complete</p>

                <div class="modal-cover"
                  :style="{backgroundImage: `url(${nextBookCoverImageUrl})`}"
                  :class="{iconNew: !nextBook.isPossessed && nextBook.isNew}"
                ></div>
                <p class="modal-title">{{ nextBook.title }}</p>
                <div v-if="nextBook.isPossessed || nextBook.isFree">
                  <a class="modal-button-read" @click="$emit('read')">Read {{ nextBook.numberingText }}</a>
                </div>
                <div v-else>
                  <p class="modal-volume">{{ nextBook.numberingText }}</p>
                  <template v-if="!banned.isBanned">
                    <div v-if="nextBook.salePrice >= 0" class="modal-price-wrap">
                      <span class="modal-price is-sale">{{ currency.formatSymbolAndPrice(nextBook.price) }}</span>
                      <span class="modal-price-sale">Sale {{ currency.formatSymbolAndPrice(nextBook.salePrice) }}</span>
                    </div>
                    <div v-if="!(nextBook.salePrice >= 0)" class="modal-price-wrap">
                      <span class="modal-price">{{ currency.formatSymbolAndPrice(nextBook.price) }}</span>
                    </div>
                    <a v-if="!nextBook.isPossessed"
                      class="modal-button-buy" @click="$emit('purchase')">Buy</a>
                    <a v-if="nextBook.isPreviewable"
                      class="modal-button-trial" @click="$emit('preview')">Preview</a>
                  </template>
                  <div v-if="banned.isBanned" class="modal-ban">
                    <span class="modal-text-ban">This account has been restricted from making purchases.</span><br>
                    <a class="modal-button-ban" :href="banned.storeUrl">More about your ban</a>
                  </div>
                </div>
                <a class="modal-close" @click="hide">Close</a>
              </div>
            </div>
            <div v-else>
              <div class="modal-container">
                <p class="modal-head">{{ book.numberingText }} Complete</p>
                <div class="modal-cover"
                  :style="{ backgroundImage: `url(${coverImageUrl})` }"
                ></div>
                <a class="modal-button-submit" :href="book.storeUrl">Manga Store</a>
                <a class="modal-close" @click="hide">Close</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  .modal-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
  }
  .modal-mask{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    font-family: Verdana,Arial;
    position: relative;
    width: 610px;
    margin: 0 auto;
    padding: 40px;
    box-sizing: border-box;
    background-color: #fff;
    color: #353535;
    border-radius: 4px;
    transition: all .3s ease;
  }
  .modal-container-rectangle {
    font-family: Verdana,Arial;
    position: relative;
    width: 480px;
    height: 524px;
    margin: 0 auto;
    padding: 40px;
    box-sizing: border-box;
    background-color: #fff;
    color: #353535;
    border-radius: 4px;
    transition: all .3s ease;
  }
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    content: '';
    display: block;
    width: 29px;
    height: 29px;
    background: url(/images/manga_store/viewer/pc/modal_close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
    cursor: pointer;
  }
  .modal-head {
    font-weight: 700;
    text-align: center;
    font-size: 20px;
    margin: 0 0 38px;
  }
  .modal-head-small {
    font-weight: 700;
    text-align: center;
    font-size: 19px;
    margin: 0 0 25px;
  }
  .modal-cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    display: block;
    width: 113px;
    height: 170px;
    margin: 0 auto;
    box-sizing: border-box;
    &.iconNew:after {
      position: absolute;
      top: 1px;
      left: 1px;
      background-image: url('/images/manga_store/icon_new.png');
      background-size: 47px 47px;
      background-position: left top;
      background-repeat: no-repeat;
      display: inline-blockl;
      width: 47px;
      height: 47px;
      content: "";
    }
  }
  .modal-title {
    display: block;
    margin: 20px auto 0;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
  }
  .modal-volume {
    display: block;
    margin: 10px auto;
    font-size: 14px;
    text-align: center;
  }
  .modal-price-wrap {
    width: 250px;
    display: block;
    margin: 10px auto;
    font-size: 14px;
    text-align: center;
    color: #C0392B;
    font-weight: 700;
  }
  .modal-price {
    &.is-sale {
      color: #323232;
      font-size: 12px;
      font-weight: 300;
      text-decoration: line-through;
    }
  }

  .modal-button-submit,
  .modal-button-read {
    display: block;
    width: 164px;
    height: 40px;
    margin: 20px auto 0;
    background-color: #fff;
    color: #2e51a2;
    border: 2px solid #2e51a2;
    border-radius: 3px;
    line-height: 36px;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background-color: rgba(#2e51a2, 0.2);
    }
  }
  .modal-button-read-special {
    display: block;
    width: 164px;
    height: 40px;
    margin: 20px auto 0;
    background-color: #2e51a2;
    color: #fff;
    border: 2px solid #2e51a2;
    border-radius: 3px;
    line-height: 36px;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background-color: rgba(#2e51a2, 0.2);
    }
  }
  .modal-button-read-special-small {
    display: block;
    width: 128px;
    height: 40px;
    margin: 5px auto 0;
    background-color: #5675C2;
    color: #fff;
    border: 2px solid #5675C2;
    border-radius: 3px;
    line-height: 40px;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background-color: rgba(#2e51a2, 0.2);
    }
    float:left;
    margin-left: 20px;
  }
  .modal-button-buy {
    display: block;
    width: 164px;
    height: 40px;
    margin: 20px auto 0;
    background-color: #fff;
    box-sizing: border-box;
    border: 2px solid #ff9600;
    color: #ff9600;
    border-radius: 3px;
    line-height: 36px;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      background-color: rgba(#ff9600, 0.2);
    }
  }
  .modal-button-container {
    margin: 20px auto;
    text-align: center;
  }
  .modal-button-back-store,
  .modal-button-back-manga {
    display: inline-block;
    width: 220px;
    height: 40px;
    margin: 0 20px;
    padding: 10px 16px;
    box-sizing: border-box;
    border-radius: 3px;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    color: #ffffff;
  }
  .modal-button-back-store {
    background-color: #ee8e05;
    &:hover {
      background-color: rgba(#ee8e05, 0.8);
    }
  }
  .modal-button-back-manga {
    background-color: #2e51a2;
    &:hover {
      background-color: rgba(#2e51a2, 0.8);
    }
  }
  .modal-button-trial {
    display: block;
    width: 164px;
    height: 40px;
    margin: 20px auto 0;
    background-color: #fff;
    box-sizing: border-box;
    border: 2px solid #2E51A2;
    color: #2E51A2;
    border-radius: 3px;
    line-height: 36px;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      background-color: rgba(#2e51a2, 0.2);
    }
  }
  .modal-text-ban {
    display: block;
    color: #ff6a86;
    font-size: 12px;
    text-align: center;
  }
  .modal-button-ban {
    display: block;
    width: 230px;
    height: 40px;
    margin: 0 auto;
    background-color: #fff;
    box-sizing: border-box;
    border: 2px solid #2E51A2;
    color: #2E51A2;
    border-radius: 3px;
    line-height: 36px;
    text-align: center;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
  }
  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .modal-chapter {
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    font-weight: bold;
  }

</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"
  import {generateCoverImageUrl} from "../../../../../components/manga_store/common/modules/book"
  import * as consts from "../../common/constants"

  export default {
    props: {
      show: Boolean,
      book: Object,
      nextBook: Object,
      currency: Object,
      isPreview: Boolean,
      isFree: Boolean,
      isAdShown: Boolean,
      currentTimestamp: Number,
      customFinish: Object,
      banned: Object,
    },
    computed: {
      coverImageUrl() {
        return generateCoverImageUrl(this.book.coverImageBaseUrl)
      },
      nextBookCoverImageUrl() {
        return generateCoverImageUrl(this.nextBook.coverImageBaseUrl)
      },
    },
    watch: {
      show() {
        if (this.show) {
          this.$store.dispatch(types.LOG, {
            type: consts.LOG_TYPE_FINISH_MODAL,
            payload: {
              preview: this.isPreview,
              next: !!this.nextBook,
              possess: this.nextBook ? !!this.nextBook.isPossessed : false,
            }
          })
        }
      }
    },
    methods: {
      hide() {
        this.$store.commit(types.HIDE_FINISH_MODAL)
      },
    },
  }
</script>
