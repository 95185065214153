import AffiliateAmazonBox from "./AffiliateAmazonBox.vue"
import AffiliateAmazonButton from "./AffiliateAmazonButton.vue"
import AffiliateAmazonLink from "./AffiliateAmazonLink.vue"

const $ = window.$
const SVue = window.sVue

$(() => {
  const loadComponent = (selector, VueComponent) => {
    if ($(selector).length > 0) {
      $(selector).each((index, $el) => {
        const v = $($el).data("v")
        const vGaType = $($el).data("v-ga-type")

        new SVue({
          el: selector,
          render: h => h(VueComponent, {
            // eslint-disable-next-line camelcase
            props: {...v, ga_type: vGaType}
          }),
        })
      })
    }
  }
  loadComponent(".v-affiliate-amazon-box", AffiliateAmazonBox)
  loadComponent(".v-affiliate-amazon-button", AffiliateAmazonButton)
  loadComponent(".v-affiliate-amazon-link", AffiliateAmazonLink)
})
