import { render, staticRenderFns } from "./ButtonDetail.vue?vue&type=template&id=4ecd1852&scoped=true&"
import script from "./ButtonDetail.vue?vue&type=script&lang=js&"
export * from "./ButtonDetail.vue?vue&type=script&lang=js&"
import style0 from "./ButtonDetail.vue?vue&type=style&index=0&id=4ecd1852&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ecd1852",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/work/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ecd1852')) {
      api.createRecord('4ecd1852', component.options)
    } else {
      api.reload('4ecd1852', component.options)
    }
    module.hot.accept("./ButtonDetail.vue?vue&type=template&id=4ecd1852&scoped=true&", function () {
      api.rerender('4ecd1852', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/vue/components/manga_store/common/atoms/manga_store/ButtonDetail.vue"
export default component.exports