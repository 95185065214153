export const INITIALIZE = "INITIALIZE"
export const MOVE_PAGE = "MOVE_PAGE"
export const PURCHASE = "PURCHASE"
export const PURCHASED = "PURCHASED"
export const PURCHASE_START = "PURCHASE_START"
export const PURCHASE_FAILED = "PURCHASE_FAILED"
export const READ = "READ"
export const LOAD_MORE = "LOAD_MORE"
export const LOAD_MORE_START = "LOAD_MORE_START"
export const ADD_ITEMS = "ADD_ITEMS"
export const SET_TAX = "SET_TAX"
