import AllBooks from "./AllBooks.vue"
import {createStore} from "../../../common/stores/all_books/factory"

const SVue = window.sVue
const $ = window.$

const selector = ".v-manga-store-all-books"

$(() => {
  const _initAllBooks = (el) => {
    const options = JSON.parse(el.getAttribute("data-options"))
    new SVue({
      el,
      store: createStore(el),
      render: h => h(AllBooks, {
        props: {options},
      }),
    })
  }

  Array.from(document.querySelectorAll(selector)).forEach(el => {
    _initAllBooks(el)
  })

  // tabのtoggle
  Array.from(document.querySelectorAll(".js-comics-list-tab-item")).forEach(el => {
    el.addEventListener("click", (e) => {
      const classListValue = el.getAttribute("class")
      if (classListValue.indexOf("is-active") < 0) {
        // tab
        Array.from(document.querySelectorAll(".js-comics-list-tab-item")).forEach(el => {
          el.classList.toggle("is-active")
        })
        // Vols,Chaps List
        Array.from(document.querySelectorAll(".js-comics-list-cont")).forEach(el => {
          el.classList.toggle("is-active")
        })
      }
    })
  })
})
