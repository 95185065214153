const $ = window.$
import incrementalSearch from "../../../common/modules/incrementalSearch"

$(function() {
  const $pmSearch = $("#pfriendadd");
  if ($pmSearch.exists()) {
    incrementalSearch.initializeVueModel("#pfriendadd", {
      type: "user",
      query: "friend_name",
      resultPosition: {
        left: 0,
        top: $(".js-pfriend-add").outerHeight(),
        width: $(".js-pfriend-add").outerWidth(),
      },
      generateItemPageUrl(item) {
        return `/myfriends.php?go=add&friend_name=${encodeURIComponent(item.name)}`
      }
    })
  }
})
