"use struct";

import * as login from './modules/gtm/login'
import * as register from './modules/gtm/register'
import * as membership from './modules/gtm/membership'

const MAL = window.MAL;

/*
 * do
 */
window.onload = function() {
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-' + MAL.GTM_ID);
  // End Google Tag Manager

  var path = location.pathname;
  var param = location.search;

  dataLayer = dataLayer || [];

  /*
   * login
   */
  // 2-2-1.メールからの会員認証ページ
  login.login(path, param, dataLayer);

  /*
   * register
   */
  // 2-2-1.会員情報入力完了
  register.register(path, dataLayer);

  /*
   * membership
   */
  // 3-1-2.MAL会員登録成功
  membership.membership_complete(path, dataLayer);
};
