
import * as types from "./mutation-types"
import {createBook} from "../../../../common/modules/book"
import {appendTax} from "../../../../common/modules/tax"

export default {
  [types.INITIALIZE](state, {firstItem, items, itemType, loginPath, currency, bookshelfComicPath, paymentSettings, termsHtml, supportUrl}) {
    state.loginPath = loginPath
    state.currency = currency

    const bookItems = items ? items.map(i => createBook(i)) : null
    state.firstItem = firstItem ? createBook(firstItem) : null
    state.items = bookItems
    state.checkedItemIds = bookItems.filter(i => !i.isPossessed && !i.isFree).map(i => i.id)
    state.itemType = itemType
    state.bookshelfComicPath = bookshelfComicPath

    // Paymentの各種設定情報
    state.payment = {
      defaultPaymentMethod: paymentSettings.defaultPaymentMethod,
      initialPaymentMethod: paymentSettings.initialPaymentMethod,
      registeredCard: paymentSettings.registeredCard ? {
        type: paymentSettings.registeredCard.type,
        digits: paymentSettings.registeredCard.digits,
        isExpired: paymentSettings.registeredCard.is_expired,
        isUsedForMalSupporter: paymentSettings.registeredCard.is_used_for_mal_supporter,
      } : null,
      braintreeRegistrationDatetime: paymentSettings.braintreeRegistrationDatetime,
    }
    state.termsHtml = termsHtml
    state.supportUrl = supportUrl
  },

  [types.UPDATE_CHECKED_IDS](state, ids) {
    state.checkedItemIds = ids
  },

  [types.IS_PURCHASED](state, status) {
    state.isPurchased = status
  },

  [types.READ]() {
    window.location.href = "/store/bookshelf"
  },

  [types.SET_TAX](state, taxes) {
    state.items = state.items.map(i => appendTax(i, taxes))
  },

  [types.UPDATE_ITEMS](state, ids) {
    state.items.forEach((item, index) => {
      if (ids.indexOf(item.id) > -1) {
        state.items[index]["isPossessed"] = true
      }
    })
    state.isPurchased = true
  },

  /**
   * Modal の表示非表示系
   */
  [types.SHOW_PAYMENT_MODAL](state) {
    state.modals.showPaymentModal = true
  },
  [types.HIDE_PAYMENT_MODAL](state) {
    state.modals.showPaymentModal = false
  },
}
