"use strict";

const { trim } = require("lodash");

const $ = window.$;
const _ = window._;

module.exports = {
  show: function() {
    $('.mal-modal').trigger('open');
  },
  hide: function() {
    $('.mal-modal').trigger('close');
  },
  clear: function() {
    $('.mal-modal').trigger('clear');
  },
  generate: function() {
    if ($('.mal-modal').length > 0) {
      this.clear();
      this.hide();
      return;
    }
    const backdrop = $('<div class="mal-modal-backdrop" />');
    const modal = $('<div class="mal-modal" />');
    const dialog = $('<div class="mal-modal-dialog" />');
    const content = $('<div class="mal-modal-content" />');
    content.append('<div class="mal-modal-header" />');
    content.append('<div class="mal-modal-body" />');
    content.append('<button type="button" class="btn-close" aria-label="Close"><i class="fa-solid fa-times"></i></button>');
    content.appendTo(dialog);
    modal.append(dialog).appendTo('body');
    backdrop.appendTo('body');
    modal
      .on('clear', function(){
        $('.mal-modal-header', this).empty();
        $('.mal-modal-body', this).empty();
      })
      .on('close', function(){
        $(this).trigger('beforeclose').off('beforeclose');
        $(this).removeClass('show');
        $('html').removeClass('show-modal');
        $('html').css('margin-right', '');
        $('.mal-modal-backdrop').removeClass('show');
        $(document).off('keydown.mal-modal-backdrop');
        $(document).off('click.mal-modal-backdrop');
      })
      .on('open', function(){
        const scrollBarWidth = window.innerWidth - $(window).width();
        $(this).addClass('show');
        $('html').addClass('show-modal');
        $('html').css('margin-right', `${scrollBarWidth}px`);
        $('.mal-modal-backdrop').addClass('show');
        $(document).on('keydown.mal-modal-backdrop', function(e) {
          if (e.keyCode === 27) {
            $('.mal-modal').trigger('close');
          }
          if (e.keyCode === 13) {
            $('.mal-modal').trigger('confirm');
          }
        });
        $(document).on('click.mal-modal-backdrop', function(e) {
          if ($(e.target).is('div.mal-modal.show') ||
            $(e.target).is('i.fa-solid.fa-times')) {
            $('.mal-modal').trigger('close');
          }
        });
      });
    $('.btn-close', content).on('click', function() {
      modal.trigger('close')
    });
    window.MAL.modal = this;
  },
  alert: function(message, code) {
    if (!message) return;
    this.generate();
    const modal = $('.mal-modal');
    const dialog = $('.mal-modal .mal-modal-dialog');
    const header = $('.mal-modal-header', dialog);
    const body = $('.mal-modal-body', dialog);
    const bodyWrapper = $('<div class="content"></div>');
    const footerWrapper = $('<div class="footer"></div>');
    const buttonsWrapper = $('<div class="mal-btn-toolbar"></div>');

    // タイトル
    header.empty();
    const titleWrapper = $('<div class="title"></div>');
    titleWrapper.text('MyAnimeList');
    header.append(titleWrapper);
    body.empty();
    bodyWrapper.append(message.replaceAll("\n", "<br>\n"));
    if (code) {
      bodyWrapper.append(`<br>(Code: ${code})`);
    }
    body.append(bodyWrapper);
    const mybutton = $('<button type="button" class="mal-btn primary">OK</button>');
    mybutton.on('click', function () {
      modal.trigger('close');
    });
    modal.on('confirm', function () {
      modal.trigger('close');
    });
    buttonsWrapper.append(mybutton);
    footerWrapper.append(buttonsWrapper);
    body.append(footerWrapper);
    modal.trigger('open');
  },
  showtext: function(message, title, buttons) {
    if (!message) return;
    if (!buttons) buttons = [];
    this.generate();
    const modal = $('.mal-modal');
    const dialog = $('.mal-modal .mal-modal-dialog');
    const header = $('.mal-modal-header', dialog);
    const body = $('.mal-modal-body', dialog);
    const bodyWrapper = $('<div class="content"></div>');
    const textWrapper = $('<textarea class="showtext" readonly></textarea>');
    const footerWrapper = $('<div class="footer"></div>');
    const buttonsWrapper = $('<div class="mal-btn-toolbar"></div>');

    // タイトル
    header.empty();
    const titleWrapper = $('<div class="title"></div>');
    titleWrapper.text((title) ? title : 'MyAnimeList');
    header.append(titleWrapper);
    body.empty();
    textWrapper.text(message);
    textWrapper.on('focus',function () {
      $(this).select();
    });
    bodyWrapper.append(textWrapper);
    body.append(bodyWrapper);
    for (const button of buttons) {
      const $button = $('<button type="button" class="mal-btn secondary outline noborder"></button>');
      $button.text(button.title);
      $button.addClass(button.class);
      buttonsWrapper.append($button);
    }
    const mybutton = $('<button type="button" class="mal-btn primary">OK</button>');
    mybutton.on('click', function () {
      modal.trigger('close');
    });
    modal.on('confirm', function () {
      modal.trigger('close');
    });
    buttonsWrapper.append(mybutton);
    footerWrapper.append(buttonsWrapper);
    body.append(footerWrapper);
    modal.trigger('open');
  },
  confirm: function(options) {
    this.generate();
    const modal = $('.mal-modal');
    const dialog = $('.mal-modal .mal-modal-dialog');
    const header = $('.mal-modal-header', dialog);
    const body = $('.mal-modal-body', dialog);
    const bodyWrapper = $('<div class="content"></div>');
    const footerWrapper = $('<div class="footer"></div>');
    const buttonsWrapper = $('<div class="mal-btn-toolbar"></div>');

    // タイトル
    header.empty();
    if (options.title) {
      const titleWrapper = $('<div class="title"></div>');
      titleWrapper.append(options.title);
      header.append(titleWrapper);
    }

    // 本文
    if (options.message) {
      bodyWrapper.append(options.message);
    }

    // OKボタン
    if (options.confirm) {
      const button = options.confirm;
      const mybutton = $(`<button type="button" class="mal-btn"></button>`);
      if (typeof button === 'function') {
        modal.off('confirm').on('confirm', function () {
          button();
          modal.off('beforeclose').trigger('close');
        });
        mybutton.text('OK');
        mybutton.addClass('primary');
      } else {
        modal.off('confirm').on('confirm', function () {
          button.action();
          modal.off('beforeclose').trigger('close');
        });
        mybutton.text(button.text ? button.text : 'OK');
        mybutton.addClass((button.class) ? button.class : 'primary');
      }
      mybutton.on('click', function () {
        modal.trigger('confirm');
      });
      buttonsWrapper.append(mybutton);
    }

    // キャンセルボタン
    if (options.cancel) {
      const button = options.cancel;
      const mybutton = $(`<button type="button" class="mal-btn"></button>`);
      if (typeof button === 'function') {
        modal.off('cancel').on('cancel', function () {
          button();
          modal.off('beforeclose').trigger('close');
        });
        mybutton.text('Cancel');
        mybutton.addClass('secondary outline');
      } else {
        modal.off('cancel').on('cancel', function () {
          button.action();
          modal.off('beforeclose').trigger('close');
        });
        mybutton.text(button.text ? button.text : 'Cancel');
        mybutton.addClass((button.class) ? button.class : 'secondary outline');
      }
      mybutton.on('click', function () {
        modal.trigger('close');
      });
      modal.off('beforeclose').on('beforeclose', function() {
        modal.trigger('cancel');
      });
      buttonsWrapper.append(mybutton);
    }

    footerWrapper.append(buttonsWrapper);
    body.empty();
    body.append(bodyWrapper);
    body.append(footerWrapper);
    modal.trigger('open');
  },
  buildCommonDialog: function(title, content) {
    const dialog = $('.mal-modal .mal-modal-dialog');
    const header = $('.mal-modal-header', dialog);
    const body = $('.mal-modal-body', dialog);
    const titleWrapper = $('<div class="row row1"><div class="title"></div></div>');
    const bodyWrapper = $('<div class="content"></div>');
    titleWrapper.append(title);
    header.empty();
    header.append(titleWrapper);
    bodyWrapper.append(content);
    body.empty();
    body.append(bodyWrapper);
  },
  buildBroadcastDialog: function(title, subtitle, broadcasts) {
    const dialog = $('.mal-modal .mal-modal-dialog');
    const header = $('.mal-modal-header', dialog);
    const body = $('.mal-modal-body', dialog);

    // ヘッダーを作成する
    header.empty();
    header.append('<div class="row row1"><div class="caption">Where to watch...</div></div>');
    header.append('<div class="row row2 reverse"><label><input type="checkbox" class="js-broadcast-hide-unavailable" checked="checked"></input> Hide unavailable</label></div>');
    $('<div class="title" />').text(title).appendTo('.row1', header);
    if (subtitle != null) {
      $('<div class="subtitle" />').text(subtitle).appendTo('.row1', header);
    }

    // ボディを作成する
    body.empty();
    // データが存在しない場合（本来呼ばれない）
    if (!broadcasts || broadcasts.count.total < 1) {
      body.append('<div class="nodata">There doesn\'t seem to be any streams available in your region.</div>');
      return;
    }
    // 優先データ
    if (broadcasts.count.typicals > 0) {
      const typicals = $('<ul class="broadcasts typicals" />');
      for (let n in broadcasts.data) {
        let data = broadcasts.data[n];
        if (data === undefined || data['platform']['type'] != 1) continue;
        const broadcast_item = $(`<li class="broadcast" data-id="${n}" data-available="${data['available']}" />`);
        broadcast_item.append(`<a href="${data['url']}" class="ga-click" data-ga-click-type="broadcast-popup-platforms"><i class="spicon spicon-${data['platform']['icon']}"></i><div class="caption">${data['platform']['name']}</div></a>`);
        broadcast_item.appendTo(typicals);
      }
      typicals.appendTo(body);
    }
    // 通常データ
    if (broadcasts.count.others > 0) {
      const others = $('<ul class="broadcasts others" />');
      for (let n in broadcasts.data) {
        let data = broadcasts.data[n];
        if (data === undefined || data['platform']['type'] != 2) continue;
        const broadcast_item = $(`<li class="broadcast" data-id="${n}" data-available="${data['available']}" />`);
        broadcast_item.append(`<a href="${data['url']}"><i class="spicon spicon-${data['platform']['icon']}"></i><div class="caption">${data['platform']['name']}</div></a>`);
        broadcast_item.appendTo(others);
      }
      others.appendTo(body);
    }
    // Hide unavailableボタンの動作を初期化する（コンテンツ追加後に実行）
    $('.js-broadcast-hide-unavailable').off('change.unavailable').on('change.unavailable', function() {
      if ($(this).is(':checked')) {
        $('li.broadcast[data-available=false]').hide();
      } else {
        $('li.broadcast[data-available=false]').show();
      }
      $('ul.broadcasts').show().each(function(){
        if ($('li.broadcast:visible', this).length == 0) {
          $(this).hide();
        }
      });
      if ($('ul.broadcasts:visible').length == 0) {
        $('.mal-modal .mal-modal-dialog .mal-modal-body').append('<div class="nodata">There doesn\'t seem to be any streams available in your region.</div>');
      }
      else {
        $('.mal-modal .mal-modal-dialog .mal-modal-body .nodata').remove();
      }
    }).trigger('change');
  },
  buildVoteDialog: function(dataId, episodeNum, title, topicId, topicType, showDontAsk) {
    const modal = $('.mal-modal');
    const dialog = $('.mal-modal-dialog', modal);
    const header = $('.mal-modal-header', dialog);
    const body = $('.mal-modal-body', dialog);
    const execVote = function(topicId, score) {
      $(`a.modal-vote-score-link[rel=${score}] i.fa-solid`).removeClass('fa-star').addClass('fa-spinner fa-spin');
      // To fire ga click event
      $('<div style="display: hidden;" class="ga-click" data-ga-click-type="anime-episodelist-poll-vote" data-ga-click-param="poll:4"></div>').appendTo('.mal-modal').trigger('click').remove();
      // Get anime info for episode vote
      $.ajax({
        url: `/forum/${topicId}/vote/score`,
        type: "POST",
        data: {score:score},
        datatype: "json",
        timeout: 10000,
        cache: false,
        success: function(data) {
          $(`a.modal-vote-score-link[rel=${score}] i.fa-solid`).removeClass('fa-spinner fa-spin').addClass('fa-star');
          // Vote success
          if (data.result) {
            this.resetToReplyDialog(data);
            return;
          }
          // Vote failed
          $('.mal-modal-body').empty().append('<div class="error">Failed to vote on this topic.</div>');
        }.bind(this)
      });
    }.bind(this);

    // ヘッダーを作成する
    header.empty();
    header.append('<div class="row row1"></div>');
    $('<div class="title" />').text(title).appendTo('.row1', header);
    if (topicType == 1) {
      $('<div class="subtitle" />').text(`Episode ${episodeNum} Discussion`).appendTo('.row1', header);
    }
    if (topicType == 2) {
      $('<div class="subtitle" />').text(`Chapter ${episodeNum} Discussion`).appendTo('.row1', header);
    }

    // ボディを作成する
    body.empty();
    if (topicType == 1) {
      body.append('<div class="modal-vote-score-label">What did you think of this episode?&nbsp;<small class="caution">(English text only; no manga spoilers.)</small></div>');
    }
    if (topicType == 2) {
      body.append('<div class="modal-vote-score-label">What did you think of this chapter?&nbsp;<small class="caution">(English text only.)</small></div>');
    }
    body.append('<ul class="modal-vote-score"></ul>');
    body.append(`<div class="modal-vote-score-footer"></div>`);
    $(`<div class="left"></div>`).appendTo('.modal-vote-score-footer', body);
    $(`<div class="right"></div>`).appendTo('.modal-vote-score-footer', body);
    $(`<li><a href="javascript:void(0);" class="modal-vote-score-link" rel="5" /><i class="fa-solid fa-star fa-fw"></i><span class="mr8">5</span>Loved it!</li>`).appendTo('.modal-vote-score', body);
    $(`<li><a href="javascript:void(0);" class="modal-vote-score-link" rel="4" /><i class="fa-solid fa-star fa-fw"></i><span class="mr8">4</span>Liked it</li>`).appendTo('.modal-vote-score', body);
    $(`<li><a href="javascript:void(0);" class="modal-vote-score-link" rel="3" /><i class="fa-solid fa-star fa-fw"></i><span class="mr8">3</span>It was OK</li>`).appendTo('.modal-vote-score', body);
    $(`<li><a href="javascript:void(0);" class="modal-vote-score-link" rel="2" /><i class="fa-solid fa-star fa-fw"></i><span class="mr8">2</span>Disliked it</li>`).appendTo('.modal-vote-score', body);
    $(`<li><a href="javascript:void(0);" class="modal-vote-score-link" rel="1" /><i class="fa-solid fa-star fa-fw"></i><span class="mr8">1</span>Hated it</li>`).appendTo('.modal-vote-score', body);
    $('a.modal-vote-score-link').one('click', function(){ execVote(topicId, $(this).attr('rel')) })

    if (showDontAsk) {
      $(`<a href="javascript:void(0)">Don't ask for this series</a>`)
        .on('click', function(){
          // To fire ga click event
          $('<div style="display: hidden;" class="ga-click" data-ga-click-type="anime-episodelist-poll-dontask"></div>').appendTo('.mal-modal').trigger('click').remove();
          const triggerUrl = "/includes/ajax.inc.php?t=" + ((topicType == 1) ? 53 : 56);
          $.post(triggerUrl, {id: dataId});
          $('.mal-modal').trigger('close');
        })
        .appendTo('.modal-vote-score-footer > .left', body);
    }
    $(`<a href="/forum/?topicid=${topicId}&pollresults=1" class="ga-click modal-vote-result-link" data-ga-click-type="anime-episodelist-poll-vote-results" data-ga-click-param="tid:${topicId}">View results</a>`).appendTo('.modal-vote-score-footer > .right', body);

    // 計測イベントを送信
    modal.off('beforeclose').on('beforeclose', function() {
      $('<div style="display: hidden;" class="ga-click" data-ga-click-type="anime-episodelist-poll-vote-close"></div>').appendTo(modal).trigger('click').remove();
    });
  },
  buildVoteCreateDialog: function(dataId, episodeNum, title, topicType) {
    const modal = $('.mal-modal');
    const dialog = $('.mal-modal-dialog', modal);
    const header = $('.mal-modal-header', dialog);
    const body = $('.mal-modal-body', dialog);

    // ヘッダーを作成する
    header.empty();
    header.append('<div class="row row1"></div>');
    $('<div class="title" />').text(title).appendTo('.row1', header);
    if (topicType == 1) {
      $('<div class="subtitle" />').text(`Episode ${episodeNum} Discussion`).appendTo('.row1', header);
    }
    if (topicType == 2) {
      $('<div class="subtitle" />').text(`Chapter ${episodeNum} Discussion`).appendTo('.row1', header);
    }

    // ボディを作成する
    body.empty();
    body.append('<div class="modal-vote-score-label">No one has created this discussion topic yet.</div>');
    body.append('<ul class="modal-vote-score"></ul>');
    body.append(`<div class="modal-vote-score-footer"></div>`);
    $(`<div class="left"></div>`).appendTo('.modal-vote-score-footer', body);
    $(`<div class="right"></div>`).appendTo('.modal-vote-score-footer', body);
    if (topicType == 1) {
      $(`<li><a href="/forum?action=post&anime_id=${dataId}&d=${episodeNum}" class="js-score-vote ga-click" data-ga-click-type="anime-episodelist-poll-vote-create" data-ga-click-param="did:${dataId}" />Create and discuss!</li>`).appendTo('.modal-vote-score', body);
    }
    if (topicType == 2) {
      $(`<li><a href="/forum?action=post&manga_id=${dataId}&d=${episodeNum}" class="js-score-vote ga-click" data-ga-click-type="anime-episodelist-poll-vote-create" data-ga-click-param="did:${dataId}" />Create and discuss!</li>`).appendTo('.modal-vote-score', body);
    }
    $(`<a href="javascript:void(0)" class="ga-click" data-ga-click-type="anime-episodelist-poll-vote-dontask" data-ga-click-param="did:${dataId}">Don't ask for this series</a>`)
      .on('click', function(){
        const triggerUrl = "/includes/ajax.inc.php?t=" + ((topicType == 1) ? 53 : 56);
        $('.mal-modal').trigger('close');
        $.post(triggerUrl, {id: dataId});
      })
      .appendTo('.modal-vote-score-footer > .left', body);

    // 計測イベントを送信
    modal.off('beforeclose').on('beforeclose', function() {
      $('<div style="display: hidden;" class="ga-click" data-ga-click-type="anime-episodelist-poll-vote-close"></div>').appendTo(modal).trigger('click').remove();
    });
  },
  buildFriendRequestDialog: function(userid, username) {
    const $modal = $('.mal-modal');
    const $dialog = $('.mal-modal-dialog', $modal);
    const $header = $('.mal-modal-header', $dialog);
    const $body = $('.mal-modal-body', $dialog);
    const $content = $('<div class="content"></div>');
    const $textarea = $('<textarea class="textarea" placeholder="Message (255 characters maximum)"></textarea>');
    const $footer = $('<div class="footer"></div>');
    const $buttons = $('<div class="mal-btn-toolbar"></div>');

    // タイトル
    $header.empty();
    $header.append(`<div class="row row1"><div class="title">Send Friend Request</div></div>`);
    $header.append(`<div class="row row2"><div class="subtitle">${username}</div></div>`);
    // ボディ
    $content.append($textarea);
    // フッター
    $('<button class="mal-btn primary">Request</button>').appendTo($buttons).on('click', function() {
      $.ajax({
        url: "/friends/api/send_request.json",
        data: JSON.stringify({
          "user_id": userid,
          "message": $textarea.val()
        }),
        contentType: "application/json",
        type: "POST",
        success: response => {
          // プロフィールカードを調整
          const $reqbutton = $(`div.mal-profile-card[rel=${username}] .mal-btn-friend-request`);
          $reqbutton.removeClass('primary js-mal-friend-request');
          $reqbutton.addClass('secondary outline disabled');
          $reqbutton.text('Pending Approval');
          $('.mal-modal').trigger('close')
        },
        error: error => {
          alert(error.responseJSON.errors[0].message);
          $('.mal-modal').trigger('close')
        }
      });
    });
    $('<button class="mal-btn secondary outline noborder">Cancel</button>').appendTo($buttons).on('click', function() {
      $('.mal-modal').trigger('close')
    });
    $footer.append($buttons);
    $body.empty();
    $body.append($content);
    $body.append($footer);
  },
  buildTopicQuickMoveDialog: function(topicId) {
    const modal = $('.mal-modal');
    const dialog = $('.mal-modal-dialog', modal);
    const header = $('.mal-modal-header', dialog);
    const body = $('.mal-modal-body', dialog);
    const container = $('<div class="content"></div>');
    const btn_group = $('<div class="mal-btn-toolbar mt4"></div>');
    const btn_move = $(`<button class="mal-btn">Move</button>`);
    const btn_cancel = $(`<button class="mal-btn secondary outline">Cancel</button>`);

    // ヘッダーを作成する
    header.empty();
    header.append('<div class="row row1"></div>');
    $('<div class="title" />').text('Quick Move').appendTo('.row1', header);

    // ボディを作成する
    container.append(`<select id="topic-admin-quickmove-location">
      <option value="-1">Select Forum</option>
      <optgroup label="Anime &amp; Manga">
        <option value="15">News Discussion</option>
        <option value="16">Anime &amp; Manga Recommendations</option>
        <option value="19">Series Discussion</option>
        <option value="1">Anime Discussion</option>
        <option value="2">Manga Discussion</option>
      </optgroup>
      <optgroup label="MyAnimeList">
        <option value="5">Updates &amp; Announcements</option>
        <option value="14">MAL Guidelines &amp; FAQs</option>
        <option value="17">DB Modification Requests</option>
        <option value="3">Support</option>
        <option value="4">Suggestions</option>
      </optgroup>
      <optgroup label="General">
        <option value="8">Introductions</option>
        <option value="7">Games, Computers &amp; Tech Support</option>
        <option value="10">Music &amp; Entertainment</option>
        <option value="6">Current Events</option>
        <option value="11">Casual Discussion</option>
        <option value="12">Creative Corner</option>
        <option value="13">MAL Contests</option>
        <option value="9">Forum Games</option>
      </optgroup>
    </select>`);
    container.append(`<input type="text" id="topic-admin-quickmove-animeid" maxlength="8" size="8" placeholder="Anime ID" />`);
    container.append(`<input type="text" id="topic-admin-quickmove-mangaid" maxlength="8" size="8" placeholder="Manga ID" />`);
    btn_group.append(btn_move);
    btn_group.append(btn_cancel);
    container.append(btn_group);
    body.empty();
    body.append(container);
    $('#topic-admin-quickmove-location').on('change', function(){
      let mv_location = parseInt($('#topic-admin-quickmove-location').val());
      if (isNaN(mv_location) || mv_location < 1 || mv_location > 19) mv_location = -1;
      if (mv_location == -1) {
        $('#topic-admin-quickmove-animeid').prop('disabled', false);
        $('#topic-admin-quickmove-mangaid').prop('disabled', false);
      } else {
        $('#topic-admin-quickmove-animeid').val('').prop('disabled', true);
        $('#topic-admin-quickmove-mangaid').val('').prop('disabled', true);
      }
    }).trigger('change');
    btn_move.on('click', function(){
      let mv_location = parseInt($('#topic-admin-quickmove-location').val());
      let mv_anime = parseInt($('#topic-admin-quickmove-animeid').val());
      let mv_manga = parseInt($('#topic-admin-quickmove-mangaid').val());
      if (isNaN($('#topic-admin-quickmove-location').val()) || isNaN(mv_location) || mv_location < 1 || mv_location > 19) mv_location = 0;
      if (isNaN($('#topic-admin-quickmove-animeid').val()) || isNaN(mv_anime) || mv_anime < 0) mv_anime = 0;
      if (isNaN($('#topic-admin-quickmove-mangaid').val()) || isNaN(mv_manga) || mv_manga < 0) mv_manga = 0;

      if (mv_location <= 0) {
        if ((mv_anime > 0 && mv_manga > 0) || (mv_anime == 0 && mv_manga == 0)) {
          alert('Error: Invalid parameter.');
          return;
        }
      } else {
        mv_anime = 0;
        mv_manga = 0;
      }

      $.ajax({
        type: "POST",
        url: "/forum/?action=movetopic",
        data: {location:mv_location, aid:mv_anime, mid:mv_manga, id:topicId, movesubmit:'Move Topic'},
        timeout: 10000,
        cache: false,
        success: function(data) {
					if (data.indexOf("seleted the same") !== -1) {
            alert("Same ID.");
            return;
					}
          $('.mal-modal').trigger('close');
          location.reload();
          return;
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          alert("Please try again.");
        },
      });
    });
    btn_cancel.on('click', function(){
      $('.mal-modal').trigger('close');
    });
  },
  buildProfileCommentDialog: function(userId, userName, workTitle, workUrl, status, notes, callback) {
    const modal = $('.mal-modal')
    const dialog = $('.mal-modal-dialog', modal)
    const header = $('.mal-modal-header', dialog)
    const body = $('.mal-modal-body', dialog)

    // ヘッダーを作成する
    header.empty()
    header.append('<div class="row row1"></div>')
    $('<div class="title" />').text(userName).appendTo('.row1', header)
    $('<div class="subtitle" />').text('Profile comment').appendTo('.row1', header)

    // ボディを作成する
    body.empty();
    const $description = $('<div class="modal-profile-comment-description"></div>').appendTo(body)
    const $text = $('<p class="description-text"></p>').appendTo($description)
    const $removeBtn = $('<button class="mal-btn primary outline noborder description-button"><i class="fas fa-circle-xmark"></i>Don\'t add quote</button>').appendTo($description)
    const $undoBtn = $('<button class="mal-btn primary outline noborder description-button"><i class="fas fa-rotate-left"></i>Undo</button>').appendTo($description)

    const $quoteText = $(`<div class="modal-profile-comment-quote"></div>`).appendTo(body)
    let quoteText = `${workTitle} · ${status}`
    if (notes) {
      quoteText += `\n${notes}`
    }
    $quoteText[0].innerText = quoteText

    $removeBtn.on('click', function() {
      $text.text('Quote removed.')
      $removeBtn.hide()
      $undoBtn.show()
      $quoteText.hide()
    })
    $undoBtn.on('click', function() {
      $text.text('This text will be quoted in your comment.')
      $removeBtn.show()
      $undoBtn.hide()
      $quoteText.show()
    }).trigger('click')

    const $form = $('<div class="modal-profile-comment-form"></div>').appendTo(body)
    const $textarea = $('<textarea class="textarea" placeholder="Write your comment..."></textarea>').appendTo($form)
    const $buttons = $('<div class="mal-btn-toolbar"></div>').appendTo($form);
    const $message = $('<div class="message"></div>').hide()
    $('<button class="mal-btn primary">Comment</button>').appendTo($buttons).on('click', function() {
      if ($textarea.val() === '') {
        $message.text('No text was input.').show()
        return
      }
      $message.text('').hide()
      const $self = $(this)
      $self.prop('disabled', true)

      let commentText = ''
      if ($quoteText.css('display') !== 'none') {
        commentText = `[quote][url=${workUrl}]${workTitle}[/url] · ${status}`
        if (notes) {
          commentText += `\n${notes}`
        }
        commentText += '[/quote]'
      }
      commentText += $textarea.val()

      $.ajax({
        type: 'POST',
        url: '/addcomment.php',
        data: {
          commentSubmit: 1,
          profileMemId: userId,
          commentText: commentText,
          area: 2,
        },
      }).done((data) => {
        const response = JSON.parse(data)
        if (response.error) {
          $message.text(response.message).show()
          return
        }
        $('.mal-modal').trigger('close')
        callback()
      }).fail(() => {
        $message.text('Error').show()
      }).always(() => {
        $self.prop('disabled', false)
      })
    })
    $('<button class="mal-btn primary outline">Cancel</button>').appendTo($buttons).on('click', function() {
      $('.mal-modal').trigger('close')
    })
    $message.appendTo($form)
  },
  buildFriendsUpdateSettingsDialog: function(data, callback) {
    const modal = $('.mal-modal')
    // モーダル全体のデザインを変更するためのクラスを付与
    modal.addClass('modal-friends-update-settings').on('beforeclose', () => {
      setTimeout(() => {
        modal.removeClass('modal-friends-update-settings')
      }, 400) // transitionの完了と合わせて付与クラスを消す
    })

    // ボディを作成する
    const rows = []
    data.forEach((row) => {
      const $titleCol = $('<td></td>')
      const $titleWrap = $('<div class="title-wrap"></div>').appendTo($titleCol)
      $('<p class="title"></p>').text(row.title).appendTo($titleWrap)
      $('<p class="overview"></p>').text(row.overview).appendTo($titleWrap)

      const $panelCol = $('<td class="switch"></td>')
      const panelName = 'panel_' + row.name
      $('<input type="checkbox" value="1">')
        .attr('id', panelName)
        .attr('name', panelName)
        .prop('checked', row.panel === 1)
        .appendTo($panelCol)
      $('<label></label>').attr('for', panelName).appendTo($panelCol)

      const $historyCol = $('<td class="switch"></td>')
      const historyName = 'history_' + row.name
      $('<input type="checkbox" value="1">')
        .attr('id', historyName)
        .attr('name', historyName)
        .prop('checked', row.history === 1)
        .appendTo($historyCol)
      $('<label></label>').attr('for', historyName).appendTo($historyCol)

      rows.push($('<tr></tr>').append($titleCol, $panelCol, $historyCol))
    })

    const $table = $('<table></table>')
    $('<thead><tr><th>Friend Updates Settings</td><th>Panel</td><th>History</td></tr></thead>').appendTo($table)
    $('<tbody></tbody>').append(rows).appendTo($table)

    const $buttons = $('<div class="mal-btn-toolbar"></div>')
    const $message = $('<div class="message"></div>').hide()
    const $ajaxLoader = $('<div class="ajax-loader"></div>').hide()
    $('<button class="mal-btn primary">Submit</button>').appendTo($buttons).on('click', () => {
      const params = {}
      $("input[type='checkbox']", $table).each((index, checkbox) => {
        const $checkbox = $(checkbox)
        params[$checkbox.attr("name")] = $checkbox.prop("checked") ? 1 : 0
      })
      $.ajax({
        url: '/history/settings.json',
        data: JSON.stringify(params),
        contentType: 'application/json',
        type: 'POST',
        beforeSend: () => {
          $ajaxLoader.show()
          $message.text('').hide()
        }
      }).done(() => {
        this.hide()
        data.forEach((row) => {
          row.panel = params['panel_' + row.name]
          row.history = params['history_' + row.name]
        })
        callback(data);
      }).fail((xhr) => {
        const json = xhr.responseJSON
        switch (xhr.status) {
          case 401: // loginしていない場合login画面に飛ばす
            window.location.href = json.redirect
            break
          default:
            $message.text('Error').show()
        }
      }).always(() => {
        $ajaxLoader.hide()
      })
    })
    $('<button class="mal-btn primary outline">Close</button>').appendTo($buttons).on('click', () => {
      this.hide()
    })

    $('.mal-modal-dialog .mal-modal-body', modal).empty().append($table, $buttons, $message, $ajaxLoader)
  },
  resetToReplyDialog: function(data) {
    const modal = $('.mal-modal');
    const dialog = $('.mal-modal-dialog', modal);
    const header = $('.mal-modal-header', dialog);
    const body = $('.mal-modal-body', dialog);

    // ボディを初期化する
    $(`a.modal-vote-score-link`).off('click');
    $(`a.modal-vote-score-link[rel=${data.score}]`).addClass('voted');
    $(`a.modal-vote-score-link:not(.voted)`).remove();
    $('div.modal-vote-score-footer .left', body).empty();

    // リプライフォームを追加する
    const replyform = $('<div class="modal-vote-reply"></div>');
    const replybuttons = $('<div class="modal-vote-reply-buttons mal-btn-toolbar"></div>');
    replyform.insertAfter('.modal-vote-score');
    $('<textarea id="messageTextVoteDialog" class="textarea" placeholder="Write your thoughts..."></textarea>').appendTo(replyform);
    replybuttons.appendTo(replyform)
    $('<span id="postIndicatorVoteDialog" class="pr8 fs14" style="display:none"><i class="fa-solid fa-spinner fa-spin"></i></span>').appendTo(replybuttons);
    $('<button id="postReplyVoteButton" class="mal-btn primary">Reply</button>').appendTo(replybuttons).on('click', function() {
      $('#postReplyVoteButton').prop('disabled', true);
      $('#postIndicatorVoteDialog').show();
      const messageText = $('#messageTextVoteDialog').val();
      $.ajax({
        type: "POST",
        url: "/includes/ajax.inc.php?t=82",
        data: {topicId: data.topic_id, messageText: messageText},
        datatype: "json",
        timeout: 10000,
        cache: false,
        success: function(replied) {
          $('#postReplyVoteButton').prop('disabled', false);
          $('#postIndicatorVoteDialog').hide();
          const repliedJson = JSON.parse(replied);
          // Vote success
          if (repliedJson.errors) {
            alert(repliedJson.errors[0].message);
            return;
          }
          location.href = `/forum/?topicid=${data.topic_id}`;
          return;
        }.bind(this),
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          alert("There was an error posting, please try again.");
          $('#postReplyVoteButton').prop('disabled', false);
          $('#postIndicatorVoteDialog').hide();
        },
      });
    }.bind(this));
    $('<button class="mal-btn primary outline">Cancel</button>').appendTo(replybuttons).on('click', function() {
      $('.mal-modal').trigger('close');
    });
    $('<button class="mal-btn primary outline noborder" onclick="window.open(\'/info.php?go=bbcode\',\'bbcode\',\'menubar=no,scrollbars=yes,status=no,width=600,height=700\');">How to use BBCode?</button>').appendTo(replybuttons);

    // 下部バー:左側
    const replies = $('<div class="modal-vote-replies"></div>');
    if (data.friends_count > 0) {
      // TODO: to implement friends list
      const friends = $(`<span class="friends"></span>`);
      friends.appendTo(replies);
      data.friends.forEach(function(friend){
        $(`<img src="${friend.image}" alt="${friend.name}" class="modal-vote-replies-friend" />`).appendTo(friends);
      });
      $(`<span class="replies">${data.friends_count} friends replied</span>`).appendTo(replies);
    } else {
      $(`<span class="replies">${data.topic_replies} members replied</span>`).appendTo(replies);
    }
    replies.appendTo('div.modal-vote-score-footer .left', body);
    // 下部バー:右側
    $('div.modal-vote-score-footer .right .modal-vote-result-link', body).text('View discussion');
  },
  buildReviewPreviewDialog: function(userName, userImg, imgWidth, reviewText, feelingTag, preliminaryTag, spoilerTag) {
    const modal = $('.mal-modal')
    const dialog = $('.mal-modal-dialog', modal)
    dialog.addClass('preview');
    const header = $('.mal-modal-header', dialog)
    const body = $('.mal-modal-body ', dialog)
    // ヘッダーを作成する
    header.empty()
    header.append('<div class="row">This is how your review will appear on the anime/manga title page.</div>');

    // ボディを作成する
    body.empty();
    const reviewBody = $('<div class="review-element"></div>').appendTo(body)

    const thumbBody = $('<div class="thumbbody mt8"></div>').appendTo(reviewBody)
    const thumb = $('<div class="thumb"></div>').appendTo(thumbBody)
    thumb.append($(`<img src="${userImg}" class="lazyloaded" width="${imgWidth}" />`))

    const textBody = $('<div class="body" />').appendTo(thumbBody)
    textBody.append('<div class="update_at">Aug 4, 2022</div>')
    textBody.append('<div class="username" />')
    $('<a herf="#" />').text(userName).appendTo('.username', textBody)

    const tags = $('<div class="tags" />').appendTo(textBody)
    tags.append(feelingTag)
    if ($("#preliminary_tag")) {
      $("#preliminary_tag").clone().appendTo(tags).show()
    }
    tags.append(preliminaryTag)
    tags.append(spoilerTag)

    const textArray = $('<div class="text mb12"></div>').appendTo(textBody)
    textArray.append('<div class="js-text"></div>')
    const review = $('<p></p>').appendTo('.js-text')
    review.html(reviewText)

    const score = $("#frmreview_overall_score").val()
    const rating = $('<div class="rating mb12">Reviewer’s Rating: </div>').appendTo(textBody)
    rating.append($(`<span class="num js-score">${score}<span>`))

    modal.off('beforeclose').on('beforeclose', function() {
      modal.on('transitionend.preview', function() {
        dialog.removeClass('preview');
        modal.off('transitionend.preview');
      });
    });
  },
  buildSpReviewPreviewDialog: function(userName, userImg, reviewText, feelingTag, preliminaryTag, spoilerTag) {
    const modal = $('.mal-modal')
    const dialog = $('.mal-modal-dialog', modal)
    dialog.addClass('preview');
    const header = $('.mal-modal-header', dialog)
    const body = $('.mal-modal-body ', dialog)
    // ヘッダーを作成する
    header.empty()
    header.append('<div class="row">This is how your review will appear on the anime/manga title page.</div>');

    // ボディを作成する
    body.empty();
    const reviewBody = $('<div class="review-element user"></div>').appendTo(body)

    const thumbBody = $('<div class="thumbbody mt8"></div>').appendTo(reviewBody)
    const thumb = $('<div class="thumb"></div>').appendTo(thumbBody)
    thumb.append($(`<img src="${userImg}" class="icon-thumb users user_small" />`))

    const textBody = $('<div class="body" />').appendTo(thumbBody)
    const userBlock = $('<div class="username" />').appendTo(textBody)
    $('<a herf="#" />').text(userName).appendTo(userBlock)

    const tags = $('<div class="tags" />').appendTo(textBody)
    tags.append(feelingTag)
    if ($("#preliminary_tag")) {
      $("#preliminary_tag").clone().appendTo(tags).show()
    }
    tags.append(preliminaryTag)
    tags.append(spoilerTag)

    const textArray = $('<div class="comment-text mb8"></div>').appendTo(reviewBody)
    textArray.append('<div class="text"></div>')
    const review = $('<p></p>').appendTo('.text')
    review.html(reviewText)

    const score = $("#review_overall_score").val()
    const rating = $('<div class="rating mt12 mb12">Reviewer’s Rating: </div>').appendTo('.text')
    rating.append($(`<span class="num js-score">${score}<span>`))

    modal.off('beforeclose').on('beforeclose', function() {
      modal.on('transitionend.preview', function() {
        dialog.removeClass('preview');
        modal.off('transitionend.preview');
      });
    });
  }
};
