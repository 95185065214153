var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show"
              }
            ],
            staticClass: "tutorial-content"
          },
          [
            _c(
              "div",
              { staticClass: "tutorial-mask", on: { click: _vm.maskClick } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isShowHowtoRead,
                        expression: "!isShowHowtoRead"
                      }
                    ],
                    staticClass: "tutorial-mask-header"
                  },
                  [
                    _c("div", { staticClass: "tutorial-mask-header-text" }, [
                      _vm._v("Move your cursor here to show the toolbar")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "tutorial-mask-gray" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isShowHowtoRead,
                        expression: "!isShowHowtoRead"
                      }
                    ],
                    staticClass: "tutorial-mask-footer"
                  },
                  [
                    _c("div", { staticClass: "tutorial-mask-footer-text" }, [
                      _vm._v("Move your cursor here to show the toolbar")
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tutorial-wrapper" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isShowHowtoRead,
                      expression: "!isShowHowtoRead"
                    }
                  ],
                  staticClass: "tutorial-container tutorial-howto-use"
                },
                [
                  _c("div", { staticClass: "tutorial-direction" }, [
                    _c("p", [
                      _vm._v("Use the left and right keys to turn pages")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "tutorial-zoom" }, [
                    _c("p", [
                      _vm._v("To zoom in double click your mouse or"),
                      _c("br"),
                      _vm._v("use the + and - keys to zoom in and out")
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowHowtoRead,
                    expression: "isShowHowtoRead"
                  }
                ],
                staticClass: "tutorial-container tutorial-howto-read"
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "button-howto-wrap" }, [
              _c(
                "a",
                {
                  staticClass: "button-howto-use",
                  class: { "is-active": !_vm.isShowHowtoRead },
                  on: { click: _vm.hideHowtoRead }
                },
                [_vm._v("How to Use")]
              ),
              _vm._v(" "),
              _c("span", {
                staticClass: "menu-divider",
                class: { "is-active-right": _vm.isShowHowtoRead }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button-howto-read",
                  class: { "is-active": _vm.isShowHowtoRead },
                  on: { click: _vm.showHowtoRead }
                },
                [_vm._v("How to Read")]
              )
            ]),
            _vm._v(" "),
            _c(
              "a",
              { staticClass: "tutorial-close", on: { click: _vm.hide } },
              [_vm._v("Got it!")]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }