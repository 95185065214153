<template>
  <div>
    <div class="continue-button">
      <button @click="continueButton" class="mal-btn primary large ga-click"
              data-ga-click-type="membership-payment-gift-continue"
              :data-ga-click-param="'product:'+gift.gift_id">Continue</button>
      <loading-modal-pc v-if="!isSp" :is-shown="showLoadingModal"></loading-modal-pc>
      <loading-modal-sp v-if="isSp" :is-shown="showLoadingModal"></loading-modal-sp>
    </div>
    <mal-support-confirm-purchase
      @purchased="purchased"
      @editPayment="moveAccountSetting"
      :gift="gift"
    ></mal-support-confirm-purchase>
    <mal-support-finish-purchase @cancel="finished" :gift="gift"></mal-support-finish-purchase>
    <payment-modal
      v-if="showPaymentModal"
      :show="showPaymentModal"
      :payment="payment"
      :terms-html="termsHtml"
      :support-url="supportUrl"
      @registerdPaymentMethod="registeredPaymentMethod"
      @hidePaymentModal="hidePaymentModal"
    ></payment-modal>
  </div>
</template>

<script type="text/babel">
import LoadingModalPc from "./../manga_store/pc/molecules/LoadingModal.vue"
import LoadingModalSp from "./../manga_store/sp/molecules/LoadingModal.vue"
import PaymentModal from "../../pages/manga_store/viewer/pc/organisms/PaymentModal.vue";
import MalSupportConfirmPurchase from "./molecules/MalSupportConfirmPurchase.vue";
import MalSupportFinishPurchase from "./molecules/MalSupportFinishPurchase.vue";
import {mapState} from "vuex";
import * as types from "./store/mutation-types"

export default {
  name: "MalSupportPurchaseButton",
  components: {
    LoadingModalPc,
    LoadingModalSp,
    PaymentModal,
    MalSupportConfirmPurchase,
    MalSupportFinishPurchase
  },
  mounted() {
    if (this.isRestorePaymentProcess()) { //決済が途中で、登録が完了して戻ってきた場合は自動で決済処理を実行する。
      this.continueButton();
    }
  },
  computed: {
    ...mapState([
      "gift",
      "termsHtml",
      "supportUrl",
      "isSp",
      "payment"
    ]),
    ...mapState({
      showPaymentModal: state => state.modals.showPaymentModal,
      showConfirmationModal: state => state.showConfirmationModal,
      showFinishModal: state => state.showFinishModal,
      showLoadingModal: state => state.showLoadingModal,
    })
  },
  data: () => {
    return {
      isSp: !!document.querySelector('body.sp')
    };
  },
  methods: {
    hidePaymentModal() {
      this.$store.commit(types.HIDE_PAYMENT_MODAL)
    },
    async continueButton() {
      try {
        await this.$store.dispatch(types.CHECK_PAYMENT_SETTING)
      } catch (e) {
        if (e.response.status === 401 && e.response.data.redirect) {
          location.href = e.response.data.redirect;
        }
        throw e;
      }
      if (!this.payment.defaultPaymentMethod) {
        if (this.isSp) {
          this.moveAccountSetting()
        } else {
          this.$store.commit(types.SHOW_PAYMENT_MODAL)
        }
      } else {
        this.$store.commit(types.CONFIRMATION_MODAL, true)
      }
    },
    async registeredPaymentMethod() {
      this.hidePaymentModal()
      // Payment ModalでStore側に反映してくれていたらやる必要がないんだけど... 仕方ないのでもう一度チェック入れる
      await this.$store.dispatch(types.CHECK_PAYMENT_SETTING)
      this.$store.commit(types.CONFIRMATION_MODAL, true)
    },
    finished() {
      this.$store.commit(types.FINISH_MODAL, false)
    },
    //アカウント設定 -> 支払い情報に飛ばす。この後戻ってこれるように情報を残しておく
    moveAccountSetting() {
      this.$cookie.set("membership_referer", location.href, 1)
      location.href = '/account/payment'
    },
    isRestorePaymentProcess() {
      const successfullyRegistered = this.$cookie.get("successfully_registered")
      if (parseInt(this.gift.gift_id) === parseInt((new URL(location.href)).searchParams.get('product'))) {
        this.$cookie.delete("membership_referer")
        this.$cookie.delete("successfully_registered")
        return successfullyRegistered
      } else {
        return false;
      }
    }
  }
}
</script>

<style scoped>
.continue-button {
  text-align: center;
  margin: 1rem 0;
}

/* 決済情報登録(payment-method)コンポーネントに対してのStyle
 * 決済情報登録画面についてはいろんなcssが当たって崩れてしまうので無理矢理適応させる。
 * どこかでモーダルを独立させて外からの影響を受けないようにしたい。
 */

/deep/ .text-payment-policy {
  width: fit-content !important;
}

/deep/ .text-payment-notice {
  width: auto !important;
  display: block !important;
}

/deep/ #card-element {
  width: auto;
  margin-bottom: 0.5rem;
}
</style>
