var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isShowPreview
      ? _c(
          "div",
          {
            staticClass: "detail-preview-container",
            class: {
              hasPreview:
                _vm.item.isPreviewable &&
                !_vm.item.isPossessed &&
                !_vm.item.isFree
            }
          },
          [
            _vm.item.isPreviewable
              ? [
                  _c(
                    "a",
                    {
                      staticClass: "detail-img",
                      attrs: { target: "_blank", href: _vm.item.previewUrl },
                      on: {
                        mouseover: _vm.mouseover,
                        mouseleave: _vm.mouseleave
                      }
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.coverImage,
                            expression: "coverImage"
                          }
                        ],
                        style: _vm.imageHoverStyle,
                        attrs: { itemprop: "image" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("preview-button", {
                    staticClass: "preview-button",
                    staticStyle: { margin: "8px auto 0" },
                    style: _vm.buttonHoverStyle,
                    attrs: {
                      "preview-url": _vm.item.previewUrl,
                      text: _vm.item.numberingText
                    },
                    on: { mouseover: _vm.mouseover, mouseleave: _vm.mouseleave }
                  })
                ]
              : [
                  _c("div", { staticClass: "detail-img" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: _vm.coverImage,
                          expression: "coverImage"
                        }
                      ]
                    })
                  ])
                ]
          ],
          2
        )
      : _c("div", { staticClass: "detail-preview-container" }, [
          _c(
            "a",
            {
              staticClass: "detail-img",
              attrs: { target: "_blank", href: _vm.item.viewerUrl },
              on: { mouseover: _vm.mouseover, mouseleave: _vm.mouseleave }
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: _vm.coverImage,
                    expression: "coverImage"
                  }
                ],
                style: _vm.imageHoverStyle,
                attrs: { itemprop: "image" }
              })
            ]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }