<template>
  <div class="root for-pc">
    <transition name="modal">
      <div v-if="isShown" class="modal-content" id="gdpr-modal-bottom">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="text">
              <p>
                To improve your experience on our site, we use cookies to provide you with a secure log-in and to remember log-in details, collect statistics to optimize site functionality and deliver content tailored to your interests. See our <a href="/about/cookie_policy" target="_blank">Cookie Policy</a> to learn more.
              </p>
              <p>
                We have updated our <a href="/about/privacy_policy" target="_blank">Privacy Policy</a>, which will take effect on February 27, 2023. <a href="/about/terms_of_use" target="_blank">Terms of Use Agreement</a> are effective as of December 12, 2022.
              </p>
            </div>
            <div class="button-wrapper">
              <button @click="close">OK</button>
              <p>
                <a href="/about/privacy_policy" target="_blank">More Info</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
  @import "../../../../css/variables";

  .modal-content {
    position: fixed;
    z-index: 9998;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 142px;
    display: table;
    background: $color-pc-bg-f8f8f8;
  }
  .modal-wrapper {
    position: relative;
    width: 1040px;
    margin: 10px auto;
    border: 10px solid $color-pc-border-e1e7f5;
  }
  .modal-container {
    height: 100%;
    font-family: Verdana,Arial;
    padding: 20px 16px;
    box-sizing: border-box;
    background-color: $color-pc-bg-ffffff;
    color: $color-pc-text-787878;
    border: 1px solid $color-pc-border-1c439b;
    box-sizing: border-box;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;

    &:after {
      content: "";
      clear: both;
      display: block;
    }
  }
  .text {
    width: 889px;
    font-size: 12px;
    line-height: 1.5em;
    text-align: left;
    float: left;
    p {
      margin-bottom: 8px;
    }
    a {
      text-decoration: none;
      font-weight: bold;
    }
  }
  .button-wrapper {
    button {
      width: 87px;
      height: 38px;
      background-color: $color-bg-submit-button;
      border: $color-pc-border-ffffff 0 solid;
      border-radius: 2px;
      color: $color-white-fixed;
      cursor: pointer;
      display: block;
      font-family: Avenir,lucida grande,tahoma,verdana,arial,sans-serif;
      font-size: 16px;
      line-height: 1.0em;
      margin: 0 auto 12px;
      text-align: center;
      text-decoration: none;
      font-weight: bold;
      &:active {
        background-color: rgba($color-bg-submit-button, 0.7);
      }
    }
    a {
      text-decoration: none;
      font-weight: bold;
    }
  }

  /* Transition Effect */
  .modal-enter-active, .modal-leave-active {
    transition: transform 0.5s;
  }
  .modal-enter, .modal-leave-to {
    -webkit-transform: translateY(142px);
    transform: translateY(142px);
  }

  .dark-mode {
    @import "../../../../css/colors_dark";
    .modal-content {
      background: #272727;
    }
    .modal-wrapper {
      border: 10px solid #272727;
    }
    .modal-container {
      background-color: #414141;
      color: #cdcdcd;
      border: none;
    }
    .button-wrapper {
      button {
        background-color: #4065ba;
        border: #6486df 0 solid;
        color: #ffffff;
      }
      &:active {
        background-color: rgba(#4065ba, 0.7);
      }
    }
  }
</style>

<script type="text/babel">
  import Cookies from "js-cookie"
  const axios = window.axios
  export default {
    mounted() {
      this.isShown = false
      $(document).ready(() => {
        let height = $("#gdpr-modal-bottom").height()
        $("body").css({paddingBottom: height})
        window.setTimeout(this.show,3000)
      })
    },
    data() {
      return {
        isShown : false
      }
    },
    methods: {
      show() {
        // 優先順位の高いポップアップが出ていたら表示しない
        if (($('.app_gdpr--2k2uB').length > 0 && $('.banner_hidden--MXmJ_').length === 0) || $('._cm-os-slider').length > 0 || $('#tbl-next-up').length > 0) {
          return
        }

        if (this.inSideIframe()) {
          return
        }

        this.isShown = true
      },
      close() {
        $("body").css({paddingBottom: 0})
        this.isShown = false
        // OK済みをCookieに保存&ログインユーザはlog記録するエンドポイント（サーバでsetCookieしないとSafariでexpireが7日に制限されるため）
        axios.put(`/gdpr/ok/20211022.json`)
      },
      inSideIframe() {
        // hideLayout=1 OR hideLayout OR hidenav=1
        const search = location.search.substring(1)
        if (!search) {
          return false
        }
        const params = search.split("&")

        const hideParam = params.find(param => {
          return param.includes("hideLayout") || param.includes("hidenav")
        })

        if (typeof hideParam === "undefined") {
          return false
        }

        const hideParamAndProperty = hideParam.split("=")
        return hideParamAndProperty.length === 1 || hideParamAndProperty[1] === "1"
      }
    }
  }
</script>
