const $ = window.$;
const _ = window._;
const sVue = window.sVue;
import CsrfProtector from "../../../../common/modules/CsrfProtector"

$(() => {

  /* eslint-disable camelcase */
  if($(".episode-edit-character")[0]){
    const orderVoiceActors = (voiceActors) => {
      return _.sortBy(voiceActors, "language_id")
    };

    // Episode list
    const formEl = "#characterForm"
    const episodeList = new sVue({
      el: formEl,
      data: {
        allEpisodes: $(formEl).data("json"),
        episodes: [],
        initialData: null,
        keyword: "",
        characters: $("#episodeAnimeCharacterList").data("json"),
        entry: {id: null, name: null},
        pulldown: false,
        hasNoResults: false,
        voice_actors: [],
      },
      created: function () {
        const self = this;
        let offset = 0;
        const loadOnetime = 30;

        (function loadEpisodes() {
          self.episodes = self.episodes.concat(self.allEpisodes.slice(offset, offset + loadOnetime));
          offset += loadOnetime;
          if (offset <= self.allEpisodes.length) {
            setTimeout(loadEpisodes, 100);
          }
        })();

        // for review page
        const data = $("#primaryVoiceActorList").data("json")
        if (data) {
          self.voice_actors = data
        }

        CsrfProtector.injectTokenToForm(formEl)
      },
      computed: {
        filteredCharacters() {
          const self = this
          const searchRegex = new RegExp(self.keyword, "i")
          return self.characters.filter((character) => {
            return searchRegex.test(character.name)
          })
        },
        orderedVoiceActors() {
          return orderVoiceActors(this.voice_actors)
        },
      },
      methods: {
        orderVoiceActors: function(voiceActors) {
          return orderVoiceActors(voiceActors)
        },
        updateEpisodes: function (data) {
          const voice_actor_list = data.voice_actor_list;
          const episode_list     = data.episode_list;

          this.allEpisodes.forEach(function (episode) {
            episode.is_added = (episode_list[episode.id].is_added ? true : false);

            const valist = JSON.parse(JSON.stringify(voice_actor_list));
            valist.forEach(function (va) {
              if (episode.is_added) {
                va.is_added = _.includes(episode_list[episode.id].role_ids, va.role_id.toString());
              } else {
                va.is_added = false;
              }
            });
            episode.voice_actors = valist;
          });

          this.initialData = this.generateSubmissionData();
        },
        addAll: function () {
          this.allEpisodes.forEach(function (episode) {
            episode.is_added = true;
          });
        },
        deleteAll: function () {
          this.allEpisodes.forEach(function (episode) {
            episode.is_added = false;
          });
        },
        addDefaultRoleId: function (role_id) {
          this.allEpisodes.forEach(function (episode) {
            episode.voice_actors.forEach(function (voice_actor) {
              if (voice_actor.role_id === role_id) {
                voice_actor.is_added = true;
              }
            });
          });
        },
        deleteDefaultRoleId: function (role_id) {
          this.allEpisodes.forEach(function (episode) {
            episode.voice_actors.forEach(function (voice_actor) {
              if (voice_actor.role_id === role_id) {
                voice_actor.is_added = false;
              }
            });
          });
        },
        addByIndices: function (indices) {
          this.allEpisodes.forEach(function (episode) {
            episode.is_added = false;
          });

          for (let i = 0; i < indices.length; i++) {
            this.allEpisodes[indices[i]].is_added = true;
          }
        },
        submitCharacters: function (e) {
          const data = this.generateSubmissionData();
          if (data === null) {
            e.preventDefault();
            alert("Select character!");
            return;
          }

          // レビューページでなければ変更点があるかどうかチェックする
          if ($("#episodeAnimeCharacterList").exists()) {
            if (!this.checkModified(data)) {
              e.preventDefault();
              alert("Nothing has been changed!");
              return;
            }
          }

          $("input[name=characters]").val(JSON.stringify(data));
        },
        generateSubmissionData: function () {
          // reviewページではキャラクターリストはない
          let character_id;
          if ($("#episodeAnimeCharacterList").exists()) {
            character_id = episodeList.getCharaterId();
            if (!character_id) {
              return null;
            }
          } else {
            character_id = $("#characterIdInput").val();
          }

          const data = [];
          this.allEpisodes.forEach(function (episode) {
            if (episode.is_added) {
              const voice_actors = [];
              episode.voice_actors.forEach(function (voice_actor) {
                if (voice_actor.is_added) {
                  voice_actors.push({role_id: voice_actor.role_id});
                }
              });
              data.push({
                id          : character_id,
                episode_id  : episode.id,
                voice_actors: voice_actors
              });
            }
          });
          return data;
        },
        checkModified: function (data) {
          // 変更があるかチェック
          if (data.length !== this.initialData.length) {
            return true;
          }
          for (let i = 0; i < data.length; i++) {
            const a = data[i], b = this.initialData[i];
            if (a.id !== b.id || a.episode_id !== b.episode_id) {
              return true;
            }
            if (a.voice_actors.length !== b.voice_actors.length) {
              return true;
            }
            for (let j = 0; j < a.voice_actors.length; j++) {
              const c = a.voice_actors[j], d = b.voice_actors[j];
              if (c.role_id !== d.role_id) {
                return true;
              }
            }
          }
          return false;
        },
        getCharacterById: function (id) {
          for (let i = 0, len = this.characters.length; i < len; i++) {
            if (this.characters[i].id === id) {
              return this.characters[i];
            }
          }
        },
        selectCharacter: function (id) {
          this.entry = this.getCharacterById(id);
          this.keyword = "";
          this.pulldown = false;

          $.get(`../character/${id}`, {}, function (value) {
            episodeList.updateVoiceActors(value.voice_actor_list);
            episodeList.updateEpisodes(value);
          });
        },
        getCharaterId: function () {
          if (this.entry) {
            return this.entry.id;
          } else {
            return false;
          }
        },
        deleteCharacter: function (id) {
          this.getCharacterById(id).is_added = false;
        },
        deleteAllCharacters: function () {
          this.characters.forEach(function (element) {
            element.is_added = false;
          });
        },
        setPrimaryActor: function(role_id) {
          if ($(`.js-checkbox-default.js-va-id-${role_id}`).is(":checked")) {
            episodeList.addDefaultRoleId(role_id);
          } else {
            episodeList.deleteDefaultRoleId(role_id);
          }
        },
        updateVoiceActors: function (voice_actor_list) {
          this.voice_actors = voice_actor_list;
        },
      }
    });

    //Select Episodes
    $(".js-data-episode-all").on("click", function() {
      if ($(this).prop("checked")) {
        episodeList.addAll();
      } else {
        episodeList.deleteAll();
      }
    });

    // 数値入力でエピソードを選択するときに、エンターキーで反映できるようにする
    $(".js-episode-numbers").on("keypress", function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        submitEpisodeNumbers();
      }
    });

    //submiit Eps Numbers
    $(".js-episode-numbers-submit").on("click", function() {
      submitEpisodeNumbers();
    });

    const submitEpisodeNumbers = () => {
      const num = $(".js-episode-numbers").val().split(",");
      const numAry = [];
      for (let i = 0; i < num.length; i++) {
        if (num[i].indexOf("-") > -1) {
          const numbers = num[i].split("-");
          for (let g = numbers[0]; g <= numbers[1]; g++) {
            numAry.push(parseInt(g) - 1);
          }
        } else {
          numAry.push(parseInt(num[i]) - 1);
        }

        episodeList.addByIndices(numAry);
      }
    }

    // show/hide Voice Actor
    $(document).on("click", ".js-data-actor", function (e) {
      e.stopPropagation();
      const $block = $(this).next(".js-edit-character-list-block");
      if ($(this).hasClass("on")) {
        $(this).removeClass("on");
        $block.hide();
      } else {
        $(".js-data-actor").removeClass("on");
        $(".js-edit-character-list-block").hide();
        $(this).addClass("on");
        $block.css({bottom: (-1 * ($block.height() - 1))}).show();
      }
    });
  }
});
