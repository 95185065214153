"use strict"

const $ = window.$

const RESTACK_USERS_NEXT_HTML = '<div class="next js-modal-next"><p><i class="fas fa-spinner fa-spin"></i>loading...</p></div>'
let $restacksModal = null
let restackUsersJqXHR = null
let userClickType = 'stacks-modal-user'

function generatRestacksModal(appendedSelector, device, pagename) {
  if ($restacksModal !== null) {
    return
  }

  $restacksModal = $('<div class="restacks-modal"></div>')
  const $list = $('<div class="list js-modal-list"></div>')
  const $background = $('<div class="modal_bg"></div>')
  const $contents = $('<div class="modal_contents">')
  const $head = $('<div class="head"><h3>Restacks</h3></div>')
  const $close = $('<div class="modal_btn_close"><i class="fas fa-times-circle"></i></div>')
  $contents.append($head, $list, $close)
  $restacksModal.append($background, $contents)
  $(appendedSelector).append($restacksModal)

  // infinity scroll
  $list.scroll(() => {
    const $next = $restacksModal.find('.js-modal-next')
    if (
      restackUsersJqXHR === null &&
      $next.exists() &&
      $next.offset().top < $list.height() + $list.offset().top
    ) {
      const id = $restacksModal.data('id')
      const offset = $restacksModal.find('.js-modal-item').length
      addRestackUsersList(id, offset)
    }
  })

  // close modal
  const closeModal = () => {
    $restacksModal.fadeOut()
    $('body').css('overflow-y','auto')
  }
  $background.on('click', closeModal)
  $close.on('click', closeModal)

  // define ga event
  if (device) {
    userClickType += `-${device}`
  }
  if (pagename) {
    userClickType += `-${pagename}`
  }
}

function openRestacksModal(id) {
  if ($restacksModal.data('id') !== id) {
    $restacksModal.data('id', id)
    $restacksModal.find('.js-modal-list').empty().append(RESTACK_USERS_NEXT_HTML)
    addRestackUsersList(id, 0)
  }

  $restacksModal.fadeIn()
  $('body').css('overflow-y', 'hidden')
}

function addRestackUsersList(id, offset) {
  if (restackUsersJqXHR &&
    restackUsersJqXHR.readyState > 0 &&
    restackUsersJqXHR.readyState < 4) {
      restackUsersJqXHR.abort()
      restackUsersJqXHR = null
  }

  restackUsersJqXHR = requestRestackUsers(id, offset)
    .done((data) => {
      $restacksModal.find('.js-modal-next').remove()
      const $list = $restacksModal.find('.js-modal-list')
      const items = []

      for (const item of data.items) {
        const profilePath = `/profile/${item.username}`
        const $item = $('<div class="item js-modal-item"></div>')
        const $image = $('<a class="image"></a>').css('background-image', `url('${item.image}')`).attr('href', profilePath)
        const $username = $('<div class="username"></div>')

        const $usernameLink = $('<a></a>').attr('href', profilePath).text(item.username).appendTo($username)
        if (item.friend == 2) {
          $('<span class="tag">Me</span>').appendTo($username)
        } else if (item.friend == 1) {
          $('<span class="tag">Friends</span>').appendTo($username)
        }

        // set ga click event
        $image.addClass('ga-click').attr('data-ga-click-type', userClickType)
        $usernameLink.addClass('ga-click').attr('data-ga-click-type', userClickType)

        $item.append($image, $username)
        items.push($item)
      }

      if (data.items.length >= 20) {
        items.push(RESTACK_USERS_NEXT_HTML)
      }

      $list.append(items)
    })
    .always(() => {
      restackUsersJqXHR = null
    })
}

function requestRestackUsers(id, offset) {
  return $.ajax({
    url: '/stacks/restack_users.json',
    data: {id, offset},
    type: 'GET',
    dataType: 'json'
  })
}

module.exports = {
  generatRestacksModal,
  openRestacksModal
}
