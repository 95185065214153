import incrementalSearch from "../../common/modules/incrementalSearch"
const $ = window.$

let isGlobalSearchInitialized = false

function initializeGlobalSearch() {
  if (!isGlobalSearchInitialized) {
    const $topSearchValue = $("#topSearchValue") // 検索の種類のドロップダウン
    const $topSearchText = $("#topSearchText")   // 検索のテキストエリア
    const vue = incrementalSearch.initializeVueModel("#top-search-bar", {
      type: $topSearchValue.val(),
      query: "keyword",
      resultPosition: {
        left: 0,
        top: $("#searchBar").outerHeight(),
        width: $topSearchValue.width() + $topSearchText.width(),
      },
      generateResultPageParams: (type, keyword) => {
        if (type === "club") {
          return `action=find&q=${encodeURIComponent(keyword)}&cat=${encodeURIComponent(type)}`
        } else if (type === "forum") {
          return `u=&uloc=1&loc=-1&q=${encodeURIComponent(keyword)}&cat=${encodeURIComponent(type)}`
        } else if (type === "store") {
          return `keyword=${encodeURIComponent(keyword)}&cat=${encodeURIComponent(type)}`
        } else {
          return `q=${encodeURIComponent(keyword)}&cat=${encodeURIComponent(type)}`
        }
      },
    })
    $topSearchValue.on("change", () => {
      $topSearchText.focus()
    })

    // 初期化後にフォーカスがテキストエリアから外れてしまうので、改めてフォーカスを当てる
    // (Vue が既存の DOM にマウントする際に DOM を再構築するためかも知れない)
    vue.$nextTick(() => {
      $("#topSearchText").focus()
    })

    isGlobalSearchInitialized = true
  }
}

$(() => {
  // 右上のグローバル検索窓
  const $topSearch = $("#top-search-bar")
  if ($topSearch.exists()) {
    // グローバル検索窓は全ページで表示されるが、毎回使用されるわけではない。
    // Vue の初期化はそこそこ重い処理なので、必要になった段階で初めて初期化が走るようにする。
    // "focus" のみではなくて "keydown" も初期化のトリガにしているのは、
    // "focus" のイベントがバインドされる前にテキストエリアにフォーカスがあてられた時に初期化が走らず、検索ができなくなるため。
    $("#topSearchText")
      .on("focus", initializeGlobalSearch)
      .on("keydown", initializeGlobalSearch)
  }

  // 各タイプごとのAdvanced検索
  const $advancedSearch = $(".js-advancedsearch")
  if ($advancedSearch.exists()) {
    const $query = "q"
    incrementalSearch.initializeVueModel(".js-advancedsearch", {
      type: $advancedSearch.data("type"),
      query: $query,
      resultPosition: {
        left: 0,
        top: $(".js-advancedSearchText").outerHeight(),
        width: $(".js-advancedSearchText").outerWidth(),
      },
      generateResultPageParams() {
        return $(".js-advancedsearch").serialize()
      }
    })

    // 以下Advanced項目の状態によって、検索ボタンをアクティブ/非アクティブ化する処理
    const checkAnimeMangaForm = () => {
      const selects = ["type", "score", "status", "p", "r", "sy", "ey"]
      const $text = $("[name='q']")
      const $tag = $("[name='tag']")
      if ($text.exists() && encodeURI($text.val()).replace(/%[0-9A-F]{2}/g, "*").length > 2) {
        return true
      }
      for (const i in selects) {
        if ($(`[name='${selects[i]}']`).val() > 0) {
          return true
        }
      }
      if ($tag.exists() && $tag.val().length > 2) {
        return true
      }
      if ($("input:checkbox[name='genre[]']:checked").exists()) {
        return true
      }
      if ($("input:checkbox[name='genre_ex[]']:checked").exists()) {
        return true
      }

      return false
    }

    const setAnimeMangaSearchButton = () => {
      if ($("form#advancedsearch").length === 0) {
        return
      }
      if (checkAnimeMangaForm()) {
        $("input[type=submit],button[type=submit]").removeClass("notActive")
      } else {
        $("input[type=submit],button[type=submit]").addClass("notActive")
      }
    }

    $("form#advancedsearch").on("submit", function() {
      return checkAnimeMangaForm()
    }).on("change", "input[type!=text], select", function() {
      setAnimeMangaSearchButton()
    }).on("keyup", "input[type=text]", function() {
      setAnimeMangaSearchButton()
    })

    setAnimeMangaSearchButton()
  }
})
