import * as types from "./mutation-types"
import {tax} from "../../modules/tax"
import { addRefererToCookie } from "../../modules/viewer"

export const NAMESPACE = "TAX_CALCULATION_MODAL"

export const PRIVATE_TYPES = {
  SETUP: "SETUP",

  // ユーザが選択した住所を元に税金をサーバに問い合わせる
  REQUEST_TAX_WITH_SELECTED_ADDRESS: "REQUEST_TAX_WITH_SELECTED_ADDRESS",

  TOGGLE_REQUESTING_STATUS: "TOGGLE_REQUESTING_STATUS",
  SET_ERROR: "SET_ERROR",
  SET_RESIDENTIAL_AREA: "SET_RESIDENTIAL_AREA",

  // 計算が終了したことを通知
  FINISH: "FINISH",

  // モーダル類の表示非表示
  SHOW_LOADING_MODAL: "SHOW_LOADING_MODAL",
  SHOW_SELECT_ADDRESS_MODAL: "SHOW_SELECT_ADDRESS_MODAL",
  SHOW_PAYMENT_MODAL: "SHOW_PAYMENT_MODAL",
}

export default function() {
  return {
    namespaced: true,

    state: {
      isPaymentModal: true,
      modals: {
        showLoadingModal: false,
        showSelectAddressModal: false,
        showPaymentModal: false,
      },
      itemType: null,
      itemIds: [],
      itemTotalAmount: 0,
      isRequesting: false,
      error: null,
      residentialArea: {
        registered: null,
        current: null,
        registeredName: null,
        currentName: null,
      },
      promiseCallbacks: {
        resolve: null,
        reject: null,
      },
      selectedCountry: null,
      isDelegated: false,
    },

    mutations: {
      /**
       * - 税金計算に必要な itemIds, itemType, itemTotalAmount を保存する
       * - 税金計算が終了したことを通知するためのコールバックをセットアップする
       */
      [PRIVATE_TYPES.SETUP](state, {itemType, itemIds, itemTotalAmount, resolve, reject}) {
        state.itemType = itemType
        state.itemIds = itemIds
        state.itemTotalAmount = itemTotalAmount
        state.promiseCallbacks.resolve = resolve
        state.promiseCallbacks.reject = reject
      },

      /**
       * ユーザに選ばせる residentialArea をセットする
       */
      [PRIVATE_TYPES.SET_RESIDENTIAL_AREA](state, {registered, registeredName, current, currentName}) {
        state.residentialArea.registered = registered
        state.residentialArea.registeredName = registeredName
        state.residentialArea.current = current
        state.residentialArea.currentName = currentName
      },

      [PRIVATE_TYPES.TOGGLE_REQUESTING_STATUS](state, {isRequesting}) {
        state.isRequesting = isRequesting
      },
      [PRIVATE_TYPES.SET_ERROR](state, {error}) {
        state.error = error
      },

      /**
       * 税金計算終了処理
       * 全てのモーダルを閉じて、Promise を resolve することで計算結果を親に通知する
       */
      [PRIVATE_TYPES.FINISH](state, {result, selectedCountry}) {
        state.modals.showLoadingModal = false
        state.modals.showSelectAddressModal = false
        state.selectedCountry = selectedCountry
        state.isDelegated = result["is_delegated"]
        state.promiseCallbacks.resolve(result["items"])
      },

      /**
       * Payment モーダルを使用するか
       */
      [types.USE_PAYMENT_MODAL](state, status = true) {
        state.isPaymentModal = status
      },

      /**
       * モーダル類の表示非表示
       */
      [PRIVATE_TYPES.SHOW_LOADING_MODAL](state, show = true) {
        state.modals.showLoadingModal = show
      },
      [PRIVATE_TYPES.SHOW_SELECT_ADDRESS_MODAL](state, show = true) {
        state.modals.showSelectAddressModal = show
      },
      [types.SHOW_PAYMENT_MODAL](state, show = true) {
        state.modals.showPaymentModal = show
      },
    },

    actions: {
      /**
       * 外部から呼ばれるアクション
       */
      [types.GET_TAX]({ state, commit }, { itemType, itemIds, itemTotalAmount, selectedCountry }) {
        return new Promise(async (resolve, reject) => {
          commit(PRIVATE_TYPES.SETUP, {itemType, itemIds, itemTotalAmount, resolve, reject})
          commit(PRIVATE_TYPES.SHOW_LOADING_MODAL, true)

          try {
            const response = await tax({
              itemType: state.itemType,
              itemIds: state.itemIds,
              itemTotalAmount: state.itemTotalAmount,
              selectedCountry: selectedCountry
            })
            commit(PRIVATE_TYPES.FINISH, {result: response.data, selectedCountry: selectedCountry})
          } catch (e) {
            // TODO: エラーによって出すモーダルを変える
            if (e.name === "BillingCountryError" || e.name === "NoPaymentSettingError") {
              if (state.isPaymentModal) {
                // モーダルを表示
                commit(types.SHOW_PAYMENT_MODAL, true) 
              } else {
                // Payment pageへ遷移
                location.href = "/account/payment?_location=mal_atf_popup"
              }
            } else if (e.name === "UnmatchedCountryError") {
              const registeredName = e.registeredName + " (based on your billing country)"
              const registered = e.registered
              const currentName = e.currentName + " (based on your IP address)"
              const current = e.current
              commit(PRIVATE_TYPES.SET_RESIDENTIAL_AREA, {
                registered: registered,
                registeredName: registeredName,
                current: current,
                currentName: currentName
              })
              commit(PRIVATE_TYPES.SHOW_SELECT_ADDRESS_MODAL, true)
            } else if (e.name === "LoginRequiredError") {
              // ここで中断し、Loading モーダルを消さない
              return
            }
          }

          commit(PRIVATE_TYPES.SHOW_LOADING_MODAL, false)
        })
      },

      /**
       * ユーザが選択した住所を元に税金をサーバーに問い合わせる
       * 返ってきた結果を元に FINISH を呼んで税金計算を終了する
       */
      async [PRIVATE_TYPES.REQUEST_TAX_WITH_SELECTED_ADDRESS]({state, commit}, {selectedAddress}) {
        commit(PRIVATE_TYPES.TOGGLE_REQUESTING_STATUS, {isRequesting: true})

        try {
          // TODO: selectedAddress をもとにリクエスト投げる
          const response = await tax({itemType: state.itemType, itemIds: state.itemIds, itemTotalAmount: state.itemTotalAmount, selectedCountry: selectedAddress})
          commit(PRIVATE_TYPES.FINISH, {result: response.data, selectedCountry: selectedAddress})
        } catch (e) {
          commit(PRIVATE_TYPES.SET_ERROR, {error: e.message})
        } finally {
          commit(PRIVATE_TYPES.TOGGLE_REQUESTING_STATUS, {isRequesting: false})
        }
      },
    },
  }
}
