// Anime Adapted to Manga (/adapted) 用のJS処理
// MEMO: 一部の処理は anime/season.js と重複するため定義しない
const $ = window.$;
import _ from "lodash";

$(function() {
  jQuery.fn.extend({
    // 選択ボックスの状態を切り替える
    toggleMalSelect: function () {
      const $button = $(this);
      $button.toggleClass('selected');
      $button.fixMalSelectIndication();
    },
    // 除外状態つき選択ボックスの状態を切り替える
    toggleMalSelectWithCrossed: function () {
      const $button = $(this);
      if ($button.hasClass('selected')) {
        // selected -> crossed
        $button.removeClass('selected');
        $button.addClass('crossed');
      }
      else if ($button.hasClass('crossed')) {
        // crossed -> none
        $button.removeClass('selected');
        $button.removeClass('crossed');
      }
      else {
        // none -> selected
        $button.addClass('selected');
        $button.removeClass('crossed');
      }
      $button.fixMalSelectIndication();
    },
    // 選択ボックスの表示内容を現在状態にあわせて変更する
    fixMalSelectIndication: function() {
      const $button = $(this);
      if ($button.hasClass('on') || $button.hasClass('selected')) {
        $button.children('.fa-check').show();
        $button.children('.fa-xmark').hide();
        return;
      }
      if ($button.hasClass('crossed')) {
        $button.children('.fa-check').hide();
        $button.children('.fa-xmark').show();
        return;
      }
      $button.children('.fa-xmark').hide();
      $button.children('.fa-check').hide();
    },
    // 選択ボックスの表示状態を文字列で返却する
    getMalSelectStatus: function() {
      const $button = $(this);
      if ($button.hasClass('selected')) {
        return 'selected';
      }
      if ($button.hasClass('crossed')) {
        return 'crossed';
      }
      return '';
    }
  });

  // フィルタされたジャンルidのリストを取得する
  const getFilteredGenreIds = function() {
    const checkIds = [];
    const hideIds = [];

    $('.js-adapted-sort-order-block.js-genres .js-btn-sort-order').each(function(){
      const $button = $(this);
      const rel = parseInt($button.attr('rel'));
      if ($button.hasClass('crossed')) {
        hideIds.push(rel);
      }
      else if ($button.hasClass('selected')) {
        checkIds.push(rel);
      }
    });

    return {
      check_ids: checkIds,
      hide_ids: hideIds,
    };
  }

  // プルダウン色変更 - アニメリスト
  const changeMyanimelistPulldownColor = function() {
    const $filter = $('.js-btn-show-sort.myanimelist');
    if($('.js-adapted-sort-order-block.js-myanimelist .js-btn-sort-order[rel=All]').hasClass('selected')) {
      $filter.removeClass('filtered')
    } else {
      $filter.addClass('filtered')
    }
  }
  // プルダウン色変更 - マンガリスト
  const changeMymangalistPulldownColor = function() {
    const $filter = $('.js-btn-show-sort.mymangalist');
    if($('.js-adapted-sort-order-block.js-mymangalist .js-btn-sort-order[rel=All]').hasClass('selected')) {
      $filter.removeClass('filtered')
    } else {
      $filter.addClass('filtered')
    }
  }
  // プルダウン色変更 - ジャンル
  const changeGenrePulldownColor = function() {
    const $filter = $('.js-btn-show-sort.genres');
    let filterGenreIds = getFilteredGenreIds();

    if(filterGenreIds.check_ids.length > 0 || filterGenreIds.hide_ids.length > 0) {
      $filter.addClass('filtered')
    } else {
      $filter.removeClass('filtered')
    }
  }

  // Ajax action for filter
  const submitAdaptedConfig = function(obj) {
    const type     = obj.type;
    const id       = obj.id;
    const url      = obj.url;
    const name     = obj.name;
    const dataType = obj.dataType;

    let data = {};
    data[name] = id;
    data.filter = {};
    data.filter.finished = $('.js-btn-show-finished').getMalSelectStatus();
    data = JSON.stringify(data);

    $.ajax({
      url: url,
      type: "PUT",
      dataType: dataType,
      data: data,
      timeout: 10000,
      success: function() {
        if (type == 'sort' || type == 'finished') {
          reloadAdaptedPage();
        }
      },
      error: function(jqXHR) {
        const errorMessages = $.parseJSON(jqXHR.responseText).errors;
        alert(errorMessages[0].message);
      },
    });
  }

  // 各種フィルター変更を伴う際のページリロード処理
  const reloadAdaptedPage = function() {
    let url = new URL(location.href);
    // 現在1ページ目の場合はリロード
    if (url.searchParams.get('page') <= 1) {
      window.location.reload();
      return;
    }
    // 現在2ページ目以降の場合は1ページ目に遷移する
    url.searchParams.delete('page');
    location.href = url;
  }

  // ナビゲーションバー上のボタン
  if ($('.js-navi-adapted').length > 0) {
    // Finished Manga ボタン
    $('.js-btn-show-finished').on('click', function() {
      const $button = $(this);
      $button.toggleMalSelectWithCrossed();
      const data = {
        id   : getFilteredGenreIds(),
        name : "genre_filter",
        type : "finished",
        url  : "/manga/adapted/config/filter.json",
      };
      submitAdaptedConfig(data);
      //Show ajax loader
      $('.ajax-loader-wrapper').addClass('show');
    });
    // 各種フィルターメニュー表示ボタン
    $('.js-navi-adapted .js-btn-show-sort').on('click', function() {
      $(document).off('click');
      const id = $(this).attr('rel');
      const $targetBlock = $(`.js-navi-adapted .js-adapted-sort-order-block[rel=${id}]`);
      if ($targetBlock.is(':visible')) {
        $(`.js-navi-adapted .js-adapted-sort-order-block[rel=${id}]`).hide();
        $('.js-navi-adapted .js-btn-show-sort').removeClass('on');
      } else {
        $('.js-navi-adapted .js-adapted-sort-order-block').hide();
        $(`.js-navi-adapted .js-adapted-sort-order-block[rel=${id}]`).show();
        $('.js-navi-adapted .js-btn-show-sort').removeClass('on');
        $(this).addClass('on');

        const showDialogInterval = setInterval(function() {
          $('.js-navi-adapted .js-adapted-sort-order-block').on('click', function(e) {
            e.stopPropagation();
          });
          $(document).on('click.adapted-sort-order', function() {
            const $shown = $(".js-adapted-sort-order-block:visible");
            if ($shown.length < 1) return;
            const rel = $shown.attr('rel');
            $shown.removeClass('show');
            $shown.hide();
            $(`.js-adapted-sort-order-block[rel=${rel}]`).trigger('close');
            $('.js-navi-adapted .js-btn-show-sort').removeClass('on');
            $(document).off('click');
            return false;
          });
          clearInterval(showDialogInterval);
        }, 500);
      }
    });
  }
  
  // 各種フィルタ用の処理
  if ($('.js-adapted-sort-order-block').length > 0) {
    // メニューを閉じる際のアクションを定義する
    $('.js-adapted-sort-order-block').on('close', function() {
      $(this).hide();
      $('.js-navi-adapted .js-btn-show-sort').removeClass('on');
      $(document).off('click.adapted-sort-order');
      if($(this).hasClass('changed')) {
        $('.ajax-loader-wrapper').addClass('show');
        reloadAdaptedPage();
      }
    });
    // メニューを閉じるボタンのクリック
    $('.js-adapted-sort-order-block .js-btn-close').on('click', function() {
      $(this).parents('.js-adapted-sort-order-block').trigger('close');
    });
    // アニメリストフィルタボタン
    $('.js-adapted-sort-order-block.js-myanimelist .js-btn-sort-order').on('click', function() {
      const $button = $(this);
      if ($button.attr('rel') === 'All') {
        const $target = $('.js-adapted-sort-order-block.js-myanimelist .js-btn-sort-order')
        if ($target.hasClass('selected')) {
          $target.removeClass('selected');
          $target.fixMalSelectIndication();
          $('.js-btn-show-sort.myanimelist').addClass('filtered');
        }
        else {
          $target.addClass('selected');
          $target.fixMalSelectIndication();
          $('.js-btn-show-sort.myanimelist').removeClass('filtered');
        }
      }
      else {
        const $target = $('.js-adapted-sort-order-block.js-myanimelist .js-btn-sort-order[rel=All]');
        $target.removeClass('selected');
        $target.fixMalSelectIndication();
        $button.toggleMalSelect();
      }
      $('.js-adapted-sort-order-block.js-myanimelist').addClass('changed');
      // プルダウン色変更
      changeMyanimelistPulldownColor();

      const checkIds = [];
      $('.js-adapted-sort-order-block.js-myanimelist .js-btn-sort-order.selected').each(function(){
        checkIds.push($(this).attr('rel'));
      });

      const data = {
        id   : {check_ids: checkIds},
        name : "myanimelist_filter",
        type : "myanimelist",
        url  : "/manga/adapted/config/myanimelist_filter.json",
      }
      submitAdaptedConfig(data);
    });
    // マンガリストフィルタボタン
    $(".js-adapted-sort-order-block.js-mymangalist .js-btn-sort-order").on('click', function() {
      const $button = $(this);
      if ($button.attr('rel') === "All") {
        const $target = $('.js-adapted-sort-order-block.js-mymangalist .js-btn-sort-order')
        if ($target.hasClass('selected')) {
          $target.removeClass('selected');
          $target.fixMalSelectIndication();
          $('.js-btn-show-sort.mymangalist').addClass('filtered');
        }
        else {
          $target.addClass('selected');
          $target.fixMalSelectIndication();
          $('.js-btn-show-sort.mymangalist').removeClass('filtered');
        }
      }
      else {
        const $target = $('.js-adapted-sort-order-block.js-mymangalist .js-btn-sort-order[rel=All]');
        $target.removeClass('selected');
        $target.fixMalSelectIndication();
        $button.toggleMalSelect();
      }
      $('.js-adapted-sort-order-block.js-mymangalist').addClass('changed');
      // プルダウン色変更
      changeMymangalistPulldownColor();

      const checkIds = [];
      $('.js-adapted-sort-order-block.js-mymangalist .js-btn-sort-order.selected').each(function(){
        checkIds.push($(this).attr('rel'));
      });

      const data = {
        id   : {check_ids: checkIds},
        name : "mymangalist_filter",
        type : "mymangalist",
        url  : "/manga/adapted/config/mymangalist_filter.json",
      }
      submitAdaptedConfig(data);
    });
    // ジャンルフィルタボタン
    $('.js-adapted-sort-order-block.js-genres .js-btn-sort-order').on('click', function() {
      const $button = $(this);
      $button.toggleMalSelectWithCrossed();
      $('.js-adapted-sort-order-block.js-genres').addClass('changed');
      // プルダウン色変更
      changeGenrePulldownColor();

      //Submit
      const data = {
        id   : getFilteredGenreIds(),
        name : "genre_filter",
        type : "genres",
        url  : "/manga/adapted/config/filter.json",
        cId  : $button.attr('rel')
      }
      submitAdaptedConfig(data);
    });
    // ジャンルフィルタ - Clear All ボタン
    $('.js-adapted-sort-order-block.js-genres .clear-all-btn').on('click', function() {
      const $button = $(".js-adapted-sort-order-block.js-genres .js-btn-sort-order");
      $(this).siblings(".selected").removeClass("selected");
      $(this).siblings().children(".fa-check").hide();
      $(this).siblings(".crossed").removeClass("crossed");
      $(this).siblings().children(".fa-xmark").hide();
      $('.js-adapted-sort-order-block.js-genres').addClass('changed');
      // プルダウン色変更
      changeGenrePulldownColor();

      //Submit
      const data = {
        id   : getFilteredGenreIds(),
        name : "genre_filter",
        type : "genres",
        url  : "/manga/adapted/config/filter.json",
        cId  : $button.attr('rel')
      }
      submitAdaptedConfig(data);
    });
    // ソートボタン
    $('.js-adapted-sort-order-block.js-sort .js-btn-sort-order').on('click', function() {
      const $button = $(this);
      const $buttons = $('.js-adapted-sort-order-block.js-sort .js-btn-sort-order');
      $buttons.removeClass('selected');
      $buttons.children('.fa-circle').hide();
      $button.addClass('selected');
      $button.children('.fa-circle').show();

      //Submit
      const data = {
        id   : $button.attr('rel'),
        name : "sort_by",
        type : "sort",
        url  : "/manga/adapted/config/sort.json",
        dataType : "html",
      }
      submitAdaptedConfig(data);

      //Show ajax loader
      $('.ajax-loader-wrapper').addClass('show');
    });
  }

  // リストビューのソート順序
  $(".js-block-list .js-btn-adapted-sort-order").on("click", function () {
    const id = $(this).attr("data-id");
    const $button = $(`.js-adapted-sort-order-block.js-sort .js-btn-sort-order[rel=${id}]`);
    $button.trigger('click');
  });

  // on load page
  if ($('.page-common.adapted')[0]) {
    // プルダウン色変更
    changeMyanimelistPulldownColor();
    changeMymangalistPulldownColor();
    changeGenrePulldownColor();
    $('.js-btn-top').on('click', function(){
      $('html,body').animate({scrollTop: 0});
    });
    // Seasonal view 用の画像調整
    $(window).on('load', function(){
      const $anime = $('.js-seasonal-anime');
      $anime.each(function(){
        const $image = $(this).find('.image');
        const img = new Image();
        img.src = $image.css('background-image').replace(/(url\(|\)|")/g, '');
        const width = img.width;
        const height = img.height;
        if (width > height) {
          $image.css({'background-size': 'contain'});
        }
      });
    });
  }
});
