<template>
  <div>
    <loading-modal
      :is-shown="modals.showLoadingModal"
    ></loading-modal>

    <select-address-modal
      :is-shown="modals.showSelectAddressModal"
      @select="selectAddress"
      @cancel="cancelSelectAddressModal"
      :residential-area="residentialArea"
      :is-requesting="isRequesting"
      :error="error"
    ></select-address-modal>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script type="text/babel">
  import LoadingModal from "../../molecules/LoadingModal.vue"
  import SelectAddressModal from "../../molecules/SelectAddressModal.vue"
  import {PRIVATE_TYPES, NAMESPACE} from "../../../common/stores/tax_calculation_modal"
  import {mapState} from "vuex"

  export default {
    components: {
      LoadingModal,
      SelectAddressModal,
    },
    computed: {
      ...mapState(NAMESPACE, [
        "modals",
        "residentialArea",
        "isRequesting",
        "error",
      ]),
    },
    methods: {
      selectAddress(selectedAddress) {
        this.$store.dispatch(`${NAMESPACE}/${PRIVATE_TYPES.REQUEST_TAX_WITH_SELECTED_ADDRESS}`, {selectedAddress})
      },

      cancelSelectAddressModal() {
        this.$store.commit(`${NAMESPACE}/${PRIVATE_TYPES.FINISH}`, {result: null})
      },
    },
  }
</script>
