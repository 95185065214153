$(function () {
  if ($("body.dbchanges").exists()) {
    $('form').on('submit', function (event) {
      $(this).off("submit", onsubmit).on("submit", false);
    });
  }

  $(document).ready(function () {
    $('.producer-date-box').each((index, element) => {
      switchDateSelector($(element))
    })
  });

  $('.select-producer-year').on('change', (event) => {
    const $this = $(event.target)
    switchDateSelector($this.parent('td'))
  })

  $('.select-producer-month').on('change', (event) => {
    const $this = $(event.target)
    switchDateSelector($this.parent('td'))
  })

  const switchDateSelector = ($parent) => {
    const $year = $parent.find('.select-producer-year')
    const $month = $parent.find('.select-producer-month')
    const $day = $parent.find('.select-producer-day')

    if ($year.val()) {
      $month.prop('disabled', false)
    } else {
      $month.prop('disabled', true)
      $day.prop('disabled', true)
      return
    }

    if ($month.val()) {
      $day.prop('disabled', false)
    } else {
      $day.prop('disabled', true)
    }
  }
})
