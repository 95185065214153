import MalSupportPurchaseButton from "./MalSupportPurchaseButton.vue"
import * as types from "./store/mutation-types"
import store from "./store"

const SVue = window.sVue
const $ = window.$

const selector = ".v-mal-support-purchase-button"

$(() => {
  Array.from(document.querySelectorAll(selector)).forEach(el => {
    const gift = JSON.parse(el.getAttribute("data-gift"))
    const termsHtml = el.getAttribute("data-terms-html")
    const supportUrl = el.getAttribute("data-support-url")
    new SVue({
      el,
      store: store(),
      render: h => h(MalSupportPurchaseButton),
      created() {
        this.$store.commit(types.INITIALIZE, {gift, termsHtml, supportUrl})
      }
    })
  })
})
