<template>
  <div class="root">
    <transition name="modal">
      <div v-show="isShown" class="modal-content">
        <div class="modal-mask" @click="cancel"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-inner">
              <div>
                <img v-lazy="coverImage" width="224">
              </div>
              <div>
                <div class="modal-right-wrap">
                  <p class="modal-title">{{ item.title }}</p>
                  <p class="modal-volume">{{ item.numberingText }}</p>
                  <ul class="modal-price-wrap">
                    <li>
                      <div>Price</div>
                      <div v-if="item.salePrice">
                        <span class="modal-price is-sale">{{ currency.formatSymbolAndPrice(item.price) }}</span>
                        <span class="modal-price-sale">Sale {{ currency.formatSymbolAndPrice(item.salePrice) }}</span>
                      </div>
                      <div v-if="!item.salePrice">
                        <span class="modal-price">{{ currency.formatSymbolAndPrice(item.price) }}</span>
                      </div>
                    </li>
                    <li>
                      <div>Tax</div>
                      <div class="modal-tax">{{ currency.formatSymbolAndPrice(item.tax) }}</div>
                    </li>
                    <li class="modal-price-total-wrap">
                      <div>Total</div>
                      <div>{{ currency.symbol }}<span class="modal-price-total">{{ currency.formatPrice(item.totalAmount) }}</span>{{ currency.name }}</div>
                    </li>
                  </ul>
                  <div class="modal-button-wrap">
                    <div class="modal-error" v-show="error">{{ error }}</div>
                    <template v-if="!isRequesting">
                      <a class="modal-cancel-button" @click="cancel">Cancel</a>
                      <a class="modal-confirm-button" @click="confirm">Confirm Purchase</a>
                    </template>
                    <template v-else>
                      <span class="modal-button-loading"></span>
                    </template>
                    <div class="modal-payment-method-button-wrap">
                      <a v-if="!isRequesting"
                         class="modal-payment-method-button" href="/account/payment">
                        <i class="fa-solid fa-gear" aria-hidden="true"></i> Edit Payment Method
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="modal-close" @click="cancel">Close</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  @import "../../../../../css/variables";
  .modal-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
  }
  .modal-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    font-family: Verdana,Arial;
    position: relative;
    width: 745px;
    margin: 0 auto;
    padding: 40px;
    box-sizing: border-box;
    background-color: $color-pc-bg-ffffff;
    color: $color-pc-text-353535;
    border-radius: 4px;
    transition: all .3s ease;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
  }
  .modal-error {
    display: block;
    max-width: 360px;
    margin-bottom: 8px;
    text-align: right;
    color: $color-pc-text-ff6a86;
    font-size: 12px;
  }
  .modal-inner {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    & > div:first-child{
      padding:0 40px 0 0;
    }
    & > div:last-child{
      flex-grow: 1;
    }
    img {
      vertical-align: bottom;
    }
  }
  .modal-right-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .modal-title {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    line-height: 1.18;
    word-wrap:break-word;
    /* ↓max width を入れないとword-wrap:break-wordが効かない*/
    max-width: 400px;
  }
  .modal-volume {
    font-size: 14px;
    line-height: 1.14;
    margin: 2px 0 0;
    color: $color-pc-text-6d6d6d;
    font-weight: 300;
  }
  .modal-price-wrap {
    display: table;
    font-size: 14px;
    font-weight: 700;
    margin-top: 17px;
    padding: 7px 0 0;
    border-top: 1px solid $color-pc-border-f8f8f8;
    border-bottom: 1px solid $color-pc-border-f8f8f8;
    width: 100%;
    list-style: none;
    & > li {
      display: table-row;
      font-weight: 700;
      & > div {
        display: table-cell;
        padding-bottom: 7px;
      }
      & > div:first-of-type {
        width: 50px;
        text-align: right;
        padding-right: 12px;
      }
    }
  }
  .modal-price {
    &.is-sale {
      font-size: 12px;
      font-weight: 300;
      text-decoration: line-through;
    }
  }
  .modal-price-sale {
    color: $color-pc-text-c0392b;
  }
  .modal-tax {
    font-weight: 700;
  }
  .modal-price-total-wrap {
    color: $color-pc-text-c0392b;
  }
  .modal-price-total {
    font-weight: 700;
    font-size: 24px;
  }
  .modal-taxamo {
    float: right;
    background: url(/images/manga_store/taxamo_logo@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    display: block;
    width: 73px;
    height: 14px;
    text-indent: -9999px;
  }
  .modal-button-wrap {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .modal-payment-method-button-wrap {
    display: block;
    text-align: right;
    margin-top: 12px;
  }
  a.modal-payment-method-button {
    font-size: 12px;
    color: $color-pc-text-323232;
    text-decoration: underline;
    &:hover {
    text-decoration: none;
    }
  }
  .modal-cancel-button{
    border: 2px solid $color-pc-border-2e51a2;
    color: $color-pc-text-2e51a2;
    font-size: 16px;
    text-align: center;
    display: inline-block;
    width: 168px;
    height: 36px;
    line-height: 33px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: 700;
    text-decoration: none;
    &:hover {
      color: $color-pc-text-2e51a2;
      background-color: $color-pc-bg-e1e7f5;
      text-decoration: none;
    }
  }
  .modal-confirm-button {
    border: 2px solid $color-bg-store-purchase-button;
    background-color: $color-bg-store-purchase-button;
    color: $color-white;
    font-size: 16px;
    text-align: center;
    display: inline-block;
    width: 180px;
    height: 36px;
    line-height: 33px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 2px;
    font-weight: 700;
    margin-left: 20px;
    text-decoration: none;
    opacity: 1;
    &:hover {
      color: $color-white;
      text-decoration: none;
      opacity: 0.7;
    }
  }
  .modal-button-loading {
    display: block;
    width: 340px;
    height: 44px;
    background: url(/images/xmlhttp-loader.gif?v=161115001) no-repeat center center;
    background-size: 16px;
    vertical-align: middle;
    text-indent: -99999px;
  }

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    content: '';
    display: block;
    width: 29px;
    height: 29px;
    background: url(/images/manga_store/pc/modal_close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
    cursor: pointer;
  }

  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .dark-mode {
    @import "../../../../../css/colors_dark";
    .modal-container {
      background-color: $color-pc-bg-ffffff;
      color: $color-pc-text-353535;
    }
    .modal-error {
      color: $color-pc-text-ff6a86;
    }
    .modal-volume {
      color: $color-pc-text-6d6d6d;
    }
    .modal-price-wrap {
      border-top: 1px solid $color-pc-border-f8f8f8;
      border-bottom: 1px solid $color-pc-border-f8f8f8;
    }
    .modal-price-sale {
      color: $color-pc-text-c0392b;
    }
    .modal-price-total-wrap {
      color: $color-pc-text-c0392b;
    }
    a.modal-payment-method-button {
      color: $color-pc-text-323232;
    }
    .modal-cancel-button{
      border: 2px solid $color-pc-border-2e51a2;
      color: $color-pc-text-2e51a2;
      &:hover {
        color: $color-pc-text-2e51a2;
        background-color: $color-pc-bg-e1e7f5;
        text-decoration: none;
      }
    }
    .modal-confirm-button {
      color: $color-white;
      &:hover {
        color: $color-white;
        text-decoration: none;
        opacity: 0.7;
      }
    }
  }
</style>

<script type="text/babel">
  import * as book from "../../common/modules/book"

  export default {
    props: {
      isShown: Boolean,
      isRequesting: Boolean,
      item: Object,
      currency: Object,
      error: String,
      paymentMethod: String,
      isDelegated: Boolean,
    },
    computed: {
      coverImage() {
        const imageSize = (window.devicePixelRatio > 1) ? book.COVER_IMAGE_SIZE_L_2X : book.COVER_IMAGE_SIZE_L
        return {
          src: book.generateCoverImageUrl(this.item.coverImageBaseUrl, imageSize),
          loading: "/images/xmlhttp-loader.gif?v=171101"
        }
      },
    },
    methods: {
      cancel() {
        if (!this.isRequesting) {
          this.$emit('cancel')
        }
      },
      confirm() {
        this.$emit('confirm')
      }
    }
  }
</script>
