const $ = window.$;
$(function () {

  const hasTouchEvent = ("ontouchstart" in window);

  //-----------------------
  //merchandise Slider
  //-----------------------
  const merchandiseSlider = function (selectorName, btnWidth, slideMargin) {
    const $animeSlideBlock = $(`#${selectorName}.merchandise-slide-block`);
    console.log($animeSlideBlock);

    if (!$animeSlideBlock.length) {
      return;
    }
    const option = $animeSlideBlock.data("json");
    var btnWidth = btnWidth || option.btnWidth;
    var slideMargin = slideMargin || option.margin;

    $animeSlideBlock.css({ width: option.width });
    $animeSlideBlock.find(".merchandise-slide-outer").css({ width: option.width });
    $animeSlideBlock.find(".merchandise-slide").css({ width: option.width });

    const moveSlideNum = $animeSlideBlock.find(".js-merchandise-slide").data("slide") || 4;//How many move

    const animeW = $animeSlideBlock.find(".merchandise-slide li.btn-merchandise").eq(0).outerWidth();
    const animeCount = $animeSlideBlock.find(".merchandise-slide li.btn-merchandise").length;//count total Anime
    const animeWidth = animeW + slideMargin;

    const setSlideWidth = animeWidth * (animeCount + moveSlideNum);//Slider Outer Width
    $animeSlideBlock.find(".merchandise-slide").width(setSlideWidth);

    //button
    const $sliderBtnLeft = $animeSlideBlock.find(".btn-merchandise-slide-side.left");
    const $sliderBtnRight = $animeSlideBlock.find(".btn-merchandise-slide-side.right");

    // hide button
    if (animeCount < moveSlideNum + 1) {
      $sliderBtnLeft.hide();
      $sliderBtnRight.hide();
    } else if (!hasTouchEvent) {
      const hideBtnTimer = setInterval(function () {
        $sliderBtnLeft.css({ left: -1 * btnWidth, opacity: 0 });
        $sliderBtnRight.css({ right: -1 * btnWidth, opacity: 0 });
        clearInterval(hideBtnTimer);
      }, 1500);
    }

    //click
    $sliderBtnLeft.find(".btn-inner").on("click", function (e) {
      const btn = {
        direction: "left",
        button: $(this)
      };
      moveSlideAnime(btn);
    });
    $sliderBtnRight.find(".btn-inner").on("click", function (e) {
      const btn = {
        direction: "right",
        button: $(this)
      };
      moveSlideAnime(btn);
    })

    //hover
    if (!hasTouchEvent) {
      $animeSlideBlock.find(".merchandise-slide-outer").on("mouseover", function () {
        $sliderBtnLeft.css({ left: 0, opacity: 1 });
        $sliderBtnRight.css({ right: 0, opacity: 1 });
      }).on("mouseout", function () {
        $sliderBtnLeft.css({ left: -1 * btnWidth, opacity: 0 });
        $sliderBtnRight.css({ right: -1 * btnWidth, opacity: 0 });
      });

      $sliderBtnLeft.on("mouseover", function () {
        $sliderBtnLeft.css({ left: 0, opacity: 1 });
        $sliderBtnRight.css({ right: -1 * btnWidth, opacity: 0 });
      }).on("mouseout", function () {
        $sliderBtnRight.css({ right: -1 * btnWidth, opacity: 0 });
        $sliderBtnLeft.css({ left: -1 * btnWidth, opacity: 0 });
      });

      $sliderBtnRight.on("mouseover", function () {
        $sliderBtnRight.css({ right: 0, opacity: 1 });
        $sliderBtnLeft.css({ left: -1 * btnWidth, opacity: 0 });
      }).on("mouseout", function () {
        $sliderBtnRight.css({ right: -1 * btnWidth, opacity: 0 });
        $sliderBtnLeft.css({ left: -1 * btnWidth, opacity: 0 });
      });
    }

    // slide function
    const moveSlideAnime = function (obj) {
      const direction = obj.direction;
      const $btnInner = obj.button;
      $btnInner.hide();

      if (direction == "right") {
        var animeSlide = [];
        $animeSlideBlock.find(".merchandise-slide li.btn-merchandise").each(function () {
          animeSlide.push($(this));
        });

        for (var i = 0; i < moveSlideNum; i++) {
          animeSlide[i].clone(true).insertAfter($animeSlideBlock.find(".merchandise-slide li.btn-merchandise:last"));
        }

        $animeSlideBlock.find(".merchandise-slide").css("marginLeft", "0px");

        $animeSlideBlock.find(".merchandise-slide").animate({
          marginLeft: `${-1 * animeWidth * moveSlideNum}px`
        }, {
          duration: 500,
          easing: "swing",
          complete: function () {
            const getMarginLeftVal = parseInt($animeSlideBlock.find(".merchandise-slide").css("marginLeft").replace("px", ""));
            for (let i = 0; i < moveSlideNum; i++) {
              $animeSlideBlock.find(".merchandise-slide").css("marginLeft", `${getMarginLeftVal + animeWidth * moveSlideNum}px`);
              $animeSlideBlock.find(".merchandise-slide li.btn-merchandise:first").remove();
            }
            $btnInner.show();
          }
        });
      } else {
        var animeSlide = [];
        $animeSlideBlock.find(".merchandise-slide li.btn-merchandise").each(function () {
          animeSlide.push($(this));
        });
        animeSlide.reverse();

        for (var i = 0; i < moveSlideNum; i++) {
          animeSlide[i].clone(true).insertBefore($animeSlideBlock.find(".merchandise-slide li.btn-merchandise:first"));
        }

        const marginLeftVal = `${-1 * animeWidth * moveSlideNum}px`;
        $animeSlideBlock.find(".merchandise-slide").css("marginLeft", marginLeftVal);

        $animeSlideBlock.find(".merchandise-slide").animate({
          marginLeft: "0px"
        }, {
          duration: 500,
          easing: "swing",
          complete: function () {
            for (let i = 0; i < moveSlideNum; i++) {
              $animeSlideBlock.find(".merchandise-slide li.btn-merchandise:last").remove();
            }
            $btnInner.show();
          }
        });
      }
    };
  }


  if ($("#amazon-slide-block")[0]) {
    merchandiseSlider('amazon-slide-block');
  }
  if ($("#tom-slide-block")[0]) {
    merchandiseSlider('tom-slide-block');
  }
});
