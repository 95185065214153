const _ = window._;
const $ = window.$;

const INTERNAL_PARAMS = ["_location", "_type", "_id", "_u"];

function parseUrlParams() {
  const url = decodeURIComponent(window.location.search.substr(1));
  const params = {};

  _.each(url.split("&"), function (segment) {
    const splitted = segment.split("=");
    let key = splitted[0], value = splitted[1] === undefined ? true : splitted[1];

    if (key === "") {
      return;
    }

    if (key.substr(-2) === "[]") {
      if (params[key] === undefined) {
        params[key] = [value];
      } else {
        params[key].push(value);
      }
    } else {
      params[key] = value;
    }
  });

  return params;
}

function removeInternalParams() {
  const params = parseUrlParams();
  let flag = false;
  for (const key in params) {
    if (_.includes(INTERNAL_PARAMS, key)) {
      delete params[key];
      flag = true;
    }
  }

  if (!flag) {
    return;
  }

  let removed = $.param(params);
  if (removed !== "") {
    removed = `?${removed}`;
  }

  const url = window.location.href.replace(window.location.search, removed);
  if (typeof window.history.replaceState === "function") {
    window.history.replaceState(null, document.title, url);
  }
}

removeInternalParams();
