<template>
  <div class="root">
    <transition name="modal">
      <div class="modal-content" v-show="showConfirmationModal">
        <div class="modal-mask" @click="cancel"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <h3 class="modal-title"><i class="fas fa-gift"></i>Purchase Gift</h3>
            <div class="modal-inner">
              <div>
                <dl>
                  <dt>Purchase Detail</dt>
                  <dd>
                    <span class="item-name">{{ gift.name }}</span>
                    <span class="price">{{ gift.price }} {{ gift.currency }}</span>
                  </dd>
                  <dt>Pay For It With</dt>
                  <dd>
                    <template v-if="paymentMethod.defaultPaymentMethod === 'stripe'">
                      <span class="card-info creditcard-information-registered"
                            :data-type="paymentMethod.registeredCard.type">
                        XXXX-XXXX-XXXX-{{ paymentMethod.registeredCard.digits }}
                      </span>
                    </template>
                    <template v-else>
                      PayPal
                    </template>
                    <template v-if="!isRequesting">
                      <a class="card-edit-link ga-click" data-ga-click-type="membership-payment-link-edit"
                         :data-ga-click-param="'product:' + gift.gift_id" href="javascript: void(0)" @click="editPayment">Edit</a>
                    </template>
                    <template v-else>
                      <span class="card-edit-link">Edit</span>
                    </template>
                  </dd>
                </dl>
                <div class="modal-error" v-show="error">{{ error }}</div>
                <div class="modal-footer">
                  <template v-if="!isRequesting">
                    <button type="button" class="mal-btn secondary outline large ga-click"
                            data-ga-click-type="membership-payment-button-back"
                            :data-ga-click-param="'product:' + gift.gift_id" @click="cancel">
                      <b data-ga-click-type="membership-payment-button-back"
                         :data-ga-click-param="'product:' + gift.gift_id">Back</b></button>
                    <button type="button" class="mal-btn primary large" @click="purchase"><b>Purchase</b></button>
                  </template>
                  <template v-else>
                    <span class="modal-button-loading"></span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script type="text/babel">

import {mapState} from "vuex";
import * as types from "./../store/mutation-types"
import GtmJsEvent from "../../../../js/common/modules/gtm/jsEvent";

export default {
  name: "MalSupportConfirmPurchase",
  props: {
    gift: Object
  },
  computed: {
    ...mapState({
      showConfirmationModal: state => state.showConfirmationModal,
      paymentMethod: state => state.payment
    })
  },
  data() {
    return {
      isRequesting: false,
      error: null
    }
  },
  methods: {
    editPayment() {
      this.$emit('editPayment')
    },
    cancel() {
      if (!this.isRequesting) {
        this.$store.commit(types.CONFIRMATION_MODAL, false)
        this.$emit('cancel')
      }
    },
    async purchase() {
      this.isRequesting = true
      this.error = null
      GtmJsEvent.send('membership-payment-button-go_to_purchase_gift', 'product:' + this.gift.gift_id)
      try {
        await this.$store.dispatch(types.PURCHASE)
        this.$store.commit(types.CONFIRMATION_MODAL, false)
        this.$store.commit(types.FINISH_MODAL, true)
        this.$emit('purchased')
      } catch (e) {
        if (e.response.data && Array.isArray(e.response.data.errors)) {
          this.error = e.response.data.errors.map(e => e.message).join("\n")
        } else {
          this.error = "A network error has occurred. Please try again."
          throw e;
        }
      }
      this.isRequesting = false
    }
  }
}
</script>

<style lang="scss" scoped>
:root {
  body.sp {
    .modal-container {
      width: 95vw;
    }

    .modal-title {
      padding: 2rem 0 1rem;
    }

    .modal-inner {
      dt {
        font-size: 1.5rem;
      }

      dd {
        margin: 1rem 0 2.5rem;
        font-size: 1.5rem;

        span:nth-child(1) {
          width: 75%;
        }
      }
    }
  }
}

.modal-content {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity .3s ease;
}

.modal-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  font-family: Verdana, Arial;
  position: relative;
  width: 700px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  background-color: #fff;
  color: #353535;
  border-radius: 4px;
  transition: all .3s ease;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
}

.modal-error {
  color: #ff6a86;
  margin: 2.5rem 0;
}

.modal-inner {
  padding: 1.5rem;
  position: relative;

  dt {
    font-weight: bold;
    font-size: 1rem;
    border-bottom: 1px solid;
    padding: 0.5rem 0;
  }

  dd {
    line-height: 1.5rem;
    margin: .5rem 1rem 2.5rem 0;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;

    .card-info {
      margin: 0;
    }
  }
}

.modal-title {
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
  font-size: 1.5rem;
  max-width: 100%;
  padding-top: 1.5rem;

  > i {
    margin-right: 1rem;
  }
}

.modal-footer {
  margin: 0 2rem;
  display: flex;
  justify-content: space-around;
}

.modal-button-loading {
  display: block;
  width: 340px;
  height: 44px;
  background: url(/images/xmlhttp-loader.gif?v=161115001) no-repeat center center;
  background-size: 16px;
  vertical-align: middle;
  text-indent: -99999px;
}


/* Transition Effect */
.modal-enter {
  opacity: 0.2;
}

.modal-leave-active {
  opacity: 0.2;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
</style>
