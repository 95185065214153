var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "root" }, [
    _vm.is_manga
      ? _c("div", { staticClass: "manga" }, [
          _vm.is_buy
            ? _c(
                "a",
                {
                  staticClass: "affiliate__buy ga-click ga-impression",
                  attrs: {
                    "data-ga-click-type": _vm.ga_type,
                    "data-ga-impression-type": _vm.ga_type,
                    "data-ga-click-param": _vm.gaParam(),
                    "data-ga-impression-param": _vm.gaParam(),
                    href: _vm.link,
                    target: "_blank"
                  }
                },
                [
                  _c("i", { staticClass: "fa-solid fa-cart-shopping mr4" }),
                  _vm._v("\n      Buy on Amazon")
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.is_wish
            ? _c(
                "a",
                {
                  staticClass: "affiliate__wish ga-click ga-impression",
                  class: [_vm.is_wishlist ? "added" : ""],
                  attrs: {
                    "data-ga-click-type": _vm.ga_type,
                    "data-ga-impression-type": _vm.ga_type,
                    "data-ga-click-param": _vm.gaParam(),
                    "data-ga-impression-param": _vm.gaParam(),
                    href: "javascript:void(0)"
                  },
                  on: {
                    click: function($event) {
                      return _vm.addWishList()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa-solid fa-star-shooting mr4" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.is_wishlist
                          ? "We'll ask the publisher!"
                          : "Wish for English release"
                      ) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.is_anime && _vm.is_buy
      ? _c("div", { staticClass: "anime" }, [
          _c(
            "a",
            {
              staticClass: "affiliate__buy ga-click ga-impression",
              attrs: {
                "data-ga-click-type": _vm.ga_type,
                "data-ga-impression-type": _vm.ga_type,
                "data-ga-click-param": _vm.gaParam(),
                "data-ga-impression-param": _vm.gaParam(),
                href: _vm.link,
                target: "_blank"
              }
            },
            [
              _c("i", { staticClass: "fa-solid fa-cart-shopping mr4" }),
              _vm._v("\n      Buy manga on Amazon")
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }