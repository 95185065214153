
/*
 * Newsのカテゴリを管理するモジュール
 * localStorageにカテゴリ情報を保存しておいて、ページ遷移してもカテゴリ絞り込みが解除されないようにする
 */

const localStorage = require("../../../common/modules/localStorage");

const LOCAL_STORAGE_NAME = "news_category_filter";

function CategoryFilter () {

  // カテゴリ選択状況を初期化
  this.categories = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME)) || {
      all : true,
      cat1: false,
      cat2: false,
      cat3: false,
      cat4: false,
      cat5: false,
      cat6: false
    };
}

CategoryFilter.prototype = {

  /**
   * 与えられたカテゴリが選択されているかどうか判定
   * 複数カテゴリを与えた場合、１つでも選択されていればtrueを返す
   * @param {string|Array} tags - 判定したいカテゴリ名(複数可)
   * @returns {boolean}
   */
  isSelected: function (tags) {
    if (typeof tags === "string") {
      tags = [tags];
    }

    for (let i = 0, len = tags.length; i < len; i++) {
      if (this.categories[tags[i]]) {
        return true;
      }
    }
    return false;
  },

  /**
   * カテゴリ情報をlocalStorageに保存
   */
  save: function () {
    localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(this.categories));
  },

  /**
   * カテゴリ情報をセット
   * @param {string} cat - 設定したいカテゴリ名
   * @param {boolean} isSelected - 選択されているかどうか
   */
  set: function (cat, isSelected) {
    this.categories[cat] = isSelected;
  }
};

module.exports = CategoryFilter;
