<template>
  <div>
    <!-- 購入前 -->
    <div v-if="isShowPreview"
      class="detail-preview-container"
      :class="{'hasPreview': item.isPreviewable && !item.isPossessed && !item.isFree}"
    >
      <!-- preview あり -->
      <template v-if="item.isPreviewable">
        <a class="detail-img" target="_blank"
          :href="item.previewUrl"
          @mouseover="mouseover"
          @mouseleave="mouseleave"
        ><img itemprop="image" v-lazy="coverImage" :style="imageHoverStyle"></a>
        <preview-button class="preview-button"
          :preview-url="item.previewUrl"
          @mouseover="mouseover"
          @mouseleave="mouseleave"
          :text="item.numberingText"
          style="margin:8px auto 0;"
          :style="buttonHoverStyle"
        ></preview-button>
      </template>
      <!-- preview なし -->
      <template v-else>
        <div class="detail-img">
          <img v-lazy="coverImage">
        </div>
      </template>
    </div>
    <!-- 購入後 -->
    <div v-else class="detail-preview-container">
      <a class="detail-img" target="_blank"
        :href="item.viewerUrl"
        @mouseover="mouseover"
        @mouseleave="mouseleave"
      ><img itemprop="image" v-lazy="coverImage" :style="imageHoverStyle"></a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import "../../../../../../css/variables";

  .detail-preview-container {
    position: relative;
    display: block;
    text-align: center;
    text-decoration: none;
    &.hasPreview {
      margin-bottom: 30px;
      &:after {
        position: absolute;
        left: 0;
        bottom: -20px;
        display:block;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid $color-pc-border-ebebeb;
        clear:both;
        content:"";
      }
    }
    .detail-img {
      display:block;
      width: 100%;
      border: 1px solid $color-pc-border-ebebeb;
      &:after {
        content:" ";
        display:block;
        clear:both;
      }
    }
    &:hover {
      .detail-img {
        opacity: 0.8;
      }
      .preview-button {
        background-color: $color-pc-bg-e1e7f5;
      }
    }
  }

  .dark-mode {
    @import "../../../../../../css/colors_dark";

    .detail-preview-container {
      &.hasPreview {
        &:after {
          border-bottom: 1px solid $color-pc-border-ebebeb;
        }
      }
      .detail-img {
        border: 1px solid $color-pc-border-ebebeb;
      }
      &:hover {
        .detail-img {
          opacity: 0.8;
        }
        .preview-button {
          background-color: $color-pc-bg-e1e7f5;
        }
      }
    }
  }
</style>

<script type="text/babel">
  import * as book from "../../../common/modules/book"
  import * as types from "./store/mutation-types"
  import eventBus from "./store/eventBus"
  import {mapState, mapMutations} from "vuex"
  import PreviewButton from "../../atoms/PreviewThinButton.vue"

  export default {
    components: {
      PreviewButton,
    },
    mounted() {
      this.isShowPreview = (this.item.isPossessed || this.item.isFree) ? false : true
    },
    created() {
      window.eventBus.$on("hide-preview", this.hidePreview)
    },
    data() {
      return {
        isShowPreview: true,
        buttonHoverStyle: null,
        imageHoverStyle: null,
      }
    },
    computed: {
      ...mapState([
        "item",
      ]),
      coverImage() {
        const imageSize = (window.devicePixelRatio > 1) ? book.COVER_IMAGE_SIZE_L_2X : book.COVER_IMAGE_SIZE_L
        return {
          src: book.generateCoverImageUrl(this.item.coverImageBaseUrl, imageSize),
        }
      },
    },
    methods: {
      hidePreview() {
        this.isShowPreview = false
      }
    },
  }
</script>
