import * as types from "./mutation-types"
import {purchase} from "../../modules/purchase"
import {NAMESPACE as TAX_NAMESPACE} from "../tax_calculation_modal"

const axios = window.axios

export default {
  /**
   * book 単巻購入実行
   *
   * @param state
   * @param id
   * @param payload
   * @returns {Promise.<void>}
   */
  async [types.PURCHASE]({state, commit, getters}, id) {
    commit(types.PURCHASE_START, id)

    const item = getters.items.find(item => item.id === id)

    try {
      await purchase({
        itemType: state.itemType,
        itemIds: [id],
        itemTotalAmount: item.totalAmount,
        selectedCountry: state[TAX_NAMESPACE].selectedCountry
      })
      commit(types.PURCHASED, item)
    } catch (e) {
      commit(types.PURCHASE_FAILED)
      throw e
    }
  },

  /**
   * 指定したページを読み込む
   *
   * すでに読み込んでいた場合はなにもせず、ページを切り替える
   *
   * @param state
   * @param commit
   * @param page
   * @returns {Promise.<void>}
   */
  async [types.LOAD_MORE]({state, commit, getters}, page) {
    if (!getters.hasPage(page)) {
      commit(types.LOAD_MORE_START)

      const res = await axios.get(state.loadItemsPath, {
        params: {
          p: page,
        },
      })

      const items = res.data

      commit(types.ADD_ITEMS, {items, page})
    }

    commit(types.MOVE_PAGE, page)
  },
}
