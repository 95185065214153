$(function () {
  $('#content a').each(function(){
    let url = $(this).attr('href');
    if (url && url.startsWith('https://docs.google.com/forms/')) {
      if (typeof window.dataLayer !== 'undefined' && window.dataLayer.length > 0){
        if (window.dataLayer[0].userId) {
          url = url.replace('%7Bmem_id%7D', window.dataLayer[0].userId);
        } else {
          url = url.replace('%7Bmem_id%7D', '');
        }
        if (window.dataLayer[0].user_name) {
          url = url.replace('%7Buser_name%7D', window.dataLayer[0].user_name);
        } else {
          url = url.replace('%7Buser_name%7D', '');
        }
        if (window.dataLayer[0].user_id_encrypted) {
          url = url.replace('%7Buser_id_e%7D', window.dataLayer[0].user_id_encrypted);
        } else {
          url = url.replace('%7Buser_id_e%7D', '');
        }
        $(this).attr('href', url);
      }
    }
  });
});
