"use strict";

/**
 * 多重実行を防止するユーティリティ。
 */
function ActionLimitter() {
  this.counter = {};
}

ActionLimitter.prototype = {
  getActiveCount: function(label) {
    if (!this.counter[label]) {
      this.counter[label] = 0;
    }
    return this.counter[label];
  },
  doAction: function(label, max, fn) {
    if (this.getActiveCount(label) >= max) {
      return false;
    }
    this.counter[label]++;
    const self = this;
    fn(function done() {
      self.counter[label]--;
    });
    return true;
  },
  one: function(label, fn) { return this.doAction(label, 1, fn); },
  two: function(label, fn) { return this.doAction(label, 2, fn); },
  three: function(label, fn) { return this.doAction(label, 3, fn); }
};

module.exports = ActionLimitter;
