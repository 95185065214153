import Vuex from "vuex"
import mutations from "./mutations"
import actions from "./actions"

export default () => {
  const state = {
    showConfirmationModal: false,
    showFinishModal: false,
    showLoadingModal: false,
    modals: {
      showLoadingModal: false,
      showPaymentModal: false,
    },
    payment: {}
  }
  return new Vuex.Store({
    state,
    mutations,
    actions
  })
}
