const $ = window.$;

$(document).ready(function () {
// 右上のEdit Company Informationボタン
  $(".js-company-edit-info-button").on("click", function () {
    $("#editdiv").toggleClass("on");
    $("#editdiv").on("click", function (e) {
      e.stopPropagation();
    });
    setTimeout(function () {
      $(document).on("click", function () {
        $("#editdiv").removeClass("on");
        $(document).off("click");
        return false;
      });
    }, 0);
  });

  $("#js-company-edit-go").on("click", (event) => {
    event.preventDefault();
    window.location.href = $("#select_EditCompanyInfo option:selected").data("url");
  });
});
