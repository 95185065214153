const $ = window.$;

$(function() {

  if ( $(".js-anime-input-seriestype")[0] ) {
    const showHideTVseriesOption = function (v) {
      const val = (v === 0 || v === 1 ) ? "show" : "hide";
      if ( val === "hide" ) {
        $(".js-insert-tvseries-hide").attr("disabled","disabled");
      } else {
        $(".js-insert-tvseries-hide").removeAttr("disabled");
      }
    }

    const $selectType = $(".js-anime-input-seriestype");
    const animeSeriesType = $selectType.val() || 0;
    showHideTVseriesOption(parseInt(animeSeriesType));
    $selectType.change(function() {
      const type =  $(this).val();
      showHideTVseriesOption(parseInt(type));
    });
  }

});
