const axios = window.axios

$(() => {
  const badResult     = document.getElementsByClassName("js-badresult")
  const badResultText = document.getElementsByClassName("js-badresult-text")

  const policy       = document.getElementsByClassName("js-supporter-policy")
  const submitButton = document.getElementsByClassName("js-subscribe-membership-with-paypal")
  if (policy.length !== 0 && submitButton.length !== 0) {
    const membershipPolicy   = policy[0]
    submitButton[0].disabled = !membershipPolicy.checked
    membershipPolicy.addEventListener("click", () => {
      submitButton[0].disabled = !membershipPolicy.checked
    })

    const data = new FormData()
    data.append("policy", membershipPolicy.checked ? "off" : "on")
    data.append("plan", document.getElementById("selected-plan").value)
    data.append("token", document.querySelector("input[name=\"token\"]").value)
    const button = submitButton[0]
    button.addEventListener("click", (element) => {
      button.disabled = true;
      badResult[0].style.display = "none"
      axios.post("/account/membership/payment/paypal", data).then((response) => {
        window.location.href = "/account/membership/complete"
        return 200
      }).catch((error) => {
        button.disabled = !membershipPolicy.checked;
        return showErrorMessage(error)
      })
    })
  }

  const agreementButton = document.getElementsByClassName("js-get-agreement-with-paypal")
  if (agreementButton.length !== 0) {
    const plans  = document.getElementsByName("plan")
    const button = agreementButton[0]
    button.addEventListener("click", (element) => {
      if (button.classList.contains("disabled")) {
        return;
      }
      button.classList.add("disabled");
      button.disabled = true;
      let planId = 0
      for (let i = 0; i < plans.length; i++) {
        if (plans[i].checked === true) {
          planId = plans[i].value
        }
      }
      axios.get("/account/membership/payment", {
        params: {
          plan: planId,
          paypal: 1
        }
      }).then((response) => {
        if(response.data === undefined || response.data.redirect === undefined) {
          button.classList.remove("disabled");
          button.disabled = false;
          return showErrorMessage('Something went wrong. Please try again later.')
        }
        window.location.href = response.data.redirect
        return 200
      }).catch((error) => {
        button.classList.remove("disabled");
        button.disabled = false;
        return showErrorMessage(error)
      })
    })
  }

  const defaultErrorMessage = `
      There was an error when registering you to the MAL Supporter plan.<br>
      Please try again in a few minutes. If this problem persists please contact us <a href="/about.php?go=support&is_form_open=1">here</a>.
      `
  /**
   * MALとの通信で発生したエラーを表示する
   * @param error
   */
  const showErrorMessage = (error) => {
    badResult[0].style.display = "none"
    if (error === undefined ||
      !('response' in error) ||
      !('status' in error.response)) {
      return
    }
    if (error.response.status === 504) { // gateway timeout
      badResult[0].style.display = ""
      return
    }
    try {
      if (error.response.data) {
        badResultText[0].innerText = error.response.data.message
        badResult[0].style.display = ""
        return
      }
      if ('data' in error.response && 'errors' in error.response.data) {
        badResultText[0].innerText = error.response.data.errors[0].message
        badResult[0].style.display = ""
        return
      }
      badResultText[0].innerHTML = defaultErrorMessage
      badResult[0].style.display = ""
    } catch (e) {
      badResultText[0].innerHTML = defaultErrorMessage
      badResult[0].style.display = ""
    }
  }
})