var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "button",
      class: {
        "is-mobile": _vm.isMobile,
        "is-border": _vm.isBorder,
        "is-disable": _vm.isDisable
      },
      style: _vm.buttonStyle,
      attrs: { target: "_blank", href: _vm.url }
    },
    [_vm._v("Preview")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }