var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "maraginals-root" }, [
    _c("header", { staticClass: "header", class: { hide: !_vm.show } }, [
      _c("span", { staticClass: "logo" }, [_vm._v("MyAnimeList")]),
      _vm._v(" "),
      !_vm.isPreview && !_vm.isFree
        ? _c(
            "a",
            { staticClass: "button-story", on: { click: _vm.showStoryModal } },
            [_vm._v("Synopsis")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isPreview
        ? _c(
            "a",
            {
              staticClass: "button-buy",
              on: {
                click: function($event) {
                  return _vm.$emit("purchase")
                }
              }
            },
            [_vm._v("Buy")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.customButton.type === 1
        ? _c(
            "a",
            {
              staticClass: "button-custom",
              style: {
                backgroundColor: _vm.customButton.backgroundColor,
                color: _vm.customButton.color,
                border: "2px solid"
              },
              on: {
                click: function($event) {
                  return _vm.custom(_vm.customButton.url)
                }
              }
            },
            [_vm._v(_vm._s(_vm.customButton.text))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.customButton.type === 2
        ? _c("a", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy:background-image",
                value: _vm.customButton.backgroundImage,
                expression: "customButton.backgroundImage",
                arg: "background-image"
              }
            ],
            staticClass: "button-custom",
            staticStyle: {
              width: "80px",
              "background-size": "contain",
              "background-repeat": "no-repeat"
            },
            on: {
              click: function($event) {
                return _vm.custom(_vm.customButton.url)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "title-wrap" }, [
        _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.book.title))]),
        _vm._v(" "),
        _c("span", { staticClass: "volume" }, [
          _vm._v(_vm._s(_vm.book.numberingText))
        ])
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.windowOpener,
              expression: "windowOpener"
            }
          ],
          staticClass: "button-close",
          on: { click: _vm.close }
        },
        [_vm._v("Close")]
      )
    ]),
    _vm._v(" "),
    _c(
      "footer",
      { staticClass: "footer", class: { hide: !_vm.show } },
      [
        _c("div", { staticClass: "zoom-wrap" }, [
          _c(
            "a",
            {
              staticClass: "button-zoom-out",
              class: { "is-disable": _vm.zoomScale < 1.25 },
              on: {
                click: function($event) {
                  return _vm.zoom(-0.25)
                }
              }
            },
            [_vm._v("-")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "zoom-percent" }, [
            _vm._v(_vm._s(Math.round(_vm.zoomScale * 100)))
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button-zoom-in",
              class: { "is-disable": _vm.zoomScale >= 2 },
              on: {
                click: function($event) {
                  return _vm.zoom(0.25)
                }
              }
            },
            [_vm._v("+")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "facing-page-warp" }, [
          _c("a", {
            staticClass: "button-facing-page-single",
            class: { "is-on": !_vm.isFacingPage },
            on: { click: _vm.facingPageSingle }
          }),
          _vm._v(" "),
          _c("a", {
            staticClass: "button-facing-page-double",
            class: { "is-on": _vm.isFacingPage },
            on: { click: _vm.facingPageDouble }
          })
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "button-tutorial",
            on: { click: _vm.showTutorialModal }
          },
          [_vm._v("Help")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "pagecount" }, [
          _c("span", { staticClass: "pagecount-current" }, [
            _vm._v(_vm._s(_vm.currentPage))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "pagecount-total" }, [
            _vm._v("/" + _vm._s(_vm.numTotalPages))
          ])
        ]),
        _vm._v(" "),
        _c("seek-bar", {
          attrs: {
            "num-total-pages": _vm.numTotalPages,
            "current-page": _vm.currentPage,
            "is-facing-page": _vm.isFacingPage,
            manuscript: _vm.manuscript,
            "page-direction": _vm.pageDirection
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "button-direction",
            class: { opposite: _vm.switchDirection },
            on: { click: _vm.changeDirection }
          },
          [_vm._v("\n      Direction\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }