var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "continue-button" },
        [
          _c(
            "button",
            {
              staticClass: "mal-btn primary large ga-click",
              attrs: {
                "data-ga-click-type": "membership-payment-gift-continue",
                "data-ga-click-param": "product:" + _vm.gift.gift_id
              },
              on: { click: _vm.continueButton }
            },
            [_vm._v("Continue")]
          ),
          _vm._v(" "),
          !_vm.isSp
            ? _c("loading-modal-pc", {
                attrs: { "is-shown": _vm.showLoadingModal }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isSp
            ? _c("loading-modal-sp", {
                attrs: { "is-shown": _vm.showLoadingModal }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("mal-support-confirm-purchase", {
        attrs: { gift: _vm.gift },
        on: { purchased: _vm.purchased, editPayment: _vm.moveAccountSetting }
      }),
      _vm._v(" "),
      _c("mal-support-finish-purchase", {
        attrs: { gift: _vm.gift },
        on: { cancel: _vm.finished }
      }),
      _vm._v(" "),
      _vm.showPaymentModal
        ? _c("payment-modal", {
            attrs: {
              show: _vm.showPaymentModal,
              payment: _vm.payment,
              "terms-html": _vm.termsHtml,
              "support-url": _vm.supportUrl
            },
            on: {
              registerdPaymentMethod: _vm.registeredPaymentMethod,
              hidePaymentModal: _vm.hidePaymentModal
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }