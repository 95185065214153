import Vuex from "vuex"
import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
import taxStore, {NAMESPACE as TAX_NAMESPACE} from "../tax_calculation_modal"

/**
 * - 複数の book を持っている
 * - それに加えて追加で book を読み込める
 * - また、指定した book を購入できる
 * という store
 */
export default ({
                  itemType,
                  items,
                  loginPath,
                  currency,
                  loadItemsPath,
                  numTotalItems,
                  numItemsPerPage,
                }) => {
  // 動作確認用
  // numItemsPerPage = 6

  if (!numItemsPerPage) {
    numItemsPerPage = items.length
  }

  if (!numTotalItems) {
    numTotalItems = items.length
  }

  const pageToItems = {}
  if (items.length >= 1) {
    for (let i = 0; i < items.length; i += numItemsPerPage) {
      const page = 1 + i / numItemsPerPage
      pageToItems[page] = items.slice(i, i + numItemsPerPage)
    }
  }

  const state = {
    itemType,
    loginPath,
    currency,
    loadItemsPath,
    numTotalItems,
    numItemsPerPage,
    pageToItems,

    /**
     * 購入リクエスト中
     */
    isPurchasing: false,

    /**
     * アイテム情報ロード中
     */
    isLoadingItems: false,

    currentPage: 1,
  }

  return new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {
      [TAX_NAMESPACE]: taxStore(),
    }
  })
}
