const $ = window.$
const _ = window._
const sVue = window.sVue

$(() => {
  if ($(".club-new-forum-topic").exists()) {
    const pollEl = "#vPollTemplate"
    const pollData = $(pollEl).data("poll")

    pollData.showMenu = Boolean(pollData.question || pollData.reqOptions.length)
    // 最初pollメニューを開いたときにoptionが1つ表示されるようにする
    if (pollData.reqOptions.length===0) {
      pollData.reqOptions.push("")
    }
    // Vueで扱い易いようにobjectのarrayにする
    pollData.options = _.map(pollData.reqOptions, (value, index) => {
      return {id: index+1, value: value}
    })

    new sVue({
      el: pollEl,
      data: pollData,
      methods: {
        pollToggle() {
          this.showMenu = !this.showMenu
        },
        addOption() {
          const lastId = _.last(this.options).id
          this.options.push({id:lastId+1, value:""})
        },
        removeOption(option) {
          this.options.splice(this.options.indexOf(option), 1)
        }
      }
    })
  }
})
