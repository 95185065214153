var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShown,
                expression: "isShown"
              }
            ],
            staticClass: "modal-content"
          },
          [
            _c("div", { staticClass: "modal-mask", on: { click: _vm.cancel } }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-wrapper" }, [
              _c("div", { staticClass: "modal-container" }, [
                _c("div", { staticClass: "modal-inner" }, [
                  _c("div", [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: _vm.coverImage,
                          expression: "coverImage"
                        }
                      ],
                      attrs: { width: "224" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "modal-right-wrap" }, [
                      _c("p", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.item.title))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "modal-volume" }, [
                        _vm._v(_vm._s(_vm.item.numberingText))
                      ]),
                      _vm._v(" "),
                      _c("ul", { staticClass: "modal-price-wrap" }, [
                        _c("li", [
                          _c("div", [_vm._v("Price")]),
                          _vm._v(" "),
                          _vm.item.salePrice
                            ? _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "modal-price is-sale" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currency.formatSymbolAndPrice(
                                          _vm.item.price
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "modal-price-sale" },
                                  [
                                    _vm._v(
                                      "Sale " +
                                        _vm._s(
                                          _vm.currency.formatSymbolAndPrice(
                                            _vm.item.salePrice
                                          )
                                        )
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.item.salePrice
                            ? _c("div", [
                                _c("span", { staticClass: "modal-price" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currency.formatSymbolAndPrice(
                                        _vm.item.price
                                      )
                                    )
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("div", [_vm._v("Tax")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "modal-tax" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currency.formatSymbolAndPrice(_vm.item.tax)
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "modal-price-total-wrap" }, [
                          _c("div", [_vm._v("Total")]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.currency.symbol)),
                            _c("span", { staticClass: "modal-price-total" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.currency.formatPrice(_vm.item.totalAmount)
                                )
                              )
                            ]),
                            _vm._v(_vm._s(_vm.currency.name))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "modal-button-wrap" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.error,
                                  expression: "error"
                                }
                              ],
                              staticClass: "modal-error"
                            },
                            [_vm._v(_vm._s(_vm.error))]
                          ),
                          _vm._v(" "),
                          !_vm.isRequesting
                            ? [
                                _c(
                                  "a",
                                  {
                                    staticClass: "modal-cancel-button",
                                    on: { click: _vm.cancel }
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "modal-confirm-button",
                                    on: { click: _vm.confirm }
                                  },
                                  [_vm._v("Confirm Purchase")]
                                )
                              ]
                            : [
                                _c("span", {
                                  staticClass: "modal-button-loading"
                                })
                              ],
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal-payment-method-button-wrap" },
                            [
                              !_vm.isRequesting
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "modal-payment-method-button",
                                      attrs: { href: "/account/payment" }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa-solid fa-gear",
                                        attrs: { "aria-hidden": "true" }
                                      }),
                                      _vm._v(
                                        " Edit Payment Method\n                    "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        2
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "modal-close", on: { click: _vm.cancel } },
                  [_vm._v("Close")]
                )
              ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }