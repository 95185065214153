const $ = window.$;
const qs = require("qs")

$(() => {
  if ($("#showclubs").exists()) {
    // pulldown
    $(".js-show-clubs").on("change", (event) => {
      const parsedQuery = qs.parse(location.search.replace(/^\?/, ""))
      // remove parameter for pagination
      parsedQuery.p = null
      parsedQuery.sort = $(event.currentTarget).val()
      location.href = `/clubs.php?${qs.stringify(parsedQuery, {skipNulls:true})}`
    })

    // sort Click
    $(".js-show-clubs").on("click", (event) => {
      const parsedQuery = qs.parse(location.search.replace(/^\?/, ""))
      // remove parameter for pagination
      parsedQuery.p = null
      parsedQuery.sort = $(event.currentTarget.attributes["data-id"]).val()
      location.href = `/clubs.php?${qs.stringify(parsedQuery, {skipNulls:true})}`
    })
  }
})
