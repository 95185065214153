<template>
  <div class="membership-payment">

    <div class="modal-head">
      <span v-if="!isRegisterdPaymentMethod">Please select your payment method.</span>
      <span v-else>Your payment method was successfully registered.</span>
    </div>

    <section v-if="!isRegisterdPaymentMethod" class="payment-method">
      <div class="form-table border-bottom">
        <div class="title alignLeft fw-b">Payment Method</div>
        <div class="data clearfix"></div>
      </div>
      <div class="form-table blank">
        <div class="title blank"></div>
        <div class="clearfix">
          <ul class="payment-method-select-container">
            <li class="po-r">
              <input v-model="paymentMethod" class="payment-radio" type="radio" value="paypal" id="paypal">
              <label for="paypal">PayPal</label>
            </li>
            <li class="po-r">
              <input v-model="paymentMethod" class="payment-radio" type="radio" value="stripe" id="stripe">
              <label for="stripe">Credit Card</label>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="payment-information">
      <div class="form-table border-bottom">
        <div class="title alignLeft fw-b">Payment Information</div>
        <div class="data clearfix"></div>
      </div>
      <div class="form-table">
        <div class="title blank"></div>
        <div class="data clearfix">
          <div v-if="isError" class="badresult">
            <div v-if="errorMessage" class="badresult-text">{{ errorMessage }}</div>
            <div v-else class="badresult-text fs14 lh16">
              There was an error when registering your payment information.<br>
              Please try again in a few minutes. If this problem persists, please contact us
              <a :href="supportUrl">here</a>.
            </div>
          </div>

          <div v-if="paymentMethod === 'stripe'">

            <div v-if="!isRegisterdPaymentMethod">
              <div v-if="registeredCard && !isCardEditMode">
                <!-- カードが MAL Supporter の方で登録済みの場合はメッセージを出す -->
                <div
                  v-if="registeredCard.isUsedForMalSupporter"
                  class="creditcard-information-registered-text"
                >Currently the following card is in use of MAL Supporter</div>
                <div class="creditcard-information-registered" :data-type="registeredCard.type">
                  XXXX-XXXX-XXXX-{{ registeredCard.digits }}
                </div>
              </div>
              <stripe v-else ref="stripe"
                :is-block-ad-block = "isBlockAdBlock"
              ></stripe>

              <div v-if="showAgreement && !isCardEditMode" class="textPaymentPolicyContainer">
                <agreement
                  :termsHtml="termsHtml"
                  :checked-agreement.sync="checkedAgreement"
                ></agreement>
              </div>

              <div class="payment-submit-creditcard-button-container">
                <!-- カードを新規登録する -->
                <template v-if="!isRequesting">
                  <button
                    v-if="!registeredCard"
                    @click="registerStripeCard"
                    class="btn-form-submit"
                    :disabled="(showAgreement && !checkedAgreement) || isRequesting">Register this card</button>

                  <!-- デフォルト決済手段を登録済みのカードにする -->
                  <button
                    v-if="registeredCard && !isCardEditMode && defaultPaymentMethod !== 'stripe'"
                    @click="updatePaymentMethod"
                    class="payment-submit-creditcard-button btn-form-submit"
                    :disabled="(showAgreement && !checkedAgreement) || isRequesting">Make this card my default payment method</button>
                </template>
                <template v-else>
                  <span class="modal-button-loading"></span>
                </template>
              </div>
            </div>

            <div v-if="isRegisterdPaymentMethod">
              <div class="creditcard-information-registered" :data-type="registeredCardInfo.type">
                  XXXX-XXXX-XXXX-{{ registeredCardInfo.digits }}
              </div>
              <!-- 決済手段登録後の遷移用 -->
              <div class="payment-submit-creditcard-button-container">
                <button class="btn-form-submit"
                  @click="proceedToCheckout">Proceed to Checkout</button>
              </div>
            </div>

          </div>

          <div v-if="paymentMethod === 'paypal'">

            <div v-if="!isRegisterdPaymentMethod">

              <div v-if="braintreeRegistrationDatetime" class="paypal-information-registered">
                You registered your PayPal account at {{ braintreeRegistrationDatetime }}.
              </div>

              <div v-if="showAgreement"
                :class="{textPaymentPolicyContainer: braintreeRegistrationDatetime}">
                <agreement
                  :termsHtml="termsHtml"
                  :checked-agreement.sync="checkedAgreement"
                ></agreement>
              </div>

              <div v-if="braintreeRegistrationDatetime"></div>
              <braintree-pay-pal
                v-else-if="!showAgreement || (showAgreement && checkedAgreement)"
                :authorized-callback="paypalCallback"
              ></braintree-pay-pal>

              <button
                v-if="braintreeRegistrationDatetime && defaultPaymentMethod !== 'paypal'"
                @click="updatePaymentMethod"
                class="btn-form-submit"
                :disabled="(showAgreement && !checkedAgreement) || isRequesting">Make PayPal my default payment method</button>
            </div>

            <div v-if="isRegisterdPaymentMethod">
              <!-- 決済手段登録後の遷移用 -->
              <div class="payment-submit-creditcard-button-container">
                <div class="paypal-information-registered">
                  You registered your PayPal account at {{ paypalRegistrationDatetime }}.
                </div>
                <button class="btn-form-submit"
                  @click="proceedToCheckout">Proceed to Checkout</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<style lang="scss" scoped>
  @import "../../../css/_variables.scss";
  $version: 180320;

// モーダル用
.modal-payment-method {
  font-family: 'Helvetica neue', Helvetica, "lucida grande", tahoma, verdana, arial, sans-serif;

  .badresult {
    margin: 10px 0;
    padding: 10px;
    font-size: 14px;
    text-align: left;
    background-color: $color-pc-bg-f5e1e1;
    border-color: $color-pc-border-b25959;
    border-width: 2px;
    border-style: solid;
  }

  .badresult a:link,.badresult a:active,.badresult a:visited {
    color: $color-pc-text-b25959;
  }

  .badresult-text,
  div[role="alert"] {
    font-size: 14px;
    color: $color-pc-text-b25959;
  }

  .modal-head {
    font-weight: 700;
    text-align: center;
    font-size: 20px;
    margin: 0 0 24px;
  }

  .modal-button-loading {
    display: block;
    width: 100%;
    height: 38px;
    background: url(/images/xmlhttp-loader.gif?v=161115001) no-repeat center center;
    background-size: 16px;
    vertical-align: middle;
    text-indent: -99999px;
  }

  .text-payment-notice {
    background-color: $color-pc-bg-f6f6f6;
    width: calc(550px - 24px);
    padding: 4px 12px 4px 32px;
    margin: 8px 0 0;
    font-size: 12px;
    line-height: 1.5em;
    list-style: initial;
    display: inline-block;
    white-space: nowrap;
    span {
      line-height: inherit;
    }
  }
  .border-bottom {
    border-bottom: $color-pc-border-999999 1px solid;
  }
  .form-table {
    .title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 5px;
    }
  }
  .payment-information {
    .badresult {
      .badresult-text {
        font-size: 14px;
        text-align: left;
      }
    }
  }
  .payment-method {
    margin-top: 24px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        margin-right: 24px;
      }
    }
    .payment-method-select-container {
      padding: 8px 0 24px;
    }
    .selected-payment-methods {
      position: absolute;
      top: 50%;
      left: -100px;
      margin-top: -4px;
      display: inline-block;
      background-color: $color-pc-bg-f6f6f6;
      border-radius: 2px;
      padding: 1px 2px;
      font-size: 10px;
      color: $color-pc-text-787878;
    }
    input.payment-radio[type="radio"] {
      display: none;
      & + label {
        position: relative;
        background-color: $color-pc-bg-ffffff;
        display: inline-block;
        padding-left: 16px;
        font-size: 16px;
        line-height: 1.2em;
        color: $color-pc-text-323232;
        font-family: 'Helvetica neue', Helvetica, "lucida grande", tahoma, verdana, arial, sans-serif;
        cursor: pointer;
        &:before {
          position: absolute;
          top: 50%;
          left: 0;
          background-image: url("/images/icon_check_box_circle.png?v=" + $version);
          background-repeat: no-repeat;
          background-size: 12px auto;
          background-position: 0px -39px;
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-top: -6px;
          content: '';
        }
      }
      &:disabled {
        & + label {
          color: $color-pc-text-999999;
          cursor: default;
          .note {
            display: inline-block;
            font-size: 11px;
          }
        }
      }
      &:checked {
        & + label {
          &::after {
            position: absolute;
            top: 50%;
            left: 0;
            background-image: url("/images/icon_check_box_circle.png?v=" + $version);
            background-repeat: no-repeat;
            background-size: 12px auto;
            background-position: 0px 2px;
            display: block;
            width: 13px;
            height: 13px;
            margin-top: -6px;
            content: '';
          }
        }
      }
    }
  }
  .payment-submit-creditcard-button-container {
    margin-top: 12px;
  }

  .payment-adblock-error-message {
    position: relative;
    margin: 0;
    padding: 0;
    color: $color-pc-text-b25959;
    font-size: 12px;
    font-weight: 300;
    padding-left: 20px;
    margin-bottom: 4px;
    &:after {
      position: absolute;
      top: 2px;
      left: 0;
      background-image: url("/images/icon-exclamation-circle-bad.png?v=" + $version);
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: center;
      display: block;
      width: 16px;
      height: 16px;
      content: '';
    }
  }

  .creditcard-note {
    position: relative;
    margin: 0;
    padding: 0;
    color: $color-pc-text-787878;
    font-size: 12px;
    font-weight: 300;
    padding-left: 20px;
    &:after {
      position: absolute;
      top: 2px;
      left: 0;
      background-image: url("/images/icon-exclamation-circle.png?v=" + $version);
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: center;
      display: block;
      width: 16px;
      height: 16px;
      content: '';
    }
  }

  // Stripe
  .creditcard-information {
    label {
      display: block;
      margin: 16px 0 8px;
      font-size: 14px;
      font-weight: 700;
    }
    .creditcard-information-icons {
      position: relative;
      display: inline-block;
      &:before {
        position: absolute;
        top: 3px;
        right: -140px;
        display: inline-block;
        color: $color-pc-text-787878;
        font-size: 11px;
        font-weight: 900;
        font-family: 'Font Awesome 6 Pro', Avenir, "lucida grande", tahoma, verdana, arial, sans-serif;
        content: "\f023 Secure";
      }
      &:after {
        position: absolute;
        top: 1px;
        right: -88px;
        background-image: url("/images/membership/icon-creditcard.png?v=" + $version);
        background-repeat: no-repeat;
        background-position: 0px 0px;
        background-size: auto 18px;
        display: inline-block;
        width: 80px;
        height: 18px;
        content: "";
      }
    }
    .StripeElement {
      width: 400px;
      padding: 8px 12px;
      border: $color-pc-border-d8d8d8 1px solid;
    }
    .StripeElement--focus {
      box-shadow: 0 1px 3px 0 $color-pc-bg-f6f6f6;
    }

    .StripeElement--invalid {
      border-color: $color-pc-text-b25959;
    }
    .StripeElement--invalid + div[role="alert"] {
      display: inline-block;
      margin-top: 8px;
      color: $color-pc-text-b25959;
      font-size: 14px;
    }
  }

  .btn-supporter-policy {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    font-size: 16px;
    line-height: 1.2em;
    cursor: pointer;
    &:before {
      position: absolute;
      top: 50%;
      left: 0px;
      background-image: url("/images/icon_check_box.png?v=" + $version);
      background-repeat: no-repeat;
      background-size: 12px auto;
      background-position: 0px -38px;
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-top: -8px;
      content: '';
    }
  }

    #agreement,
    #supporter-policy {
      display: none;
      &:checked {
        & + label {
          &::after {
            position: absolute;
            top: 50%;
            left: -4px;
            background-image: url("/images/icon_check_box.png?v=" + $version);
            background-repeat: no-repeat;
            background-size: 20px auto;
            background-position: -1px 3px;
            display: block;
            width: 20px;
            height: 20px;
            margin-top: -9px;
            content: '';
          }
        }
      }
    }

  // ボタンは共通化させたい
  .btn-edit {
    background-color: $color-pc-bg-ffffff;
    color: $color-pc-text-2e51a2;
    border: $color-pc-border-2e51a2 1px solid;
    border-radius: 2px;
    display: inline-block;
    padding: 2px 6px;
    cursor: pointer;
    &:hover {
      background-color:rgba($color-pc-bg-2e51a2, 0.3);
    }
    &[data="delete"] {
      border: 1px solid $color-pc-border-ff3824;
      color: $color-pc-text-ff3824;
      &:hover {
        background-color:rgba($color-pc-bg-ff3824, 0.3);
      }
    }
  }
  .payment-edit-creditcard-button-area {
    border-top: $color-pc-border-b25959 1px solid;
  }

  .creditcard-information-registered-text {
    color: $color-pc-text-808080;
    font-size: 14px;
    padding-bottom: 8px;
  }
  .creditcard-information-registered-edit-button {
    background-color: $color-pc-bg-ffffff;
    border: $color-pc-border-2e51a2 1px solid;
    border-radius: 2px;
    color: $color-pc-text-2e51a2;
    font-size: 11px;
    line-height: 1.0;
    vertical-align: middle;
    padding: 2px 6px;
    &:hover {
      background-color: rgba($color-pc-bg-2e51a2,.3);
      text-decoration: none;
    }
  }
  .creditcard-information-registered {
    position: relative;
    display: inline-block;
    padding-left: 0;
    font-size: 16px;
    &:after {
      position: absolute;
      top: 50%;
      left: 0;
      background-image: url("/images/membership/icon-creditcard.png?v=" + $version);
      background-repeat: no-repeat;
      background-position: 0px 0px;
      background-size: auto 24px;
      display: inline-block;
      width: 0;
      height: 24px;
      margin-top: -12px;
      content: "";
    }
    &[data-type="Visa"] {
      padding-left: 44px;
      &:after {
        background-position: -40px 0;
        width: 34px;
      }
    }
    &[data-type="MasterCard"] {
      padding-left: 44px;
      &:after {
        background-position: 0 0;
        width: 34px;
      }
    }
    &[data-type="American Express"] {
      padding-left: 36px;
      &:after {
        background-position: -78px 0;
        width: 26px;
      }
    }
    &[data-type="Discover"] {
      padding-left: 50px;
      &:after {
        background-position: -107px 0;
        width: 40px;
      }
    }
    &[data-type="JCB"] {
      padding-left: 40px;
      &:after {
        background-position: -149px 0;
        width: 30px;
      }
    }
    &[data-type="Diners Club"] {
      padding-left: 40px;
      &:after {
        background-position: -149px 0;
        width: 30px;
      }
    }
  }

  .textPaymentPolicyContainer {
    position: relative;
    padding-top: 1px;
    margin-top: 24px;
    margin-bottom: 16px;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 620px;
      height: 1px;
      border-bottom: $color-pc-border-999999 1px solid;
      content: ""
    }
  }
  .text-payment-policy {
    display: block;
    width: 550px;
    height: 80px;
    margin: 16px 0 8px;
    padding: 4px 8px;
    resize: both;
    overflow-y: scroll;
    border: $color-pc-border-d8d8d8 1px solid;

    h1 {
      font-size: 16.5px;
      font-weight: 700;
      margin: 8px 0 12px;
    }
    h2 {
      border-color: $color-pc-border-bebebe;
      border-style: solid;
      border-width: 0 0 1px;
      color: $color-pc-text-000000;
      font-size: 12px;
      font-weight: 700;
      margin: 4px 0 5px;
      padding: 3px 0;
    }
    div {
      font-size: 11px;
      line-height: 1.1em;
    }
    p {
      font-size: 11px;
    }
    ul {
      margin: 8px 0;
      padding: 0 18px;
      &.list-disc {
        list-style-type:disc;
      }
      &.list-lower-alpha {
        list-style-type: lower-alpha;
      }
      &.list-lower-roman {
        list-style-type: lower-roman;
      }
      li {
        padding-bottom: 8px;
        &:last-of-type {
          padding-bottom: 0px;
        }
      }
    }
    &.no-border {
      width: inherit;
      height: inherit;
      padding: 0;
      overflow-y: visible;
      border: $color-pc-border-d8d8d8 0px solid;
    }
    .mal-address {
      background-color: $color-pc-bg-e5e5e5;
      padding: 6px !important;
      margin: 6px 0 !important;
      width: 50%;
    }
  }
  .paypal-button-container-outer {
    margin-top: 12px;
    text-align: center;
    .fa-spinner {
      display: block;
      width: 100%;
      height: 38px;
      background: url(/images/xmlhttp-loader.gif?v=161115001) no-repeat center center;
      background-size: 16px;
      vertical-align: middle;
      text-indent: -99999px;
    }
  }
  .paypal-information-registered {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.btn-form-submit {
  background-color: $color-bg-submit-button;
  display: block;
  margin: 0 auto;
  border: none;
  border-radius: 2px;
  color: $color-white-fixed;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  min-width: 160px;
  padding: 10px 12px;
  text-align: center;

  &:disabled {
    background-color: $color-pc-bg-ebebeb;
    color: $color-pc-text-999999;
  }
}

  .dark-mode {
    @import "../../../css/colors_dark";


    .modal-payment-method {

      .badresult {
        background-color: $color-pc-bg-f5e1e1;
        border-color: $color-pc-border-b25959;
      }
      .badresult a:link,.badresult a:active,.badresult a:visited {
        color: $color-pc-text-b25959;
      }
      .badresult-text,
      div[role="alert"] {
        color: $color-pc-text-b25959;
      }
      .text-payment-notice {
        background-color: $color-pc-bg-f6f6f6;
      }
      .border-bottom {
        border-bottom: $color-pc-border-999999 1px solid;
      }

      .payment-method {
        .selected-payment-methods {
          background-color: $color-pc-bg-f6f6f6;
          color: $color-pc-text-787878;
        }
        input.payment-radio[type="radio"] {
          & + label {
            background-color: $color-pc-bg-ffffff;
            color: $color-pc-text-323232;
          }
          &:disabled {
            & + label {
              color: $color-pc-text-999999;
            }
          }
        }
      }

      .payment-adblock-error-message {
        color: $color-pc-text-b25959;
      }
      .creditcard-note {
        color: $color-pc-text-787878;
      }

      .creditcard-information {
        .creditcard-information-icons {
          &:before {
            color: $color-pc-text-787878;
          }
        }
        .StripeElement {
          border: $color-pc-border-d8d8d8 1px solid;
        }
        .StripeElement--focus {
          box-shadow: 0 1px 3px 0 $color-pc-bg-f6f6f6;
        }
      }

      .btn-edit {
        background-color: $color-pc-bg-ffffff;
        color: $color-pc-text-2e51a2;
        border: $color-pc-border-2e51a2 1px solid;
        &:hover {
          background-color:rgba($color-pc-bg-2e51a2, 0.3);
        }
        &[data="delete"] {
          border: 1px solid $color-pc-border-ff3824;
          color: $color-pc-text-ff3824;
          &:hover {
            background-color:rgba($color-pc-bg-ff3824, 0.3);
          }
        }
      }

      .payment-edit-creditcard-button-area {
        border-top: $color-pc-border-b25959 1px solid;
      }
      .creditcard-information-registered-text {
        color: $color-pc-text-808080;
      }
      .creditcard-information-registered-edit-button {
        background-color: $color-pc-bg-ffffff;
        border: $color-pc-border-2e51a2 1px solid;
        color: $color-pc-text-2e51a2;
        &:hover {
          background-color: rgba($color-pc-bg-2e51a2,.3);
          text-decoration: none;
        }
      }

      .textPaymentPolicyContainer {
        &:after {
          border-bottom: $color-pc-border-999999 1px solid;
        }
      }
      .text-payment-policy {
        border: $color-pc-border-d8d8d8 1px solid;
        h2 {
          border-color: $color-pc-border-bebebe;
          color: $color-pc-text-000000;
        }
        &.no-border {
          border: $color-pc-border-d8d8d8 0px solid;
        }
        .mal-address {
          background-color: $color-pc-bg-e5e5e5;
        }
      }

      .btn-form-submit {
        background-color: $color-bg-submit-button;
        color: $color-white-fixed;
        &:disabled {
          background-color: $color-pc-bg-ebebeb;
          color: $color-pc-text-999999;
        }
      }
    }
  }

</style>

<script type="text/babel">
  import Stripe from "./components/Stripe.vue"
  import BraintreePayPal from "./components/BraintreePayPal.vue"
  import Agreement from "./components/Agreement.vue"
  import LoadingModal from "./components/LoadingModal.vue"
  import GtmJsEvent from "../../../js/common/modules/gtm/jsEvent";

  const axios = window.axios

  export default {
    mounted() {
      blockAdBlock.onDetected(() => {
        this.isBlockAdBlock = true
      })
    },
    components: {
      Stripe,
      BraintreePayPal,
      Agreement,
      LoadingModal,
    },
    props: {
      initialPaymentMethod: String,
      braintreeRegistrationDatetime: String,
      supportUrl: String,
      termsHtml: String,
      defaultPaymentMethod: String,
      registeredCard: Object,
    },
    data() {
      return {
        paymentMethod: this.initialPaymentMethod,
        checkedAgreement: false,
        isCardEditMode: false,
        isRequesting: false,
        isError: false,
        errorMessage: null,
        isRegisteredPayment: false,
        isRegisterdPaymentMethod: false,
        isBlockAdBlock: false,
        paypalRegistrationDatetime: null,
        registeredCardInfo: {
          type: null,
          digits: null,
          expiry_date: null,
        },
      }
    },
    computed: {
      showAgreement() {
        // デフォルト決済手段が登録されていない時に出す
        return !this.defaultPaymentMethod
      },
    },
    methods: {
      /**
       * カードを新規に登録し、デフォルト決済手段に設定する
       */
      async registerStripeCard() {
        this.isRequesting = true
        this.isError = false

        GtmJsEvent.send('membership-payment-button-stripe')

        try {
          const token = await this.$refs.stripe.fetchToken()
          if (!token) {
            this.isRequesting = false
            return
          }

          const response = await axios.post("/payment/register/stripe", {token})
          const responsePaymentSetting = await axios.get("/payment/setting")
          this.registeredCardInfo.type   = responsePaymentSetting.data.registered_card.type
          this.registeredCardInfo.digits = responsePaymentSetting.data.registered_card.digits
          this.isRegisterdPaymentMethod  = true
          this.paymentMethod = "stripe"
        } catch (error) {
          this.isError = true
          if (error.response.data.redirect) {
            window.location.href = error.response.data.redirect
          } else {
            this.errorMessage = error.response.data.message
            if (!this.errorMessage && error.response.data.errors) {
              this.errorMessage = error.response.data.errors[0].message
            }
            this.isRequesting = false
          }
        }
      },

      /**
       * PayPal のダイアログで認証された時に呼ばれるコールバック
       * PayPal の情報を新規に登録し、デフォルト決済手段に設定する
       */
      async paypalCallback(nonce) {
        this.isRequesting = true
        this.isError = false

        GtmJsEvent.send('membership-payment-button-paypal')

        try {
          const response = await axios.post("/payment/register/paypal", {token: nonce})
          const responsePaymentSetting = await axios.get("/payment/setting")
          const braintree_registration_datetime = responsePaymentSetting.data.braintree_registration_datetime.date
          const convertToDate = new Date(braintree_registration_datetime.split(".")[0].replace(/-/g, "/"))
          this.paypalRegistrationDatetime = convertToDate.toDateString()
          this.isRegisterdPaymentMethod = true
          this.paymentMethod = "paypal"
        } catch (error) {
          this.isError = true
          this.errorMessage = error.response.data.message
          if (!this.errorMessage && error.response.errors) {
            this.errorMessage = error.response.errors[0].message
          }
          this.isRequesting = false
        }
      },

      /**
       * 既に登録済みの決済手段を、デフォルトの決済手段として設定する
       */
      async updatePaymentMethod() {
        this.isRequesting = true
        this.isError = false

        try {
          const response = await axios.put(`/payment/register/${this.paymentMethod}`)
          this.isRegisterdPaymentMethod = true

          if (this.paymentMethod === "stripe") {
            this.registeredCardInfo.type   = this.registeredCard.type
            this.registeredCardInfo.digits = this.registeredCard.digits
          }
          if (this.paymentMethod === "paypal") {
            this.paypalRegistrationDatetime   = this.braintreeRegistrationDatetime
          }

        } catch (error) {
          this.isError = true
          this.errorMessage = error.response.data.message
          if (!this.errorMessage && error.response.errors) {
            this.errorMessage = error.response.errors[0].message
          }
          this.isRequesting = false
        }
      },
      async proceedToCheckout() {
        this.isRequesting = true
        this.isError = false
        try {
          const responsePaymentSetting = await axios.get("/payment/setting")
          let responseText = null
          if (responsePaymentSetting.data.registered_card) {
            responseText = responsePaymentSetting.data.registered_card.digits
          }
          if (responsePaymentSetting.data.braintree_registration_datetime) {
            responseText = responsePaymentSetting.data.braintree_registration_datetime.date
          }
          if (responseText === null) {
            this.isRequesting = false
            this.isRegisterdPaymentMethod = false
            this.isError = true
            this.errorMessage = "You have no payment information registered. Please try again."
          }
          this.$emit('registerdPaymentMethod')
        } catch (error) {
          this.isError = true
          this.errorMessage = error.response.data.message
          this.isRequesting = false
        }
      }
    }
  }
</script>
