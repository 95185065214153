<template>
  <div>
    <!-- Books -->
    <div v-if="isLoadingItems">
      <i class="fa-solid fa-spinner fa-spin fa-2x fa-fw"></i>
    </div>
    <div v-if="!isLoadingItems"
         class="itemColumn" v-for="itemRow in currentRows">
      <book
        v-if="isTypeVolume"
        v-for="item in itemRow"
        @accessLimit="accessLimit(item)"
        :key="item.id"
        :item="item"
        :currency="currency"
        :width="792 / numItemsPerRow"
        :hide-title="true"
        :location="options.location"
      ></book>
      <chapter
        v-if="isTypeChapter"
        v-for="item in itemRow"
        @accessLimit="accessLimit(item)"
        :key="item.id"
        :item="item"
        :currency="currency"
        :login-path="loginPath"
        :width="792 / numItemsPerRow"
        :hide-title="true"
        @purchase="purchase(item)"
        :is-requesting="isLoadingItems"
        :is-lazyload="isLazyload"
        :location="options.location"
      ></chapter>
    </div>
    <div class="pager-area" v-if="numPages > 1">
      <ul class="pager">
        <li class="pager-item" v-for="i in numPages">
          <a class="pager-item-button"
             :class="{selected: i === currentPage}"
             @click="loadItems(i)">{{ i }}</a>
        </li>
      </ul>
    </div>

    <!-- modal -->
    <tax-calculation-modal></tax-calculation-modal>
    <template v-if="showModal">
      <purchase-single-modal
        @confirm="confirm"
        @cancel="showConfirmationModal = false"
        :is-shown="showConfirmationModal"
        :is-requesting="isPurchasing"
        :error="error"
        :item="modalItem"
        :currency="currency"
        :is-delegated="isDelegated"
      ></purchase-single-modal>
      <purchase-finish-modal
        @read="read"
        @close="showFinishModal = false"
        :is-shown="showFinishModal"
        :is-single="true"
        :title="modalItem.title"
        :numberingText="modalItem.numberingText"
      ></purchase-finish-modal>
    </template>
    <access-limit-modal
      :is-shown="showAccessLimitModal"
      :item="saleItem"
      :show-loading-modal="showLoadingModal"
      @purchase="moveToPurchase"
      @cancel="cancel"
    ></access-limit-modal>
  </div>
</template>

<style scoped lang="scss">
  $color-link: #1d439b;
  .itemColumn {
    display: flex;
    padding-bottom: 20px;
  }

  div.pager-area {
    position: relative;
    display: block;
    width: 792px;
    overflow: hidden;

    ul.pager {
      float: left;
      position: relative;
      left: 50%;
      padding-left: 0;

      li.pager-item {
        position: relative;
        left: -50%;
        float: left;
        display: inline-block;
        padding: 0 10px;

        a.pager-item-button {
          background: $color-link;
          display: block;
          min-width: 24px;
          padding: 5px 2px;
          margin-bottom: 5px;
          border-radius: 3px;
          color: #fff;
          text-align: center;
          text-decoration: none;
          opacity: 0.3;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
          &.selected {
            opacity: 1;
            cursor: default;
          }
        }
      }
    }
  }
</style>

<script type="text/babel">
  import * as types from "../../../common/stores/all_books/mutation-types"
  import {mapState, mapMutations, mapGetters} from "vuex"
  import Book from "../../molecules/Book.vue"
  import Chapter from "../../molecules/Chapter.vue"
  import PurchaseSingleModal from "../../molecules/PurchaseSingleModal.vue"
  import PurchaseFinishModal from "../../molecules/PurchaseFinishModal.vue"
  import AccessLimitModal from "../../molecules/AccessLimitModal.vue"

  import {NAMESPACE as TAX_NAMESPACE} from "../../../common/stores/tax_calculation_modal"
  import * as taxCalculationModalTypes from "../../../common/stores/tax_calculation_modal/mutation-types"
  import TaxCalculationModal from "../tax_calculation_modal/TaxCalculationModal.vue"

  const axios = window.axios

  export default {
    components: {
      PurchaseSingleModal,
      PurchaseFinishModal,
      Book,
      Chapter,
      TaxCalculationModal,
      AccessLimitModal,
    },
    props: {
      options: {
        type: Object,
        default: {},
      },
    },
    data() {
      return {
        numItemsPerRow: 6,
        pageNumber: 1,
        modalItem: {},
        showConfirmationModal: false,
        showFinishModal: false,
        isRequesting: false,
        showAccessLimitModal: false,
        saleItem: null,
        showLoadingModal: null,

        error: null,
        showModal: false,
        paymentMethod: "Stripe", //[TODO] 仮
      }
    },
    computed: {
      ...mapState([
        "itemType",
        "items",
        "currentPage",
        "loginPath",
        "currency",
        "numItemsPerPage",
        "numTotalItems",
        "loadItemsPath",
        "loadedPages",
        "isPurchasing",
        "isLoadingItems",
      ]),
      ...mapGetters([
        "isTypeVolume",
        "isTypeChapter",
        "numPages",
        "currentItems",
      ]),
      ...mapState(`${TAX_NAMESPACE}`, {
        isDelegated: state => state.isDelegated
      }),
      currentRows() {
        let rows = []
        const numRowsPerPage = this.numItemsPerPage / this.numItemsPerRow
        for (let i = 0; i < numRowsPerPage; i++) {
          if (!this.currentItems) {
            return
          }
          const beginIndex = this.numItemsPerRow * i
          const items = this.currentItems.slice(beginIndex, beginIndex + this.numItemsPerRow)
          if (items.length > 0) {
            rows.push(items)
          }
        }
        return rows
      },
      isLazyload() {
        let Lazyloadable = false
        const userAgent = window.navigator.userAgent.toLowerCase()
        if (userAgent.indexOf('edge') != -1) {
          Lazyloadable = false
        } else if (userAgent.indexOf("chrome") != -1) {
          Lazyloadable = true
        } else if (userAgent.indexOf("firefox") != -1) {
          Lazyloadable = true
        }
        return Lazyloadable
      },
    },
    methods: {
      async loadItems(page) {
        try {
          await this.$store.dispatch(types.LOAD_MORE, page)
          if (this.pageNumber === page ) {
            return
          }
          this.pageNumber = page
          this.scrollToTabs()
        } catch (e) {
          // 握りつぶす
        }
      },

      async confirm() {
        this.error = null
        try {
          await this.$store.dispatch(types.PURCHASE, this.modalItem.id)
          this.showConfirmationModal = false
          this.showFinishModal = true
        } catch (e) {
          this.error = e.message
        }
      },

      scrollToTabs() {
        const comicList = document.querySelector(".comics-list")
        const duration = 500
        const startPos = window.pageYOffset
        const clientY = comicList.getBoundingClientRect().top
        let timeStart, timeElapsed

        const loop = (time) => {
          timeElapsed = time - timeStart
          window.scrollTo(0, easeInOutQuad(timeElapsed, startPos, clientY, duration))
          if (timeElapsed < duration) {
            requestAnimationFrame(loop)
          } else {
            window.scrollTo(0, startPos + clientY)
          }
        }

        // Robert Penner's easeInOutQuad - http://robertpenner.com/easing/
        const easeInOutQuad = (t, b, c, d) => {
          t /= d / 2
          if(t < 1) return c / 2 * t * t + b
          t--
          return -c / 2 * (t * (t - 2) - 1) + b
        }

        requestAnimationFrame((time) => {
          timeStart = time
          loop(time)
        })
      },

      read() {
        window.open(this.modalItem.viewerUrl)
        this.showFinishModal = false
      },

      async purchase(item) {
        if (this.loginPath) {
          location.href = this.loginPath
        } else {
          this.isRequesting = true

          const taxInfo = await this.$store.dispatch(`${TAX_NAMESPACE}/${taxCalculationModalTypes.GET_TAX}`, {
            itemType: item.itemType,
            itemIds: [item.id],
            itemTotalAmount: item.salePrice || item.price,
            selectedCountry: null
          })
          this.$store.commit(types.SET_TAX, {item, taxes: taxInfo})

          this.isRequesting = false
          this.showModal = true
          this.modalItem = item
          this.showConfirmationModal = true
        }
      },
      moveToPurchase() {
        if (this.isTypeVolume) {
          location.href = `/store/manga_volume/${this.saleItem.saleBookId}/${this.saleItem.saleBookTitle}`
        } else {
          location.href = `/store/manga/${this.saleItem.saleBookId}/${this.saleItem.saleBookTitle}`
        }
      },
      cancel() {
        this.showAccessLimitModal = false
      },
      async accessLimit(item) {
        this.error = null
        this.showLoadingModal = true
        let newWindow = window.open(window.location.href)
        try {
          let res
          if (this.isTypeVolume) {
            res = await axios.get(`/store/manga_volume/${item.id}/access/count`)
          } else {
            res = await axios.get(`/store/manga/${item.id}/access/count`)
          }
          if (res.data.login_path) {
            this.showLoadingModal = false
            window.location.href = res.data.login_path
            newWindow.close()
          } else if (!(res.data.user_access_count > 0) && res.data.total_access_count > item.accessLimit) {
            this.saleItem = item
            this.showLoadingModal = false
            this.showAccessLimitModal = true
            newWindow.close()
          } else {
            this.showLoadingModal = false
            newWindow.location.href = item.viewerUrl
          }
        } catch (e) {
          this.showLoadingModal = false
          // 握りつぶす
        }
      },
    },
  }
</script>
