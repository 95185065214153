import GtmJsEvent from "../../../common/modules/gtm/jsEvent";
import Modal from "../../../common/modules/modal";

const $ = window.$;
const localStorage = require("../../../common/modules/localStorage");

$(function () {

  // Settings dialog
  if ($(".js-btn-settings").exists()) {
    $(".js-btn-settings").click(function(e){
      const settingsDialogData = $(e.currentTarget).data("settingsDialog")
      Modal.generate()
      Modal.buildFriendsUpdateSettingsDialog(settingsDialogData, (newData) => {
        $(e.currentTarget).data("settingsDialog", newData)
      })
      Modal.show()
    });
  }

  const hasTouchEvent = ("ontouchstart" in window);


  const animeSlider = function (selectorName,btnWidth,slideMargin) {

console.log(selectorName,btnWidth,slideMargin)

    const $slideBlock = $(`#${selectorName}.widget-slide-block`);

    if (!$slideBlock.find(".widget-slide li.btn-anime").length) {
      return;
    }

    const moveSlideNum = $slideBlock.find(".js-widget-slide").data("slide") || 4;//How many move
    const animeW     = $slideBlock.find(".widget-slide li.btn-anime").eq(0).outerWidth();
    const animeCount = $slideBlock.find(".widget-slide li.btn-anime").length;//count total Anime
    const animeWidth = animeW + slideMargin;


    const setSlideWidth = animeWidth * (animeCount + moveSlideNum);//Slider Outer Width
    $slideBlock.find(".widget-slide").width(setSlideWidth);

    //button
    const $sliderBtnLeft = $slideBlock.find(".btn-widget-slide-side.left");
    const $sliderBtnRight = $slideBlock.find(".btn-widget-slide-side.right");

    // hide button
    if(animeCount < moveSlideNum + 1) {
      $sliderBtnLeft.hide();
      $sliderBtnRight.hide();
    }else if(!hasTouchEvent){
      const hideBtnTimer = setInterval(function(){
          $sliderBtnLeft.css({left: -1*btnWidth,opacity:0});
          $sliderBtnRight.css({right: -1*btnWidth,opacity:0});
          clearInterval(hideBtnTimer);
      } , 1500);
    }

    //click
    $sliderBtnLeft.find(".btn-inner").on("click",function(e){
      const btn = {
        direction : "left",
        button    : $(this)
      };
      moveSlideAnime(btn);
    });
    $sliderBtnRight.find(".btn-inner").on("click",function(e){
      const btn = {
        direction : "right",
        button    : $(this)
      };
      moveSlideAnime(btn);
    })

    //hover
    if(!hasTouchEvent){
      $slideBlock.find(".widget-slide-outer").on("mouseover",function(){
        $sliderBtnLeft.css({left: 0,opacity:1});
        $sliderBtnRight.css({right: 0,opacity:1});
      }).on("mouseout",function(){
        $sliderBtnLeft.css({left: -1*btnWidth,opacity:0});
        $sliderBtnRight.css({right: -1*btnWidth,opacity:0});
      });

      $sliderBtnLeft.on("mouseover",function(){
        $sliderBtnLeft.css({left: 0,opacity:1});
        $sliderBtnRight.css({right: -1*btnWidth,opacity:0});
      }).on("mouseout",function(){
        $sliderBtnRight.css({right: -1*btnWidth,opacity:0});
        $sliderBtnLeft.css({left: -1*btnWidth,opacity:0});
      });

      $sliderBtnRight.on("mouseover",function(){
        $sliderBtnRight.css({right: 0,opacity:1});
        $sliderBtnLeft.css({left: -1*btnWidth,opacity:0});
      }).on("mouseout",function(){
        $sliderBtnRight.css({right: -1*btnWidth,opacity:0});
        $sliderBtnLeft.css({left: -1*btnWidth,opacity:0});
      });
    }

    // slide function
    const moveSlideAnime = function(obj) {
      const direction = obj.direction;
      const $btnInner = obj.button;
      $btnInner.hide();

      if(direction == "right") {
        var animeSlide = [];
        $slideBlock.find(".widget-slide li.btn-anime").each(function(){
          animeSlide.push($(this));
        });

        for(var i=0;i<moveSlideNum;i++){
          animeSlide[i].clone(true).insertAfter($slideBlock.find(".widget-slide li.btn-anime:last"));
        }

        $slideBlock.find(".widget-slide").css("marginLeft","0px");

        $slideBlock.find(".widget-slide").animate({
          marginLeft : `${-1*animeWidth*moveSlideNum}px`
          }, {
          duration : 500,
          easing   : "swing",
          complete : function() {
              const getMarginLeftVal = parseInt($slideBlock.find(".widget-slide").css("marginLeft").replace("px",""));
              for(let i=0;i<moveSlideNum;i++){
                $slideBlock.find(".widget-slide").css("marginLeft",`${getMarginLeftVal+animeWidth*moveSlideNum}px`);
                $slideBlock.find(".widget-slide li.btn-anime:first").remove();
              }
              $btnInner.show();
            }
        });
      }else{
        var animeSlide = [];
        $slideBlock.find(".widget-slide li.btn-anime").each(function(){
          animeSlide.push($(this));
        });
        animeSlide.reverse();

        for(var i=0;i<moveSlideNum;i++){
          animeSlide[i].clone(true).insertBefore($slideBlock.find(".widget-slide li.btn-anime:first"));
        }

        const marginLeftVal = `${-1*animeWidth*moveSlideNum}px`;
        $slideBlock.find(".widget-slide").css("marginLeft",marginLeftVal);

        $slideBlock.find(".widget-slide").animate({
            marginLeft : "0px"
            }, {
            duration : 500,
            easing   : "swing",
            complete : function() {
              for(let i=0;i<moveSlideNum;i++){
                $slideBlock.find(".widget-slide li.btn-anime:last").remove();
              }
              $btnInner.show();
            }
        });
      }
    };
  }

  //anime slider in index.php
  if($(".widget-slide-block")[0]){
    animeSlider("widget-seasonal-video",40,6);
    animeSlider("widget-episode-video",30,8);
    animeSlider("widget-manga-store",30,8);
    animeSlider("widget-promotional-video",30,8);
  }//$('.watch-anime-slide-block')[0]

  // anime eposide video widget
  if ($(".js-widget-episode-video-link")[0]) {
    $(".js-widget-episode-video-link").on("click", function() {
      const url = $(this).data("url");
      location.href = url;
    });
    $(".js-widget-episode-video-link .title").on("mouseover", function() {
      $(this).parents(".js-widget-episode-video-link").css({ opacity: 1 });
    }).on("mouseout", function() {
      $(this).parents(".js-widget-episode-video-link").css({ opacity: "" });
    });
  }

  // friend updates widget
  if ($(".js-widget-friend-list-updates-item")[0]) {
    const widgetFriendListUpdates = {
      requestingIds: {},
      showNotesText: ($targetItem) => {
        $targetItem.find(".js-widget-friend-list-updates-show-notes").addClass("hidden")
        $targetItem.find(".js-widget-friend-list-updates-notes-text").removeClass("hidden")
        widgetFriendListUpdates.showReadMore($targetItem)
      },
      hideNotesText: ($targetItem) => {
        $targetItem.find(".js-widget-friend-list-updates-show-notes").removeClass("hidden")
        $targetItem.find(".js-widget-friend-list-updates-notes-text").addClass("hidden")
        $targetItem.find(".js-widget-friend-list-updates-read-more").addClass("hidden")
      },
      showMoreText: ($targetItem) => {
        $targetItem.find(".js-widget-friend-list-updates-read-more").addClass("hidden")
        $targetItem.find(".js-widget-friend-list-updates-notes-text").removeClass("show-less")
      },
      showReadMore: ($targetItem) => {
        $targetItem.each((index, element) => {
          const $element = $(element);
          const $notesTextInner = $element.find(".js-widget-friend-list-updates-notes-text-inner")
          if ($notesTextInner && $notesTextInner.height() > $element.find(".js-widget-friend-list-updates-notes-text").height()) {
            $element.find(".js-widget-friend-list-updates-read-more").removeClass("hidden")
          }
        })
      },
      showMuteFriendBtn: ($targetItem) => {
        $targetItem.find(".js-widget-friend-list-updates-mutefriend").removeClass("hidden")
        $targetItem.find(".js-widget-friend-list-updates-unmute").addClass("hidden")
      },
      hideMuteFriendBtn: ($targetItem) => {
        $targetItem.find(".js-widget-friend-list-updates-mutefriend").addClass("hidden")
        $targetItem.find(".js-widget-friend-list-updates-unmute").removeClass("hidden")
      },
      requestsMuteNotes: (id, isMuteNotes) => {
        return $.ajax({
          url: "/friends/api/mute_notes.json",
          data: JSON.stringify({
            "user_id": id,
            "is_mute_notes": isMuteNotes
          }),
          contentType: "application/json",
          type: "POST",
        }).catch((jqXHR) => {
          if (jqXHR.status === 401) {
            window.location.href = jqXHR.responseJSON.redirect;
          }
          return jqXHR;
        });
      }
    }
    $(".js-widget-friend-list-updates-show-notes").on("click", function() {
      widgetFriendListUpdates.showNotesText($(this).closest(".js-widget-friend-list-updates-item"))
    })
    $(".js-widget-friend-list-updates-read-more").on("click", function() {
      widgetFriendListUpdates.showMoreText($(this).closest(".js-widget-friend-list-updates-item"))
    });
    $(".js-widget-friend-list-updates-comment").on("click", function() {
      const $targetItem = $(this).closest(".js-widget-friend-list-updates-item")
      Modal.generate()
      Modal.buildProfileCommentDialog(
        $targetItem.attr("data-user-id"),
        $targetItem.attr("data-user-name"),
        $targetItem.attr("data-work-title"),
        $targetItem.attr("data-work-url"),
        $targetItem.attr("data-status"),
        $targetItem.attr("data-notes"),
        () => {
          $(this).html('<i class="fas fa-check"></i>Sent').addClass("sent")
          GtmJsEvent.send("notes-sent-comment-top")
        }
      )
      Modal.show()
    });
    $(".js-widget-friend-list-updates-mutefriend").on("click", function() {
      const userId = $(this).closest(".js-widget-friend-list-updates-item").attr("data-user-id")
      if (widgetFriendListUpdates.requestingIds[userId]) {
        return;
      }
      widgetFriendListUpdates.requestingIds[userId] = true;
      widgetFriendListUpdates.requestsMuteNotes(parseInt(userId), true).then(() => {
        const $targetItems = $(`.js-widget-friend-list-updates-item[data-user-id="${userId}"]`)
        widgetFriendListUpdates.hideNotesText($targetItems)
        widgetFriendListUpdates.hideMuteFriendBtn($targetItems)
      }).always(() => {
        widgetFriendListUpdates.requestingIds[userId] = false;
      });
    });
    $(".js-widget-friend-list-updates-unmute").on("click", function() {
      const userId = $(this).closest(".js-widget-friend-list-updates-item").attr("data-user-id")
      if (widgetFriendListUpdates.requestingIds[userId]) {
        return;
      }
      widgetFriendListUpdates.requestingIds[userId] = true;
      widgetFriendListUpdates.requestsMuteNotes(parseInt(userId), false).then(() => {
        const $targetItems = $(`.js-widget-friend-list-updates-item[data-user-id="${userId}"]`)
        widgetFriendListUpdates.showMuteFriendBtn($targetItems)
        if ($(".js-widget-friend-list-updates").data("setting-show-notes")) {
          widgetFriendListUpdates.showNotesText($targetItems)
        }
      }).always(() => {
        widgetFriendListUpdates.requestingIds[userId] = false;
      });
    });
    widgetFriendListUpdates.showReadMore($(".js-widget-friend-list-updates-item"));
  }
});

$(document).ready(function() {
  const KEY = "clubsWidgetData";

  const $club = $(".widget.clubs");
  if ($club.length === 0) {
    return;
  }

  $club.find(".js-new").each(function() {
    const $new = $(this);
    const time = $new.data("time");
    const clubId = $new.data("clubId");

    const data = localStorage[KEY] ? JSON.parse(localStorage[KEY]) : {
      version: 1,
      clubs: {}
    };
    if (!data.clubs[clubId]) {
      $new.removeClass("hidden"); // New!
    } else {
      const t = data.clubs[clubId].time;
      if (t < time) {
        $new.removeClass("hidden"); // New!
      }
    }
    data.clubs[clubId] = {time: time};
    localStorage[KEY] = JSON.stringify(data);
  });
});
