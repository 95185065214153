"use struct";

export function membership_complete(path, dataLayer) {
  if ( path.match("account/membership/complete") && document.getElementById('ga_goodresult') ) {
    dataLayer.push({
        'trackPageview': '/member-register-complete?c-ms_payment=2&c-ms_mode=3',
        'member_title': '3-1-2.MAL会員登録成功',
        'event': 'loadready-member-register-complete'
    });
  }
}
