<template>

  <div class="purchase-button-container">
<!-- buttons -->
    <read-button
      v-if="item.isPossessed || item.isFree"
      @read="readOrLimit"
      style="margin-right:16px;"
    ></read-button>
    <purchase-button
      v-if="!item.isPossessed && !item.isFree"
      @purchase="purchase"
      :text="'Checkout'"
    ></purchase-button>
    <icon-payment-method
      v-if="!hasDefaultPatmentMethod"
      style="position: absolute;bottom: 0;right: -100px;margin-left: 8px;"
    ></icon-payment-method>

<!-- modal -->
    <tax-calculation-modal></tax-calculation-modal>
    <payment-modal
      :show="showPaymentModal"
      :payment="payment"
      :terms-html="termsHtml"
      :support-url="supportUrl"
      @registerdPaymentMethod="registerdPaymentMethod"
      @hidePaymentModal="hidePaymentModal"
    ></payment-modal>
    <purchase-single-modal
      @confirm="confirm"
      @cancel="showConfirmationModal = false"
      :is-shown="showConfirmationModal"
      :is-requesting="isRequesting"
      :is-delegated="isDelegated"
      :error="error"
      :item="item"
      :currency="currency"
    ></purchase-single-modal>
    <purchase-finish-modal
      @read="read"
      @close="showFinishModal = false"
      :is-shown="showFinishModal"
      :is-single="true"
      :title="item.title"
      :numbering-text="item.numberingText"
    ></purchase-finish-modal>
    <access-limit-modal
      :is-shown="showAccessLimitModal"
      :item="saleItem"
      :show-loading-modal="showLoadingModal"
      @purchase="moveToPurchase"
      @cancel="cancel"
    ></access-limit-modal>
  </div>

</template>

<style lang="scss" scoped>
  .purchase-button-container {
    position: relative;
    display: inline-block;
  }
</style>

<script type="text/babel">
  import * as types from "./store/mutation-types"
  import eventBus from "./store/eventBus"
  import {mapState, mapMutations} from "vuex"
  import PaymentModal from "../../../../../pages/manga_store/viewer/pc/organisms/PaymentModal.vue"
  import PurchaseSingleModal from "../../molecules/PurchaseSingleModal.vue"
  import PurchaseFinishModal from "../../molecules/PurchaseFinishModal.vue"
  import PurchaseButton from "../../atoms/PurchaseButton.vue"
  import ReadButton from "../../atoms/ReadButton.vue"
  import IconPaymentMethod from "../../atoms/IconPaymentMethod.vue"
  import AccessLimitModal from "../../molecules/AccessLimitModal.vue"

  import {NAMESPACE as TAX_NAMESPACE} from "../../../common/stores/tax_calculation_modal"
  import * as taxCalculationModalTypes from "../../../common/stores/tax_calculation_modal/mutation-types"
  import TaxCalculationModal from "../tax_calculation_modal/TaxCalculationModal.vue"

  const axios = window.axios

  export default {
    components: {
      PurchaseSingleModal,
      PurchaseFinishModal,
      PurchaseButton,
      ReadButton,
      IconPaymentMethod,
      TaxCalculationModal,
      PaymentModal,
      AccessLimitModal,
    },
    data() {
      return {
        showConfirmationModal: false,
        showFinishModal: false,
        isRequesting: false,
        error: null,
        showAccessLimitModal: false,
        saleItem: null,
        showLoadingModal: null,
      }
    },
    computed: {
      ...mapState([
        "item",
        "loginPath",
        "currency",
        "payment",
        "termsHtml",
        "supportUrl",
      ]),
      ...mapState(`${TAX_NAMESPACE}`, {
        isDelegated: state => state.isDelegated,
        showPaymentModal: state => state.modals.showPaymentModal,
      }),
      hasDefaultPatmentMethod() {
        return this.payment.defaultPaymentMethod ? true : false
      },
    },
    methods: {
      async confirm() {
        this.error = null
        this.isRequesting = true
        try {
          await this.$store.dispatch(types.PURCHASE)
          this.showConfirmationModal= false
          this.showFinishModal = true
          window.eventBus.$emit('hide-preview')
        } catch (e) {
          this.isRequesting = false
          this.error = e.message
        }
      },
      read() {
        this.showFinishModal = false
        this.$store.commit(types.READ)
      },
      async purchase() {
        if (this.loginPath) {
          location.href = this.loginPath
        } else {
          this.isRequesting = true

          const taxInfo = await this.$store.dispatch(`${TAX_NAMESPACE}/${taxCalculationModalTypes.GET_TAX}`, {
            itemType: this.item.itemType,
            itemIds: [this.item.id],
            itemTotalAmount: this.item.salePrice || this.item.price,
            selectedCountry: null
          })
          this.$store.commit(types.SET_TAX, taxInfo)

          this.isRequesting = false
          this.showConfirmationModal = true
        }
      },
      hidePaymentModal() {
        this.$store.commit(`${TAX_NAMESPACE}/${taxCalculationModalTypes.SHOW_PAYMENT_MODAL}`, false)
      },
      registerdPaymentMethod() {
        this.purchase()
        this.hidePaymentModal()
      },
      async readOrLimit(){
        if (this.item.isPossessed || !this.item.accessLimit) {
          window.open(this.item.viewerUrl)
        } else {
          this.error = null
          this.showLoadingModal = true
          let newWindow = window.open(window.location.href)
          try {
            const res = await axios.get(`/store/manga_volume/${this.item.id}/access/count`)
            if (res.data.login_path) {
              this.showLoadingModal = false
              window.location.href = res.data.login_path
              newWindow.close()
            } else if (!(res.data.user_access_count > 0) && res.data.total_access_count > this.item.accessLimit) {
              this.saleItem = this.item
              this.showLoadingModal = false
              this.showAccessLimitModal = true
              newWindow.close()
            } else {
              this.showLoadingModal = false
              newWindow.location.href = this.item.viewerUrl
            }
          } catch (e) {
            this.showLoadingModal = false
            // 握りつぶす
          }
        }
      },
      moveToPurchase() {
        location.href = `/store/manga_volume/${this.saleItem.saleBookId}/${this.saleItem.saleBookTitle}`
      },
      cancel() {
        this.showAccessLimitModal = false
      },
    },
  }
</script>
