const $ = window.$;

$(function () {

  if($("#banner-anime-streaming-block-detail .banner-anime-streaming-block4 a")[0]){
    $("#banner-anime-streaming-block-detail .banner-anime-streaming-block4 .anime-image").on("mouseover",function(){
      $(this).find(".btn-play").css({"background-color": "rgba(255,255,255,0.8)"});
    }).on("mouseout",function(){
      $(this).find(".btn-play").css({"background-color": "rgba(255,255,255,0)"});
    });
  }

  if($("#banner-anime-streaming-block-banner .banner-anime-streaming-block3 a")[0]){
    $("#banner-anime-streaming-block-banner .banner-anime-streaming-block3 a").each(function(){
      $(this).on("mouseover",function(){
        $(this).addClass("on");
      }).on("mouseout",function(){
        $(this).removeClass("on");
      });
    });
  }

  if($("#banner-anime-streaming-block-banner .banner-anime-streaming-block2 a")[0]){
    $("#banner-anime-streaming-block-banner .banner-anime-streaming-block2 a").each(function(){
      $(this).on("mouseover",function(){
        $(this).addClass("on");
      }).on("mouseout",function(){
        $(this).removeClass("on");
      });
    });
  }

  if($("#banner-anime-streaming-block-index .banner-anime-streaming-block1 a")[0]){
    $("#banner-anime-streaming-block-index .banner-anime-streaming-block1 a").each(function(){
      $(this).on("mouseover",function(){
        $(this).addClass("on");
      }).on("mouseout",function(){
        $(this).removeClass("on");
      });

    });
  }


});
