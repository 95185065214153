const $ = window.$
const _ = window._
const sVue = window.sVue

import Editor from "../../common/modules/editor";

$(document).ready(function () {
  Editor.attach('.bbcode-message-editor');

  const touchsupport = ("ontouchstart" in window);

  // グローバルナビゲーションのホバー処理
  $("#nav li").hoverIntent({
    sensitivity: 1,
    interval   : 0,
    over       : function () {
      $(this).addClass("hover");
      $("ul", this).show();
    },
    timeout    : 0,
    out        : function () {
      $(this).removeClass("hover");
      $("ul", this).hide();
    }
  });

  $(".Lightbox_AddEdit").fancybox({
    width         : 990,
    height        : "85%",
    autoScale     : true,
    autoDimensions: true,
    transitionIn  : "none",
    transitionOut : "none",
    type          : "iframe",
    onStart : function(){
      $(window).trigger('mal-fancybox-addedit-open', this);
    },
    onClosed : function(){
      $(window).trigger('mal-fancybox-addedit-close', this);
    }
  });
  $(window).on('mal-fancybox-addedit-close', () => {
    if (MAL && MAL.fancyBox) {
      if (MAL.fancyBox.onClose) {
        MAL.fancyBox.onClose(window);
      }
      MAL.fancyBox.onClose = null;
    }
  });

  // window.addEventListener('popstate', (event) => {
  // console.log(event);
  // });

  $(".Lightbox_Small").fancybox({
    width         : 400,
    height        : 400,
    autoScale     : true,
    autoDimensions: true,
    transitionIn  : "none",
    transitionOut : "none",
    type          : "iframe"
  });

  $(".Lightbox_Pic").fancybox({
    autoScale     : true,
    autoDimensions: true,
    transitionIn  : "none",
    transitionOut : "none"
  });


  $(".min2chars").on("keyup", function () {
    const objBut = $(this).closest("form").find("input[type=submit],button[type=submit]");
    if ($(this).val().length > 1) {
      objBut.removeClass("notActive");
    } else {
      objBut.addClass("notActive");
    }
  });

  // ヘッダーメニューの処理
  if ($("#header-menu")[0]) {
    //list and profile
    $("#header-menu").on("click", '.js-header-menu-unit', function () {
      if ($(this).hasClass("on")) {
        $(".header-menu-dropdown").hide();
        $(".js-header-menu-unit").removeClass("on");
      } else {
        $(".header-menu-dropdown").hide();
        $(`.header-${$(this).data("id")}-dropdown`).show();
        $(".js-header-menu-unit").removeClass("on");
        $(this).addClass("on");
      }
      $(document).on("click", function (event) {
        if (!$(event.target).closest(".js-header-menu-unit").length) {
          $(".header-menu-dropdown").hide();
          $(".js-header-menu-unit").removeClass("on");
        }
      });
    });
    // notificationのdropdownの開閉はvue制御なのでここでは不要
  }

  // PCでウィンドウ幅が1060pxより狭い場合に生じるフッターのずれを調整（PCの場合"ownlist"内のフッターも調整対象）
  if (!touchsupport) {
    $(window).on("load resize", function () {
      const footerW = $(window).width() < 1060 ? 1060 : $(window).width();
      $("#footer-block").css({width: `${footerW}px`});
      if ($(".footer-ranking")[0]) {
        $(".footer-ranking").css({width: `${footerW}px`});
      }
    });
  }

  // リストページではフッターの調整を行わない
  if (!$("body").hasClass("ownlist")) {
    // SPでフッターの横幅を広げるための処理
    if (touchsupport) {
      $(window).on("load resize", function () {
        const contentW = $(document).width() < $(window).width() ? $(document).width() : $(window).width();
        $("#footer-block").css({width: `${contentW}px`});
        if ($(".footer-ranking")[0]) {
          $(".footer-ranking").css({width: `${contentW}px`});
        }
      });
    }

    // フッターをスクリーンの一番下に寄せるためのマージン調整
    $(window).on("load resize", function () {
      const contentH = $("#myanimelist").outerHeight();
      const docH     = $(window).height();
      const rankingH = $(".footer-ranking")[0] ? $(".footer-ranking").outerHeight() : 0;
      const footerH  = $("#footer-block").outerHeight();
      let mtH = 0;
      if (docH > contentH + footerH + rankingH) {
        mtH = (docH - (contentH + footerH + rankingH) > 0) ? docH - (contentH + footerH + rankingH) : 0;
      }
      if ($(".footer-ranking")[0]) {
        $(".footer-ranking").css({"margin-top": `${mtH}px`});
      } else {
        $("#footer-block").css({"margin-top": `${mtH}px`});
      }
    });
  }

  // お気に入り追加/削除ボタン
  if ($("#v-favorite").exists()) {
    const el = "#v-favorite"
    const data = $(el).data("favorite")
    _.assign(data, {
      sending: false,
      msg: undefined,
      isError: false,
    })

    const vFav = new sVue({ // eslint-disable-line no-unused-vars
      el: el,
      data: data,
      methods: {
        toggleFavorite() {
          const method = this.isFavorite? "DELETE": "POST"
          const self = this
          const ftype = self.type
          const fid = self.id
          grecaptcha.ready(function(){
            grecaptcha.execute(window.GRECAPTCHA_SITE_KEY, {action: "social"}).then(function(token){
              $.ajax({
                url: `/favorite/${ftype}/${fid}.json`,
                method: method,
                dataType: "json",
                data: {'g-recaptcha-response': token},
                beforeSend: () => {
                  self.sending = true
                  self.msg = undefined
                }
              })
              .done(() => {
                if (method === "POST") {
                  self.isFavorite = true
                  self.msg = "Added successfully"
                } else {
                  self.isFavorite = false
                  self.msg = "Removed successfully"
                }
                self.isError = false
              })
              .fail((xhr) => {
                const json = xhr.responseJSON
                self.isError = true
                switch (xhr.status) {
                  case 400: // 最大favorite数を越えた場合
                    if (json.is_supporter) {
                      self.msg = `Only a maximum of ${json.max_favs} favorites allowed.`
                    } else {
                      self.msg = `Only a maximum of ${json.max_favs} favorites allowed. Become a <a href="${json.url}" target="_blank" class="link-mal-supporter">MAL Supporter</a> to increase it to twice!`
                    }
                    break;
                  case 401: // loginしていない場合login画面に飛ばす
                    window.location.href = json.redirect
                    break;
                  default:
                    self.msg = (json === null)? "Unknown error occured": json.errors[0].message
                }
              })
              .always(() => {
                self.sending = false
              })
            })
          })
        },
      }
    })
  }

  // ellipsis (3点リーダ)
  Array.from(document.querySelectorAll(".ellipsis-text")).forEach(el => {
    let wordEllipsis  = el.innerHTML
    let cloneEllipsis = el.cloneNode(true)
    const heightEllipsis = el.offsetHeight

    cloneEllipsis.setAttribute("style", `opacity:0; position:absolute; overflow:visible; max-height:none; height:auto; width:${el.offsetWidth}px;`)

    el.parentNode.insertBefore(cloneEllipsis, el.nextElementSibling)

    if (cloneEllipsis.offsetHeight > heightEllipsis) {
      while((wordEllipsis.length > 0) && (cloneEllipsis.offsetHeight > heightEllipsis)) {
        wordEllipsis = wordEllipsis.substr(0, wordEllipsis.length - 1)
        cloneEllipsis.innerHTML = wordEllipsis + "..."
      }
      el.innerHTML = cloneEllipsis.innerHTML
    }
    cloneEllipsis.parentNode.removeChild(cloneEllipsis)
  })

})
