const $ = window.$;
const CategoryFilter = require("../../../common/pages/news/categoryFilter");

$(function () {

  if ($(".news .menu-category .btn-category").exists()) {
    // カテゴリ情報を初期化
    const categories = new CategoryFilter();

    // カテゴリボタンを選択
    $(".news .menu-category .btn-category").each(function () {
      const $this = $(this);
      const id = $this.attr("id");
      if (categories.isSelected(id)) {
        $this.addClass("selected").css("border-bottom-color", $this.data("color"));
      }
    });

    // 記事を表示
    if (categories.isSelected("all")) {
      $(".news .news-list .news-unit").show();
    } else {
      $(".news .news-list .news-unit").each(function () {
        const $this = $(this);
        const tags = $this.data("tag").split(" ");
        if (categories.isSelected(tags)) {
          $this.show();
        }
      });
    }

    $(".news .menu-category .btn-category").on("click", function () {
      const $btnALL   = $(".news .menu-category .btn-category#all");
      const $btn      = $(".news .menu-category .btn-category");
      const $newsUnit = $(".news .news-list .news-unit");
      const $this     = $(this);
      if ($this.attr("id") =="all") {
        $newsUnit.show();
        $btn.removeClass("selected");
        $this.addClass("selected");
        $btn.css({"border-bottom-color": "#FFFFFF"});
        $this.css({"border-bottom-color": $this.data("color")})
      } else {
        $btnALL.removeClass("selected");
        $this.toggleClass("selected");
        selectNews();
      }

      // カテゴリ選択状況を保存
      $(".news .menu-category .btn-category").each(function () {
        categories.set($(this).attr("id"), $(this).hasClass("selected"));
      });
      categories.save();
    });
  }

  //quotetext内のimageの幅の調整
  if ($(".news .comment-list .text .quotetext")[0]) {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const webkit = userAgent.indexOf("safari") > -1;
    // except webkit
    if (webkit === false) {
      const resizeNewsCommentImage = function(obj,w) {
        const img = new Image();
        img.src = obj.attr("src");
        if (img.src) {
          const imgW  = img.width;
          //set image size
          if (imgW > w) {
            obj.width(w);
          } else {
            obj.width(imgW);
          }
        }
      }
      $(".news .comment-list .text .quotetext").each(function() {
        const $quotetext = $(this);
        const quotetextW = $quotetext.width();

        //manual resize image
        $(this).find(".spoiler .button").on("click",function() {
          const btnVal = $(this).val();
          if (btnVal === "Show spoiler" ) {
            const spoilerW = $(this).parent(".spoiler").width();
            const $userimg = $(this).next(".spoiler_content").find(".userimg");
            const userimgNum = $userimg.length;
            for (let i = 0; i < userimgNum; i++) {
              resizeNewsCommentImage($userimg.eq(i),quotetextW);
            }
          }
        });

        //auto resize image
        const $userimg = $(this).find(".userimg");
        const userimgNum = $userimg.length;
        if(userimgNum > 0){
          for (let i = 0; i < userimgNum; i++) {
            //get origin image size
            resizeNewsCommentImage($userimg.eq(i),quotetextW);
          }
        }
      });
    }
  }

  const selectNews = function(){
    const $btnALL   = $(".news .menu-category .btn-category#all");
    const $btn      = $(".news .menu-category .btn-category");
    const $newsUnit = $(".news .news-list .news-unit");

    $newsUnit.hide();
    $btnALL.css({"border-bottom-color": "#FFFFFF"});
    $btn.css({"border-bottom-color": "#FFFFFF"});
    let showAll = 0;
    $btn.each(function () {
      const $this = $(this);
      if ($this.hasClass("selected")) {
        $this.css({"border-bottom-color": $this.data("color")});
        const id = $this.attr("id");
        $newsUnit.each(function () {
          const tags = $(this).data("tag");
          if (tags.indexOf(id) != -1) {
            $(this).show();
          }
        });
        showAll += 1;
      }
    });
    if (showAll < 1) {
      $newsUnit.show();
      $btnALL.addClass("selected");
      $btnALL.css({"border-bottom-color": $btnALL.data("color")});
    }
  };

  $(".menu-category-tags .btn-category").click(function () {
    const id = $(this).data("id");
    const pos = $(`#${id}`).offset().top;
    $("html,body").animate({ scrollTop: (pos-70) });
    return false;
  });


});
