var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShown,
                expression: "isShown"
              }
            ],
            staticClass: "modal-content"
          },
          [
            _c("div", {
              staticClass: "modal-mask",
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-wrapper" }, [
              _c("div", { staticClass: "modal-container" }, [
                _c("p", { staticClass: "modal-lead" }, [
                  _vm._v("Purchase completed.")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "modal-title" }, [
                  _vm._v(_vm._s(_vm.title))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "modal-volume" }, [
                  _vm._v(_vm._s(_vm.numberingText))
                ]),
                _vm._v(" "),
                _vm.isSubscription
                  ? _c("p", { staticClass: "modal-text" }, [
                      _vm._v(
                        "\n            Subscriptions are set to auto-renew. You can change the subscription setting from your\n            "
                      ),
                      _c(
                        "a",
                        { attrs: { href: "/store/bookshelf/magazine" } },
                        [_vm._v("Bookshelf")]
                      ),
                      _vm._v(".\n          ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isSingle
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "modal-read-button",
                          on: {
                            click: function($event) {
                              return _vm.$emit("read")
                            }
                          }
                        },
                        [_vm._v("Read")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "modal-close-button",
                          on: {
                            click: function($event) {
                              return _vm.$emit("close")
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa-solid fa-xmark",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" Close")
                        ]
                      )
                    ])
                  : _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "modal-read-button",
                          on: {
                            click: function($event) {
                              return _vm.$emit("read")
                            }
                          }
                        },
                        [_vm._v("Bookshelf")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "modal-close-button",
                          on: {
                            click: function($event) {
                              return _vm.$emit("close")
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa-solid fa-xmark",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" Close")
                        ]
                      )
                    ])
              ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }