const $ = window.$;
import List from "../../../common/modules/ownlist";

$(function() {
  // マンガ個別ページであるか判定（見る要素が適切で無いので本来はbodyタグで識別したい）
  if ($(".js-manga-addtolist-block").exists()) {
    // TODO: SNS
    // mangaはFacebookがまだ未対応なので以下を呼ぶ必要はない。
    // いずれ対応するときにコメントアウトすること
    // window.MAL.SNSFunc.initializeFacebook();

    // 右カラムと同期
    const syncRightColumn = function (score, status, chapters, volumes) {
      $(".js-user-status-block .js-form-user-status-btn").remove();
      $(".js-user-status-block .js-form-user-status-select").prop("disabled", false).show();

      $(".js-user-status-block #myinfo_score").val(score);
      $(".js-user-status-block #myinfo_status").val(status);
      $(".js-user-status-block #myinfo_chapters").val(chapters);
      $(".js-user-status-block #myinfo_volumes").val(volumes);
      $(".js-user-status-block").removeClass("on")
    }

    // 左カラムのAdd to My Listボタンの処理
    $("#showAddtolistManga").on("click", function () {
      $("#addtolist").slideToggle();
      $(this).toggleClass("on");
    });

    // 左カラムのAddボタン
    $(".js-manga-addtolist-block .js-manga-add-button").on("click", function () {
      const mangaId  = $(".js-manga-addtolist-block #myinfo_manga_id").val();
      const score    = $(".js-manga-addtolist-block #myinfo_score").val();
      const status   = $(".js-manga-addtolist-block #myinfo_status").val();
      const chapters = $(".js-manga-addtolist-block #myinfo_chapters").val();
      const volumes  = $(".js-manga-addtolist-block #myinfo_volumes").val();

      $("#myinfoDisplay").html("<i class='fa-solid fa-spinner fa-spin'></i>");

      List.addMangaEntry(mangaId, {
        status  : status,
        score   : score,
        chapters: chapters,
        volumes : volumes,
      }, {
        onSuccess() {
          $("#myinfoDisplay").html("");
          $("#addtolist").html("Successfully added.");

          // 右カラムとの同期
          syncRightColumn(score, status, chapters, volumes);
          $('mal-tooltip').remove();
        },
        onFail(error) {
          $("#myinfoDisplay").html("");
          $("#addtolist").text(error);
        },
      });
    });

    // 左カラムのUpdateボタン
    $(".js-manga-addtolist-block .js-manga-update-button").on("click", function () {
      const mangaId  = $(".js-manga-addtolist-block #myinfo_manga_id").val();
      const score    = $(".js-manga-addtolist-block #myinfo_score").val();
      const status   = $(".js-manga-addtolist-block #myinfo_status").val();
      const chapters = $(".js-manga-addtolist-block #myinfo_chapters").val();
      const volumes  = $(".js-manga-addtolist-block #myinfo_volumes").val();

      $("#myinfoDisplay").html("<i class='fa-solid fa-spinner fa-spin'></i>");

      // 右カラム更新不可
      $(".js-user-status-block").addClass("on")

      List.updateMangaEntry(mangaId, {
        status  : status,
        score   : score,
        chapters: chapters,
        volumes : volumes,
      }, {
        onSuccess() {
          $("#myinfoDisplay").html("Successfully Updated");

          // 右カラムと同期
          syncRightColumn(score, status, chapters, volumes)
        },
        onFail() {
          $("#myinfoDisplay").html("Failed to Update");
        },
      });
    });

    // 左カラムのステータスドロップダウン
    $(".js-manga-addtolist-block .js-manga-status-dropdown").on("change", function () {
      if ($(".js-manga-addtolist-block #myinfo_status").val() === "2") {
        $(".js-manga-addtolist-block #myinfo_chapters").val($("#totalChaps").text());
        $(".js-manga-addtolist-block #myinfo_volumes").val($("#totalVols").html());
      }
    });

    // Similar Recommendationのトグルボタン
    $(".js-similar-recommendations-button").on("click", function () {
      $(`#simaid${$(this).data("id")}`).toggle();
    });

    // 右上のEdit Manga Informationボタン
    $(".js-manga-edit-info-button").on("click", function () {
      $("#editdiv").toggleClass("on");
      $("#editdiv").on("click", function(e) {
        e.stopPropagation();
      });
      setTimeout(function () {
        $(document).on("click", function () {
          $("#editdiv").removeClass("on");
          $(document).off("click");
          return false;
        });
      }, 0);
    });

    $(".js-manga-toggle-alternative-title-button").on("click", function () {
      $(".js-alternative-titles").slideToggle("fast");
      if ($(this).html().indexOf("More") > -1) {
        $(this).html("<i class=\"fa-solid fa-chevron-up mr4\"></i>Less titles");
      } else {
        $(this).html("<i class=\"fa-solid fa-chevron-down mr4\"></i>More titles");
      }
    });
  }
});
