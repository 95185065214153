import elVis from "../../../common/element-visibility"

const $ = window.$
const getUA = require("../../modules/utils/getua")

$(() => {

  if ($("#maSearchText")[0]) {
    //focus
    const $maSearchText = $("#maSearchText");

    //placeholder for legacy IE
    const ua = getUA();
    let ieBrowser = false;
    let ieVersion = 0;
    if (ua.browser.indexOf("ie") == 0) {
      ieBrowser = true;
      ieVersion = parseInt(ua.browser.split("ie")[1]);
    }

    if(ieBrowser && ieVersion <= 9) {
      const searchText = $maSearchText.attr("placeholder");
      $maSearchText.val(searchText);
      $maSearchText.css("color", "#999");
      $maSearchText.focus(function() {
        if($(this).val() == searchText) {
          $(this).val("");
          $(this).css("color", "#000");
        }
      }).blur(function() {
        if($(this).val() == "") {
          $(this).val(searchText);
          $maSearchText.css("color", "#999");
        }
      });
    } else {
      // IE9未満はfocusするとplaceholderが消えるのfocusさせない
      // それ以外はさせる
      $maSearchText.focus();
    }
  }


  // auto recommendations for quick add
  if ($(".js-quick-add-recs")[0]) {

    // filter
    sVue.filter ("delimiter", function(value) {
      return value.toLocaleString()
    })
    sVue.filter ("member_unit", function(value) {
      return (value > 1) ? "members" : "member"
    })

    $(".js-quick-add-recs").each(function() {

      const id = this.id
      const placement = this.getAttribute("data-placement")
      const initialData = this.getAttribute("data-initial-data")

      var timeoutId = null

      const autoRecs = new sVue({
        el: `#${id}`,
        data: {
          isLoading: true,
          recs: []
        },
        mounted: function () {
          let data = JSON.parse(initialData)
          if (data === null) {
            $.ajax({
              url: "auto_recommendation/personalized_suggestions.json",
              data: {
                placement: placement
              },
              success: response => {
                this.recs = response
                this.isLoading = false
              }
            })
          } else {
            this.recs = data
            this.isLoading = false
          }

        },
        methods: {
          onClick: function (index) {
            this.logClick(index)
          },
          onContextMenu: function (index) {
            this.logClick(index)
          },
          onAddClick: function (index) {
            this.logClick(index)
          },
          logClick: function(index) {
          },
          scroll: function (direction) {
          }
        }
      })
    })

  }


})
