var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "viewer-root" },
    [
      _c("main-canvas", {
        attrs: {
          "current-page": _vm.currentPage,
          "num-total-pages": _vm.numTotalPages,
          "newest-loaded-image-page": _vm.newestLoadedImagePage,
          "is-facing-page": _vm.isFacingPage,
          manuscript: _vm.manuscript,
          images: _vm.images,
          "zoom-scale": _vm.zoomScale,
          "page-direction": _vm.pageDirection
        }
      }),
      _vm._v(" "),
      _c("marginals", {
        attrs: {
          show: _vm.isMenuVisible,
          "is-facing-page": _vm.isFacingPage,
          "is-preview": _vm.isPreview,
          "is-free": _vm.isFree,
          "is-ad-shown": _vm.isAdShown,
          "current-timestamp": _vm.currentTimestamp,
          "custom-button": _vm.customButton,
          "current-page": _vm.currentPage,
          "num-total-pages": _vm.numTotalPages,
          "zoom-scale": _vm.zoomScale,
          book: _vm.book,
          manuscript: _vm.manuscript,
          "page-direction": _vm.pageDirection
        },
        on: { purchase: _vm.purchase }
      }),
      _vm._v(" "),
      _c("story-modal", {
        attrs: {
          show: _vm.modals.showStoryModal,
          book: _vm.book,
          authors: _vm.authors,
          "num-total-pages": _vm.numTotalPages
        }
      }),
      _vm._v(" "),
      _c("tutorial-modal", { attrs: { show: _vm.modals.showTutorialModal } }),
      _vm._v(" "),
      _c("nopage-modal", { attrs: { show: _vm.modals.showNopageModal } }),
      _vm._v(" "),
      _c("lefttoright-modal", {
        attrs: { show: _vm.modals.showLefttorightModal }
      }),
      _vm._v(" "),
      _c("finish-modal", {
        attrs: {
          show: _vm.modals.showFinishModal,
          book: _vm.book,
          "next-book": _vm.nextBook,
          currency: _vm.currency,
          "is-preview": _vm.isPreview,
          "is-free": _vm.isFree,
          "is-ad-shown": _vm.isAdShown,
          "current-timestamp": _vm.currentTimestamp,
          "custom-finish": _vm.customFinish,
          banned: _vm.banned
        },
        on: {
          purchase: _vm.purchase,
          read: _vm.read,
          preview: _vm.preview,
          backstore: _vm.backstore,
          backmanga: _vm.backmanga
        }
      }),
      _vm._v(" "),
      _vm.nextBook
        ? [
            _c("tax-calculation-modal"),
            _vm._v(" "),
            _c("payment-modal", {
              attrs: {
                show: _vm.showPaymentModal,
                payment: _vm.payment,
                "terms-html": _vm.termsHtml,
                "support-url": _vm.supportUrl
              },
              on: {
                registerdPaymentMethod: _vm.registerdPaymentMethod,
                hidePaymentModal: _vm.hidePaymentModal
              }
            }),
            _vm._v(" "),
            _c("purchase-single-modal", {
              attrs: {
                "is-shown": _vm.showConfirmationModal,
                "is-requesting": _vm.isRequesting,
                error: _vm.error,
                item: _vm.nextBook,
                currency: _vm.currency,
                "is-delegated": _vm.isDelegated
              },
              on: {
                confirm: _vm.confirm,
                cancel: function($event) {
                  _vm.showConfirmationModal = false
                }
              }
            }),
            _vm._v(" "),
            _c("purchase-finish-modal", {
              attrs: {
                "is-shown": _vm.showPurchaseFinishModal,
                "is-single": true,
                title: _vm.nextBook.title,
                numberingText: _vm.nextBook.numberingText,
                onSaleAt: _vm.nextBook.onSaleAt,
                closeSaleAt: _vm.nextBook.closeSaleAt,
                currentTimestamp: _vm.currentTimestamp
              },
              on: {
                read: _vm.read,
                close: function($event) {
                  _vm.showPurchaseFinishModal = false
                }
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }