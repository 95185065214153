const _ = window._

import * as book from "../../../common/modules/book"

export default {
  generatePath: state => {
    let base = "/store/search"

    const params = []
    if (state.keyword !== "") {
      params.push(`keyword=${encodeURIComponent(state.keyword)}`)
    }
    if (state.selectedGenreId > 0) {
      params.push(`genre=${state.selectedGenreId}`)
    }

    if (params.length > 0) {
      base += "?"
      base += params.join("&")
    }

    return base
  },

  numPages: state => {
    return Math.ceil(state.numTotalItems / state.numItemsPerPage)
  },

  isTypeComic: state => {
    return (book.ITEM_TYPE_STRING[state.itemType] === book.TYPE_COMIC)
  },
  isTypeVolume: state => {
    return (book.ITEM_TYPE_STRING[state.itemType] === book.TYPE_COMIC_VOLUME)
  },
  isTypeChapter: state => {
    return (book.ITEM_TYPE_STRING[state.itemType] === book.TYPE_COMIC_CHAPTER)
  },

  /**
   * current page の items を取得する
   * @param state
   */
  currentItems: (state) => {
    return state.pageToItems[state.currentPage]
  },

  /**
   * 全てのアイテムを取得する
   *
   * 順番はページ順
   *
   * @param state
   */
  items: (state) => {
    const pages = Object.keys(state.pageToItems).map(s => parseInt(s)).sort()
    return _.flatten(pages.map(page => state.pageToItems[page]))
  },

  hasPage: (state) => (page) => {
    return state.pageToItems.hasOwnProperty(page)
  },

  hasMorePage: (state, getters) => {
    return Object.keys(state.pageToItems).length < getters.numPages
  },
}
