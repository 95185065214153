const $ = window.$
const Cookies = require("js-cookie")

$(() => {
  const $switchViewButton = $(".js-btn-view-style")
  if ($switchViewButton.exists()) {
    $switchViewButton.on("click", function () {
      const id = $(this).attr("id")
      Cookies.set("search_view", id)
      location.reload()
    })
  }
  const $switchViewButton2 = $(".js-btn-view-style2")
  if ($switchViewButton2.exists()) {
    $switchViewButton2.on("click", function () {
      const id = $(this).attr("id")
      Cookies.set("search_view2", id)
      location.reload()
    })
  }
})
