var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show"
              }
            ],
            staticClass: "modal-content"
          },
          [
            _c("div", { staticClass: "modal-mask", on: { click: _vm.hide } }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-wrapper" }, [
              _c("div", { staticClass: "modal-container" }, [
                _c("div", { staticClass: "modal-inner" }, [
                  _c("div", [
                    _c("img", {
                      attrs: { src: _vm.coverImageUrl, width: "224" }
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.book.title))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "modal-volume" }, [
                      _vm._v(_vm._s(_vm.book.numberingText))
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      _vm._l(_vm.authors, function(names, role) {
                        return _c(
                          "span",
                          [
                            _vm._v("\n                  " + _vm._s(role)),
                            _c("br"),
                            _vm._v(" "),
                            _vm._l(names, function(name) {
                              return _c(
                                "span",
                                { staticClass: "modal-author" },
                                [_vm._v(_vm._s(name)), _c("br")]
                              )
                            }),
                            _c("br")
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "modal-header" }, [
                      _vm._v("Synopsis")
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "modal-discription",
                        style: { height: _vm.modalDiscriptionHeight + "px" }
                      },
                      [_c("span", {}, [_vm._v(_vm._s(_vm.book.synopsis))])]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "modal-close", on: { click: _vm.hide } },
                  [_vm._v("Close")]
                )
              ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }