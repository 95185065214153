const $ = window.$;

const getUA = require("../../modules/utils/getua");

//汎用
const truncate = function(obj){
  const $inner = obj.find(".js-truncate-inner");
  const outerH = obj.height();
  const innerH = $inner.height();
  if (outerH < innerH ) {
    if (obj.find(".js-btn-truncate").length === 0) {
      const btnHTML = "<div class=\"btn-truncate js-btn-truncate\"></div>";
      obj.append(btnHTML);
      obj.find(".js-btn-truncate").fadeIn();
      //button
      obj.find(".js-btn-truncate").on("click", function() {
        const trunkH = $.parseJSON($(this).attr("data-height"));
        const btnH = $(this).height() * 2;
        const $outer = $(this).parent(".js-truncate-outer");
        if($(this).hasClass("open")) {
          $outer.css({"max-height": trunkH.outer,"padding-bottom": 0});
        } else {
          const newInnerH = $outer.find(".js-truncate-inner").height();
          $(this).parent(".js-truncate-outer").css({"max-height": trunkH.inner,"padding-bottom": btnH});
          $outer.on("oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend",function(){
            $(this).parent(".js-truncate-outer").css({"max-height": "inherit","padding-bottom": btnH});
          });
        }
        $(this).toggleClass("open");
      });
    } else {
      if (obj.find(".js-btn-truncate").hasClass("open")) {
        obj.parent(".js-truncate-outer").css({"max-height": innerH});
      }
    }
  }
  obj.find(".js-btn-truncate").attr("data-height",`{\"outer\":${outerH},\"inner\":${innerH}}`);
}

// on image loaded
const truncateImageLoaded = function(obj) {
  if (obj.find(".profile-about-user.js-truncate-inner img")[0]) {
    //only for profile about
    const $profileAboutImg = obj.find(".profile-about-user.js-truncate-inner img");
    const profileAboutImgCount = $profileAboutImg.length;
    for (let i = 0; i < profileAboutImgCount; i++) {
      if (i === 0) {
        truncate(obj);
      }
      if ($profileAboutImg.eq(i).data("src") || $profileAboutImg.eq(i).attr("src")) {
        $profileAboutImg.eq(i).on("load", function(){
          const $outer = $(this).parents(".js-truncate-outer");
          if (i > 0) {
            const $trunkOuter = $outer;
            const $trunkInner = $trunkOuter.find(".js-truncate-inner");
            const trunkInnerH = $trunkInner.height();
            $trunkOuter.find(".js-btn-truncate").attr("data-height",`{\"outer\":1000,\"inner\":${trunkInnerH}}`);
            if ($trunkOuter.find(".js-btn-truncate").hasClass("open")) {
              $trunkOuter.css({"max-height": trunkInnerH});
            }
          } else {
            truncate($outer);
          }
        });
      }
    }
  } else {
    truncate(obj);
  }
}

$(function () {
  // mods title
  if ( $(".js-profile-team-title").length > 0 ) {
    const teamTitleW = $(".js-profile-team-title").eq(0).width();
    $(".js-profile-team-title").eq(0).css({right:(-1 * (teamTitleW + 12))});
    if( $(".js-profile-team-title")[1] ){
      const teamTitleW2 = $(".js-profile-team-title").eq(1).width();
      $(".js-profile-team-title").eq(1).css({right:(-1 * (teamTitleW + teamTitleW2 + 24))});   
    }
  }

  if (!$("body.profile").length) {
    return;
  }

  // ===========================
  // re-calc height of outer Div
  // ===========================
  if ( $(".profile .js-truncate-outer")[0] ) {
    $(".js-truncate-outer").each(function() {
      const $obj = $(this);
      truncateImageLoaded($obj);
    });
  }

  // on click event for profile-about
  if ( $(".user-profile-about.js-truncate-outer .spoiler input.button")[0] ) {
    //youtube
    if ( $(".user-profile-about.js-truncate-outer .movie.youtube")[0] ) {
      $(".user-profile-about.js-truncate-outer .movie.youtube").each(function() {
        const addText = "&wmode=opaque";
        const src = $(this).attr("src");
        $(this).attr("src", src + addText);
      });
    }

    //button (Spoiler)
    $(".js-truncate-inner .spoiler input.button").on("click", function() {
      const $outer = $(".user-profile-about.js-truncate-outer");
      const $button = $outer.find(".js-btn-truncate");
      if ( $(this).hasClass("show_button") || $(this).val() === "Show spoiler" ) {
        const $spoiler = $(this).next(".spoiler_content");
        var spoilerH = $spoiler.height();
        var innerH = $outer.find(".js-truncate-inner").height() + spoilerH;
        if(innerH > 1000 ){
          $spoiler.attr("data-spoiler",spoilerH);
          $outer.css({"max-height": innerH});
          $outer.find(".js-btn-truncate").attr("data-height",`{\"outer\":1000,\"inner\":${innerH}}`);
        }
      } else {
        var spoilerH = $(this).parent(".spoiler_content").attr("data-spoiler") || 0;
        var innerH = $outer.css("max-height") || 0;
        if (innerH > 1000 && spoilerH > 0) {
          $outer.css({"max-height": innerH - spoilerH});
        }
      }
    });

  }

  //button (Expand Quote)
  if ( $(".expand_quote")[0] ) {
    $(".expand_quote").on("click", function() {
      const $outer = $(".user-profile-about.js-truncate-outer");
      const $button = $outer.find(".js-btn-truncate");
      const $spoiler = $(this).next(".spoiler_content");
      const spoilerH = $spoiler.height();
      const innerH = $outer.find(".js-truncate-inner").height() + spoilerH;
      if(innerH > 1000 ){
        $spoiler.attr("data-spoiler",spoilerH);
        $outer.css({"max-height": innerH});
        $outer.find(".js-btn-truncate").attr("data-height",`{\"outer\":1000,\"inner\":${innerH}}`);
      }
    });
  }

});

$(window).on("load", function() {
  if ( $("body.profile")[0]) {
  	const ua = getUA();
  	if (ua.browser === "firefox") {
      $(".profile .statistics-updates .image img").css({"image-rendering":"inherit"});
      $(".profile .user-favorites .favorites-list .list .image").css({"image-rendering":"inherit"});
  	}
    if ( !$(".user-profile-about.js-truncate-outer .js-btn-truncate")[0] ) {
      const $obj = $(".user-profile-about.js-truncate-outer");
      truncate($obj);
    }
    // Modern About Me Expand
    const content = $('#modern-about-me');
    if (content[0]) {
      const foldHeight = 1000;
      const originHeight = $('#modern-about-me-inner').height();
      if (foldHeight < originHeight) {
        const button = $('#modern-about-me-expand-button');
        content.data('origin-size', `${originHeight}px`);
        content.data('accordion', 'closed');
        content.css('max-height', `${foldHeight}px`);

        button.css('display', '');
        button.on('click', function () {
          if (content.data('accordion') === 'closed') {
            content.data('accordion', 'open');
            button.addClass('is-open');
            content.css('max-height', content.data('origin-size'));
          } else {
            content.data('accordion', 'closed');
            button.removeClass('is-open');
            content.css('max-height', content.data('fold-height'));
          }
        });
      }
    }
  }
});

$(function() {
  // favorites slider
  const hasTouchEvent = ("ontouchstart" in window);
  const favSlider = function(selectorName) {
    const $favSlideBlock = $(`#${selectorName}.fav-slide-block`);
    if (!$favSlideBlock.length) return;

    let nowCount = 0;
    const sliderWidth = 800;
    const btnWidth = 40;
    const moveSlideNum = $favSlideBlock.find(".fav-slide").data("slide") || 4;
    const favW = $favSlideBlock.find(".fav-slide li.btn-fav").eq(0).outerWidth();
    const favCount = $favSlideBlock.find(".fav-slide li.btn-fav").length;
    const favWidth = favW + 8;
    const setSlideWidth = favWidth * (favCount + moveSlideNum);
    $favSlideBlock.find(".fav-slide").width(setSlideWidth);

    //button
    const $sliderBtnLeft  = $favSlideBlock.find(".btn-fav-slide-side.left");
    const $sliderBtnRight = $favSlideBlock.find(".btn-fav-slide-side.right");

    // hide button
    if (favCount < moveSlideNum + 1) {
      $sliderBtnLeft.hide();
      $sliderBtnRight.hide();
    } else if (!hasTouchEvent) {
      const hideBtnTimer = setInterval(function () {
        $sliderBtnLeft.css({left: -1 * btnWidth, opacity: 0});
        $sliderBtnRight.css({right: -1 * btnWidth, opacity: 0});
        clearInterval(hideBtnTimer);
      }, 1500);
    }
    $sliderBtnLeft.hide();

    //click
    $sliderBtnLeft.find(".btn-inner").on("click", function (e) {
      const btn = {
        direction: "left",
        button   : $(this)
      };
      moveSlideFav(btn);
    });
    $sliderBtnRight.find(".btn-inner").on("click", function (e) {
      const btn = {
        direction: "right",
        button   : $(this)
      };
      moveSlideFav(btn);
    })

    // hover
    if (!hasTouchEvent) {
      $favSlideBlock.find(".fav-slide-outer").on("mouseover", function () {
        $sliderBtnLeft.css({left: 0, opacity: 1});
        $sliderBtnRight.css({right: 0, opacity: 1});
      }).on("mouseout", function () {
        $sliderBtnLeft.css({left: -1 * btnWidth, opacity: 0});
        $sliderBtnRight.css({right: -1 * btnWidth, opacity: 0});
      });

      $sliderBtnLeft.on("mouseover", function () {
        $sliderBtnLeft.css({left: 0, opacity: 1});
        $sliderBtnRight.css({right: -1 * btnWidth, opacity: 0});
      }).on("mouseout", function () {
        $sliderBtnRight.css({right: -1 * btnWidth, opacity: 0});
        $sliderBtnLeft.css({left: -1 * btnWidth, opacity: 0});
      });

      $sliderBtnRight.on("mouseover", function () {
        $sliderBtnRight.css({right: 0, opacity: 1});
        $sliderBtnLeft.css({left: -1 * btnWidth, opacity: 0});
      }).on("mouseout", function () {
        $sliderBtnRight.css({right: -1 * btnWidth, opacity: 0});
        $sliderBtnLeft.css({left: -1 * btnWidth, opacity: 0});
      });
    }

    // slide function
    const moveSlideFav = function (obj) {
      const direction = obj.direction;
      const $btnInner = obj.button;
      $btnInner.hide();

      if (direction == "right") {
        // slide limit
        const maxCount = favCount - parseInt(sliderWidth / favWidth);
        if (nowCount + moveSlideNum >= maxCount) {
          nowCount = maxCount;
          $sliderBtnRight.hide();
        } else {
          nowCount += moveSlideNum;
        }

        $favSlideBlock.find(".fav-slide").animate({
          marginLeft: `${-1 * favWidth * nowCount}px`
        }, {
          duration: 500,
          easing  : "swing",
          complete: function () {
            $btnInner.show();
            $sliderBtnLeft.show();
            if (nowCount != maxCount) {
              $sliderBtnRight.show();
            }
          }
        });
      } else {
        // slide limit
        if (nowCount - moveSlideNum <= 0) {
          nowCount = 0;
          $sliderBtnLeft.hide();
        } else {
          nowCount -= moveSlideNum;
        }

        $favSlideBlock.find(".fav-slide").animate({
          marginLeft: `${-1 * favWidth * nowCount}px`
        }, {
          duration: 500,
          easing  : "swing",
          complete: function () {
            $btnInner.show();
            $sliderBtnRight.show();
            if (nowCount != 0) {
              $sliderBtnLeft.show();
            }
          }
        });
      }
    };
  }

  // set favorites slider
  if ($(".fav-slide-block")[0]) {
    const slideCount = $(".fav-slide-block").length;
    for (let i = 0; i < slideCount; i++) {
      const id = $(".fav-slide-block").eq(i).attr("id");
      favSlider(id);
    }
  }

  // read more favorites
  let moving_favmore = false;
  $(".btn-favmore").on("click", function(){
    if (!moving_favmore) {
      moving_favmore = true;
      if ($(this).hasClass("open")) {
        $(this).removeClass("open");
        $(this).prev(".favmore").slideUp({
          duration: 500,
          easing  : "swing",
          complete: function(){
            moving_favmore = false;
          }
        });
      } else {
        $(this).addClass("open");
        $(this).prev(".favmore").slideDown({
          duration: 500,
          easing  : "swing",
          complete: function(){
            moving_favmore = false;
          }
        });
      }
    }
  });

  // Interest Stacks
  if ($(".js-stacks-navi")[0]) {

    // tab button
    $(".js-btn-tab").on("click", function() {
      $(".js-btn-tab").removeClass("on");
      $(this).addClass("on");
      const url = location.href.substring(0, location.href.indexOf('?'));
      window.location.href = url + '?tab=' + $(this).data("key");
    });

    // show dialog buttons
    $(".js-btn-show-sort").on("click", function() {
      $(document).off("click");
      const id = $(this).data("id");
      const $targetBlock = $(`.js-stacks-navi .js-${id}`);
      if ( $targetBlock.css("display") === "block" ) {
        $(`.js-sort-order-block.js-${id}`).hide();
        $(".js-btn-show-sort").removeClass("on");
      } else {
        $(".js-sort-order-block").hide();
        $(".js-btn-show-sort").removeClass("on");
        $(`.js-sort-order-block.js-${id}`).show();
        $(this).addClass("on");

        const showDialogInterval = setInterval(function() {
            closeFilterDialog();
            clearInterval(showDialogInterval);
          }, 500);
      }
    });

    // click outside the area to close
    const closeFilterDialog = function(id) {
      $(".js-stacks-navi .js-sort-order-block").on("click", function(e) {
        e.stopPropagation();
      });
      $(document).on("click", function() {
        $(".js-stacks-navi .js-sort-order-block").hide();
        $(".js-stacks-navi .js-btn-show-sort").removeClass("on");
        $(document).off("click");
        return false;
      });
    }

    // sort button
    $(".js-sort-order-block.js-sort .js-btn-sort-order").on("click", function() {
      $(".js-sort-order-block.js-sort .js-ajax-loader").show();
      $(".js-sort-order-block.js-sort .js-btn-sort-order").removeClass("selected");
      $(this).addClass("selected");
      const url = location.href.substring(0, location.href.indexOf('?'));
      window.location.href = url + '?sort=' + $(this).attr("id");
    });

    // close button
    $(".js-stacks-navi .js-btn-close").on("click", function() {
      $(this).parents(".js-sort-order-block").hide();
      $(".js-stacks-navi .js-btn-show-sort").removeClass("on");
      $(document).off("click");
    });
  }

  // scroll top
  if ($(".js-btn-top")[0]) {
    $(".js-btn-top").on("click", function(e){
      e.preventDefault();
      $('html, body').animate({scrollTop:0});
    });
  }
});
