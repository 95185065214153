var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slider-wrap" },
    [
      _c(
        "div",
        {
          staticClass: "slider-container",
          style: { width: _vm.sliderContainerWidth + "px" }
        },
        [
          _c(
            "div",
            {
              staticClass: "slider",
              class: {
                "is-move-left": _vm.isMoveLeft,
                "is-move-right": !_vm.isMoveLeft
              }
            },
            [
              _c(
                "transition-group",
                { attrs: { name: "slide", tag: "div" } },
                [
                  _vm._l(_vm.currentItems, function(item) {
                    return !_vm.isTypeComic
                      ? _c("book", {
                          key: item.id,
                          attrs: {
                            item: item,
                            currency: _vm.currency,
                            width: _vm.sliderWidth,
                            location: _vm.options.location,
                            isRecommendation: _vm.isRecommendation
                          },
                          on: {
                            accessLimit: function($event) {
                              return _vm.accessLimit(item)
                            }
                          }
                        })
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.currentItems, function(item) {
                    return _vm.isTypeComic
                      ? _c("comic", {
                          key: item.id,
                          attrs: {
                            item: item,
                            width: _vm.sliderWidth,
                            location: _vm.options.location,
                            isRecommendation: _vm.isRecommendation
                          }
                        })
                      : _vm._e()
                  })
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "button-left",
          class: { "is-disable": !_vm.hasPrevPage },
          on: {
            click: function($event) {
              return _vm.move(-1)
            }
          }
        },
        [_vm._v("left")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "button-right",
          class: { "is-disable": !_vm.hasNextPage },
          on: {
            click: function($event) {
              return _vm.move(1)
            }
          }
        },
        [_vm._v("right")]
      ),
      _vm._v(" "),
      _c("access-limit-modal", {
        attrs: {
          "is-shown": _vm.showAccessLimitModal,
          item: _vm.saleItem,
          "show-loading-modal": _vm.showLoadingModal
        },
        on: { purchase: _vm.purchase, cancel: _vm.cancel }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }