var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-preview-container" },
    [
      _c(
        "a",
        {
          staticClass: "detail-img",
          attrs: { target: "_blank", href: _vm.firstItem.previewUrl },
          on: { mouseover: _vm.mouseover, mouseleave: _vm.mouseleave }
        },
        [
          _c("img", {
            style: _vm.imageHoverStyle,
            attrs: { itemprop: "image", src: _vm.coverImage }
          })
        ]
      ),
      _vm._v(" "),
      _c("preview-button", {
        staticClass: "preview-button",
        staticStyle: { margin: "8px auto 0" },
        style: _vm.buttonHoverStyle,
        attrs: {
          "preview-url": _vm.firstItem.previewUrl,
          text: _vm.firstItem.numberingText
        },
        on: { mouseover: _vm.mouseover, mouseleave: _vm.mouseleave }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }