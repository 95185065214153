
const axios = window.axios

export async function tax({itemType, itemIds, itemTotalAmount, selectedCountry}) {
  try {
    return await axios.post(
      "/tax/calculate",
      {
        item_type: itemType,
        item_ids: itemIds,
        item_total_amount: itemTotalAmount,
        selected_country: selectedCountry
      }
    )
  } catch (e) {
    if (e.response && e.response.status === 401) {
      if (e.response.data.message === "Login Required") {
        window.location.href = e.response.data.redirect
        e.name = "LoginRequiredError"
      } else {
        e.name = "NoPaymentSettingError"
      }
      throw e
    } else if (e.response && e.response.status === 400) {
      e.name = "BillingCountryError"
      if (e.response.data.errors[0] && e.response.data.errors[0].message) {
        e.message = e.response.data.errors[0].message
      }
      if (e.message === "PRICE_MISMATCH_ERROR") {
        e.name = "PRICE_MISMATCH_ERROR"
        window.location.reload()
      }
      throw e
    } else if (e.response && e.response.status === 412) {
      e.name = "UnmatchedCountryError"
      e.currentName = e.response.data.current_name
      e.current = e.response.data.current
      e.registeredName = e.response.data.registered_name
      e.registered = e.response.data.registered
      throw e
    } else {
      throw new Error(e.response.data.errors[0].message)
    }
  }
}

export function appendTax(item, taxJson) {
  if (taxJson.hasOwnProperty(item.id)) {
    item.tax = taxJson[item.id].tax
    item.totalAmount = taxJson[item.id].total_amount
  }
  return item
}
