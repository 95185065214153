const $ = window.$;

$(() => {
  if ($("#users-advancedsearch").exists()) {
    let
      $btn = $(".js-submit")
    , $location = $("input[name='loc']")
    , $keyword  = $("input[name='q']")
    , $age = {low: $("input[name='agelow']"), high: $("input[name='agehigh']")}
    , $gender = $("select[name='g']")

    $("#users-advancedsearch").on("change", "input[type!=text], select", (event) => {
      switchButtonState()
    })
    $("#users-advancedsearch").on("keyup", "input[type=text]", (event) => {
      switchButtonState()
    })
    $("#users-advancedsearch").on("submit", (event) => {
      if (!canSearch()) {
        event.preventDefault()
      }
    })

    function canSearch() {
      // ref. https://www.softel.co.jp/blogs/tech/archives/3318
      const keywordLength = encodeURI($keyword.val()).replace(/%[0-9A-F]{2}/g, "*").length
      if (keywordLength > 2) {
        return true
      } else if (0 < keywordLength) {
        return false
      }

      // see also isValidParams at legacy/users.php
      if ($location.val().length>1 || ($age.low.val()>0 && $age.high.val()>0) || $gender.val()) {
        return true
      } else {
        return false
      }
    }
    function switchButtonState() {
      if (canSearch()) {
        $btn.removeClass("notActive")
      } else {
        $btn.addClass("notActive")
      }
    }

    switchButtonState()
  }
})
