<template>
  <div class="detail-preview-container">
    <a class="detail-img" target="_blank"
      :href="firstItem.previewUrl"
      @mouseover="mouseover"
      @mouseleave="mouseleave"
    ><img itemprop="image" :src="coverImage" :style="imageHoverStyle"></a>
    <preview-button class="preview-button"
      :preview-url="firstItem.previewUrl"
      @mouseover="mouseover"
      @mouseleave="mouseleave"
      :text="firstItem.numberingText"
      style="margin:8px auto 0;"
      :style="buttonHoverStyle"
    ></preview-button>
  </div>
</template>

<style lang="scss" scoped>
  @import "../../../../../../css/variables";

  .detail-preview-container {
    position: relative;
    display: block;
    margin-bottom: 30px;
    text-align: center;
    text-decoration: none;
    &:after {
      position: absolute;
      left: 0;
      bottom: -20px;
      display:block;
      width: 100%;
      height: 1px;
      border-bottom: 1px solid $color-pc-border-ebebeb;
      clear:both;
      content:"";
    }
  }
  a.detail-img {
    display:block;
    width: 100%;
    border: 1px solid $color-pc-border-ebebeb;
    &:after {
      content:" ";
      display:block;
      clear:both;
    }
    &:hover {
      .detail-img {
        opacity: 0.8;
      }
      .preview-button {
        background-color: $color-pc-bg-e1e7f5;
      }
    }
  }

  .dark-mode {
    @import "../../../../../../css/colors_dark";

    .detail-preview-container {
      &:after {
        border-bottom: 1px solid $color-pc-border-ebebeb;
      }
      a.detail-img {
        border: 1px solid $color-pc-border-ebebeb;
      }
      &:hover {
        a.detail-img {
          opacity: 0.8;
        }
        .preview-button {
          background-color: $color-pc-bg-e1e7f5;
        }
      }
    }
  }
</style>

<script type="text/babel">
  import * as book from "../../../common/modules/book"
  import * as types from "./store/mutation-types"
  import {mapState, mapMutations} from "vuex"
  import PreviewButton from "../../atoms/PreviewThinButton.vue"

  export default {
    components: {
      PreviewButton,
    },
    data() {
      return {
        buttonHoverStyle: null,
        imageHoverStyle: null,
      }
    },
    computed: {
      ...mapState([
        "firstItem",
      ]),
      coverImage() {
        console.log("firstItem.previewUrl", this.firstItem.previewUrl)
        const imageSize = (window.devicePixelRatio > 1) ? book.COVER_IMAGE_SIZE_L_2X : book.COVER_IMAGE_SIZE_L
        return book.generateCoverImageUrl(this.firstItem.coverImageBaseUrl, imageSize)
      },
    },
    methods: {
      hidePreview() {
        this.isShowPreview = false
      }
    },
  }
</script>
