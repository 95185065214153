var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "purchase-button-container" },
    [
      (_vm.showBulkPurchaseButton = _vm.purchasableItems.length > 0)
        ? _c("purchase-button", {
            attrs: { text: "Checkout - All Volumes" },
            on: { purchase: _vm.purchase }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasDefaultPatmentMethod
        ? _c("icon-payment-method", {
            staticStyle: {
              position: "absolute",
              bottom: "0",
              right: "-100px",
              "margin-left": "8px"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.firstItem && (_vm.firstItem.isPossessed || _vm.firstItem.isFree)
        ? _c("read-button", {
            staticStyle: { "margin-left": "16px" },
            on: { read: _vm.readOrLimit }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("tax-calculation-modal"),
      _vm._v(" "),
      _c("payment-modal", {
        attrs: {
          show: _vm.showPaymentModal,
          payment: _vm.payment,
          "terms-html": _vm.termsHtml,
          "support-url": _vm.supportUrl
        },
        on: {
          registerdPaymentMethod: _vm.registerdPaymentMethod,
          hidePaymentModal: _vm.hidePaymentModal
        }
      }),
      _vm._v(" "),
      _c("purchase-bulk-selection-modal", {
        attrs: {
          "is-shown": _vm.showSelectionModal,
          items: _vm.items,
          currency: _vm.currency,
          title: _vm.title,
          "checked-item-ids": _vm.checkedItemIds,
          "is-purchased": _vm.isPurchased,
          "purchasable-items": _vm.purchasableItems,
          "total-price-without-tax": _vm.totalPriceWithoutTax
        },
        on: {
          proceed: _vm.proceed,
          updateCheckedItemIds: _vm.updateCheckedItemIds,
          updateIsPurchased: _vm.updateIsPurchased,
          cancel: _vm.cancel
        }
      }),
      _vm._v(" "),
      _c("purchase-bulk-confirmation-modal", {
        attrs: {
          "is-shown": _vm.showConfirmationModal,
          error: _vm.error,
          "is-requesting": _vm.isRequesting,
          title: _vm.title,
          items: _vm.checkedItems,
          currency: _vm.currency,
          "total-price": _vm.totalPrice,
          "total-price-without-tax": _vm.totalPriceWithoutTax,
          "total-tax": _vm.totalTax,
          "is-delegated": _vm.isDelegated
        },
        on: { confirm: _vm.confirm, cancel: _vm.back }
      }),
      _vm._v(" "),
      _c("purchase-finish-modal", {
        attrs: {
          "is-single": false,
          "is-shown": _vm.showFinishModal,
          title: _vm.title
        },
        on: { read: _vm.read, close: _vm.close }
      }),
      _vm._v(" "),
      _c("access-limit-modal", {
        attrs: {
          "is-shown": _vm.showAccessLimitModal,
          item: _vm.saleItem,
          "show-loading-modal": _vm.showLoadingModal
        },
        on: { purchase: _vm.moveToPurchase, cancel: _vm.cancelAccessLimit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }