import ActionLimitter from "../../../common/modules/ActionLimitter";
import {pascalize} from "../../../common/modules/string";

const $ = window.$;
const _ = window._;

//--------------------------------------------------
// Itemのインターフェース
//--------------------------------------------------

/**
 * 本来ならclassを使いたいが、Vueがprototypeを適切に取り扱えないので、
 * assignベースで継承機構を再現することにした。
 * 参考: http://forum.vuejs.org/topic/134/using-vue-with-class-instead-of-simple-data-object/7
 * この関数はベースクラスなイメージ。
 */
function createCommonNotificationItem(data) {
  return _.assign({}, {
    id: data.id, // bigintサイズなのでStringとして扱う
    typeIdentifier: data.typeIdentifier,
    categoryName: data.categoryName,
    createdAt: data.createdAt,
    createdAtForDisplay: data.createdAtForDisplay,
    isRead: !!data.isRead,
    url: data.url,
    isDeleted: data.isDeleted,
    isNewThan(epoch) {
      return this.createdAt > epoch;
    },
    getHyphenedTypeIdentifier() {
      return this.typeIdentifier.replace(/_/g, "-");
    }
  });
}

/**
 * FriendRequestItem
 */
export function createNotificationItemFriendRequest(data) {
  return _.assign({}, createCommonNotificationItem(data), {
    isApproved: data.isApproved,
    friendName: data.friendName,
    friendProfileUrl: data.friendProfileUrl,
    friendImageUrl: data.friendImageUrl,
    message: data.message,
  });
}

/**
 * FriendRequestAcceptItem
 */
export function createNotificationItemFriendRequestAccept(data) {
  return _.assign({}, createCommonNotificationItem(data), {
    friendName: data.friendName,
    friendProfileUrl: data.friendProfileUrl,
    friendImageUrl: data.friendImageUrl,
  });
}

/**
 * FriendRequestDenyItem
 */
export function createNotificationItemFriendRequestDeny(data) {
  return _.assign({}, createCommonNotificationItem(data), {
    friendName: data.friendName,
    friendProfileUrl: data.friendProfileUrl,
    friendImageUrl: data.friendImageUrl,
  });
}

/**
 * ProfileCommentItem
 */
export function createNotificationItemProfileComment(data) {
  return _.assign({}, createCommonNotificationItem(data), {
    commentUserName: data.commentUserName,
    commentUserProfileUrl: data.commentUserProfileUrl,
    commentUserImageUrl: data.commentUserImageUrl,
    text: data.text
  });
}

/**
 * ForumQuote
 */
export function createNotificationItemForumQuote(data) {
  return _.assign({}, createCommonNotificationItem(data), {
    quoteUserName: data.quoteUserName,
    quoteUserProfileUrl: data.quoteUserProfileUrl,
    topicUrl: data.topicUrl,
    topicTitle: data.topicTitle
  });
}

/**
 * BlogComment
 */
export function createNotificationItemBlogComment(data) {
  return _.assign({}, createCommonNotificationItem(data), {
    commentUserName: data.commentUserName,
    commentUserProfileUrl: data.commentUserProfileUrl,
    commentUserImageUrl: data.commentUserImageUrl,
  });
}

/**
 * WatchedTopicMessage
 */
export function createNotificationItemWatchedTopicMessage(data) {
  return _.assign({}, createCommonNotificationItem(data), {
    postedUserName: data.postedUserName,
    postedUserProfileUrl: data.postedUserProfileUrl,
    topicUrl: data.topicUrl,
    topicTitle: data.topicTitle
  });
}

/**
 * ClubMassMessageInForum
 */
export function createNotificationItemClubMassMessageInForum(data) {
  return _.assign({}, createCommonNotificationItem(data), {
    sharedUserName: data.sharedUserName,
    sharedUserProfileUrl: data.sharedUserProfileUrl,
    topicUrl: data.topicUrl,
    topicTitle: data.topicTitle,
    clubName: data.clubName,
    clubUrl: data.clubUrl,
  });
}

/**
 * Added Related Anime
 */
export function createNotificationItemRelatedAnimeAdd(data) {
  return _.assign({}, createCommonNotificationItem(data), {
    anime: data.anime,
  })
}

/**
 * Payment on Stripe
 */
export function createNotificationItemPaymentStripe(data) {
  return _.assign({}, createCommonNotificationItem(data), {
    planName: data.plan_name,
  })
}


/**
 * UserMention.* の基底
 * @private
 */
function createNotificationItemUserMention(data) {
  return _.assign({}, createCommonNotificationItem(data), {
    senderName: data.senderName,
    senderProfileUrl: data.senderProfileUrl,
    pageUrl: data.pageUrl,
    pageTitle: data.pageTitle
  });
}

export function createNotificationItemUserMentionInForumMessage(data) {
  return _.assign({}, createNotificationItemUserMention(data), {
  });
}
export function createNotificationItemUserMentionInClubComment(data) {
  return _.assign({}, createNotificationItemUserMention(data), {
  });
}

export function createNotificationItemOnAir(data) {
  return _.assign({}, createCommonNotificationItem(data), {
    date: data.date,
    animes: data.animes
  });
}

/**
 * Item生成関数名をtypeIdentifierから解決
 */
function resolveNotificationItemCreator(typeIdentifier) {
  const creatorName = `createNotificationItem${pascalize(typeIdentifier)}`;
  const creator = {
    createNotificationItemFriendRequest,
    createNotificationItemFriendRequestAccept,
    createNotificationItemFriendRequestDeny,
    createNotificationItemProfileComment,
    createNotificationItemForumQuote,
    createNotificationItemBlogComment,
    createNotificationItemWatchedTopicMessage,
    createNotificationItemClubMassMessageInForum,
    createNotificationItemUserMentionInForumMessage,
    createNotificationItemUserMentionInClubComment,
    createNotificationItemOnAir,
    createNotificationItemRelatedAnimeAdd,
    createNotificationItemPaymentStripe,
  }[creatorName];
  if (!creator) {
    console.error(`${creatorName} is not found.`);
    return null;
  }
  return creator;
}

/**
 * NotificationItemオブジェクト生成。
 * @param  {object} data 受理するプロパティは各生成メソッドを参照。
 * @return {object}
 */
export function createNotificationItem(data) {
  const create = resolveNotificationItemCreator(data.typeIdentifier);
  return create ? create(data) : null;
}

//--------------------------------------------------
// Vue Mixins
//--------------------------------------------------
// 超簡易Fluxなイメージ:
// - state: containerのdataが相当
// - action creator: container.methodsが相当
//
// 鉄則:
// - コアの状態はcontainer（ルートcomponent）が持ち、子にはpropsで伝搬（子では書き換えない）。
// - 状態変更はcontainerのみで行う。
//   - containerではデータ操作に集中。
//   - UIのためのアクションは行わない（confirmなど）。
//   - UIのために必要なこともデータに落とし込み、その操作に徹する。
// − 子からの変更の依頼は$emitを利用。
//
// 以下の様な階層構造を想定:
// <NotificationContainer>
//   <NotificationList>
//     <NotificationItem></NotificationItem>
//     <NotificationItem></NotificationItem>
//     ...
//   </NotificationList>
// </NotificationContainer>

// ### Container ###

export const NotificationContainerMixin = {
  data() {
    return {
      items: [],
      historyItems: [],
      itemsCheckedInThisSession: {},
      ajaxLimitter: null
    };
  },
  created() {
    this.ajaxLimitter = new ActionLimitter();
  },
  methods: {
    /**
     * @param  {array} items NotificationItem列
     */
    checkItems(items) {
      items = _.filter(items, (item) => !item.isRead);
      if (items.length === 0) {
        return;
      }
      this.ajaxLimitter.one("check-items", (done) => {
        const ids = _.map(items, ({id}) => id);
        $.ajax({
          url: "/notification/api/check-items-as-read.json",
          data: JSON.stringify({notification_ids: ids}), // eslint-disable-line camelcase
          contentType: "application/json",
          type: "POST"
        })
          .done(() => {
            items.forEach((item) => {
              item.isRead = true;
              this.itemsCheckedInThisSession[item.id] = true;
            });
          })
          .fail((err) => {
            console.error(err); // TODO
          })
          .always(done);
      });
    },
    /**
     * @param  {array} items FriendRequestItem列
     */
    acceptFriendRequests(items) {
      if (items.length === 0) {
        return;
      }
      this.ajaxLimitter.one("friend-requests", (done) => {
        const ids = _.map(items, ({id}) => id);
        $.ajax({
          url: "/notification/api/accept-friend-request.json",
          data: JSON.stringify({notification_ids: ids}), // eslint-disable-line camelcase
          contentType: "application/json",
          type: "POST"
        })
          .done(() => {
            items.forEach((item) => {
              item.isApproved = true;
              item.isRead = true;
              this.itemsCheckedInThisSession[item.id] = true;
            });
          })
          .fail((err) => {
            console.error(err); // TODO
          })
          .always(done);
      });
    },
    /**
     * @param  {array} items FriendRequestItem列
     */
    denyFriendRequests(items) {
      if (items.length === 0) {
        return;
      }
      this.ajaxLimitter.one("friend-requests", (done) => {
        const ids = _.map(items, ({id}) => id);
        if (ids.length === 0) {
          return done();
        }
        $.ajax({
          url: "/notification/api/deny-friend-request.json",
          data: JSON.stringify({notification_ids: ids}), // eslint-disable-line camelcase
          contentType: "application/json",
          type: "POST"
        })
          .done(() => {
            this.items = _.without(this.items, ...items);
            this.historyItems = _.without(this.historyItems, ...items);
          })
          .fail((err) => {
            console.error(err); // TODO
          })
          .always(done);
      });
    }
  }
};

// ### List ###

export const NotificationListMixin = {
  props: {
    items: {type: Array, required: true},
    numMoreUnreadItems: 0,
    itemsCheckedInThisSession: {type: Object, required: true}
  },
  components: {
    // mixin先で notification-item-*: {} の実装が必要
  },
  computed: {
    hasUnreadItems() {
      return _.some(this.items, (item) => !item.isRead);
    }
  },
  methods: {
    resolveComponent(item) {
      return `notification-item-${item.getHyphenedTypeIdentifier()}`;
    },
    checkItems(items) {
      this.$emit("checkItems", items);
    },
    checkAllItems() {
      this.checkItems(this.items);
    },
    checkItem(item) {
      this.checkItems([item]);
    },
    isItemCheckedInThisSession(item) {
      return !!this.itemsCheckedInThisSession[item.id];
    },
    acceptFriendRequest(item) {
      this.$emit("acceptFriendRequests", [item]);
    },
    denyFriendRequest(item) {
      this.$emit("denyFriendRequests", [item]);
    },
  }
};

// ### Item系 ###

const NotificationItemMixin = {
  props: {
    item: {type: Object, required: true}
  },
  methods: {
    checkItem() {
      this.$emit("checkItems", [this.item]);
    }
  }
};

export const NotificationItemFriendRequestMixin = {
  mixins: [NotificationItemMixin],
  methods: {
    accept(e) {
      e.stopPropagation();
      this.$emit("acceptFriendRequest", this.item);
    },
    deny(e) {
      e.stopPropagation();
      if (window.confirm("Are you sure you want to deny this request?")) { // eslint-disable-line no-alert
        this.$emit("denyFriendRequest", this.item);
      }
    }
  }
};

export const NotificationItemFriendRequestAcceptMixin = {
  mixins: [NotificationItemMixin]
};

export const NotificationItemFriendRequestDenyMixin = {
  mixins: [NotificationItemMixin]
};

export const NotificationItemProfileCommentMixin = {
  mixins: [NotificationItemMixin]
};

export const NotificationItemForumQuoteMixin = {
  mixins: [NotificationItemMixin]
};

export const NotificationItemBlogCommentMixin = {
  mixins: [NotificationItemMixin]
};

export const NotificationItemWatchedTopicMessageMixin = {
  mixins: [NotificationItemMixin]
};

export const NotificationItemClubMassMessageInForumMixin = {
  mixins: [NotificationItemMixin]
};

export const NotificationItemRelatedAnimeAddMixin = {
  mixins: [NotificationItemMixin]
};

export const NotificationItemPaymentStripeMixin = {
  mixins: [NotificationItemMixin]
};

/**
 * UserMention.* で共通
 */
export const NotificationItemUserMentionMixin = {
  mixins: [NotificationItemMixin]
};

export const NotificationItemOnAirMixin = {
  mixins: [NotificationItemMixin]
};
