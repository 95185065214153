<template>
  <div class="root">
    <transition name="modal">
      <div
        v-show="show"
        class="modal-content"
        :style="{height:'100%' }">
        <div class="modal-mask"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <p class="modal-head">Your payment method was successfully registered.</p>
            <div v-if="defaultPaymentMethod === 'stripe'"
              class="creditcard-information-registered" :data-type="registeredCard.type">
              <span>XXXX-XXXX-XXXX-{{ registeredCard.digits }}</span>
            </div>
            <div v-if="defaultPaymentMethod === 'paypal'" class="paypal-information-registered">
              You registered your PayPal account at {{ braintreeRegistrationDatetime }}.
            </div>
            <button class="modal-button-submit"
              @click="$emit('proceedToCheckout')"
            >Proceed to Checkout</button>
            <a class="modal-close" @click="hide">Close</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  $version: 180401;
  :root body:not(.sp) .creditcard-information-registered:after{ //不本意だけど、元々Mobile対応のcomponentをPCで表示しようとするとこれで調整しないとダメなので... //2022/10/26
      margin-top: 3px;
  }
  .modal-content {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
    -webkit-text-size-adjust: none;
  }
  .modal-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    font-family: Avenir,"lucida grande",tahoma,verdana,arial,sans-serif;
    position: relative;
    width: 70%;
    max-width: 285px;
    margin: 0 auto;
    padding: 20px 15px 20px;
    box-sizing: border-box;
    background-color: #fff;
    color: #353535;
    border-radius: 4px;
    transition: all .3s ease;
  }
  .modal-close {
    position: absolute;
    top: - 20px;
    right: - 35px;
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background: url("/images/manga_store/viewer/sp/modal_close@2x.png?v=" + $version) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
    cursor: pointer;
  }
  .modal-head {
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    line-height: 1.6rem;
  }
  .modal-button-submit {
    display: block;
    width: 164px;
    height: 40px;
    margin: 15px auto 0;
    background-color: #fff;
    box-sizing: border-box;
    border: 2px solid #2e51a2;
    color: #2e51a2;
    border-radius: 3px;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    white-space: nowrap;
    &:active {
      background-color: rgba(#2e51a2, 0.2);
    }
    cursor: pointer;
  }
  // PayPal
  .paypal-information-registered {
    font-weight: 300;
  }
  // Stripe
  .creditcard-information-registered {
    position: relative;
    display: block;
    margin: 15px auto;
    width: 180px;
    font-size: 14px;
    line-height: 1.4rem;
    font-weight: 300;
    span {
      white-space: nowrap;
    }
    &:after {
      position: absolute;
      top: -6px;
      left: 0;
      background-image: url("/images/membership/icon-creditcard.png?v=" + $version);
      background-repeat: no-repeat;
      background-position: 0px 0px;
      background-size: auto 24px;
      display: inline-block;
      width: 0;
      height: 24px;
      content: "";
    }
    &[data-type="Visa"] {
      padding-left: 36px;
      &:after {
        background-position: -40px 0;
        width: 34px;
      }
    }
    &[data-type="MasterCard"] {
      padding-left: 36px;
      &:after {
        background-position: 0 0;
        width: 34px;
      }
    }
    &[data-type="American Express"] {
      padding-left: 28px;
      &:after {
        background-position: -78px 0;
        width: 26px;
      }
    }
    &[data-type="Discover"] {
      padding-left: 42px;
      &:after {
        background-position: -107px 0;
        width: 40px;
      }
    }
    &[data-type="JCB"] {
      padding-left: 32px;
      &:after {
        background-position: -149px 0;
        width: 30px;
      }
    }
    &[data-type="Diners Club"] {
      padding-left: 32px;
      &:after {
        background-position: -149px 0;
        width: 30px;
      }
    }
  }



  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  @media only screen and (max-width : 320px) and (max-height : 480px) {
    .modal-container {
      padding: 10px;
    }
    .modal-head {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
  @media only screen and (orientation : landscape) {
    .modal-head{
      font-size: 12px;
      margin: 0 0 5px;
    }
    .modal-container {
      max-width: 400px;
      padding: 10px;
    }
    .modal-head {
      font-size: 12px;
      margin-bottom: 10px;
    }

  }
</style>

<script type="text/babel">

  export default {
    props: {
      show: Boolean,
      defaultPaymentMethod: String,
      registeredCard: Object,
      braintreeRegistrationDatetime: String,
    },
    methods: {
      hide() {
        this.$emit("close")
      },
    },
  }
</script>
