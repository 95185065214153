<template>
  <div class="root">
    <transition name="modal">
      <div v-show="isShown" class="modal-content">
        <div class="modal-mask"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="loading">
              <i class='fa-solid fa-spinner fa-2x fa-spin'></i>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  .modal-content {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
  }
  .modal-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 40px;
    box-sizing: border-box;
    color: #353535;
    transition: all .3s ease;
    font-weight: 300;
    font-family: Verdana,Arial;
  }
  .loading {
    display: block;
    text-align: center;
    color: #ffffff;
    font-size: 24px;
  }

  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>

<script type="text/babel">
  export default {
    props: {
      isShown: Boolean,
    },
  }
</script>
