const $ = window.$;

// 共通で使う機能をまとめていく

$(() => {

  //common
  if ($(".js-anchor-link")[0]) {
    $(".js-anchor-link").on("click", function() {
      const anchor_href = $(this).attr("href");
      const anchor_target_pos = $(anchor_href).offset().top;
      $("html,body").animate({ scrollTop: anchor_target_pos });
    });
  }

  // FB Lazyload in footer
  if ($(".fb-page")[0]) {
    const $fb_page = $(".fb-page")
    const fb_pos = $fb_page.offset().top
    const fb_page_url = "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FOfficialMyAnimeList%2F&tabs&width=450&height=154&small_header=true&adapt_container_width=false&hide_cover=true&show_facepile=true&appId"

    if ($(window).height() > fb_pos) {
      $fb_page.attr("src",fb_page_url)
    } else {
      $(window).on("scroll resize",function() {
        if (!$fb_page.attr("src")) {
          const winow_height = $(window).height()
        	const window_pos = $(this).scrollTop()
          if (winow_height + window_pos > fb_pos ) {
            $fb_page.attr("src",fb_page_url)
          }
        }
      })
    }
  }

  // FAQ
  if ($(".js-faq-question")[0]) {
    const $btnFaqQuestion = $(".js-faq-question")
    $btnFaqQuestion.on("click", function () {
      const faqId = $(this).attr("id")
      $(this).toggleClass("on")
      $(this).siblings(`.${faqId}`).toggleClass("show")
    })
  }

  // external links
  $(".js-more-links").on("click", function(event) {
    event.preventDefault();
    $(".js-links[data-rel=" + $(this).data("rel") + "]").slideToggle("fast")
    if ($(this).html().indexOf("More") > -1) {
      $(this).html("<i class=\"fa-solid fa-chevron-up mr4\"></i>Less links")
    } else {
      $(this).html("<i class=\"fa-solid fa-chevron-down mr4\"></i>More links")
    }
  })

});
