<template>
  <div class="gauge-outer">
    <span class="gauge-inner" :style="gaugeWidth"></span>
    <span class="gauge-text">{{ score }}% matched</span>
  </div>
</template>

<style lang="scss" scoped>
  $version: 180416;
  .gauge-outer {
    position: relative;
    display: block;
    width: 100%;
    height: 14px;
    border: #EBEBEB 1px solid;
    border-radius: 12px;
  }
  .gauge-inner {
    background-image: url("/images/manga_store/recommendation-gauge-bg.png?v="+ $version);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 105px 14px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 14px;
    border-radius: 12px;
  }
  .gauge-text {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    font-size: 9px;
    line-height: 1.0rem;
    color: #323232;
    text-shadow: #ffffff 1px 1px 1px;
    font-family: Avenir, "lucida grande", tahoma, verdana, arial, sans-serif;
  }
</style>

<script type="text/babel">
  export default {
    props: {
      score: Number,
    },
    computed: {
      gaugeWidth() {
        return `width: ${this.score}%`
      },
    },
  }
</script>
