const $ = window.$;
import diff_match_patch from "diff-match-patch";

$(function () {
  $(document).ready(function () {
    $('.show-diff').each((index, element) => {
      showDiff($(element))
    })
  });

  const showDiff = ($parent) => {
    const newValue = $parent.find('textarea').val()
    const oldValue = $parent.data('old')

    const dmp = new diff_match_patch()
    const diff = dmp.diff_main(oldValue, newValue)
    dmp.diff_cleanupSemantic(diff)
    const pretty = dmp.diff_prettyHtml(diff)
    $parent.find('.compare').html(pretty)
  }
});
