<template>
  <div class="root">
    <transition name="modal">
      <div v-if="isShown" class="modal-content">
        <div class="modal-mask" @click="cancel"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <span class="modal-message">
              Your registered address doesn’t match your access location.<br>
              Please select your current area of residence in order to continue shopping.
            </span>

            <ul>
              <li>
                <input type="radio" name="address" id="id0" :value="residentialArea.registered" v-model="selectedAddress">
                <label for="id0">{{ residentialArea.registeredName }}</label>
              </li>
              <li>
                <input type="radio" name="address" id="id1" :value="residentialArea.current" v-model="selectedAddress">
                <label for="id1">{{ residentialArea.currentName }}</label>
              </li>
            </ul>

            <div class="modal-button-wrap">
              <div class="modal-error" v-show="error">
                <span>{{ error }}</span>
              </div>
              <template v-if="!isRequesting">
                <a class="modal-cancel-button" @click="cancel">Cancel</a>
                <a class="modal-select-button" @click="select">Select</a>
              </template>
              <template v-else>
                <span class="modal-button-loading"></span>
              </template>
            </div>
            <a class="modal-close" @click="cancel">Close</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  @import "../../../../../css/variables";

  .modal-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
  }
  .modal-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    position: relative;
    width: 745px;
    margin: 0 auto;
    padding: 40px;
    box-sizing: border-box;
    background-color: $color-pc-bg-ffffff;
    color: $color-pc-text-353535;
    border-radius: 4px;
    transition: all .3s ease;
    font-weight: 300;
    font-family: Verdana,Arial;
    -webkit-font-smoothing: antialiased;
  }
  .modal-error {
    display: block;
    margin-bottom: 8px;
    text-align: center;
    color: $color-pc-text-ff6a86;
    font-size: 12px;
  }
  .modal-message {
    font-size: 16px;
  }
  .modal-button-wrap {
    display: block;
    width:100%;
    margin-top: 24px;
    text-align: center;
  }
  .modal-select-button {
    border: 2px solid $color-pc-border-2e51a2;
    background-color: $color-pc-bg-2e51a2;
    color: $color-pc-text-ffffff;
    font-size: 16px;
    text-align: center;
    display: inline-block;
    width: 168px;
    height: 36px;
    line-height: 33px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 2px;
    font-weight: 700;
    margin-left: 20px;
    text-decoration: none;
    opacity: 1;
    &:hover {
      color: $color-pc-text-ffffff;
      text-decoration: none;
      opacity: 0.7;
    }
  }
  .modal-cancel-button {
    border: 2px solid $color-pc-border-2e51a2;
    color: $color-pc-bg-2e51a2;
    font-size: 16px;
    text-align: center;
    display: inline-block;
    width: 168px;
    height: 36px;
    line-height: 33px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: 700;
    text-decoration: none;
    &:hover {
      color: $color-pc-bg-2e51a2;
      background-color: $color-pc-bg-e1e7f5;
      text-decoration: none;
    }
  }
  .modal-button-loading {
    display: block;
    width: 100%;
    height: 36px;
    background: url(/images/xmlhttp-loader.gif?v=161115001) no-repeat center center;
    background-size: 16px;
    vertical-align: middle;
    text-indent: -99999px;
  }
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    content: '';
    display: block;
    width: 29px;
    height: 29px;
    background: url(/images/manga_store/pc/modal_close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
    cursor: pointer;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 24px 0 36px;
    > li {
      font-size: 14px;
      margin-bottom: 12px;
      &:last-of-type {
      margin-bottom: 0;
      }
    }
  }
  input[type="radio"] + label {
    cursor: pointer;
  }

  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .dark-mode {
    @import "../../../../../css/colors_dark";

    .modal-container {
      background-color: $color-pc-bg-ffffff;
      color: $color-pc-text-353535;
    }
    .modal-error {
      color: $color-pc-text-ff6a86;
    }
    .modal-select-button {
      border: 2px solid $color-pc-border-2e51a2;
      background-color: $color-pc-bg-2e51a2;
      color: $color-pc-text-ffffff;
      opacity: 1;
      &:hover {
        color: $color-pc-text-ffffff;
        text-decoration: none;
        opacity: 0.7;
      }
    }
    .modal-cancel-button {
      border: 2px solid $color-pc-border-2e51a2;
      color: $color-pc-bg-2e51a2;
      &:hover {
        color: $color-pc-bg-2e51a2;
        background-color: $color-pc-bg-e1e7f5;
        text-decoration: none;
      }
    }
  }
</style>

<script type="text/babel">
  export default {
    data() {
      return {
        // TODO: 初期値コレでいい？？
        selectedAddress: null
      }
    },
    props: {
      isShown: Boolean,
      isRequesting: Boolean,
      error: String,
      residentialArea: Object,
    },
    watch: {
      isShown() {
        if (this.isShown) {
          this.selectedAddress = this.residentialArea.registered
        }
      }
    },
    methods: {
      cancel() {
        this.$emit('cancel')
      },
      select() {
        this.$emit('select', this.selectedAddress)
      },
    }
  }
</script>
