import * as types from "./mutation-types"

const axios = window.axios

export default {
  async [types.PURCHASE]({state}) {
    const purchaseToken = (await axios.get("/purchase/token")).data.purchase_token
    // eslint-disable-next-line camelcase
    return axios.post("/purchase/gift", {gift_id: state.gift.gift_id, purchase_token: purchaseToken})
  },
  async [types.CHECK_PAYMENT_SETTING]({state, commit}) {
    commit(types.LOADING_MODAL, true)
    commit(types.CHECK_PAYMENT_SETTING, (await axios.get("/payment/setting")).data)
  }
}
