<template>
  <div class="root">
    <div class="seekbar-wrap" :class="{opposite: switchDirection}">
      <div class="seekbar-bg-wrap">
        <div class="seekbar-bg" :style="backgroundStyle"></div>
      </div>
      <input type="range" step="1" min="1"
             :max="maxValue"
             :value="currentValue"
             @change="change"
             @input="change"
             @keydown.prevent>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  $seekbar-width: 470px;
  $seekbar-width-m: 270px;
  $seekbar-width-s: 200px;
  $seekbar-width-xs: 150px;
  $seekbar-height: 4px;
  $seekbar-handle-width: 16px;
  $seekbar-color: #fff;
  $seekbar-lower-color: #50E3C2;
  $seekbar-handle-color:#fff;
  .seekbar-wrap {
    position: relative;
    display: block;
    width: $seekbar-width + $seekbar-handle-width;
    @media screen and (max-width: 1060px) {
      width: $seekbar-width-m + $seekbar-handle-width;
    }
    @media screen and (max-width: 840px) {
      width: $seekbar-width-s + $seekbar-handle-width * 2;
    }
    @media screen and (max-width: 760px) {
      width: $seekbar-width-xs + $seekbar-handle-width;
    }
    @media screen and (max-width: 700px) {
      width: $seekbar-width-xs + $seekbar-handle-width;
      left: -80px;
    }
    @media screen and (max-width: 535px) {
      width: $seekbar-width-xs + $seekbar-handle-width;
      left: -80px;
      display: none;
    }
    margin: 0 auto;
    transform: scale(-1, 1);
    &.opposite {
      transform: scale(1, 1);
    }
  }
  .seekbar-bg-wrap {
    display: block;
    position: relative;
    margin: 0 $seekbar-handle-width/2;
  }
  /* for webkit browser seekbar-lower-color  */
  .seekbar-bg {
    position: absolute;
    width: 100%;
    height: $seekbar-height;
    display: block;
    border-radius: 3px;
    margin-top: 6px;
  }
  input[type=range] {
    cursor: pointer;
    position: absolute;
    top:0;
    -webkit-appearance: none;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: 0;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: $seekbar-height;
    background-color: transparent;
    border: none;
    border-radius: 2px;
    margin-top: 6px;
  }
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: $seekbar-handle-width;
    width: $seekbar-handle-width;
    border-radius: 50%;
    background-color: $seekbar-handle-color;
    margin-top: -6px;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
      background-color: transparent;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: $seekbar-height;
    background-color: transparent;
    border: none;
    border-radius: 3px;
  }
  input[type=range]::-moz-range-progress {
    background-color: transparent;
    height: $seekbar-height;
    border-radius: 2px;
  }
  input[type=range]::-moz-range-thumb {
    border: none;
    height: $seekbar-handle-width;
    width: $seekbar-handle-width;
    border-radius: 50%;
    background-color: $seekbar-handle-color;
  }
  /*hide the outline behind the border*/
  input[type=range]:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
  }
  input[type=range]::-ms-track {
    position: absolute;
    top:0;
    width: 100%;
    height: $seekbar-height;
    background: transparent;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background-color: transparent;
    border-radius: 10px;
  }
  input[type=range]::-ms-fill-upper {
    background-color: transparent;
    border-radius: 10px;
  }
  input[type=range]::-ms-thumb {
    border: none;
    height: $seekbar-handle-width;
    width: $seekbar-handle-width;
    border-radius: 50%;
    background-color: $seekbar-handle-color;
  }
  input[type=range]::-ms-tooltip {
    display: none;
  }
  input[type=range]:focus::-ms-fill-lower {
    background-color: transparent;
  }
  input[type=range]:focus::-ms-fill-upper {
    background-color: transparent;
  }
</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"

  export default {
    props: {
      numTotalPages: Number,
      currentPage: Number,
      isFacingPage: Boolean,
      manuscript: Object,
      pageDirection: Number,
    },
    computed: {
      switchDirection() {
        return this.pageDirection === -1 ? true : false
      },
      maxValue() {
        if (this.isFacingPage) {
          const pseudoPages = this.numTotalPages + (this.manuscript.isLeftFirst ? 1 : 0)
          return Math.ceil(pseudoPages / 2)
        } else {
          return this.numTotalPages
        }
      },
      currentValue() {
        if (this.isFacingPage) {
          const pseudoCurrentPage = this.currentPage + (this.manuscript.isLeftFirst ? 1 : 0)
          return Math.floor((pseudoCurrentPage + 1) / 2)
        } else {
          return this.currentPage
        }
      },
      backgroundStyle() {
        const percentage = (this.currentValue - 1) / (this.maxValue - 1) * 100
        return {
          background: `linear-gradient(to right, #50E3C2, #50E3C2 ${percentage}%, #fff ${percentage}%, #fff)`
        }
      }
    },
    methods: {
      change(e) {
        let v = parseInt(e.target.value)
        let page
        if (v === 1) {
          page = 1
        } else if (v === this.maxValue) {
          page = this.numTotalPages
        } else {
          if (this.isFacingPage) {
            page = (v - 1) * 2 + (this.manuscript.isLeftFirst ? 0 : 1)
          } else {
            page = v
          }
        }
        this.$store.commit(types.JUMP_PAGE, {page})
      },
    },
  }
</script>
