<template>
  <div class="root">
    <transition name="modal">
      <div v-show="show" class="tutorial-content">
        <div class="tutorial-mask" @click="maskClick">
          <div class="tutorial-mask-header" v-show="!isShowHowtoRead"><div class="tutorial-mask-header-text">Move your cursor here to show the toolbar</div></div>
          <div class="tutorial-mask-gray"></div>
          <div class="tutorial-mask-footer" v-show="!isShowHowtoRead"><div class="tutorial-mask-footer-text">Move your cursor here to show the toolbar</div></div>
        </div>
        <div class="tutorial-wrapper">
          <div class="tutorial-container tutorial-howto-use" v-show="!isShowHowtoRead">
            <div class="tutorial-direction">
              <p>Use the left and right keys to turn pages</p>
            </div>
            <div class="tutorial-zoom">
              <p>To zoom in double click your mouse or<br>use the + and - keys to zoom in and out</p>
            </div>
          </div>
          <div class="tutorial-container tutorial-howto-read" v-show="isShowHowtoRead"></div>
        </div>
        <div class="button-howto-wrap">
          <a class="button-howto-use" @click="hideHowtoRead" :class="{'is-active': !isShowHowtoRead }">How to Use</a>
          <span class="menu-divider" :class="{'is-active-right': isShowHowtoRead }"></span>
          <a class="button-howto-read" @click="showHowtoRead" :class="{'is-active': isShowHowtoRead }">How to Read</a>
        </div>
        <a class="tutorial-close" @click="hide">Got it!</a>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  $header-height: 50px;
  $footer-height: 50px;
  $tutorial-content-z-index: 9998;
  .tutorial-content {
    position: fixed;
    z-index: $tutorial-content-z-index;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
    font-family: Verdana,Arial;
  }
  .tutorial-mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: table;
    table-layout: auto;
  }
  .tutorial-mask-gray{
    display: table-row;
    &::after{
      content: ' ';
      display: table-cell;
      background-color: rgba(0, 0, 0, .7);
    }
  }
  .tutorial-mask-header,
  .tutorial-mask-footer{
    display: table-row;
    height: $header-height;
  }
  .tutorial-mask-header-text,
  .tutorial-mask-footer-text {
    vertical-align: middle;
    &::before {
      content: '';
      display: inline-block;
      width: 23px;
      height: 36px;
      background: url(/images/manga_store/viewer/pc/tutorial_pointer@2x.png?v=161115001) no-repeat left top;
      background-size: contain;
      vertical-align: middle;
      margin: 10px 10px 0 0;
      text-indent: -99999px;
    }
    color: #333;
    font-weight: bold;
    text-align: center;
    display: table-cell;
    background-color: rgba(198, 232, 255, .8);
  }
  .tutorial-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .tutorial-container {
    position: relative;
    width: 800px;
    margin: 0 auto;
    display: block;
    transition: all .3s ease;
    color: #fff;
    font-weight: bold;
  }
  .tutorial-direction{
    width: 50%;
    float: left;
    p {
      &::before {
        content: '';
        display: block;
        margin: 0 auto 20px;
        width: 212px;
        height: 141px;
        background: url(/images/manga_store/viewer/pc/tutorial_direction@2x.png?v=161115001) no-repeat left top;
        background-size: contain;
        vertical-align: middle;
        text-indent: -99999px;
      }
      text-align: center;
      line-height: 27px;
      font-size: 18px;
      white-space: nowrap;
    }
  }
  .tutorial-zoom{
    width: 50%;
    float: right;
    position: relative;
    margin-top: 32px;
    p {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 47px;
        display: block;
        width: 207px;
        height: 142px;
        background: url(/images/manga_store/viewer/pc/tutorial_zoom@2x.png?v=161115001) no-repeat left top;
        background-size: contain;
        vertical-align: middle;
        text-indent: -99999px;
      }
      padding: 32px 0 0 164px;
      margin: 0;
      line-height: 27px;
      font-size: 18px;
      white-space: nowrap;
    }
  }
  .tutorial-howto-read{
    &::before {
      content: '';
      display: block;
      margin: 0 auto 104px;
      width: 620px;
      height: 461px;
      background: url(/images/manga_store/viewer/pc/tutorial_read@2x.png?v=161115001) no-repeat center top;
      background-size: contain;
      vertical-align: middle;
    }
  }
  .tutorial-close{
    position: fixed;
    z-index: $tutorial-content-z-index + 1;
    top: $header-height + 10px;
    right: 10px;
    content: '';
    display: block;
    width: 126px;
    height: 34px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 2px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    line-height: 34px;
  }

  $menu-height: 31px;
  .button-howto-wrap{
    position: fixed;
    z-index: $tutorial-content-z-index + 1;
    left: 0;
    bottom: $footer-height + 10px;
    width: 100%;
    height: $menu-height;
    overflow: hidden;
    text-align: center;
    font-size: 0;
    vertical-align: bottom;
    a{
      background-color: #fff;
      color: #333;
      line-height: $menu-height;
      height: $menu-height;
      font-size: 16px;
      font-weight: bold;
      padding: 0 10px;
      display: inline-block;
      cursor: pointer;
      &.is-active{
        cursor: auto;
        background-color: #C6E8FF;
        color: #2E51A2;
      }
      &:first-child{
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
      &:last-child{
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }
  .menu-divider{
    display: inline-block;
    width: 14px;
    height: $menu-height;
    vertical-align: bottom;
    background: url(/images/manga_store/viewer/pc/tutorial_menu_active_left@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    &.is-active-right{
      background: url(/images/manga_store/viewer/pc/tutorial_menu_active_right@2x.png?v=161115001) no-repeat left top;
      background-size: contain;
    }
  }
  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .tutorial-container,
  .modal-leave-active .tutorial-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"
  import { mapGetters } from "vuex"

  export default {
    props: {
      show: Boolean,
    },
    data() {
      return {
        isShowHowtoRead : false,
      }
    },
    methods: {
      maskClick() {
        if (this.isShowHowtoRead) {
          this.$store.commit(types.HIDE_TUTORIAL_MODAL)
        } else {
          this.isShowHowtoRead = true
        }

      },
      hide() {
        this.$store.commit(types.HIDE_TUTORIAL_MODAL)
      },
      showHowtoRead() {
        this.isShowHowtoRead = true
      },
      hideHowtoRead() {
        this.isShowHowtoRead = false
      },
    },
  }
</script>
