const $ = window.$;
const localStorage = require("../../../common/modules/localStorage");

$(function() {
  const vaFilterItems = {
    'people-va-notinmylist': true,
    'people-va-plantowatch': true,
    'people-va-inmylist': true
  };
  const staffFilterItems = {
    'people-staff-notinmylist': true,
    'people-staff-plantowatch': true,
    'people-staff-inmylist': true
  };
  const mangaFilterItems = {
    'people-manga-notinmylist': true,
    'people-manga-plantowatch': true,
    'people-manga-inmylist': true
  };

  const peopleCharacterSortEvent = function (btnId) {
    $("#js-people-character-sort-title").html("Sorted by " + $("#" + btnId).html());
    $(".js-people-character-sort-order-block.js-sort .js-btn-sort-order").removeClass("selected");
    $("#" + btnId).addClass("selected");
    sortEntriesByMethod("character", btnId);
    localStorage.setItem('peopleCharacterSort', btnId);
  }

  const peopleStaffSortEvent = function (btnId) {
    $("#js-people-staff-sort-title").html("Sorted by " + $("#" + btnId).html());
    $(".js-people-staff-sort-order-block.js-staff-sort .js-btn-sort-order").removeClass("selected");
    $("#" + btnId).addClass("selected");
    sortEntriesByMethod("staff", btnId);
    localStorage.setItem('peopleStaffSort', btnId);
  }

  const peopleMangaSortEvent = function (btnId) {
    $("#js-people-manga-sort-title").html("Sorted by " + $("#" + btnId).html());
    $(".js-people-manga-sort-order-block.js-manga-sort .js-btn-sort-order").removeClass("selected");
    $("#" + btnId).addClass("selected");
    sortEntriesByMethod("manga", btnId);
    localStorage.setItem('peopleMangaSort', btnId);
  }

  // btn filter event
  if ($(".js-navi-people-character")[0]) {
    //filter button
    $(".js-navi-people-character .js-btn-show-sort").on("click", function() {
      $(document).off("click");
      const id = $(this).data("id");
      const $targetBlock = $(`.js-navi-people-character .js-${id}`);
      if ( $targetBlock.css("display") === "block" ) {
        $(`.js-navi-people-character .js-people-character-sort-order-block.js-${id}`).hide();
        $(".js-navi-people-character .js-btn-show-sort").removeClass("on");
      } else {
        $(".js-navi-people-character .js-people-character-sort-order-block").hide();
        $(".js-navi-people-character .js-btn-show-sort").removeClass("on");
        $(`.js-navi-people-character .js-people-character-sort-order-block.js-${id}`).show();
        $(this).addClass("on");

        const showDialogInterval = setInterval(function() {
          closePeopleFilterDialog('character');
          clearInterval(showDialogInterval);
        }, 500);
      }
    });
  }

  if ($(".js-navi-people-staff")[0]) {
    //filter button
    $(".js-navi-people-staff .js-btn-show-sort").on("click", function() {
      $(document).off("click");
      const id = $(this).data("id");

      const $targetBlock = $(`.js-navi-people-staff .js-${id}`);
      if ( $targetBlock.css("display") === "block" ) {
        $(`.js-navi-people-staff .js-people-staff-sort-order-block.js-${id}`).hide();
        $(".js-navi-people-staff .js-btn-show-sort").removeClass("on");
      } else {
        $(".js-navi-people-staff .js-people-staff-sort-order-block").hide();
        $(".js-navi-people-staff .js-btn-show-sort").removeClass("on");
        $(`.js-navi-people-staff .js-people-staff-sort-order-block.js-${id}`).show();
        $(this).addClass("on");

        const showDialogInterval = setInterval(function() {
          closePeopleFilterDialog('staff');
          clearInterval(showDialogInterval);
        }, 500);
      }
    });
  }

  if ($(".js-navi-people-manga")[0]) {
    //filter button
    $(".js-navi-people-manga .js-btn-show-sort").on("click", function() {
      $(document).off("click");
      const id = $(this).data("id");
      const $targetBlock = $(`.js-navi-people-manga .js-${id}`);
      if ( $targetBlock.css("display") === "block" ) {
        $(`.js-navi-people-manga .js-people-manga-sort-order-block.js-${id}`).hide();
        $(".js-navi-people-manga .js-btn-show-sort").removeClass("on");
      } else {
        $(".js-navi-people-manga .js-people-manga-sort-order-block").hide();
        $(".js-navi-people-manga .js-btn-show-sort").removeClass("on");
        $(`.js-navi-people-manga .js-people-manga-sort-order-block.js-${id}`).show();
        $(this).addClass("on");

        const showDialogInterval = setInterval(function() {
          closePeopleFilterDialog('manga');
          clearInterval(showDialogInterval);
        }, 500);
      }
    });
  }

  //領域外クリックで閉じる
  const closePeopleFilterDialog = function(type) {
    $(".js-navi-people-" + type + " .js-people-" + type + "-sort-order-block").on("click", function(e) {
      e.stopPropagation();
    });
    $(document).on("click", function() {
      $(".js-navi-people-" + type + " .js-people-" + type + "-sort-order-block").hide();
      $(".js-navi-people-" + type + " .js-btn-show-sort").removeClass("on");
      $(document).off("click");
      $(".js-table-people-" + type).click();
      return false;
    });
  }

  const myListHideAnime = function(type) {
    const $btnMylist = $(".js-people-" + type + "-sort-order-block.js-" + type + "-mylist .js-btn-sort-order");
    $btnMylist.each(function(){
      const id = $(this).attr("id");
      if (!$(this).hasClass("selected")) {
        $(".js-anime-watch-status-" + id).hide();
      } else {
        $(".js-anime-watch-status-" + id).show();
      }
    });
  }

  const changePeopleCharacterFilter = function (btnId) {
    $(".js-people-character-sort-order-block.js-character-mylist .js-btn-sort-order#All").removeClass("selected");
    $('#' + btnId).toggleClass("selected");
    checkAllMyListButton("character","mylist");
    if ($('#' + btnId).hasClass("selected")) {
      vaFilterItems[btnId] = true;
    } else {
      vaFilterItems[btnId] = false;
    }
    $(".js-categories-people .js-people-character").show();
    myListHideAnime('character');
  }
  const changePeopleStaffFilter = function (btnId) {
    $(".js-people-staff-sort-order-block.js-staff-mylist .js-btn-sort-order#All").removeClass("selected");
    $('#' + btnId).toggleClass("selected");
    checkAllMyListButton("staff");
    if ($('#' + btnId).hasClass("selected")) {
      staffFilterItems[btnId] = true;
    } else {
      staffFilterItems[btnId] = false;
    }
    $(".js-categories-people .js-people-staff").show();
    myListHideAnime('staff');
  }

  const changePeopleMangaFilter = function (btnId) {
    $(".js-people-manga-sort-order-block.js-manga-mylist .js-btn-sort-order#All").removeClass("selected");
    $('#' + btnId).toggleClass("selected");
    checkAllMyListButton("manga");
    if ($('#' + btnId).hasClass("selected")) {
      mangaFilterItems[btnId] = true;
    } else {
      mangaFilterItems[btnId] = false;
    }
    $(".js-categories-people .js-people-manga").show();
    myListHideAnime('manga');
  }

  //自動的に Allもチェックされる処理
  const checkAllMyListButton = function(type) {
    let $btnPeopleMylist = $(`.js-people-${type}-sort-order-block.js-${type}-mylist .js-btn-sort-order`);
    let selectedMyListBtnAry = [];
    $btnPeopleMylist.each(function(){
      if ($(this).hasClass("selected") || $(this).hasClass("crossed")) {
        selectedMyListBtnAry.push($(this).attr("id"));
      }
    });
    if (selectedMyListBtnAry.length >= $btnPeopleMylist.length-1) {
      $btnPeopleMylist.addClass(function() {
        if (!$(this).hasClass("crossed")) {
          return "selected"
        }
      });
      $(`.js-btn-show-sort.${type}-mylist`).removeClass("filtered");
    } else {
      $(`.js-btn-show-sort.${type}-mylist`).addClass("filtered");
    }
  }

  // Fliterの選択エリア内の処理
  if ($(".js-people-character-sort-order-block")[0]) {

    //close button
    $(".js-people-character-sort-order-block .js-btn-close").on("click", function() {
      $(this).parents(".js-people-character-sort-order-block").hide();
      $(".js-navi-people-character .js-btn-show-sort").removeClass("on");
      $(".js-table-people-character").click();
    });

    //mylist ボタン
    $(".js-people-character-sort-order-block.js-character-mylist .js-btn-sort-order").on("click", function() {
      const btnId = $(this).attr("id");
      if (btnId === "All") {
        const $peopleAnime = $(".js-people-character");
        let checked = true;
        if ($(this).hasClass("selected")) {
          $(".js-people-character-sort-order-block.js-character-mylist .js-btn-sort-order").removeClass("selected");
          $peopleAnime.hide();
          $(".js-btn-show-sort.character-mylist").addClass("filtered");
          checked = false;
        } else {
          $(".js-people-character-sort-order-block.js-character-mylist .js-btn-sort-order").addClass("selected");
          $peopleAnime.show();
          $(".js-btn-show-sort.character-mylist").removeClass("filtered");
        }
        for (const item in vaFilterItems) {
          vaFilterItems[item] = checked;
        }
      } else {
        changePeopleCharacterFilter(btnId);
      }
      localStorage.setItem('peopleCharacterFilter', JSON.stringify(vaFilterItems));
    });
  }

  if ($(".js-people-staff-sort-order-block")[0]) {
    //close button
    $(".js-people-staff-sort-order-block .js-btn-close").on("click", function() {
      $(this).parents(".js-people-staff-sort-order-block").hide();
      $(".js-navi-people-staff .js-btn-show-sort").removeClass("on");
      $(".js-table-people-staff").click();
    });
    $(".js-people-staff-sort-order-block.js-staff-mylist .js-btn-sort-order").on("click", function() {
      const btnId = $(this).attr("id");
      if (btnId === "All") {
        const $peopleAnime = $(".js-people-staff");
        let checked = true;
        if ($(this).hasClass("selected")) {
          $(".js-people-staff-sort-order-block.js-staff-mylist .js-btn-sort-order").removeClass("selected");
          $peopleAnime.hide();
          $(".js-btn-show-sort.staff-mylist").addClass("filtered");
          checked = false;
        } else {
          $(".js-people-staff-sort-order-block.js-staff-mylist .js-btn-sort-order").addClass("selected");
          $peopleAnime.show();
          $(".js-btn-show-sort.staff-mylist").removeClass("filtered");
        }
        for (const item in staffFilterItems) {
          staffFilterItems[item] = checked;
        }
      } else {
        changePeopleStaffFilter(btnId);
      }
      localStorage.setItem('peopleStaffFilter', JSON.stringify(staffFilterItems));
    });
  }

  if ($(".js-people-manga-sort-order-block")[0]) {
    //close button
    $(".js-people-manga-sort-order-block .js-btn-close").on("click", function() {
      $(this).parents(".js-people-manga-sort-order-block").hide();
      $(".js-navi-people-manga .js-btn-show-sort").removeClass("on");
      $(".js-table-people-manga").click();
    });
    $(".js-people-manga-sort-order-block.js-manga-mylist .js-btn-sort-order").on("click", function() {
      const btnId = $(this).attr("id");
      if (btnId === "All") {
        const $peopleAnime = $(".js-people-manga");
        let checked = true;
        if ($(this).hasClass("selected")) {
          $(".js-people-manga-sort-order-block.js-manga-mylist .js-btn-sort-order").removeClass("selected");
          $peopleAnime.hide();
          $(".js-btn-show-sort.manga-mylist").addClass("filtered");
          checked = false;
        } else {
          $(".js-people-manga-sort-order-block.js-manga-mylist .js-btn-sort-order").addClass("selected");
          $peopleAnime.show();
          $(".js-btn-show-sort.manga-mylist").removeClass("filtered");
        }
        for (const item in mangaFilterItems) {
          mangaFilterItems[item] = checked;
        }
      } else {
        changePeopleMangaFilter(btnId);
      }
      localStorage.setItem('peopleMangaFilter', JSON.stringify(mangaFilterItems));
    });
  }

  // sort ボタン
  $(".js-people-character-sort-order-block.js-sort .js-btn-sort-order").on("click", function() {
    peopleCharacterSortEvent($(this).attr("id"));
    $(".js-people-character-sort-order-block").hide();
    $(".js-navi-people-character .js-btn-show-sort").removeClass("on");
    $(".js-table-people-character").click();
  });

  $(".js-people-staff-sort-order-block.js-staff-sort .js-btn-sort-order").on("click", function() {
    peopleStaffSortEvent($(this).attr("id"));
    $(".js-people-staff-sort-order-block").hide();
    $(".js-navi-people-staff .js-btn-show-sort").removeClass("on");
    $(".js-table-people-staff").click();
  });

  $(".js-people-manga-sort-order-block.js-manga-sort .js-btn-sort-order").on("click", function() {
    peopleMangaSortEvent($(this).attr("id"));
    $(".js-people-manga-sort-order-block").hide();
    $(".js-navi-people-manga .js-btn-show-sort").removeClass("on");
    $(".js-table-people-manga").click();
  });


  const getBlocks = function (entryType) {
    return [...document.querySelectorAll('.js-people-' + entryType)]
  }

  const sortEntriesByMethod = function (entryType, method) {
    const orderedBlocks =
      (method === 'people-va-alphabetical' || method === 'people-staff-alphabetical' || method === 'people-manga-alphabetical')
        ? getBlocks(entryType).sort(blockAlphabeticalSorter)
        : (method === 'people-va-mostrecent' || method === 'people-staff-mostrecent' || method === 'people-manga-mostrecent')
        ? getBlocks(entryType).sort(blockChronologicalDescSorter)
        : (method === 'people-va-startingworks' || method === 'people-staff-startingworks' || method === 'people-manga-startingworks')
        ? getBlocks(entryType).sort(blockChronologicalAscSorter)
        : (method === 'people-va-score' || method === 'people-staff-score' || method === 'people-manga-score')
        ? getBlocks(entryType).sort(blockScoreSorter)
        : getBlocks(entryType).sort(blockPopularitySorter)

    orderedBlocks.forEach(
      el => $(".js-table-people-" + entryType).append(el)
    )
  }
  const blockAlphabeticalSorter = function(a, b) {
    const aName = a.querySelector('.js-people-title').innerHTML
    const bName = b.querySelector('.js-people-title').innerHTML
    return (aName > bName) ?  1 :
      (aName < bName) ? -1 :
        0
  }

  const blockChronologicalDescSorter = function(a, b) {
    const aDate = a.querySelector('.entry-date').innerHTML
      .match(/\d+/)[0]
    const bDate = b.querySelector('.entry-date').innerHTML
      .match(/\d+/)[0]
    return (aDate < bDate) ? 1 : (aDate > bDate) ? -1 : 0
  }

  const blockChronologicalAscSorter = function(a, b) {
    const aDate = a.querySelector('.entry-date').innerHTML
      .match(/\d+/)[0]
    const bDate = b.querySelector('.entry-date').innerHTML
      .match(/\d+/)[0]
    return (aDate > bDate) ? 1 : (aDate < bDate) ? -1 : 0
  }

  function blockPopularitySorter(a, b) {
    const getField = el => el.querySelector('.js-people-favorites')
    const aUserCount = getField(a).innerHTML.replaceAll(/\D/g, '')
    const bUserCount = getField(b).innerHTML.replaceAll(/\D/g, '')
    return bUserCount - aUserCount
  }

  function blockScoreSorter(a, b) {
    const getField = el => el.querySelector('.total-score')
    const aScore = getField(a).innerHTML.replaceAll(/\D/g, '')
    const bScore = getField(b).innerHTML.replaceAll(/\D/g, '')
    return bScore - aScore
  }

  if (localStorage['peopleCharacterFilter']) {
    const json = JSON.parse(localStorage['peopleCharacterFilter']);
    for (const key in vaFilterItems) {
      if (json[key] !== undefined) {
        vaFilterItems[key] = json[key];
        if (json[key] === false) {
          changePeopleCharacterFilter(key);
        }
      }
    }
  }
  if (localStorage['peopleStaffFilter']) {
    const json = JSON.parse(localStorage['peopleStaffFilter']);
    for (const key in staffFilterItems) {
      if (json[key] !== undefined) {
        staffFilterItems[key] = json[key];
        if (json[key] === false) {
          changePeopleStaffFilter(key);
        }
      }
    }
  }
  if (localStorage['peopleMangaFilter']) {
    const json = JSON.parse(localStorage['peopleMangaFilter']);
    for (const key in mangaFilterItems) {
      if (json[key] !== undefined) {
        mangaFilterItems[key] = json[key];
        if (json[key] === false) {
          changePeopleMangaFilter(key);
        }
      }
    }
  }
  if (
    localStorage['peopleCharacterSort'] == 'people-va-alphabetical' ||
    localStorage['peopleCharacterSort'] == 'people-va-mostrecent' ||
    localStorage['peopleCharacterSort'] =='people-va-startingworks' ||
    localStorage['peopleCharacterSort'] =='people-va-popularity'
  ) {
    peopleCharacterSortEvent(localStorage['peopleCharacterSort']);
  } else {
    peopleCharacterSortEvent('people-va-mostrecent');
  }
  $(".js-table-people-character").show();
  if (
    localStorage['peopleStaffSort'] == 'people-staff-alphabetical' ||
    localStorage['peopleStaffSort'] == 'people-staff-mostrecent' ||
    localStorage['peopleStaffSort'] =='people-staff-startingworks' ||
    localStorage['peopleStaffSort'] =='people-staff-popularity' ||
    localStorage['peopleStaffSort'] =='people-staff-score'
  ) {
    peopleStaffSortEvent(localStorage['peopleStaffSort']);
  } else {
    peopleStaffSortEvent('people-staff-popularity');
  }
  $(".js-table-people-staff").show();
  if (
    localStorage['peopleMangaSort'] == 'people-manga-alphabetical' ||
    localStorage['peopleMangaSort'] == 'people-manga-mostrecent' ||
    localStorage['peopleMangaSort'] =='people-manga-startingworks' ||
    localStorage['peopleMangaSort'] =='people-manga-popularity' || 
    localStorage['peopleMangaSort'] =='people-manga-score'
  ) {
    peopleMangaSortEvent(localStorage['peopleMangaSort']);
  } else {
    peopleMangaSortEvent('people-manga-popularity');
  }
  $(".js-table-people-manga").show();
});
