import * as types from "./mutation-types"
import {createBook} from "../../../../common/modules/book"
import {appendTax} from "../../../../common/modules/tax"

export default {
  [types.INITIALIZE](state, {item, loginPath, currency, paymentSettings, termsHtml, supportUrl}) {
    state.loginPath = loginPath
    state.currency = currency
    state.item = createBook(item)

    // Paymentの各種設定情報
    state.payment = {
      defaultPaymentMethod: paymentSettings.defaultPaymentMethod,
      initialPaymentMethod: paymentSettings.initialPaymentMethod,
      registeredCard: paymentSettings.registeredCard ? {
        type: paymentSettings.registeredCard.type,
        digits: paymentSettings.registeredCard.digits,
        isExpired: paymentSettings.registeredCard.is_expired,
        isUsedForMalSupporter: paymentSettings.registeredCard.is_used_for_mal_supporter,
      } : null,
      braintreeRegistrationDatetime: paymentSettings.braintreeRegistrationDatetime,
    }
    state.termsHtml = termsHtml
    state.supportUrl = supportUrl
  },

  [types.READ](state) {
    window.open(state.item.viewerUrl)
  },

  [types.SET_TAX](state, taxes) {
    state.item = appendTax(state.item, taxes)
  },

  /**
   * item を購入完了した
   * @param state
   */
  [types.PURCHASED](state) {
    state.item.isPossessed = true
  },

  /**
   * Modal の表示非表示系
   */
  [types.SHOW_PAYMENT_MODAL](state) {
    state.modals.showPaymentModal = true
  },
  [types.HIDE_PAYMENT_MODAL](state) {
    state.modals.showPaymentModal = false
  },
}
