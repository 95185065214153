<template>
  <a class="button" target="_blank"
    @click="click"
    :class="{ 'is-mobile': isMobile, 'is-disable':isDisable }"
    :style="buttonStyle"
  >Read</a>
</template>

<style lang="scss" scoped>
  $text-color       : #2E51A2;
  $background-color : #fff;
  $border-color     : #2E51A2;
  $disable-color    : #bdbdbd;
  .button {
    display: block;
    background-color: $background-color;
    color: $text-color;
    text-align: center;
    border: 2px solid $border-color;
    border-radius: 4px;
    font-weight: bold;
    vertical-align: middle;
    box-sizing: border-box;
    cursor: pointer;
    padding-bottom: 1px;
    font-family: Verdana,Arial;
    text-decoration: none;
    &:hover {
      color: $text-color;
      background-color: #e1e7f5;
    }
    &.is-mobile{
      font-family: Avenir,"lucida grande",tahoma,verdana,arial,sans-serif;
    }
    &.is-disable{
      color: $disable-color;
      border-color:  $disable-color;
    }
  }
</style>

<script type="text/babel">
  export default {
    props: {
      url         : String,
      isDisable   : Boolean,
      isMobile    : Boolean,
      width       : Number,
      height      : Number,
      price       : Number,
      isSmall     : Boolean,
    },
    computed: {
      buttonStyle() {
        // マンガ詳細画面のボタン用
        if (this.isSmall) {
          return {
            width          : this.width ? `${this.width}px` : '100%',
            height         : this.height ? `${this.height}px` : 'auto',
            lineHeight     : this.height ? `${this.height - (this.isMobile ? 1 : 2 ) }px` : 'nomal',
            fontSize       : '10px',
            fontWeight     : 'normal',
            borderRadius   : '2px',
            borderWidth    : '1px',
            textDecoration : 'none',
          }
        }

        let font_size
        if (this.isMobile) {
          font_size = (this.height >= 26) ? '14px' : '10px'
        } else {
          font_size = this.height >= 42 ? '16px' : ( (this.height < 24 || this.width < 105) ? '12px' : '14px')
        }
        return {
          width      : this.width ? `${this.width}px` : '100%',
          height     : this.height ? `${this.height}px` : 'auto',
          lineHeight : this.height ? `${this.height - (this.isMobile ? 2 : 3 ) }px` : 'nomal',
          fontSize   : font_size,
        }
      },
    },
    methods: {
      click() {
        this.$emit('read')
      }
    },
  }
</script>
