var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loading-modal", {
        attrs: { "is-shown": _vm.modals.showLoadingModal }
      }),
      _vm._v(" "),
      _c("select-address-modal", {
        attrs: {
          "is-shown": _vm.modals.showSelectAddressModal,
          "residential-area": _vm.residentialArea,
          "is-requesting": _vm.isRequesting,
          error: _vm.error
        },
        on: { select: _vm.selectAddress, cancel: _vm.cancelSelectAddressModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }