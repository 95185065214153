<template>
  <div class="root">
    <transition name="modal">
      <div v-show="isShown" class="modal-content">
        <div class="modal-mask" @click="cancel"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <div class="modal-title">{{ title }}</div>
              <div class="modal-table">
                <div class="modal-price-wrap">
                  <ul>
                    <li><div>Price</div>{{ currency.formatSymbolAndPrice(totalPriceWithoutTax) }}</li>
                    <li><div>Tax</div>{{ currency.formatSymbolAndPrice(totalTax) }}</li>
                    <li class="modal-price">
                      <div>Total</div>{{ currency.symbol }}<span>{{ currency.formatPrice(totalPrice) }}</span>{{ currency.name }}
                    </li>
                  </ul>
                </div>
                <div class="modal-button-set-wrap">
                  <template v-if="!isRequesting">
                    <div class="modal-button-wrap">
                      <a class="modal-cancel-button" @click="cancel">Cancel</a>
                      <a class="modal-confirm-button margin-l10" v-show="!isRequesting" @click="$emit('confirm')">Confirm Purchase</a>
                    </div>
                  </template>
                  <template v-else>
                    <div class="modal-button-wrap">
                      <span class="modal-button-loading"></span>
                    </div>
                  </template>
                  <div class="modal-error" v-show="error">{{ error }}</div>
                  <div class="modal-payment-method-button-wrap">
                    <a v-if="!isRequesting"
                       class="modal-payment-method-button" href="/account/payment">
                      <i class="fa-solid fa-gear" aria-hidden="true"></i> Edit Payment Method
                    </a><br>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-list">
              <div>
                <div class="modal-list-item" v-for="item in items">
                  <div>
                    <img :src="coverImageUrl(item.coverImageBaseUrl)"
                      width="46" height="auto"
                      class="modal-list-cover margin-r10">
                  </div>
                  <div class="modal-list-info">
                    <div class="modal-list-volume"
                         :class="{ 'icon-new-item': item.isNew }"
                    >{{ item.numberingText }}</div>
                    <div class="modal-list-price">
                      <template v-if="item.salePrice">
                        <span class="is-sale">{{ currency.formatSymbolAndPrice(item.price) }}</span> Sale
                        <span class="modal-price-sale">{{ currency.formatSymbolAndPrice(item.salePrice) }}</span>
                      </template>
                      <template v-else>
                        {{ currency.formatSymbolAndPrice(item.price) }}
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="modal-close" @click="cancel">Close</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  @import "../../../../../css/variables";

  $text-price-color:$color-pc-text-be3a47;
  $text-volume-color:$color-pc-text-7b7d82;
  $button-payment-color:$color-bg-store-purchase-button;
  $button-cancel-color:$color-pc-text-2e51a2;
  $button-disable-color:$color-pc-text-999999;
  $border-color:$color-pc-border-ebebeb;
  $border-light-color:$color-pc-border-e5e5e5;
  $border-cover-color:$color-pc-border-e1e7f5;
  $header-height:147px;
  $modal-height:600px;
  $modal-height-s:400px;
  $modal-width:606px;
  $modal-side-padding:40px;
  img {
    vertical-align: bottom;
  }
  .modal-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
  }
  .modal-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color-pc-bg-000000, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    font-family: Verdana,Arial;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    width: $modal-width;
    height: $modal-height;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: $color-pc-bg-ffffff;
    color: $color-pc-text-353535;
    border-radius: 4px;
    transition: all .3s ease;
    @media screen and (max-height: $modal-height) {
      height: $modal-height-s;
    }
  }
  .modal-header {
    display: block;
    border-bottom: 1px solid $border-light-color;
  }
  .modal-title {
    width: 100%;
    box-sizing: border-box;
    padding: 18px $modal-side-padding;
    line-height: 18px;
    font-size: 16px;
    font-weight: 700;
    word-wrap: break-word;
  }
  .modal-table {
    display: flex;
    padding: 0 $modal-side-padding 20px;
    width: 100%;
    box-sizing: border-box;
  }
  .modal-price-wrap {
    flex: 1;
    flex-grow: 1;
    font-weight: 700;
    font-size: 16px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    li {
      & > div {
        display: inline-block;
        width: 50px;
        padding-bottom: 4px;
        font-weight: 300;
      }
      &:last-child {
        & > div {
          padding-bottom: 0;
        }
      }
    }
  }
  .modal-price {
    color: $text-price-color;
    padding-top: 6px;
    span {
      font-size: 30px;
    }
  }
  .modal-taxamo {
    background: url(/images/manga_store/taxamo_logo@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    display: inline-block;
    width: 64px;
    height: 12px;
    margin-top: 16px;
    text-indent: -9999px;
    overflow: hidden;
  }
  .modal-payment-method-button-wrap {
    margin-top: 12px;
    text-align: right;
  }
  a.modal-payment-method-button {
    color: $color-pc-text-323232;
    text-decoration: underline;
    &:hover {
    text-decoration: none;
    }
  }
  .modal-button-set-wrap {
    position: absolute;
    right: 40px;
  }
  .modal-button-wrap {
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }
  %button {
    font-weight: 700;
    font-size: 14px;
    width: 150px;
    padding: 7px 0;
    display: block;
    cursor: pointer;
    text-align: center;
    line-height: 16px;
    border-radius: 3px;
    white-space: nowrap;
    &.is-disable{
      cursor: auto;
      color: $button-disable-color;
      border: 2px solid $button-disable-color;
    }
  }
  a.modal-edit-payment-method-button {
    font-size: 14px;
    color: $color-pc-text-323232;
    text-decoration: underline;
    &:hover {
    text-decoration: none;
    }
  }
  .modal-cancel-button {
    color: $button-cancel-color;
    border: 2px solid $button-cancel-color;
    @extend %button;
    &:hover {
      color: $button-cancel-color;
      background-color: $color-pc-bg-e1e7f5;
      text-decoration: none;
    }
  }
  .modal-confirm-button {
    background-color: $button-payment-color;
    color: $color-white;
    border: 2px solid $button-payment-color;
    @extend %button;
    opacity: 1;
    &:hover {
      color: $color-white;
      text-decoration: none;
      opacity: 0.7;
    }
  }
  .modal-button-loading {
    display: block;
    width: 254px;
    height: 44px;
    background: url(/images/xmlhttp-loader.gif?v=161115001) no-repeat center center;
    background-size: 16px;
    vertical-align: middle;
    text-indent: -99999px;
  }

  .modal-list {
    flex: 1;
    flex-grow: 1;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .modal-list-item {
    margin: 0;
    padding: 10px $modal-side-padding;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid $border-light-color;
    &:last-child{
      border-bottom: none;
    }
    &.is-hold{
      color: $color-pc-text-cccccc;
    }
  }
  .modal-list-cover {
    box-sizing: border-box;
    border: 1px solid  $border-cover-color;
  }
  .modal-list-cover-grayscale {
    width: 46px;
  }
  .modal-list-info{
    width: 435px + 34px;
    display: flex;
    flex-direction: column;
  }
  .modal-list-volume {
    font-size: 12px;
    font-weight: 700;
    flex: 1;
    flex-grow: 1;
    .is-hold &{
      color: $color-pc-text-cccccc;
    }
    &.icon-new-item {
      position: relative;
      padding-left: 36px;
      &:after {
        position: absolute;
        top: 1px;
        left: 0;
        background-color: $color-pc-bg-c0392b;
        display: inline-block;
        padding: 2px 4px;
        border-radius: 2px;
        color: $color-pc-text-ffffff;
        font-size: 10px;
        font-weight: 300;
        line-height: 1.0;
        content: "New";
      }
    }
  }
  .modal-list-price {
    font-size: 14px;
    color: $text-price-color;
    .is-sale {
      color: $color-pc-text-787878;
      font-size: 12px;
      font-weight: 300;
      text-decoration: line-through;
    }
    .modal-price-sale {
      font-weight: 700;
    }
  }
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    content: '';
    display: block;
    width: 29px;
    height: 29px;
    background: url(/images/manga_store/pc/modal_close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
    cursor: pointer;
  }
  .margin-l10{
    margin-left: 10px;
  }
  .margin-r10{
    margin-right: 10px;
  }
  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .modal-error {
    display: block;
    max-width: 254px;
    margin-top: 12px;
    color: $color-pc-text-ff6a86;
    text-align: left;
  }

  .dark-mode {
    @import "../../../../../css/colors_dark";

    $text-price-color:$color-pc-text-be3a47;
    $text-volume-color:$color-pc-text-7b7d82;
    $button-cancel-color:$color-pc-text-2e51a2;
    $button-disable-color:$color-pc-text-999999;
    $border-color:$color-pc-border-ebebeb;
    $border-light-color:$color-pc-border-e5e5e5;
    $border-cover-color:$color-pc-border-e1e7f5;
    $header-height:147px;
    $modal-height:600px;
    $modal-height-s:400px;
    $modal-width:606px;
    $modal-side-padding:40px;

    .modal-mask {
      background-color: rgba($color-pc-bg-000000, .5);
    }

    .modal-container {
      background-color: $color-pc-bg-ffffff;
      color: $color-pc-text-353535;
    }
    .modal-header {
      border-bottom: 1px solid $border-light-color;
    }
    .modal-price {
      color: $text-price-color;
    }
    a.modal-payment-method-button {
      color: $color-pc-text-323232;
      &:hover {
      text-decoration: none;
      }
    }
    %button {
      &.is-disable{
        color: $button-disable-color;
        border: 2px solid $button-disable-color;
      }
    }
    a.modal-edit-payment-method-button {
      color: $color-pc-text-323232;
      &:hover {
      text-decoration: none;
      }
    }
    .modal-cancel-button {
      color: $button-cancel-color;
      border: 2px solid $button-cancel-color;
      @extend %button;
      &:hover {
        color: $button-cancel-color;
        background-color: $color-pc-bg-e1e7f5;
        text-decoration: none;
      }
    }
    .modal-list-item {
      border-bottom: 1px solid $border-light-color;
      &.is-hold{
        color: $color-pc-text-cccccc;
      }
    }
    .modal-list-cover {
      border: 1px solid  $border-cover-color;
    }
    .modal-list-volume {
      .is-hold &{
        color: $color-pc-text-cccccc;
      }
      &.icon-new-item {
        &:after {
          background-color: $color-pc-bg-c0392b;
          color: $color-pc-text-ffffff;
        }
      }
    }
    .modal-list-price {
      color: $text-price-color;
      .is-sale {
        color: $color-pc-text-787878;
      }
    }
    .modal-error {
      color: $color-pc-text-ff6a86;
      text-align: left;
    }

  }

</style>

<script type="text/babel">
  import {generateCoverImageUrl} from "../../common/modules/book"

  export default {
    props: {
      isShown: Boolean,
      title: String,
      items: Array,
      currency: Object,
      totalPrice: Number,
      error: String,
      isRequesting: Boolean,
      paymentMethod: String,
      totalPriceWithoutTax: Number,
      totalTax: Number,
      isDelegated: Boolean
    },
    methods: {
      coverImageUrl(coverImageBaseUrl) {
        return generateCoverImageUrl(coverImageBaseUrl)
      },
      cancel() {
        if (!this.isRequesting) {
          this.$emit('cancel')
        }
      },
    },
  }
</script>
