import Cookies from "js-cookie"
const axios = window.axios

$(function() {
  let closed = []
  if (Cookies.get("text_ribbon")) {
    closed = JSON.parse(Cookies.get("text_ribbon"))
  }

  let isSp = $("body.sp").length > 0
  let shownCount = 0

  $(".text-ribbon").each(function(i, e) {
    let id = $(e).data("id")
    if (isSp) {
      $(e).insertBefore('.page-title')
      $(e).insertBefore('.page-index .content-main')
    }
    if (!closed.find(value => value === id) && shownCount === 0) {
      $(e).css("display", "block")
      $(e).css("max-height", "10em")
      shownCount++
    }
  })

  $(".text-ribbon .js-close-button").click(function() {
    let e = $(this).parent().parent().css("max-height", "0")
    $(e).css("max-height", "0")
    setTimeout(function() {
      $(e).css("display", "none")
    }, 300)

    closed.push($(e).data("id"))
    axios.post("/cookie/set", {
      key: "text_ribbon",
      value: JSON.stringify(closed),
      expires: 30
    })
  })
})
