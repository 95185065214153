<template>
  <div class="item"
    :style="{ width : width ? ( width + 'px') : 'auto' }"
  >
    <div class="cover-wrap"
      :class="{iconNew: !item.isPossessed && item.isNew}"
      @mouseover="mouseOver"
      @mouseout="mouseOut"
    >
      <a v-if="isLazyload"
        v-lazy:background-image="coverImage"
        class="cover-image"
        :class="{hasButton: item.isPossessed || item.isFree || item.isPreviewable}"
        :href="isTablet ? viewerUrlWithLocation : null"
        target="_blank"
      ></a>
      <a v-if="!isLazyload"
        :style="{ backgroundImage: `url(${coverImage.src})` }"
        class="cover-image"
        :class="{hasButton: item.isPossessed || item.isFree || item.isPreviewable}"
        :href="isTablet ? viewerUrlWithLocation : null"
        target="_blank"
      ></a>
      <button-sample
        v-show="!item.isPossessed && item.isPreviewable && isHover && !item.isFree"
        :url="urlWithLocation(item.previewUrl, location)"
        :is-mobile="false"
        :height="24"
        :width="72"
        :is-border="true"
        class="button">
      </button-sample>
      <button-read
        v-show="(item.isPossessed || item.isFree)  && isHover"
        @read="read"
        :is-mobile="false"
        :height="24"
        :width="72"
        :is-border="true"
        class="button">
      </button-read>
    </div>

    <template v-if="item.isPossessed || item.isFree">
      <div v-if="hideTitle !== true" class="title-wrap" :style="{ width : width ? ( (width - 20) + 'px') : 'auto' }">
        <div class="title" ref="ellipsis">{{ item.title }}</div>
      </div>
      <div class="volume">{{ item.numberingText }}</div>
    </template>

    <template v-if="!item.isPossessed && !item.isFree">
      <div v-if="hideTitle !== true" class="title-wrap" :style="{ width : width ? ( (width - 20) + 'px') : 'auto' }">
        <div class="title" ref="ellipsis">{{ item.title }}</div>
      </div>
      <div class="volume purchase-button" v-if="showPurchaseButton" @click="click">
        <i class="fa-solid fa-cart-shopping" aria-hidden="true"></i> Buy {{ item.numberingText }}
      </div>
      <div class="volume" v-if="!showPurchaseButton">{{ item.numberingText }}</div>
      <div class="price-wrap" v-if="!item.isPossessed">
        <span class="price" :class="{'is-sale': item.salePrice >= 0}">{{ currency.formatSymbolAndPrice(item.price) }}</span>
        <span class="price-sale" v-show="item.salePrice >= 0">{{ currency.formatSymbolAndPrice(item.salePrice) }}</span>
      </div>
    </template>

  </div>
</template>

<style lang="scss" scoped>
  $title-color : #323232;
  $volume-color : #7a7a7a;
  $price-color : #C0392B;
  $price-sale-color : #FF0000;

  /* Style */
  .item {
    display: table-cell;
    text-align: center;
    padding: 0 10px;
    font-family: Verdana,Arial;
    box-sizing: border-box;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    a {
      text-decoration: none;
    }
  }
  .cover-wrap {
    position: relative;
    display: inline-block;
    height: 142px;
    text-decoration: none;
    margin-bottom: 4px;
    &.iconNew:after {
      position: absolute;
      top: 1px;
      left: 1px;
      background-image: url('/images/manga_store/icon_new.png');
      background-size: 47px 47px;
      background-position: left top;
      background-repeat: no-repeat;
      display: inline-blockl;
      width: 47px;
      height: 47px;
      content: "";
    }
  }
  .cover-image {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 140px;
    width: 95px;
    box-sizing: border-box;
    vertical-align: bottom;
    transition-duration: .3s;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    opacity: 1;
    &.hasButton:hover {
      opacity: 0.8;
    }
  }
  .cover-image[lazy=loading] {
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    .cover-image {
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
    }
  }
  .button {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50% ,-50%);
  }
  .title-wrap {
    margin: 2px 0 0;
  }
  .title {
    color: $title-color;
    font-size: 12px;
    text-align: left;
    text-decoration: none;
    word-wrap:break-word;
    line-height: 14px;
    overflow: hidden;
    max-height: (14 * 2)px;
  }
  .volume {
    margin: 2px 0 0;
    color: $volume-color;
    font-size: 12px;
    text-align: left;
    &.button {

    }
  }
  .price-wrap {
    margin: 2px 0 0;
    font-size: 12px;
    text-decoration: none;
    text-align: left;
  }
  .price {
    color: $price-color;
    &.is-sale {
      font-size: 12px;
      color: #7a7a7a;
      text-decoration: line-through;
    }
  }
  .price-sale {
    color: $price-sale-color;
    font-weight: 700;
  }
  .purchase-button {
    padding: 0;
    color: #2E51A2;
    font-size: 11px;
    outline: 0;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

</style>

<script type="text/babel">
  import * as book from "../../common/modules/book"
  import ButtonSample from "../../common/atoms/manga_store/ButtonSample.vue"
  import ButtonRead from "../../common/atoms/manga_store/ButtonRead.vue"

  export default {
    components: {
      ButtonSample,
      ButtonRead,
    },
    mounted() {
      if (this.hideTitle !== true) {
        let elementEllipsis = $(this.$refs.ellipsis)
        let wordEllipsis = elementEllipsis.html()
        let cloneEllipsis = elementEllipsis.clone()
        const heightEllipsis = elementEllipsis.height()

        cloneEllipsis.css({
          'opacity'   : '0',
          'position'  : 'absolute',
          'overflow'  : 'visible',
          'max-height': 'none',
          'height'    : 'auto'
        })
        .width(elementEllipsis.width())
        .height('auto')

        elementEllipsis.after(cloneEllipsis)

        while((wordEllipsis.length > 0) && (cloneEllipsis.height() > heightEllipsis)) {
          wordEllipsis = wordEllipsis.substr(0, wordEllipsis.length - 1)
          cloneEllipsis.html(wordEllipsis + '...')
        }

        elementEllipsis.html(cloneEllipsis.html())
        cloneEllipsis.remove()
      }
    },
    props: {
      /**
       * @param {String} item.title
       * @param {String} item.numberingText
       * @param {String} item.url
       * @param {String} item.previewUrl
       * @param {String} item.thumbnail
       * @param {Boolean} item.isPurchased
       * @param {Number} item.price
       * @param {Number} item.salePrice
       * @param {String} currency.symbol
       * @param {String} currency.name
       * @param {String} currency.fa
       * @param {String} location
       */
      item: Object,
      currency: Object,
      width: Number,
      loginPath: String,
      isLazyload: {
        type: Boolean,
        default: false,
      },
      hideTitle: {
        type: Boolean,
        default: false,
      },
      location: String,
    },
    data() {
      return {
        isHover: false,
      }
    },
    methods: {
      mouseOut() {
        this.isHover = false
      },
      mouseOver() {
        this.isHover = true
      },
      click() {
        this.$emit('purchase')
      },
      read(){
        if (this.item.isPossessed || !this.item.accessLimit) {
          window.open(this.item.viewerUrl)
        } else {
          this.$emit('accessLimit', this.item)
        }
      },
    },
    computed: {
      coverImage() {
        const imageSize = (window.devicePixelRatio > 1) ? book.COVER_IMAGE_SIZE_M_2X : book.COVER_IMAGE_SIZE_M
        return {
          src: book.generateCoverImageUrl(this.item.coverImageBaseUrl, imageSize),
          loading: "/images/xmlhttp-loader.gif?v=171101"
        }
      },
      viewerUrlWithLocation() {
        if (this.item.isPossessed) {
          return this.urlWithLocation(this.item.viewerUrl, this.location)
        } else {
          if (this.item.isPreviewable) {
            return this.urlWithLocation(this.item.previewUrl, this.location)
          }
        }
        return null
      },
      isTablet() {
        return ("ontouchstart" in window)
      },
      showPurchaseButton() {
        return (!this.item.isPossessed && !this.loginPath) ? true : false
      },
    },
  }
</script>
