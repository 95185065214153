var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFinishModal,
                expression: "showFinishModal"
              }
            ],
            staticClass: "modal-content"
          },
          [
            _c("div", { staticClass: "modal-mask", on: { click: _vm.cancel } }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-wrapper" }, [
              _c("div", { staticClass: "modal-container" }, [
                _c("h3", { staticClass: "modal-title" }, [
                  _c("i", { staticClass: "fas fa-gift" }),
                  _vm._v("Thank you")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-inner" }, [
                  _c("div", [
                    _c("p", [
                      _vm._v("Your purchase is ready to be gifted!"),
                      _c("br"),
                      _vm._v(
                        "\n                Send it to a friend or use it on your account."
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "a",
                        {
                          staticClass: "mal-btn primary large ga-click",
                          attrs: {
                            "data-ga-click-type":
                              "membership-payment-gift-button-go_to_purchase_gift",
                            "data-ga-click-param":
                              "product:" + _vm.gift.gift_id,
                            href: "/account/membership/purchasedgifts"
                          }
                        },
                        [_c("b", [_vm._v("Go To Purchased Gifts")])]
                      )
                    ])
                  ])
                ])
              ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }