const $ = window.$
const sVue = window.sVue

$(() => {
  if ($("#club-mass-message-subscribe").exists()) {
    const subEl = "#club-mass-message-subscribe"
    const subData = $(subEl).data("subscribe")
    subData.sending = false
    subData.msg = undefined
    subData.isError = undefined

    const vSub = new sVue({
      el: subEl,
      data: subData,
      methods: {
        toggleSubscription() {
          updateSubscribe(!this.isSubscribed, (isSubscribe) => {
            this.isError = false
            this.isSubscribed = isSubscribe
            this.msg = "Successfully updated!"
          })
        },
        beforeSend() {
          this.sending = true
          this.msg = undefined
        },
        afterSend() {
          this.sending = false
        },
        showErrorMsg(err) {
          this.isError = true
          this.msg = err
        },
      }
    })

    const updateSubscribe = (isSubscribe, onSuccess) => {
      $.ajax({
        url: subData.url,
        method: "PUT",
        data: {is_subscribe: isSubscribe? 1:0},  // eslint-disable-line camelcase
        dataType: "json",
        beforeSend: () => {
          vSub.beforeSend()
        }
      })
      .done(() => {
        onSuccess(isSubscribe)
      })
      .fail((xhr) => {
        vSub.showErrorMsg(xhr.responseJSON.errors[0].message)
      })
      .always(() => {
        vSub.afterSend()
      })
    }
  }
})
