/*
A: elRelative
B: elFixed

## Aの方が大きい場合

-----+----
  A  | B
     +----
     |

* Bの下端を過ぎると、Bは position: fixed で下部固定 (bottom分スペースが空く)
* Aの下端を過ぎると、BはA下部に position: absolute で固定 (bottom分スペース空く)

## Bの方が大きい場合

-----+----
  A  | B
-----+
     |

* 特に何もしない
*/

const _ = window._;

export default function scrollfixAtBottom(opts) {
  opts = _.assign({
    // 固定対象
    elFixed: document.querySelector(".js-scrollfix-bottom"),
    // 固定対象と相対のコンテンツ
    elRelative: document.querySelector(".js-scrollfix-bottom-rel"),
    // 下スペース [px]
    bottom: 10,
    // scroll処理を間引く時間 [ms]
    throttle: 50,
    // デバッグモード
    debug: false
  }, opts);

  if (!opts.elFixed || !opts.elRelative) {
    return;
  }

  if (opts.debug) {
    opts.elFixed.style.backgroundColor = "red";
    opts.elRelative.style.backgroundColor = "red";
  }

  const STATE_SCROLL              = 1;
  const STATE_FIXED_AT_BOTTOM     = 2;
  const STATE_BOTTOM_OF_CONTAINER = 3;

  let state;
  function setState(newState) {
    if (state === newState) {
      return;
    }
    state = newState;
    switch (state) {
      case STATE_SCROLL: {
        _.assign(opts.elFixed.style, {
          position: "static",
          bottom: null
        });
        break;
      }
      case STATE_FIXED_AT_BOTTOM: {
        _.assign(opts.elFixed.style, {
          position: "fixed",
          bottom: `${opts.bottom}px`
        });
        break;
      }
      case STATE_BOTTOM_OF_CONTAINER: {
        _.assign(opts.elFixed.style, {
          position: "absolute",
          bottom: `${opts.bottom}px`
        });
        break;
      }
    }
  }

  // コンテンツ領域は画像ロードやAjaxで高さが変わる可能性があるので、
  // 上端だけ最初に取得しておき、高さは毎回取得するようにする。
  const t1 = window.pageYOffset + opts.elFixed.getBoundingClientRect().top;
  const t2 = window.pageYOffset + opts.elRelative.getBoundingClientRect().top;
  const wrapperEle = document.querySelector(".wrapper");

  window.addEventListener("scroll", _.throttle(() => {
    // ブラウザ幅がコンテンツよりも狭い場合は無効にしたいのでコンテンツの位置を取得
    const contentLeft = wrapperEle.getBoundingClientRect().left;

    // scroll位置を取得したいのでwindowの高さを引く。
    // 性能を追求するならt1,t2で引いておくのがベストだが可読性下がるのでとりあえずここで。
    const y1 = t1 + opts.elFixed.clientHeight + opts.bottom - window.innerHeight;
    const y2 = t2 + opts.elRelative.clientHeight - window.innerHeight;
    if (y1 >= y2) {
      return setState(STATE_SCROLL);
    }
    if (window.pageYOffset > y2 && contentLeft >= 0) {
      setState(STATE_BOTTOM_OF_CONTAINER);
    } else if (window.pageYOffset > y1 && contentLeft >= 0) {
      setState(STATE_FIXED_AT_BOTTOM);
    } else {
      setState(STATE_SCROLL);
    }
  }, opts.throttle));
}
