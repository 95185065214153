<!--
Stripe のカード情報入力フォームのコンポーネント
Note: コレ使うには stripe.js をグローバルにロードしておく必要があります
-->

<template>
  <div>
    <!-- Adblock 対策 -->
    <div v-if="isBlockAdBlock" class="payment-adblock-error-message">
      There is a possibility that your adblocker is preventing the credit card entry form from displaying.<br>
      Please disable your adblocker temporarily and reload the page.
    </div>

    <div class="creditcard-information">
      <div ref="card-element" id="card-element">
        <!-- Stripe.js でここにカード情報入力フォームがインジェクトされます -->
      </div>
      <div role="alert">
        {{ error }}
      </div>
    </div>

    <div class="creditcard-note di-ib">
      Your credit card information will not be seen nor stored on MyAnimeList's servers.<br>
      All payments are processed using <a class="ga-click" data-ga-click-type="membership-payment-link-stripe" href="https://stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a>.
      Their service is safe, secure and
      <a class="ga-click" data-ga-click-type="membership-payment-link-stripe_pcidss" href="https://www.pcisecuritystandards.org/pci_security/" target="_blank" rel="noopener noreferrer">PCI DSS</a>
      compliant.<br>
    </div>

  </div>
</template>

<style lang="scss" scoped>
</style>

<script type="text/babel">
  let stripe = null

  export default {
    data() {
      return {
        cardElement: null,
        error: null,
      }
    },
    props: {
      isBlockAdBlock: Boolean,
    },
    mounted() {

      if (!stripe) {
        stripe = Stripe($(`meta[name="stripe-publishable-key"]`).attr("content"))
      }

      const elements = stripe.elements()
      // style
      const isMobile = "ontouchend" in window
      let style = {}
      if (isMobile) {
        // for SP
        style = {
          base: {
            fontSmoothing: "antialiased",
            fontSize: "14px",
            "::placeholder": {
              color: "#bebebe"
            }
          },
          invalid: {
            color: "#b25959",
            iconColor: "#C0392B"
          }
        }
      } else {
        // for PC
        let customizedColor = "#000000"
        let customizedIconColor = "#515e80"
        let customizedIconColorComplete = "#6772e5"
        let customizedColorInvalid = "#b25959"
        let customizedIconColorInvalid = "#c0392b"
        // for Dark Mode
        if ($("html").hasClass("dark-mode")) {
          customizedColor = "#e0e0e0"
          customizedIconColor = "#ffffff"
          customizedIconColorComplete = "#8eb1ff"
          customizedColorInvalid = "#d98080"
          customizedIconColorInvalid = "#ff8f81"
        }
        style = {
          base: {
            fontSmoothing: "antialiased",
            fontSize: "16px",
            color: customizedColor,
            iconColor: customizedIconColor,
            "::placeholder": {
              color: "#bebebe"
            }
          },
          complete: {
            iconColor: customizedIconColorComplete
          },
          invalid: {
            color: customizedColorInvalid,
            iconColor: customizedIconColorInvalid
          }
        }
      }
      // create card element
      this.cardElement = elements.create("card", {
        hidePostalCode: true,
        style: style,
      })
      this.cardElement.mount(this.$refs["card-element"])
      this.cardElement.addEventListener("change", event => {
        if (event.error) {
          this.error = event.error.message
        } else {
          this.error = null
        }
      })
    },
    methods: {
      async fetchToken() {
        const {token, error} = await stripe.createToken(this.cardElement);

        if (error) {
          this.error = error.message
          return null
        }

        return token.id
      },
    }
  }
</script>
