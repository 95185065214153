<template>
  <div class="maraginals-root">
    <header class="header" :class="{'hide': !show}">
      <span class="logo">MyAnimeList</span>
      <a v-if="!isPreview && !isFree" class="button-story" @click="showStoryModal">Synopsis</a>
      <a v-if="isPreview" class="button-buy" @click="$emit('purchase')">Buy</a>
      <a v-if="customButton.type === 1" class="button-custom" :style="{ backgroundColor: customButton.backgroundColor, color: customButton.color, border: '2px solid' }" @click="custom(customButton.url)">{{ customButton.text }}</a>
      <a v-if="customButton.type === 2" class="button-custom" v-lazy:background-image="customButton.backgroundImage" style="width: 80px; background-size: contain; background-repeat: no-repeat;" @click="custom(customButton.url)"></a>
      <div class="title-wrap">
        <span class="title">{{ book.title }}</span>
        <span class="volume">{{ book.numberingText }}</span>
      </div>
      <a class="button-close" v-show="windowOpener" @click="close">Close</a>
    </header>
    <footer class="footer" :class="{'hide': !show}">
      <div class="zoom-wrap">
        <!-- <span class="zoom">Zoom</span> -->
        <a class="button-zoom-out" :class="{ 'is-disable': zoomScale < 1.25 }" @click="zoom( -0.25 )">-</a>
        <span class="zoom-percent">{{ Math.round( zoomScale * 100 ) }}</span>
        <a class="button-zoom-in" :class="{ 'is-disable': zoomScale >= 2 }" @click="zoom( 0.25 )">+</a>
      </div>
      <div class="facing-page-warp">
        <a class="button-facing-page-single" :class="{ 'is-on': !isFacingPage }" @click="facingPageSingle"> </a>
        <a class="button-facing-page-double" :class="{ 'is-on': isFacingPage }" @click="facingPageDouble"> </a>
      </div>
      <a class="button-tutorial" @click="showTutorialModal">Help</a>
      <div class="pagecount">
        <span class="pagecount-current">{{ currentPage }}</span>
        <span class="pagecount-total">/{{ numTotalPages }}</span>
      </div>
      <seek-bar
        :num-total-pages="numTotalPages"
        :current-page="currentPage"
        :is-facing-page="isFacingPage"
        :manuscript="manuscript"
        :page-direction="pageDirection"
      ></seek-bar>
      <span class="button-direction"
         @click="changeDirection"
         :class="{opposite: switchDirection}">
        Direction
      </span>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
  span {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .maraginals-root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .zoom-wrap {
    position: absolute;
    right: 20px;
    top: 17px;
    vertical-align: middle;
    font-size: 16px;
    @media screen and (max-width: 860px) {
      font-size: 13px;
    }
  }
  .zoom {
    color: #C5CDDB;
    line-height: 16px;
    display: inline-block;
    vertical-align: top;
  }
  .zoom-percent {
    line-height: 16px;
    display: inline-block;
    vertical-align: top;
    &::after{
      content: '%';
      font-size: 12px
    }
  }
  .button-zoom-in {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(/images/manga_store/viewer/pc/zoom_in@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    text-indent: -99999px;
    cursor: pointer;
    vertical-align: top;
    &.is-disable{
      opacity: 0.2;
      cursor: auto;
    }
  }
  .button-zoom-out {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(/images/manga_store/viewer/pc/zoom_out@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    text-indent: -99999px;
    cursor: pointer;
    vertical-align: top;
    &.is-disable{
      opacity: 0.2;
      cursor: auto;
    }
  }
  .button-direction {
    position: absolute;
    top: 12px;
    right: 236px;
    content: '';
    display: block;
    cursor: pointer;
    width: 28px;
    height: 28px;
    background: url(/images/manga_store/viewer/pc/direction@2x.png?v=161130001) no-repeat left top;
    background-size: auto 21px;
    background-position: left center;
    vertical-align: top;
    text-indent: -99999px;
    &.opposite {
      background-position: right center;
    }
  }
  .header,.footer {
    font-family: Verdana,Arial;
  }
  .title-wrap {
    margin: 10px 280px 0;
    line-height: 30px;
    display: block;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    margin: 10px 60px;
    position: relative;
  }
  .title {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    //追加分
    width: 610px;
    @media screen and (max-width: 1060px) {
      width: 560px;
    }
    @media screen and (max-width: 900px) {
      width: 430px;
    } 
    @media screen and (max-width: 750px) {
      width: 320px;
    }
  }
  .volume {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 10px;
    //追加分
    position: absolute;
    right: 10px;
  }
  .logo {
    position: absolute;
    top: 15px;
    left: 20px;
    width: 129px;
    height: 22px;
    display: block;
    background: url(/images/manga_store/viewer/pc/logo@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    text-indent: -99999px;
    vertical-align: top;
    //追加分
    display: none;
  }
  .button-story {
    position: absolute;
    top: 10px;
    left: 20px;
    background: url(/images/manga_store/viewer/pc/icon-info-circle.png?v=161115001) no-repeat left top;
    background-size: 29px;
    display: inline-block;
    height: 29px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    text-indent: 36px;
    overflow: hidden;
    z-index: 10;
    cursor: pointer;
  }
  .button-buy {
    position: absolute;
    top: 10px;
    left: 20px;
    background-color: #ffffff;
    display: inline-block;
    padding: 0 20px;
    border: 2px solid #ff9600;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
    color: #ff9600;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    height: 29px;
    overflow: hidden;
    z-index: 10;
    &:hover {
      background-color: #ffead3;
    }
  }
  .button-custom {
    position: absolute;
    top: 10px;
    left: 20px;
    display: inline-block;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    height: 29px;
    overflow: hidden;
    z-index: 10;
  }
  .button-close {
    position: absolute;
    top: 10px;
    right: 18px;
    content: '';
    display: block;
    cursor: pointer;
    width: 29px;
    height: 29px;
    background: url(/images/manga_store/viewer/pc/close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
  }
  .header {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #2E51A2;
    color: #fff;
    height: 50px;
    transition: all .3s ease;
    &.hide {
      top: -50px;
    }
  }
  .pagecount {
    text-align: center;
    font-weight: bold;
    margin-top: 6px;
    line-height: 17px;
    @media screen and (max-width: 700px) {
      position: relative;
      left: -80px;
    }
    @media screen and (max-width: 535px) {
      margin-top: 16px;
    }
  }
  .pagecount-current {
    font-size: 14px;
  }
  .pagecount-total {
    font-size: 14px;
  }
  .facing-page-warp {
    position: absolute;
    right: 128px;
    top: 11px;
    width: 85px;
    height: 28px;
    @media screen and (max-width: 860px) {
      right: 128px;
      width: 85px;
    }
    &::before {
      content: '';
      width: 14px;
      height: 6px;
      background: url(/images/manga_store/viewer/pc/facing_pate_swich@2x.png?v=161115001) no-repeat left top;
      display: block;
      background-size: contain;
      position: absolute;
      left: 25px;
      top: 10px;
      @media screen and (max-width: 860px) {
        left: 25px;
      }
    }
  }
  .button-facing-page-single{
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 20px;
    height: 28px;
    box-sizing: border-box;
    border: 2px solid #fff;
    cursor: pointer;
    &.is-on{
      background-color: #fff;
      cursor: auto;
    }
  }
  .button-facing-page-double{
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 28px;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    &::after,
    &::before{
      content: '';
      width: 20px;
      height: 28px;
      box-sizing: border-box;
      display: block;
      position: absolute;
      top: 0;
      border: 2px solid #fff;
    }
    &::before{
      left: 0;
      border-right: 1px solid #fff;
    }
    &::after{
      right: 0;
      border-left: 1px solid #fff;
    }
    &.is-on{
      cursor: auto;
      &::after,
      &::before{
        width: 19px;
        background-color: #fff;
        border: none;
      }
    }
  }
  .button-tutorial{
    &:before{
      content: '';
      width: 30px;
      height: 30px;
      background: url(/images/manga_store/viewer/pc/tutorial@2x.png?v=161115001) no-repeat left top;
      background-size: contain;
      vertical-align: middle;
      display: inline-block;
      margin: 0 9px 0 0;
      line-height: 30px;
    }
    position: absolute;
    top: 10px;
    left: 18px;
    content: '';
    display: block;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
  .footer {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #2E51A2;
    color: #fff;
    height: 50px;
    transition: all .3s ease;
    &.hide{
      bottom: -50px;
    }
  }
</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"
  import SeekBar from "./SeekBar.vue"

  export default {
    components: { SeekBar },
    data() {
      return {
        windowOpener: !!window.opener,
      }
    },
    props: {
      show: Boolean,
      book: Object,
      customButton: Object,
      manuscript: Object,
      currentPage: Number,
      numTotalPages: Number,
      isFacingPage: Boolean,
      isPreview: Boolean,
      isFree: Boolean,
      zoomScale: Number,
      pageDirection: Number,
    },
    computed: {
      switchDirection() {
        return this.pageDirection === -1 ? true : false
      }
    },
    methods: {
      facingPageSingle() {
        this.$store.dispatch(types.CHANGE_FACING_TYPE, { isFacingPage: false ,isForseRepaint: false })
      },
      facingPageDouble() {
        this.$store.dispatch(types.CHANGE_FACING_TYPE, { isFacingPage: true ,isForseRepaint: false })
      },
      zoom(zoomScaleChange) {
        this.$store.commit(types.CHANGE_ZOOM, { zoomScale: (this.zoomScale + zoomScaleChange) })
      },
      close() {
        window.close()
      },
      showStoryModal() {
        this.$store.commit(types.SHOW_STORY_MODAL)
      },
      showTutorialModal() {
        this.$store.commit(types.SHOW_TUTORIAL_MODAL)
      },
      changeDirection() {
        this.$store.commit(types.CHANGE_DIRECTION)
      },
      custom(url) {
          location.href = url
      },
    },
  }
</script>
