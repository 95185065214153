<template>
  <a :href="previewUrl"
    @mouseover="mouseover"
    @mouseleave="mouseleave"
    target="_blank"
  >{{ buttonText }}</a>
</template>

<style lang="scss" scoped>
  @import "../../../../../css/variables";

  a {
    background-color: $color-pc-bg-ffffff;
    display: inline-block;
    min-width: 180px;
    padding: 0 4px;
    border: 1px solid $color-pc-border-2e51a2;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 1.0rem;
    text-align: center;
    text-decoration: none;
    color: #2e51a2;
    font-weight: 300;
    font-family: Avenir,lucida grande,tahoma,verdana,arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      background-color: $color-pc-bg-e1e7f5;
      opacity: 0.8;
    }
  }

  .dark-mode {
    @import "../../../../../css/colors_dark";

    a {
      background-color: $color-pc-bg-ffffff;
      border: 1px solid $color-pc-border-2e51a2;
      &:hover {
        text-decoration: none;
        background-color: $color-pc-bg-e1e7f5;
      }
    }
  }
</style>

<script type="text/babel">
  export default {
    props: {
      previewUrl: String,
      text: String,
    },
    computed: {
      buttonText() {
        return this.text ? `Preview ${this.text}` : `Preview`
      }
    },
    methods: {
      mouseover() {
        this.$emit('mouseover')
      },
      mouseleave() {
        this.$emit('mouseleave')
      }
    }
  }
</script>
