const $ = window.$;
import CsrfProtector from "./modules/CsrfProtector"

$(document).ready(function(){
  $('.recaptcha-form').each(function(){
    var form = $(this);
    var recaptcha_action = form.attr('data-recaptcha-action');
    if (typeof recaptcha_action === 'undefined' || recaptcha_action === false) {
      recaptcha_action = 'homepage';
    }
    form.off('submit').one('submit', function(event){
      CsrfProtector.injectTokenToForm(this);
      event.preventDefault();
      if (!window.GRECAPTCHA_SITE_KEY) {
        alert('The ReCAPTCHA key is undefined. Please contact your site administrator for assistance.');
        return false;
      }
      if (!window.grecaptcha) {
        alert("You are unable to perform this action because Google reCAPTCHA is currently blocked. MyAnimeList uses this service to keep the website secure.  Please change your content settings (e.g. AdBlock) to allow reCAPTCHA on MAL. If this problem persists, please contact Customer Support.");
        return false;
      }
      grecaptcha.ready(function(){
        grecaptcha.execute(window.GRECAPTCHA_SITE_KEY, {action: recaptcha_action}).then(function(token){
          $('input[name=g-recaptcha-response]', form).remove();
          form.append(`<input name='g-recaptcha-response' type='hidden' value='${token}'>`);
          form.submit();
        });
      });
    });
  });
});
