<template>

  <div>
<!-- buttons -->
    <button-sample
      v-show="!item.isPossessed && item.isPreviewable && !item.isFree"
      :url="urlWithLocation(item.previewUrl, location)"
      :is-mobile="false"
      :height="20"
      :width="62"
      :is-border="true"
      :is-small="true"
      class="button related-button-sample">
    </button-sample>
    <button-read
      v-show="item.isPossessed || item.isFree"
      @read="read"
      :is-mobile="false"
      :height="20"
      :width="62"
      :is-border="true"
      :is-small="true"
      class="button related-button-read">
    </button-read>
    <button-detail
      v-show="!item.isPossessed && !item.isPreviewable && !item.isFree"
      :url="urlWithLocation(item.url, location)"
      :is-mobile="false"
      :height="20"
      :width="62"
      :is-border="true"
      :is-small="true"
      class="button related-button-detail">
    </button-detail>

<!-- modal -->
    <access-limit-modal
      :is-shown="showAccessLimitModal"
      :item="saleItem"
      :show-loading-modal="showLoadingModal"
      @purchase="moveToPurchase"
      @cancel="cancel"
    ></access-limit-modal>
  </div>

</template>

<style lang="scss" scoped>
  @import "../../../../../../css/variables";
  .button {
    .related-button-sample,
    .related-button-read,
    .related-button-detail {
      color: $color-white;
    }
  }

  .dark-mode {
    @import "../../../../../../css/colors_dark";
    .related-button-sample,
    .related-button-read,
    .related-button-detail {
      color: $color-white;
    }
  }
</style>


<script type="text/babel">
  import ButtonSample from "../../../common/atoms/manga_store/ButtonSample.vue"
  import ButtonRead from "../../../common/atoms/manga_store/ButtonRead.vue"
  import ButtonDetail from "../../../common/atoms/manga_store/ButtonDetail.vue"
  import AccessLimitModal from "../../molecules/AccessLimitModal.vue"

  const axios = window.axios

  export default {
    components: {
      ButtonRead,
      ButtonSample,
      ButtonDetail,
      AccessLimitModal,
    },
    data() {
      return {
        showAccessLimitModal: false,
        saleItem: null,
        showLoadingModal: false,
      }
    },
    props: {
      item : Object,
    },
    methods: {
      async read(){
        if (this.item.isPossessed || !this.item.accessLimit) {
          window.open(this.item.viewerUrl)
        } else {
          this.error = null
          this.showLoadingModal = true
          let newWindow = window.open(window.location.href)
          try {
            const res = await axios.get(`/store/manga_volume/${this.item.id}/access/count`)
            if (res.data.login_path) {
              this.showLoadingModal = false
              window.location.href = res.data.login_path
              newWindow.close()
            } else if (!(res.data.user_access_count > 0) && res.data.total_access_count > this.item.accessLimit) {
              this.saleItem = this.item
              this.showLoadingModal = false
              this.showAccessLimitModal = true
              newWindow.close()
            } else {
              this.showLoadingModal = false
              newWindow.location.href = this.item.viewerUrl
            }
          } catch (e) {
            this.showLoadingModal = false
            // 握りつぶす
          }
        }
      },
      moveToPurchase() {
        location.href = `/store/manga_volume/${this.saleItem.saleBookId}/${this.saleItem.saleBookTitle}`
      },
      cancel() {
        this.showAccessLimitModal = false
      },
    },
  }
</script>
