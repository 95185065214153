const $ = window.$;
const _ = window._;

$(function () {

    // emailの入力エラーにより無効となったsubmitを、入力の変更を検知して有効にする
    const registeredEmail = $("#registered-email");
    const submitDisabled = $("#submit-disabled");

    registeredEmail.keypress( function () {
        submitDisabled.prop('disabled', false);
    });

    registeredEmail.keyup( function (e) {
        if (e.keyCode == 46 || e.keyCode == 8){
            submitDisabled.prop('disabled', false);
        }
    });

});
