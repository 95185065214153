const $ = window.$
const qs = require("qs")

$(function () {
  const params    = window.location.href.split("?")
  const parsedQuery = qs.parse(params[1])
  const is_form_open = parsedQuery.is_form_open;

  if (is_form_open === "1") {
    $(".js-btn-support-form-outer").hide()
    $(".js-support-form").show()
    $("#inquiry_type").val("supporter")
  } else if ( is_form_open === "2" ) {
    $(".js-btn-support-form-outer").hide()
    $(".js-support-form").show()
    $("#inquiry_type").val("manga_store")
  } else if ( is_form_open === "3" ) {
    $(".js-btn-support-form-outer").hide()
    $(".js-support-form").show()
    $("#inquiry_type").val("login")
  }

  $(".js-btn-support-form").on("click", function(){
    $(".js-btn-support-form-outer").fadeOut(function(){
      $(".js-support-form").fadeIn()
    })
  })
})
