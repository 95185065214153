import qs from "qs"
const sVue = window.sVue
const notify = window.noty
const $ = window.$

$(() => {
  // club adminがtopicをmemberにshareする時
  if ($("#vClubShareTemplate").exists()) {
    const btnEl = "#vClubShareTemplate"
    const parsedQuery = qs.parse(location.search.replace(/^\?/, ""))
    const topicId = parsedQuery.topicid
    const btnData = {sendStatus:"before"}

    const vBtn = new sVue({
      el: btnEl,
      data: btnData,
      methods: {
        shareTopic(e) {
          if (confirm("Are you sure you want all club members to be notified about this discussion?")===false) {
            return
          }
          const url = $(e.currentTarget).attr("href")
          shareTopicId(url, topicId, () => {
            this.sendStatus = "sended"
          })
        },
        beforeSend() {
          this.sendStatus = "sending"
        },
        showErrorMsg(err) {
          notify.showError(err)
          this.sendStatus = "before"
        },
      }
    })

    const shareTopicId = (url, topicId, onSuccess) => {
      $.ajax({
        url: url,
        method: "POST",
        data: {topic_id: topicId},  // eslint-disable-line camelcase
        dataType: "json",
        beforeSend: () => {
          vBtn.beforeSend()
        }
      })
      .done(() => {
        onSuccess()
      })
      .fail((xhr) => {
        if (xhr.responseJSON === null) {
          vBtn.showErrorMsg("Failed to share this topic")
          console.error(`internal server error: topic_id=${topicId}`)
        } else {
          vBtn.showErrorMsg(xhr.responseJSON.errors[0].message)
        }
      })
    }
  }
})
