<template>
  <div class="root">
    <transition name="modal">
      <div class="modal-lefttoright" v-show="show">This content is read from left to right. Please tap the right side or press the right key to move forward.</div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  .modal-lefttoright{
    font-family: Verdana,Arial;
    z-index: 99999;
    display: block;
    width: 380px;
    padding: 25px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    color: #fff;
    font-size: 24px;
    text-align: center;
  }

  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"

  export default {
    props: {
      show: Boolean,
    },
    watch: {
      show() {
        if (this.show) {
          const modalShowTime = 4000
          setTimeout(() => {
            this.$store.commit(types.HIDE_LEFTTORIGHT_MODAL)
          }, modalShowTime)
        }
      }
    },
  }
</script>
