const $ = window.$;

$(function () {

  if($(".js-watch-anime a.link-image")[0]){
    $(".js-watch-anime a.link-image").each(function(){
      $(this).on("mouseover",function(){
        $(this).find(".btn-play").css({"background-color": "rgba(255,255,255,0.8)"});
      }).on("mouseout",function(){
        $(this).find(".btn-play").css({"background-color": "rgba(255,255,255,0)"});
      });

    });
  }

  if($(".js-watch-anime .js-synopsis")[0]){
    $(".js-watch-anime .js-synopsis").on({
      mouseenter: function() {
        $(this).addClass("block-scroll");
      },
      mouseleave: function() {
        $(this).removeClass("block-scroll");
      }
    });
  }

  // hide
  if ($(".js-btn-hide-blocked-video")[0]) {
    $(".js-btn-hide-blocked-video").on("click", function() {
      $(this).toggleClass("on");
      $(".watch-anime-list.watch-video .video-block .video-list-outer-vertical.is_blocked").toggle();
    });
  }

  //click to link
  if ($(".js-video-list-episode")[0]) {
    $(".js-video-list-episode").on("click", function() {
      const url = $(this).data("url");
      location.href = url;
    });
    $(".js-video-list-episode .title").on("mouseover", function() {
      $(this).parents(".js-video-list-episode").css({ opacity: 1 });
    }).on("mouseout", function() {
      $(this).parents(".js-video-list-episode").css({ opacity: "" });
    });
  }

  //get Origin image size
  const getImageSize = function(ele) {
    const $anime = $(".js-watch-anime");
    $anime.each( function() {
        const img = new Image();
        img.src = $(this).find(".image").css("background-image").replace( /(url\(|\)|")/g, "" );
        const width = img.width;
        const height = img.height;
        if (width > height) {
          $(this).find(".image").css({"background-size":"contain"});
        }
    });
  }
  
  $(window).on("load", function(){
    if ($(".page-common.watch")[0]) {
      getImageSize();
    }
  });
});
