<template>
  <div class="root">
    <transition name="modal">
      <div v-show="show" class="modal-content">
        <div class="modal-mask" @click="hide"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-inner">
              <div>
                <img :src="coverImageUrl" width="224">
                <p class="modal-title">{{ book.title }}</p>
                <p class="modal-volume">{{ book.numberingText }}</p>
                <p>
                  <span v-for="(names, role) in authors">
                    {{ role }}<br>
                    <span class="modal-author" v-for="name in names">{{ name }}<br></span><br>
                  </span>
                </p>
              </div>
              <div>
                <div class="modal-header">Synopsis</div>
                <p class="modal-discription" :style='{"height": modalDiscriptionHeight + "px"}'>
                  <span class="">{{ book.synopsis }}</span>
                </p>
              </div>
            </div>
            <a class="modal-close" @click="hide">Close</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  .modal-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
  }
  .modal-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    font-family: Verdana,Arial;
    position: relative;
    width: 750px;
    min-height: 500px;
    margin: 80px auto;
    padding: 40px;
    box-sizing: border-box;
    background-color: #fff;
    color: #353535;
    border-radius: 4px;
    transition: all .3s ease;
  }
  .modal-inner {
    display: table;
    & > div{
      display: table-cell;
      vertical-align: top;
      font-size: 12px;
      font-weight: 300;
      -webkit-font-smoothing: antialiased;
    }
    & > div:first-child{
      padding:0 40px 0 0;
    }
    img {
      vertical-align: bottom;
    }
  }
  .modal-title {
    font-size: 16px;
    font-weight: bold;
    margin: 13px 0 4px;
    line-height: 1.18;
  }
  .modal-volume {
    font-weight: 700;
    margin: 0 0 8px;
  }
  .modal-author {
    font-weight: 700;
    line-height: 1.14;
    margin: 2px 0 0;
  }
  .modal-header {
    line-height: 1.0;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 17px;
    border-bottom: 1px solid #F5F5F5;
  }
  .modal-discription {
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-wrap;
    margin: 23px 0 0;
    display: block;
    max-height: 350px;
    overflow-y: auto;
  }
  .modal-close{
    position: absolute;
    top: 10px;
    right: 10px;
    content: '';
    display: block;
    width: 29px;
    height: 29px;
    background: url(/images/manga_store/viewer/pc/modal_close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
    cursor: pointer;
  }

  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"
  import {generateCoverImageUrl} from "../../../../../components/manga_store/common/modules/book"
  import {mapGetters} from "vuex"

  export default {
    props: {
      show: Boolean,
      book: Object,
      authors: Object,
    },
    computed: {
      modalDiscriptionHeight() {
        return window.innerHeight - 140
      },
      coverImageUrl() {
        return generateCoverImageUrl(this.book.coverImageBaseUrl)
      }
    },
    methods: {
      hide() {
        this.$store.commit(types.HIDE_STORY_MODAL)
      },
    },
  }
</script>
