"use strict"

const $ = window.$
const MAL = window.MAL

const REVIEW_VOTES_NEXT_HTML = '<div class="next js-modal-next"><p><i class="fas fa-spinner fa-spin"></i>loading...</p></div>'
let $reviewVotesModal = null
let reviewVotesJqXHR = null
let userClickType = 'review-votes-modal-user'

function generateVotesModal(appendedSelector, device, pagename) {
  if ($reviewVotesModal !== null) {
    return
  }

  $reviewVotesModal = $('<div class="review-votes-modal"></div>')
  const $list = $('<div class="list js-modal-list"></div>')
  const $background = $('<div class="modal_bg"></div>')
  const $contents = $('<div class="modal_contents">')
  const $head = $('<div class="head"><h3>Review Votes</h3></div>')
  const $close = $('<div class="modal_btn_close"><i class="fas fa-times-circle"></i></div>')
  $contents.append($head, $list, $close)
  $reviewVotesModal.append($background, $contents)
  $(appendedSelector).append($reviewVotesModal)

  // infinity scroll
  $list.scroll(() => {
    const $next = $reviewVotesModal.find('.js-modal-next')
    if (
      reviewVotesJqXHR === null &&
      $next.exists() &&
      $next.offset().top < $list.height() + $list.offset().top
    ) {
      const id = $reviewVotesModal.data('id')
      const offset = $reviewVotesModal.find('.js-modal-item').length
      addVotesUsersList(id, offset)
    }
  })

  // close modal
  const closeModal = () => {
    $reviewVotesModal.fadeOut()
    $('body').css('overflow-y','auto')
  }
  $background.on('click', closeModal)
  $close.on('click', closeModal)

  // define ga event
  if (device) {
    userClickType += `-${device}`
  }
  if (pagename) {
    userClickType += `-${pagename}`
  }
}

function openVotesModal(id) {
  if ($reviewVotesModal.data('id') !== id) {
    $reviewVotesModal.data('id', id)
    $reviewVotesModal.find('.js-modal-list').empty().append(REVIEW_VOTES_NEXT_HTML)
    addVotesUsersList(id, 0)
  }

  $reviewVotesModal.fadeIn()
  $('body').css('overflow-y', 'hidden')
}

function addVotesUsersList(id, offset) {
  if (reviewVotesJqXHR &&
    reviewVotesJqXHR.readyState > 0 &&
    reviewVotesJqXHR.readyState < 4) {
      reviewVotesJqXHR.abort()
      reviewVotesJqXHR = null
  }

  reviewVotesJqXHR = requestReviewVotes(id, offset)
    .done((data) => {
      $reviewVotesModal.find('.js-modal-next').remove()
      const $list = $reviewVotesModal.find('.js-modal-list')
      const items = []

      for (const item of data.item) {
        const profilePath = `/profile/${item.username}`
        const $item = $('<div class="item js-modal-item"></div>')
        const $image = $('<a class="image"></a>').css('background-image', `url('${item.image}')`).attr('href', profilePath)
        const $username = $('<div class="username"></div>')
        const $usernameLink = $('<a></a>').attr('href', profilePath).text(item.username).appendTo($username)
        if (item.friend == 2) {
          $('<span class="tag">Me</span>').appendTo($username)
        } else if (item.friend == 1) {
          $('<span class="tag">Friends</span>').appendTo($username)
        }
        const $reaction_title = $('<div class="reaction_title">' + MAL.REACTIONS_TITLE[item.reaction] + '</div>')
        const $reaction = $('<div class="reaction"><img src="' + MAL.REACTIONS_IMAGE_PATH + MAL.REACTIONS_EMOTICON[item.reaction] + '" alt="' + MAL.REACTIONS_TITLE[item.reaction] + '"></div>')

        // set ga click event
        $image.addClass('ga-click').attr('data-ga-click-type', userClickType)
        $usernameLink.addClass('ga-click').attr('data-ga-click-type', userClickType)

        $item.append($image, $username, $reaction_title, $reaction)
        items.push($item)
      }

      if (data.item.length >= 20) {
        items.push(REVIEW_VOTES_NEXT_HTML)
      }

      $list.append(items)
    })
    .always(() => {
      reviewVotesJqXHR = null
    })
}

function requestReviewVotes(id, offset) {
  return $.ajax({
    url: '/reviews/votes.json',
    data: {id, offset},
    type: 'GET',
    dataType: 'json'
  })
}

module.exports = {
  generateVotesModal,
  openVotesModal
}
