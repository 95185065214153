<!-- リスト内の 1 volume を表示する(book という名まえであるが) -->

<template>
  <div class="item"
    :style="{ width : width ? ( width + 'px') : 'auto' }"
  >
    <div class="cover-wrap"
      :class="{iconNew: !item.isPossessed && item.isNew}"
      @mouseover="mouseOver"
      @mouseout="mouseOut"
    >
      <a :href="urlWithLocation(item.url, location)" v-lazy:background-image="coverImage" class="cover-image"></a>
      <button-sample
        v-show="!item.isPossessed && item.isPreviewable && isHover && !item.isFree"
        :url="urlWithLocation(item.previewUrl, location)"
        :is-mobile="false"
        :height="24"
        :width="72"
        :is-border="true"
        class="button">
      </button-sample>
      <button-read
        v-show="(item.isPossessed || item.isFree) && isHover"
        @read="read"
        :is-mobile="false"
        :height="24"
        :width="72"
        :is-border="true"
        class="button">
      </button-read>
    </div>

    <recommendation-gauge
      v-if="isRecommendation === true"
      :score="item.score"
    ></recommendation-gauge>

    <div v-if="hideTitle !== true" class="title-wrap" :style="{ width : width ? ( (width - 20) + 'px') : 'auto' }">
      <a :href="urlWithLocation(item.url, location)" class="title" ref="ellipsis">{{ item.title }}</a>
    </div>
    <div class="volume"><a :href="urlWithLocation(item.url, location)">{{ item.numberingText }}</a></div>

    <div class="price-wrap" v-if="!item.isPossessed">
      <span class="price" :class="{'is-sale': item.salePrice >= 0}">{{ currency.formatSymbolAndPrice(item.price) }}</span>
      <span class="price-sale" v-show="item.salePrice >= 0">{{ currency.formatSymbolAndPrice(item.salePrice) }}</span>
    </div>

  </div>
</template>

<style lang="scss" scoped>
  @import "../../../../../css/variables";

  $title-color : $color-pc-text-323232;
  $volume-color : $color-pc-text-787878;
  $price-color : $color-pc-text-c0392b;
  $price-sale-color : $color-pc-text-ff0000;
  $title-line-height : 14px;

  /* Style */
  .item {
    display: table-cell;
    text-align: center;
    padding: 0 10px;
    font-family: Verdana,Arial;
    box-sizing: border-box;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    a {
      display: inline-block;
      text-decoration: none;
    }
  }
  .cover-wrap {
    position: relative;
    display: inline-block;
    height: 140px;
    text-decoration: none;
    cursor: pointer;
    background-image: url('/images/xmlhttp-loader.gif?v=171010');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 4px;
    &.iconNew:after {
      position: absolute;
      top: 0px;
      left: 0px;
      background-image: url('/images/manga_store/icon_new.png');
      background-size: 47px 47px;
      background-position: left top;
      background-repeat: no-repeat;
      display: inline-blockl;
      width: 47px;
      height: 47px;
      content: "";
    }
  }
  a.cover-image {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    height: 140px;
    width: 95px;
    box-sizing: border-box;
    vertical-align: bottom;
    transition-duration: .3s;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    opacity: 1;
    &:hover {
      opacity: 0.8;
    }
  }
  .cover-image[lazy=loading] {
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    .cover-image {
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
    }
  }
  .button {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50% ,-50%);
  }
  .title-wrap {
    margin: 2px 0 0;
    text-align: left;
  }
  a.title {
    display: inline-block;
    width: 100%;
    color: $title-color;
    font-size: 12px;
    text-decoration: none;
    word-wrap: break-word;
    line-height: $title-line-height;
    overflow: hidden;
    max-height: $title-line-height * 2;
  }
  .volume {
    margin: 2px 0 0;
    color: $volume-color;
    font-size: 12px;
    text-align: left;
    word-wrap: break-word;
    a {
      color: $volume-color;
    }
  }
  .price-wrap {
    margin: 2px 0 0;
    font-size: 12px;
    text-decoration: none;
    text-align: left;
  }
  .price {
    color: $price-color;
    &.is-sale {
      font-size: 12px;
      color: $color-pc-text-787878;
      text-decoration: line-through;
    }
  }
  .price-sale {
    color: $price-sale-color;
    font-weight: 700;
  }

  .dark-mode {
    @import "../../../../../css/colors_dark";

    $title-color : $color-pc-text-323232;
    $volume-color : $color-pc-text-787878;
    $price-color : $color-pc-text-c0392b;
    $price-sale-color : $color-pc-text-ff0000;

    a.title {
      color: $title-color;
    }
    .volume {
      color: $volume-color;
      a {
        color: $volume-color;
      }
    }
    .price {
      color: $price-color;
      &.is-sale {
        color: $color-pc-text-787878;
      }
    }
    .price-sale {
      color: $price-sale-color;
    }
  }
</style>

<script type="text/babel">
  import * as book from "../../common/modules/book"
  import ButtonSample from "../../common/atoms/manga_store/ButtonSample.vue"
  import ButtonRead from "../../common/atoms/manga_store/ButtonRead.vue"
  import RecommendationGauge from "../atoms/RecommendationGauge.vue"

  export default {
    components: {
      ButtonSample,
      ButtonRead,
      RecommendationGauge,
    },
    mounted() {
      if (this.hideTitle !== true) {
        let elementEllipsis = $(this.$refs.ellipsis)
        let wordEllipsis = elementEllipsis.html()
        let cloneEllipsis = elementEllipsis.clone()
        const heightEllipsis = elementEllipsis.height()

        cloneEllipsis.css({
          'opacity'   : '0',
          'position'  : 'absolute',
          'overflow'  : 'visible',
          'max-height': 'none',
          'height'    : 'auto'
        })
        .width(elementEllipsis.width())
        .height('auto')

        elementEllipsis.after(cloneEllipsis)

        while((wordEllipsis.length > 0) && (cloneEllipsis.height() > heightEllipsis)) {
          wordEllipsis = wordEllipsis.substr(0, wordEllipsis.length - 1)
          cloneEllipsis.html(wordEllipsis + '...')
        }

        elementEllipsis.html(cloneEllipsis.html())
        cloneEllipsis.remove()
      }
    },
    props: {
      /**
       * @param {String} item.title
       * @param {String} item.numberingText
       * @param {String} item.url
       * @param {String} item.previewUrl
       * @param {String} item.previewUrlForNovel
       * @param {String} item.thumbnail
       * @param {Boolean} item.isPurchased
       * @param {Number} item.price
       * @param {Number} item.salePrice
       * @param {Boolean} item.isFree
       * @param {Number} item.accessLimit
       * @param {Number} item.onSaleAt
       * @param {Number} item.currentTimestamp

       * @param {String} currency.symbol
       * @param {String} currency.name
       * @param {String} currency.fa
       */
      item: Object,
      currency: Object,
      width: Number,
      location: String,
      hideTitle: {
        type: Boolean,
        default: false,
      },
      isRecommendation: Boolean,
    },
    data() {
      return {
        isHover: false,
      }
    },
    methods: {
      mouseOut() {
        this.isHover = false
      },
      mouseOver(){
        this.isHover = true
      },
      read(){
        if (this.item.isPossessed || !this.item.accessLimit) {
          window.open(this.item.viewerUrl)
        } else {
          this.$emit('accessLimit', this.item)
        }
      },
    },
    computed: {
      coverImage() {
        const imageSize = (window.devicePixelRatio > 1) ? book.COVER_IMAGE_SIZE_M_2X : book.COVER_IMAGE_SIZE_M
        return {
          src: book.generateCoverImageUrl(this.item.coverImageBaseUrl, imageSize),
          loading: "/images/xmlhttp-loader.gif?v=171101"
        }
      }
    },
  }
</script>
