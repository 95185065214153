const $ = window.$
const _ = window._
const sVue = window.sVue

$(() => {

  function IgnoredUser(id, name) {
    this.id = id
    this.name = name
  }

  const $ignoredUsers = $(".js-ignored-users")
  if ($ignoredUsers.length) {
    const vm = new sVue({ // eslint-disable-line no-unused-vars
      el: $ignoredUsers[0],
      template: $("#template-ignored-users").text(),
      data: {
        inputUserName: "",
        isLoading: false,
        inputErrorMessage: "",
        commonErrorMessage: "",
        ignoredUsers: window.MAL.ForumSettings.ignoredUsers.map(function(u) {
          return new IgnoredUser(u.id, u.name)
        })
      },
      computed: {
        trimedInputUserName() {
          return this.inputUserName.trim()
        },
        canAdd() {
          return this.trimedInputUserName.length > 0 && !this.isLoading
        },
        canRemove() {
          return !this.isLoading
        }
      },
      methods: {
        clearErrorMessages() {
          this.inputErrorMessage = ""
          this.commonErrorMessage = ""
        },
        onInputIgnoredName(e) {
          this.clearErrorMessages()
          if (this.canAdd && e.keyCode === 13) {
            this.addInputUser()
          }
        },
        addInputUser() {
          this.addIgnoredUser(this.trimedInputUserName)
        },
        addIgnoredUser(userName) {
          const self = this

          const exists = _.some(self.ignoredUsers, function(user) {
            return user.name === userName
          });
          if (exists) {
            self.inputErrorMessage = "This user already exists in your ignored list."
            return
          }

          self.isLoading = true
          self.clearErrorMessages()
          $.ajax({
            url: "/forum/settings/ignored_users",
            method: "POST",
            data: {name: userName}
          }).then((result) => {
            self.ignoredUsers.push(new IgnoredUser(result.userId, userName))
            self.inputUserName = ""
          }, (e) => {
            const json = e.responseJSON
            switch (e.status) {
              case 400:
                if (json.is_supporter) {
                  self.inputErrorMessage = `There is a maximum of ${json.max}`
                } else {
                  self.commonErrorMessage = `There is a maximum of ${json.max} ignored users. Become a <a href="${json.url}" target="_blank">MAL Supporter</a> to increase it to twice!`
                }
                break
              case 401: // loginしていない場合login画面に飛ばす
                window.location.href = json.redirect
                break
              case 403:
                self.inputErrorMessage = "This user cannot be added to the list."
                break
              case 404:
                self.inputErrorMessage = "This user was not found."
                break
              default:
                self.inputErrorMessage = "Unknown error happened."
            }
          }).always(() => {
            self.isLoading = false
          })
        },
        removeIgnoredUser(userId) {
          const self = this
          if (!self.canRemove) {
            return
          }
          self.isLoading = true
          self.clearErrorMessages()
          $.ajax({
            url: `/forum/settings/ignored_users/${userId}`,
            method: "DELETE"
          }).then(() => {
            self.ignoredUsers = _.filter(self.ignoredUsers, function(user) {
              return user.id !== userId
            });
          }, (e) => {
            switch (e.status) {
              case 401: // loginしていない場合login画面に飛ばす
                window.location.href = e.responseJSON.redirect
                break
              default:
                self.commonErrorMessage = "Unknown error happened."
            }
          }).always(() => {
            self.isLoading = false
          })
        }
      }
    })
  }

})
