import GtmJsEvent from "../../../../common/modules/gtm/jsEvent";

const $ = window.$;
const axios = window.axios

const Cookies = require("js-cookie");
const advancedKey = "manga_update_advanced";

$(function () {
  if ($("body").hasClass("ownlist_manga_update")) {
    const oldNotes = $("#add_manga_comments").val();

    function toggleAdvanced (show) {
      $("#show-advanced-button").toggle(!show);
      $("#hide-advanced-button").toggle(show);
      $("table.advanced").toggle(show);
      $("#top-submit-buttons").toggle(show);
    }

    $("#advanced-button").on("click", function (e) {
      const showAdvanced = $("#show-advanced-button:visible").exists();
      toggleAdvanced(showAdvanced);
      axios.post("/cookie/set", {key: advancedKey, value: showAdvanced ? 1 : 0, expires: 365});
    });
    if (Cookies.get(advancedKey) == 1) {
      toggleAdvanced(true);
    } else {
      toggleAdvanced(false);
    }

    // ajaxで返ってくるHTMLのためにグローバルにメソッドを定義しておく
    // ajaxのエンドポイントをリファクタしたら消す
    window.chooseManga = function (mid, vols, status) {
      $("#manga_id").val(mid);
      $("#volumes").val(vols);
      $("#mstatus").val(status);
      $("#search_result").hide();
      $("#search_field").hide();
      $("#selected_field").show().html(_.template(`<strong><%- title %></strong> <small><a href="javascript:void(0);" onclick="cancelSelection();">Cancel</a></small>`)({title: document.getElementById(`mangaTitle${mid}`).innerText}));
    };
    window.cancelSelection = function () {
      $("#selected_field").hide();
      $("#search_result").html("");
      $("#search_field").show();
    };

    function enableEndDate (isEnable) {
      if (isEnable === null) {
        // トグル動作
        const change = $("#unknown_end").prop("checked");
        $("#add_manga_finish_date_month").prop("disabled", change);
        $("#add_manga_finish_date_year").prop("disabled", change);
        $("#add_manga_finish_date_day").prop("disabled", change);
        $("#unknown_end").prop("checked", change);
      } else {
        $("#add_manga_finish_date_month").prop("disabled", !isEnable);
        $("#add_manga_finish_date_year").prop("disabled", !isEnable);
        $("#add_manga_finish_date_day").prop("disabled", !isEnable);
        $("#unknown_end").prop("checked", !isEnable);
      }
    }

    function enableStartDate (isEnable) {
      if (isEnable === null) {
        // トグル動作
        const change = $("#unknown_start").prop("checked");
        $("#add_manga_start_date_month").prop("disabled", change);
        $("#add_manga_start_date_year").prop("disabled", change);
        $("#add_manga_start_date_day").prop("disabled", change);
        $("#unknown_start").prop("checked", change);
      } else {
        $("#add_manga_start_date_month").prop("disabled", !isEnable);
        $("#add_manga_start_date_year").prop("disabled", !isEnable);
        $("#add_manga_start_date_day").prop("disabled", !isEnable);
        $("#unknown_start").prop("checked", !isEnable);
      }
    }

    function setToday(isStart) {
      const dateVar = new Date();
      const year = dateVar.getFullYear();
      const month = dateVar.getMonth() + 1;
      const day = dateVar.getDate();

      if (isStart) {
        if (!$("#add_manga_start_date_month").prop("disabled")) {
          $("#add_manga_start_date_month").val(month);
          $("#add_manga_start_date_year").val(year);
          $("#add_manga_start_date_day").val(day);
        }
      } else {
        if (!$("#add_manga_finish_date_month").prop("disabled")) {
          $("#add_manga_finish_date_month").val(month);
          $("#add_manga_finish_date_year").val(year);
          $("#add_manga_finish_date_day").val(day);
        }
      }
    }

    function searchManga() {
      $("#search_result").show().html("<div class=\"normal_header\">Search Results</div><img src=\"/images/xmlhttp-loader.gif\">");
      const searchString = $("#queryTitle").val();

      $.get(`/includes/masearch.inc.php?m=${searchString}`, function (data__safe) {
        $("#search_result").show().html(`<div class="normal_header">Search Results</div>${data__safe}`);
      });
    }

    // submitボタン
    $(".main_submit").on("click", function () {
      $(this).prop('disabled',true);
      if (!$("#manga_id").val()) {
        alert("You did not select a manga title from the Manga Database.");
        $(this).prop('disabled',false);
        return;
      }
      if ($("#add_manga_status").val() == 0) {
        alert("You must select a status (reading, completed, etc...) for this manga.");
        $(this).prop('disabled',false);
        return;
      }
      if ($("#add_manga_comments").val() !== oldNotes) {
        // 計測イベントを送信
        const sendParam = 'mid:' + $("#manga_id").val();
        GtmJsEvent.send("notes-submit-mangalist-update", sendParam);
      }
      $("#main-form").submit();
    });

    // deleteボタン
    $(".delete_submit").on("click", function () {
      if (!confirm("Are you sure you want to delete this manga?")) {
        return;
      }
      $("#delete-form").submit();
    });

    // 検索枠でエンターをキャッチ
    $("#queryTitle").on("keypress", function (e) {
      const key = e.which;
      if (key === 13) {
        searchManga();
        return false;
      }
    });
    $("#search_submit").on("click", searchManga);

    // +ボタン
    $("#increment_chapter").on("click", function () {
      const val = parseInt($("#add_manga_num_read_chapters").val()) || 0;
      $("#add_manga_num_read_chapters").val(val + 1);
    });
    $("#increment_volume").on("click", function () {
      const val = parseInt($("#add_manga_num_read_volumes").val()) || 0;
      $("#add_manga_num_read_volumes").val(val + 1);
    });

    // Unknown Dateボタン
    $("#unknown_start").on("click", function () {
      enableStartDate(null);
    });
    $("#unknown_end").on("click", function () {
      enableEndDate(null);
    });

    // Insert Todayボタン
    $("#start_date_insert_today").on("click", function () {
      setToday(true);
    });
    $("#end_date_insert_today").on("click", function () {
      setToday(false);
    });

    // ストレージドロップダウン
    $("#add_manga_storage_type").on("change", function () {
      const $storageObj = $("#storage_div");
      const storageVal = $("#add_manga_storage_type").val();

      if (storageVal == 4) {
        // Retail Manga
        $storageObj.show();
      } else {
        $storageObj.hide();
      }
    });

    // ステータスドロップダウン
    $("#add_manga_status").on("change", function () {
      const val = $(this).val();
      if (val == 2) {
        // completed
        const chapters = $("#totalChap").text();
        const volumes = $("#totalVol").text();

        if (chapters != "?") {
          $("#add_manga_num_read_chapters").val(chapters);
        }
        if (volumes != "?") {
          $("#add_manga_num_read_volumes").val(volumes);
        }

        enableEndDate(true);
        $("#rereading_span").show();
      } else {
        enableEndDate(false);
        $("#rereading_span").hide();
      }
    });

    // Storageのイベントを発火して、DOMの状態を更新する
    $("#add_manga_storage_type").trigger("change");

    // StatusがCompletedならrereadingのチェックボックスを表示
    if ($("#add_manga_status").val() == 2) {
      $("#rereading_span").show();
    }
  }
});
