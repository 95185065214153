var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "item",
      style: { width: _vm.width ? _vm.width + "px" : "auto" }
    },
    [
      _c(
        "a",
        { attrs: { href: _vm.urlWithLocation(_vm.item.url, _vm.location) } },
        [
          _c("div", { staticClass: "cover-wrap" }, [
            _c("span", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy:background-image",
                  value: _vm.coverImage,
                  expression: "coverImage",
                  arg: "background-image"
                }
              ],
              staticClass: "cover-image"
            })
          ]),
          _vm._v(" "),
          _vm.isRecommendation === true
            ? _c("recommendation-gauge", { attrs: { score: _vm.item.score } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "title-wrap",
              style: { width: _vm.width ? _vm.width - 20 + "px" : "auto" }
            },
            [
              _c("div", { ref: "ellipsis", staticClass: "title" }, [
                _vm._v(_vm._s(_vm.item.title))
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }