const sVue = window.sVue;
import {
  createNotificationItem,
  NotificationContainerMixin,
  NotificationListMixin,
  NotificationItemFriendRequestMixin,
  NotificationItemFriendRequestAcceptMixin,
  NotificationItemFriendRequestDenyMixin,
  NotificationItemProfileCommentMixin,
  NotificationItemForumQuoteMixin,
  NotificationItemBlogCommentMixin,
  NotificationItemWatchedTopicMessageMixin,
  NotificationItemClubMassMessageInForumMixin,
  NotificationItemUserMentionMixin,
  NotificationItemOnAirMixin,
  NotificationItemRelatedAnimeAddMixin,
  NotificationItemPaymentStripeMixin,
} from "../../modules/components/Notification";

const _ = window._;
const $ = window.$;
const MAL = window.MAL;

$(function() {

  if (!$("body.notification").exists()) {
    return;
  }

  const vmMain = new sVue({ // eslint-disable-line no-unused-vars
    mixins: [NotificationContainerMixin],
    el: $(".js-notification-main")[0],
    replace: false,
    template: MAL.notification.templates.main,
    components: {
      "notification-list": {
        mixins: [NotificationListMixin],
        template: MAL.notification.templates.list,
        props: {
          isHistory: {type: Boolean, required: true}
        },
        components: {
          "notification-item-friend-request": {
            mixins: [NotificationItemFriendRequestMixin],
            template: MAL.notification.templates.itemFriendRequest
          },
          "notification-item-friend-request-accept": {
            mixins: [NotificationItemFriendRequestAcceptMixin],
            template: MAL.notification.templates.itemFriendRequestAccept
          },
          "notification-item-friend-request-deny": {
            mixins: [NotificationItemFriendRequestDenyMixin],
            template: MAL.notification.templates.itemFriendRequestDeny
          },
          "notification-item-profile-comment": {
            mixins: [NotificationItemProfileCommentMixin],
            template: MAL.notification.templates.itemProfileComment
          },
          "notification-item-forum-quote": {
            mixins: [NotificationItemForumQuoteMixin],
            template: MAL.notification.templates.itemForumQuote
          },
          "notification-item-blog-comment": {
            mixins: [NotificationItemBlogCommentMixin],
            template: MAL.notification.templates.itemBlogComment
          },
          "notification-item-watched-topic-message": {
            mixins: [NotificationItemWatchedTopicMessageMixin],
            template: MAL.notification.templates.itemWatchedTopicMessage
          },
          "notification-item-club-mass-message-in-forum": {
            mixins: [NotificationItemClubMassMessageInForumMixin],
            template: MAL.notification.templates.itemClubMassMessageInForum
          },
          "notification-item-user-mention-in-forum-message": {
            mixins: [NotificationItemUserMentionMixin],
            template: MAL.notification.templates.itemUserMentionInForumMessage
          },
          "notification-item-user-mention-in-club-comment": {
            mixins: [NotificationItemUserMentionMixin],
            template: MAL.notification.templates.itemUserMentionInForumMessage
          },
          "notification-item-on-air": {
            mixins: [NotificationItemOnAirMixin],
            template: MAL.notification.templates.itemOnAir
          },
          "notification-item-related-anime-add": {
            mixins: [NotificationItemRelatedAnimeAddMixin],
            template: MAL.notification.templates.itemRelatedAnimeAdd
          },
          "notification-item-payment-stripe": {
            mixins: [NotificationItemPaymentStripeMixin],
            template: MAL.notification.templates.itemPaymentStripe
          },
        }
      }
    },
    created() {
      this.items = _.map(MAL.notification.items, createNotificationItem);
      this.historyItems = _.map(MAL.notification.historyItems, createNotificationItem);
    },
    events: {
    }
  });

});
