<template>
  <div class="root">
    <transition name="modal">
      <div class="modal-content" v-show="showFinishModal">
        <div class="modal-mask" @click="cancel"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <h3 class="modal-title"><i class="fas fa-gift"></i>Thank you</h3>
            <div class="modal-inner">
              <div>
                <p>Your purchase is ready to be gifted!<br>
                  Send it to a friend or use it on your account.</p>
                <div class="modal-footer">
                  <a class="mal-btn primary large ga-click" data-ga-click-type="membership-payment-gift-button-go_to_purchase_gift"
                     :data-ga-click-param="'product:' + gift.gift_id" href="/account/membership/purchasedgifts">
                    <b>Go To Purchased Gifts</b></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script type="text/babel">

import {mapState} from "vuex";

export default {
  name: "MalSupportConfirmPurchase",
  props: {
    gift: Object
  },
  computed: {
    ...mapState({
      showFinishModal: state => state.showFinishModal
    })
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>

:root {
  body.sp {
    .modal-container {
      width: 95vw;
    }

    .modal-title {
      padding: 2rem 0 1rem;
    }

    .modal-inner {
      p {
        margin: 2.5rem 0;
        font-size: 1.5rem;
      }
    }

    .modal-footer {
      margin: 2rem 2rem;
    }
  }
}

.modal-content {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity .3s ease;
}

.modal-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  font-family: Verdana, Arial;
  position: relative;
  width: 700px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  background-color: #fff;
  color: #353535;
  border-radius: 4px;
  transition: all .3s ease;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
}

.modal-error {
  display: block;
  max-width: 360px;
  margin-bottom: 8px;
  text-align: right;
  color: #ff6a86;
  font-size: 12px;
}

.modal-inner {
  padding: 1.5rem;
  position: relative;

  p {
    line-height: 2rem;
    margin: 2rem 0;
    font-size: 1rem;
    text-align: center;
  }
}


.modal-title {
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
  font-size: 1.5rem;
  max-width: 100%;
  padding-top: 1.5rem;

  > i {
    margin-right: 1rem;
  }
}

.modal-footer {
  margin: 1rem 2rem;
  display: flex;
  justify-content: space-around;
}

.modal-button-loading {
  display: block;
  width: 340px;
  height: 44px;
  background: url(/images/xmlhttp-loader.gif?v=161115001) no-repeat center center;
  background-size: 16px;
  vertical-align: middle;
  text-indent: -99999px;
}


/* Transition Effect */
.modal-enter {
  opacity: 0.2;
}

.modal-leave-active {
  opacity: 0.2;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
</style>
