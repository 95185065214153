<template>
  <div class="canvas-root">
    <canvas ref="canvas" moz-opaque
        :width="displayWidth * pixelDensity"
        :height="displayHeight * pixelDensity"
        :style="canvasStyle">
    </canvas>
    <div class="cursor"
         v-if="hoverPosition.y > 0 && hoverPosition.x > 0 && isCursorShow"
         :style="cursorStyle"
         :class="{
          'cursor-arrow':((cursorPosition.arrow || currentPage !== 1) && zoomScale === 1),
          'cursor-move' : (zoomScale !== 1 ),
          'cursor-nopage-right': (cursorPosition.nopage && currentPage === 1 && zoomScale === 1),
         }"></div>
    <!-- background-color rgba(0, 0, 0, 0) for IE10-->
    <div class="hover-area"
         style="background-color: rgba(0, 0, 0, 0);"
         :style="{ height: (displayHeight - 100) + 'px' }"
         :draggable="draggable"
         @mouseover="mouseOver"
         @mouseout="mouseOut"
         @click="canvasClick"
         @dblclick="canvasDlClick"
         @mousedown.prevent="dragStart"
         @mouseup="stopDrag"
         @mousemove="mouseMove"
    ></div>
    <div :style="loadingImageLeftStyle" class="loading-image"></div>
    <div :style="loadingImageRightStyle" class="loading-image"></div>
  </div>
</template>

<style lang="scss" scoped>
  .canvas-root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #000;
  }
  .hover-area {
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50px;
    z-index: 1;
    cursor: none;
    user-drag: element;
  }
  .cursor {
    position: absolute;
    display: block;
    cursor: none;
    width: 40px;
    height: 40px;
    &.cursor-arrow {
      background: url(/images/manga_store/viewer/pc/cursor_arrow@2x.png) no-repeat center center;
      background-size: contain;
      transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &.cursor-move {
      background: url(/images/manga_store/viewer/pc/cursor_move@2x.png) no-repeat center center;
      background-size: contain;
    }
    &.cursor-nopage-right {
      width: 40px;
      height: 40px;
      background: url(/images/manga_store/viewer/pc/no_page_right@2x.png) no-repeat center center;
      background-size: contain;
    }
    &.cursor-nopage-left {
      width: 40px;
      height: 40px;
      background: url(/images/manga_store/viewer/pc/no_page_right@2x.png) no-repeat center center;
      background-size: contain;
      transform: scale(1, -1);
    }
  }
  canvas {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #000;
  }
  .loading-image {
    position: absolute;
    background: url(/images/manga_store/viewer/loader.gif) no-repeat center center;
  }
</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"
  import * as consts from "../../common/constants"

  export default {
    mounted() {
      // canvas の context を保存しておく
      this.context = this.$refs.canvas.getContext('2d')
      this.displayHeight = window.innerHeight
      this.displayWidth = window.innerWidth

      const resizeInterval = 100
      let resizeTimer = null
      window.addEventListener("resize", () => {
        if (resizeTimer !== false) {
          clearTimeout( resizeTimer )
        }
        resizeTimer = setTimeout( () => {
          this.resized()
        }, resizeInterval )
      })

      const mousewheelevent = 'onwheel' in document ? 'wheel' : ('onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll')
      $(document).on(mousewheelevent,this.mousewheel)
      window.addEventListener('keyup', this.keyUp)

      this.startSendingStats()
    },
    props: {
      manuscript: Object,
      images: Object,
      currentPage: Number,
      numTotalPages: Number,
      isFacingPage: Boolean,
      zoomScale: Number,
      pageDirection: Number,
      newestLoadedImagePage: Number,
    },
    data() {
      return {
        context          : null,
        displayHeight    : 0,
        displayWidth     : 0,
        didFirstTap      : false,
        isStartDrag      : false,
        isCursorShow     : true,
        isMouseMove      : false,
        hoverPosition    : {x: 0, y: 0},
        translate        : {x: 0, y: 0},
        startDragPostion : {x: 0, y: 0},
        offset           : {x: 0, y: 0},
        pixelDensity     : ( window.devicePixelRatio ? window.devicePixelRatio : 1 ),
        mouseWheelDirection : true,
        beforeMouseWheelDirection : true,
      }
    },
    computed: {

      draggable() {
        const ua = window.navigator.userAgent.toLowerCase()
        let draggableBrowser = true
        if (ua.indexOf('edge') !== -1) {
          //Edge
          draggableBrowser = false
        } else if (ua.indexOf('trident/7') !== -1) {
          //IE11
          draggableBrowser = false
        } else if (ua.indexOf("msie")!== -1) {
          // IE
          draggableBrowser = false
        } else if (ua.indexOf('chrome') !== -1) {
          // chrome
          draggableBrowser = false
        }
        return draggableBrowser
      },

      cursorStyle() {
        return {
          top       : `${this.hoverPosition.y - 20}px`,
          left      : `${this.hoverPosition.x- 20}px`,
          transform : `scale(${this.displayWidth / 2 < this.hoverPosition.x ? 1 : -1}, 1)`,
        }
      },

      cursorPosition() {
        if (this.pageDirection === 1) {
          return {
            arrow  : (this.displayWidth/2 > this.hoverPosition.x),
            nopage : (this.displayWidth/2 < this.hoverPosition.x),
          }
        } else {
          return {
            arrow  : (this.displayWidth/2 < this.hoverPosition.x),
            nopage : (this.displayWidth/2 > this.hoverPosition.x),
          }
        }
      },

      canvasStyle() {
        return {
          width  : `${ this.displayWidth }px`,
          height : `${ this.displayHeight }px`,
        }
      },

      isLoadingImageLeft() {
        return this.currentImages.left && !this.currentImages.left.isLoaded
      },
      isLoadingImageRight() {
        return this.currentImages.right && !this.currentImages.right.isLoaded
      },

      loadingImageLeftStyle() {
        if (this.isLoadingImageLeft) {
          const rect = this.imageRenderingRects.left
          return {
            left   : `${rect.x / this.pixelDensity}px`,
            top    : `${rect.y / this.pixelDensity}px`,
            width  : `${rect.w / this.pixelDensity}px`,
            height : `${rect.h / this.pixelDensity}px`,
          }
        } else {
          return {display: "none"}
        }
      },

      loadingImageRightStyle() {
        if (this.isLoadingImageRight) {
          const rect = this.imageRenderingRects.right
          return {
            left: `${rect.x / this.pixelDensity}px`,
            top: `${rect.y / this.pixelDensity}px`,
            width: `${rect.w / this.pixelDensity}px`,
            height: `${rect.h / this.pixelDensity}px`,
          }
        } else {
          return {display: "none"}
        }
      },

      currentImages() {
        if (this.isFacingPage) {
          let leftPage = this.manuscript.isLeftFirst
            ? this.currentPage + 1 - this.currentPage % 2
            : this.currentPage + this.currentPage % 2

          if (this.pageDirection === 1) {//右から左の場合
            return {
              left: this.images[leftPage],
              right: this.images[leftPage - 1]
            }
          }else{//左から右の場合
            return {
              left: this.images[leftPage - 1],
              right: this.images[leftPage]
            }
          }
        } else {
          return {
            left: this.images[this.currentPage],
            right: undefined,
          }
        }
      },

      // 画像を描画する矩形領域を計算する
      imageRenderingRects() {
        if (this.isFacingPage) {
          let imageWidth, imageHeight
          if ((2 / this.manuscript.aspectRatio) > (this.displayWidth / this.displayHeight)) {
            imageWidth = this.displayWidth / 2 * this.zoomScale
            imageHeight = (this.displayWidth / 2) * this.manuscript.aspectRatio * this.zoomScale
          } else {
            imageWidth = this.displayHeight / this.manuscript.aspectRatio * this.zoomScale
            imageHeight = this.displayHeight * this.zoomScale
          }

          let basePositionX, basePositionY
          basePositionX = (this.displayWidth - imageWidth * 2) * this.pixelDensity / 2
          basePositionY = (this.displayWidth < this.displayHeight) ?
                          (this.displayHeight - imageHeight) / 2 :
                          (this.displayHeight - imageHeight) * this.pixelDensity / 2

          // 移動先の座標
          const offsetDistance = {
            x: this.offset.x + this.translate.x,
            y: this.offset.y + this.translate.y,
          }

          // はみ出ないように
          const offsetLimit = {
            x: (this.displayWidth / 2 - imageWidth) * 2 * this.pixelDensity,
            y: (this.displayHeight - imageHeight) * this.pixelDensity,
          }

          const translateX = basePositionX + offsetDistance.x
          const translateY = basePositionY + offsetDistance.y

          let rectX, rectY
          if (this.isMouseMove === true) {
            rectX = (this.displayWidth / 2 >= imageWidth) ? translateX : (offsetLimit.x > translateX) ? offsetLimit.x : (0 < translateX) ? 0 : translateX
          } else {
            rectX = translateX
          }
          rectY = (this.displayHeight > imageHeight) ? translateY :
                  (offsetLimit.y > translateY) ? offsetLimit.y : (0 < translateY) ? 0 : translateY

          const rects = {
            left: {
              x: rectX,
              y: rectY,
              w: imageWidth * this.pixelDensity,
              h: imageHeight * this.pixelDensity,
            },
            right: {
              x: rectX + imageWidth * this.pixelDensity,
              y: rectY,
              w: imageWidth * this.pixelDensity,
              h: imageHeight * this.pixelDensity,
            },
          }

          this.offset.x = offsetDistance.x
          this.offset.y = offsetDistance.y

          return {
            left: {
              x: rects.left.x,
              y: rects.left.y,
              w: rects.left.w,
              h: rects.left.h,
            },
            right: {
              x: rects.right.x,
              y: rects.right.y,
              w: rects.right.w,
              h: rects.right.h,
            },
          }
        } else {
          const imageWidth = this.displayHeight / this.manuscript.aspectRatio * this.zoomScale
          const imageHeight = this.displayHeight * this.zoomScale

          const offsetDistance = {
            x: this.offset.x + this.translate.x,
            y: this.offset.y + this.translate.y,
          }

          this.offset.x = offsetDistance.x
          this.offset.y = offsetDistance.y

          return {
            left: {
              x: (this.displayWidth - imageWidth) * this.pixelDensity / 2 + offsetDistance.x,
              y: (this.displayHeight - imageHeight) * this.pixelDensity / 2 + offsetDistance.y,
              w: imageWidth * this.pixelDensity,
              h: imageHeight * this.pixelDensity,
            },
            right: undefined,
          }
        }
      },
    },
    watch: {
      currentPage() {
        this.isCursorShow = false
        this.repaint()
      },
      isFacingPage() {
        this.offset = {x: 0, y: 0}
        this.translate = {x: 0, y: 0}
        this.repaint()
      },
      zoomScale() {
        this.offset = {x: 0, y: 0}
        this.translate = {x: 0, y: 0}
        this.repaint()
      },

      // 新しく画像のロードが完了した時に更新される変数
      // ロード完了した画像が現在のページのものであれば、再描画を行う
      newestLoadedImagePage() {
        const {left, right} = this.currentImages
        if (
          (left && this.newestLoadedImagePage === left.page) ||
          (right && this.newestLoadedImagePage === right.page)
        ) {
          this.repaint()
        }
      },
    },
    methods: {
      keyUp(e) {
        if (this.$store.getters.isTutorialModalVisible) {
          return
        }
        switch(e.which) {
          case 39: // right
            this.$store.commit(types.MOVE_PAGE, {dir: -1*this.pageDirection})
            break
          case 13: // Enter
          case 32: // space
            this.$store.commit(types.MOVE_PAGE, {dir: 1})
            break
          case 37: // left
            this.$store.commit(types.MOVE_PAGE, {dir: 1*this.pageDirection})
            break
          case 38: // up
            if (this.zoomScale !== 1) {
              this.translate.x = 0
              this.translate.y = (this.translate.y < 0) ? 0 : this.translate.y
              this.translate.y = this.translateY(this.translate.y + 10)
              this.beforeMouseWheelDirection = true
              this.repaint()
            }
            break
          case 40: // down
            if (this.zoomScale !== 1) {
              this.translate.x = 0
              this.translate.y = (this.translate.y > 0) ? 0 : this.translate.y
              this.translate.y = this.translateY(this.translate.y + -10)
              this.beforeMouseWheelDirection = false
              this.repaint()
            }
            break
          case 27: // ESCAPE key pressed
            this.$store.commit(types.CHANGE_ZOOM, { zoomScale: 1 })
            break
          case 187: //plus
          case 59: //plus firefox
          case 107: //テンキーplus
            this.$store.commit(types.CHANGE_ZOOM, { zoomScale: (this.zoomScale + 0.25 ) })
            break
          case 189: //minus
          case 173: //minu firefox
          case 109: //テンキーminus
            this.$store.commit(types.CHANGE_ZOOM, { zoomScale: (this.zoomScale - 0.25 ) })
            break
          default:
            return
        }
        e.stopPropagation()
      },
      translateX(movementX) {
        // Xの可動範囲
        const rect = (this.imageRenderingRects.left.w) ? this.imageRenderingRects.left : this.imageRenderingRects.right
        const rangeX = (this.displayWidth - rect.w / this.pixelDensity * (this.isFacingPage ? 2 : 1)) * this.pixelDensity
        if (rangeX < 0) {
          return (rect.x + movementX >= rangeX && rect.x + movementX <= 0) ? movementX : 0
        } else {
          return 0
        }
      },
      translateY(movementY) {
        // Yの可動範囲
        const rect = (this.imageRenderingRects.left.h) ? this.imageRenderingRects.left : this.imageRenderingRects.right
        const rangeY = (this.displayHeight * this.pixelDensity - rect.h)
        if (rangeY < 0) {
          return (rect.y + movementY >= rangeY && rect.y + movementY <= 0) ? movementY : 0
        } else {
          return 0
        }
      },
      mousewheel(e) {
        const deltaY = e.originalEvent.deltaY ? -(e.originalEvent.deltaY) : ( e.originalEvent.wheelDelta ? e.originalEvent.wheelDelta : -(e.originalEvent.detail) )
        if (this.zoomScale !== 1) {
          this.translate.x = 0
          const scrollAmount = (deltaY > 0) ? 5 : -5
          this.mouseWheelDirection = (deltaY > 0) ? true : false
          this.translate.y = (this.beforeMouseWheelDirection !== this.mouseWheelDirection) ? 0 : this.translate.y
          this.translate.y = this.translateY(this.translate.y + scrollAmount)
          this.beforeMouseWheelDirection = this.mouseWheelDirection
          this.repaint()
        }
      },

      mouseMove(e) {
        this.hoverPosition.x = e.clientX
        this.hoverPosition.y = e.clientY
        this.isCursorShow    = true
        if (this.isStartDrag && this.zoomScale !== 1) {
          const clientX = e.clientX
          const clientY = e.clientY
          this.isMouseMove = true
          const movementX = (clientX - this.startDragPostion.x) * this.pixelDensity
          const movementY = (clientY - this.startDragPostion.y) * this.pixelDensity
          this.translate.x = this.translateX(movementX)
          this.translate.y = this.translateY(movementY)
          this.repaint()
          this.startDragPostion.x = clientX
          this.startDragPostion.y = clientY
        }
      },

      stopDrag(e) {
        this.isStartDrag = false
        this.isMouseMove = false
      },

      dragStart(e) {
        if(!this.isStartDrag && this.zoomScale !== 1) {
          this.isStartDrag = true
          this.isMouseMove = false
          this.startDragPostion.x = e.clientX
          this.startDragPostion.y = e.clientY
          this.translate = {x: 0, y: 0}
        }
      },

      canvasClick(e) {
        //シングルタップの場合
        // 1回目のタップ判定を真にする
        this.didFirstTap = true
        this.isStartDrag = false
        // 350ミリ秒だけ、1回目のタップ判定を残す
        const doubleTapTime = 350
        setTimeout(() => {
          if (this.didFirstTap && this.zoomScale === 1) {
            const dir = ((this.displayWidth / 2 < e.clientX) ? -1 : 1) * this.pageDirection
            this.$store.commit(types.MOVE_PAGE, {dir})
          }
          this.didFirstTap = false
        }, doubleTapTime )

      },

      canvasDlClick(e) {
        // ダブルタップの場合
        // 1回目のタップ判定を解除
        this.didFirstTap = false
        this.isStartDrag = false
        // 移動系の値をresetする
        this.translate = {x: 0, y: 0}
        // zoom
        if (this.zoomScale !== 1) {
          this.$store.commit(types.CHANGE_ZOOM, { zoomScale: 1 })
        } else {
          this.$store.commit(types.CHANGE_ZOOM, { zoomScale: 1.5 })
        }
      },

      mouseOut() {
        this.hoverPosition.x = 0
        this.hoverPosition.y = 0
        this.$store.commit(types.CHANGE_CANVAS_HOVER_STATE, { isHover: false })
        this.didFirstTap = false
        this.isStartDrag = false
      },

      mouseOver(){
        this.$store.commit(types.CHANGE_CANVAS_HOVER_STATE, { isHover: true })
      },

      resized() {
        this.pixelDensity = ( window.devicePixelRatio ? window.devicePixelRatio : 1 )
        this.displayHeight = window.innerHeight
        this.displayWidth = window.innerWidth
        this.$nextTick(this.repaint)
      },

      drawImage(rect, imageInfo) {
        //antialiasing
        if ( imageInfo.imageObject.naturalHeight > rect.h * 2 ) {
          let oc = document.createElement('canvas'),
          octx = oc.getContext('2d');
          oc.width  = imageInfo.imageObject.naturalWidth * 0.5
          oc.height = imageInfo.imageObject.naturalHeight * 0.5
          octx.drawImage(imageInfo.imageObject, 0, 0, oc.width, oc.height)
          if ( oc.width > rect.h * 4 ) {
            this.context.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5, rect.x, rect.y, rect.w, rect.h)
          } else {
            this.context.drawImage(oc, rect.x, rect.y, rect.w, rect.h)
          }
        } else {
          this.context.drawImage(imageInfo.imageObject, rect.x, rect.y, rect.w, rect.h)
        }
      },

      repaint() {
        // 毎回リフレッシュ必要（拡大率変更や見開き切り替えがあるため）
        this.context.clearRect(0, 0, this.displayWidth * this.pixelDensity, this.displayHeight * this.pixelDensity)

        // 単一ページ表示のときは right は undefined になるため、 left のみレンダリングされる
        const leftImageInfo  = this.currentImages.left
        const rightImageInfo = this.currentImages.right

        const mathCeil = (rect) => {
          rect.x = Math.ceil(rect.x)
          rect.y = Math.ceil(rect.y)
          rect.w = Math.ceil(rect.w)
          rect.h = Math.ceil(rect.h)
          return rect
        }

        if (leftImageInfo && leftImageInfo.isLoaded) {
          const rect = mathCeil(this.imageRenderingRects.left)
          this.drawImage(rect, leftImageInfo)
        }

        if (rightImageInfo && rightImageInfo.isLoaded) {
          const rect = mathCeil(this.imageRenderingRects.right)
          this.drawImage(rect, rightImageInfo)
        }
      },

      startSendingStats() {
        // 画像が完全に出ていたフレーム数と、ローディング中のフレーム数をカウントする
        // これで、どのくらいの間ユーザがローディング中のくるくるを見ているのかが分かるようになる
        let totalFrames = 0, loadingFrames = 0
        setInterval(() => {
          totalFrames ++
          if (this.isLoadingImageLeft || this.isLoadingImageRight) {
            loadingFrames ++
          }
        }, 100)

        // 5秒ごとに統計を送信する
        setInterval(() => {
          // ロードが完了している画像の平均レスポンスタイムを計算
          let loadedImages = 0, totalResponseTime = 0
          for (let p in this.images) {
            let image = this.images[p]
            if (image.isLoaded) {
              loadedImages ++
              totalResponseTime += image.responseTime
            }
          }

          // ログ送信
          this.$store.dispatch(types.LOG, {
            type: consts.LOG_TYPE_STATS,
            payload: {
              // 直近5秒で何％ローディングが出ていたか
              lr: Number((loadingFrames / totalFrames).toFixed(3)),

              // 画像の平均レスポンスタイム
              ar: parseInt(totalResponseTime / loadedImages),
            },
          })

          // フレーム数のカウントをリセット
          totalFrames = loadingFrames = 0
        }, 5000)
      },
    },
  }
</script>
