const $ = window.$;

$(function () {
  // fansubグループへのvoteボタン
  $(".js-fansub-set-vote-button").on("click", function () {
    const groupId  = $(this).data("group-id");
    const animeId  = $(this).data("anime-id");
    const imageId  = $(this).data("image-id");
    const voteType = parseInt($(this).data("type"));
    setVote(groupId, animeId, imageId, voteType);
  });

  // commentの表示/非表示
  $(".js-fansub-toggle-comment-button").on("click", function () {
    const groupId = $(this).data("id");
    $(`#fsgComments${groupId}`).toggle();
  });

  function setVote(groupId, animeId, imageId, voteType) {
    const text = voteType == 2 ? "didn't like" : "liked";

    if (voteType == 3) {
      // vote取り消し
      $.post("/includes/ajax.inc.php?t=19", {fsgid: groupId, value: voteType, aid: animeId}, function (data) {
        $(`#good${imageId}`).attr("src", "/images/good-off.gif");
        $(`#bad${imageId}`).attr("src", "/images/bad-off.gif");
      });
    } else {
      const fsgHtml = `
<div style="font-family: verdana, arial; font-size: 11px; text-align: center;">
  <span id="explain">Please explain in short detail<br>why you ${text} this group's subbing performance (255 chars max).</span>
  <div style="margin-top: 5px;"><textarea id="fsgcomm" class="textarea" rows="3" cols="40"></textarea></div>
  <div style="margin-top: 5px;">
    <input type="button" value="Cancel Vote" class="inputButton" onclick="$.fancybox.close()">
    &nbsp;&nbsp;
    <input type="button" value="Submit Vote" class="inputButton js-fansub-comment-button">
  </div>
</div>`;

      $.fancybox({
        content       : fsgHtml,
        autoScale     : true,
        autoDimensions: true,
        onComplete    : function () {
          $(".js-fansub-comment-button").on("click", function () {
            $.post("/includes/ajax.inc.php?t=19", {
              fsgid  : groupId,
              value  : voteType,
              aid    : animeId,
              comment: $("#fsgcomm").val()
            }, function (data) {
              $.fancybox.close();
              if (voteType == 1) {
                $(`#good${imageId}`).attr("src", "/images/good-on.gif");
                $(`#bad${imageId}`).attr("src", "/images/bad-off.gif");
              } else {
                $(`#good${imageId}`).attr("src", "/images/good-off.gif");
                $(`#bad${imageId}`).attr("src", "/images/bad-on.gif");
              }
            });
          });
        }
      });
    }
  }
});
