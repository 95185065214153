const $ = window.$;
const _ = window._;
const MAL = window.MAL;
const sVue = window.sVue;

import {
  createNotificationItem,
  NotificationContainerMixin,
  NotificationListMixin,
  NotificationItemFriendRequestMixin,
  NotificationItemFriendRequestAcceptMixin,
  NotificationItemFriendRequestDenyMixin,
  NotificationItemProfileCommentMixin,
  NotificationItemForumQuoteMixin,
  NotificationItemBlogCommentMixin,
  NotificationItemWatchedTopicMessageMixin,
  NotificationItemClubMassMessageInForumMixin,
  NotificationItemUserMentionMixin,
  NotificationItemOnAirMixin,
  NotificationItemRelatedAnimeAddMixin,
  NotificationItemPaymentStripeMixin,
} from "../modules/components/Notification";

$(document).ready(function() {

  const HeaderNotificationButton = {
    template: MAL.headerNotification.templates.button,
    props: {
      hasNotSeen: {type: Boolean, required: true},
      onClick: {type: Function, required: true},
      isDropdownVisible: {type: Boolean, required: true},
      numWholeUnreadItems: {type: Number, required: true}
    },
    computed: {
      numWholeUnreadItemsAsString() {
        return this.numWholeUnreadItems > 99 ? "99+" : this.numWholeUnreadItems.toString();
      },
      hasUnread() {
        return this.numWholeUnreadItems > 0;
      }
    }
  };

  const HeaderNotificationDropdown = {
    mixins: [NotificationListMixin],
    template: MAL.headerNotification.templates.dropdown,
    props: {
      numWholeUnreadItems: {type: Number, required: true},
      dropdownOpenedAt: {type: Number, required: true},
      isVisible: {type: Boolean, required: true},
      wasDropdownClosed: {type: Boolean, required: true},
      isLoaded: {type: Boolean, required: true},
    },
    components: {
      "notification-item-friend-request": {
        mixins: [NotificationItemFriendRequestMixin],
        template: MAL.headerNotification.templates.itemFriendRequest
      },
      "notification-item-friend-request-accept": {
        mixins: [NotificationItemFriendRequestAcceptMixin],
        template: MAL.headerNotification.templates.itemFriendRequestAccept
      },
      "notification-item-friend-request-deny": {
        mixins: [NotificationItemFriendRequestDenyMixin],
        template: MAL.headerNotification.templates.itemFriendRequestDeny
      },
      "notification-item-profile-comment": {
        mixins: [NotificationItemProfileCommentMixin],
        template: MAL.headerNotification.templates.itemProfileComment
      },
      "notification-item-forum-quote": {
        mixins: [NotificationItemForumQuoteMixin],
        template: MAL.headerNotification.templates.itemForumQuote
      },
      "notification-item-blog-comment": {
        mixins: [NotificationItemBlogCommentMixin],
        template: MAL.headerNotification.templates.itemBlogComment
      },
      "notification-item-watched-topic-message": {
        mixins: [NotificationItemWatchedTopicMessageMixin],
        template: MAL.headerNotification.templates.itemWatchedTopicMessage
      },
      "notification-item-club-mass-message-in-forum": {
        mixins: [NotificationItemClubMassMessageInForumMixin],
        template: MAL.headerNotification.templates.itemClubMassMessageInForum
      },
      "notification-item-user-mention-in-forum-message": {
        mixins: [NotificationItemUserMentionMixin],
        template: MAL.headerNotification.templates.itemUserMentions
      },
      "notification-item-user-mention-in-club-comment": {
        mixins: [NotificationItemUserMentionMixin],
        template: MAL.headerNotification.templates.itemUserMentions
      },
      "notification-item-on-air": {
        mixins: [NotificationItemOnAirMixin],
        template: MAL.headerNotification.templates.itemOnAir
      },
      "notification-item-related-anime-add": {
        mixins: [NotificationItemRelatedAnimeAddMixin],
        template: MAL.headerNotification.templates.itemRelatedAnimeAdd
      },
      "notification-item-payment-stripe": {
        mixins: [NotificationItemPaymentStripeMixin],
        template: MAL.headerNotification.templates.itemPaymentStripe
      }
    },
    methods: {
      isNewItem(item) {
        return item.isNewThan(this.dropdownOpenedAt)
      }
    }
  };

  const vmNotification = new sVue({
    mixins: [NotificationContainerMixin],
    el: $(".js-header-notification")[0],
    components: {
      "header-notification-button": HeaderNotificationButton,
      "header-notification-dropdown": HeaderNotificationDropdown
    },
    template: MAL.headerNotification.templates.root,
    data: {
      items: [],
      isLoaded: false,
      hasNewItems: false,
      dropdownOpenedAt: 0,
      numMoreUnreadItems: 0,
      isDropdownVisible: false,
      wasDropdownOpened: false,
      wasDropdownClosed: false
    },
    created() {
      this.dropdownOpenedAt = MAL.headerNotification.dropdownOpenedAt;
      this.hasNewItems = MAL.headerNotification.hasNewItems;
      this.numMoreUnreadItems = MAL.headerNotification.countDigest;
    },
    computed: {
      hasUnreadItems() {
        return this.numWholeUnreadItems > 0;
      },
      numWholeUnreadItems() {
        return this.numMoreUnreadItems + this.numNewestUnreadItems;
      },
      numNewestUnreadItems() {
        return _.filter(this.items, (item) => !item.isRead).length;
      },
      /**
       * 一度も（dropdownを開いて）表示されていない項目があるか。
       */
      hasNotSeenItems() {
        return !this.wasDropdownOpened && this.hasNewItems
      }
    },
    methods: {
      toggleDropdown() {
        if (this.isDropdownVisible) {
          this.hideDropdown();
        } else {
          this.showDropdown();
        }
      },
      showDropdown() {
        if (!this.wasDropdownOpened) {
          this.ajaxLimitter.one("open-dropdown", (done) => {
            $.ajax({
                url : "/notification/api/request-items.json",
                type: "POST"
              })
              .done((data) => {
                this.isLoaded = true
                this.items = _.map(data.items, createNotificationItem);
                this.numMoreUnreadItems = MAL.headerNotification.countDigest - this.numNewestUnreadItems;
              })
              .fail((err) => {
                console.error(err);
              })
              .always(done);
          });
        }
        this.wasDropdownOpened = true;
        this.isDropdownVisible = !this.isDropdownVisible;
      },
      hideDropdown() {
        this.isDropdownVisible = false;
        this.wasDropdownClosed = true;
      }
    }
  });

  // 表示状態の時dropdown以外の任意の部分のクリックで消えるようにする。
  // 若干イレギュラーであるがVM外部からjQueryで処理。
  $("html").on("click", function hideDropdown(e) {
    if ($(vmNotification.$el).has(e.target).exists()) {
      // notificationボタンが押された時もここに来るが、
      // ボタンには別途表示切替ロジックが割り当てられているので問題ない。
      return;
    }
    vmNotification.hideDropdown();
  });

});
