const $ = window.$
const _ = window._

// JavaScript Document
const Swiper = require("../../common/swiper")

$(() => {
  const storeTopSwiper = new Swiper(".swiper-container", {
    // Optional parameters
    autoplay: 5000,
    loop: true,

    // If we need pagination
    pagination: ".swiper-pagination",

    // Navigation arrows
    nextButton: ".swiper-button-next",
    prevButton: ".swiper-button-prev",
    buttonDisabledClass: ".swiper-button-disabled",

    width: 900,
    height: 300,

    slidesOffsetBefore: 27,

    centeredSlides: true,
    paginationClickable: true,
    spaceBetween: 15,
    roundLengths: true,
  })

  Array.from(document.querySelectorAll(".swiper-container")).forEach(el => {
    const dataCount = parseInt(el.getAttribute("data-swiper"))
    if (dataCount <= 1) {
      storeTopSwiper.params = {
        autoplay: false,
        loop: false,
        loopAdditionalSlides: 0,
        loopedSlides: 0,
        allowTouchMove: false,
      }
      // loopをdefaultでtrueの場合は後からfalseにしても要素がduplicate済なので
      Array.from(document.querySelectorAll(".swiper-container .swiper-slide.swiper-slide-duplicate")).forEach(el => {
        el.style.opacity = "0"
        el.innerHTML = ""
      })
    }
    el.addEventListener("mouseenter", (e) => {
      storeTopSwiper.stopAutoplay()
    })
    el.addEventListener("mouseleave", (e) => {
      storeTopSwiper.startAutoplay()
    })
  })

  // 3 point leader for non-webkit browser
  const ua = window.navigator.userAgent.toLowerCase()
  if (ua.indexOf("edge") > -1 || ua.indexOf("webkit") === -1) {
    Array.from(document.querySelectorAll(".swiper-container .content-book-description.ellipsis")).forEach(el => {
      let wordEllipsis = el.innerHTML
      let cloneEllipsis = el.cloneNode(true)
      const heightEllipsis = el.offsetHeight
      cloneEllipsis.setAttribute("style", `opacity:0; position:absolute; overflow:visible; max-height:none; height:auto; width:${el.offsetWidth}px;`)
      el.parentNode.insertBefore(cloneEllipsis, el.nextElementSibling)
      if (cloneEllipsis.offsetHeight > heightEllipsis) {
        while ((wordEllipsis.length > 0) && (cloneEllipsis.offsetHeight > heightEllipsis)) {
          wordEllipsis = wordEllipsis.substr(0, wordEllipsis.length - 1)
          cloneEllipsis.innerHTML = wordEllipsis + "..."
        }
        el.innerHTML = cloneEllipsis.innerHTML
      }
      cloneEllipsis.parentNode.removeChild(cloneEllipsis)
    })
  }

  // Store search
  const storeSearchFormInput = document.querySelector(".js-manga-store-search")
  if (storeSearchFormInput) {
    const isActiveInputButton = () => {
      const storeSearchFormButton = document.querySelector(`.js-manga-store-search + button[type="submit"]`)
      if (storeSearchFormInput.value.length > 0) {
        storeSearchFormButton.classList.remove("notActive")
        storeSearchFormButton.disabled = false
      } else {
        storeSearchFormButton.classList.add("notActive")
        storeSearchFormButton.disabled = true
      }
    }

    const storeSearchKeyword = () => {
      Array.from(document.querySelectorAll(".js-manga-store-genre-list .manga-store-genre-list-item")).forEach(el => {
        const genreSearchPath = el.href.split("?")[0]
        const genreNum = el.search.match(/genre=(.*?)(&|$)/)
        const paramGenre = (genreNum) ? genreNum[1] : ""
        const paramKeyword = storeSearchFormInput.value
        el.href = `${genreSearchPath}?genre=${paramGenre}&keyword=${paramKeyword}`
      })
    }

    storeSearchFormInput.addEventListener("keyup", () => {
      isActiveInputButton()
      storeSearchKeyword()
    })
    document.addEventListener("DOMContentLoaded", () => {
      isActiveInputButton()
    })
  }

  // Genre
  Array.from(document.querySelectorAll(".js-manga-store-genre-list")).forEach(el => {
    const toggleButton = el.querySelector(".js-manga-store-genre-list-toggle")
    toggleButton.addEventListener("click", () => {
      toggleButton.classList.toggle("is-open")
      Array.from(el.querySelectorAll("li.is-hide")).forEach(e => {
        e.classList.toggle("hide")
      })
    })
  })
})
