var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "canvas-root" }, [
    _c("canvas", {
      ref: "canvas",
      style: _vm.canvasStyle,
      attrs: {
        "moz-opaque": "",
        width: _vm.displayWidth * _vm.pixelDensity,
        height: _vm.displayHeight * _vm.pixelDensity
      }
    }),
    _vm._v(" "),
    _vm.hoverPosition.y > 0 && _vm.hoverPosition.x > 0 && _vm.isCursorShow
      ? _c("div", {
          staticClass: "cursor",
          class: {
            "cursor-arrow":
              (_vm.cursorPosition.arrow || _vm.currentPage !== 1) &&
              _vm.zoomScale === 1,
            "cursor-move": _vm.zoomScale !== 1,
            "cursor-nopage-right":
              _vm.cursorPosition.nopage &&
              _vm.currentPage === 1 &&
              _vm.zoomScale === 1
          },
          style: _vm.cursorStyle
        })
      : _vm._e(),
    _vm._v(" "),
    _c("div", {
      staticClass: "hover-area",
      staticStyle: { "background-color": "rgba(0, 0, 0, 0)" },
      style: { height: _vm.displayHeight - 100 + "px" },
      attrs: { draggable: _vm.draggable },
      on: {
        mouseover: _vm.mouseOver,
        mouseout: _vm.mouseOut,
        click: _vm.canvasClick,
        dblclick: _vm.canvasDlClick,
        mousedown: function($event) {
          $event.preventDefault()
          return _vm.dragStart($event)
        },
        mouseup: _vm.stopDrag,
        mousemove: _vm.mouseMove
      }
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "loading-image",
      style: _vm.loadingImageLeftStyle
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "loading-image",
      style: _vm.loadingImageRightStyle
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }