const $ = window.$;

$(function() {

  // resionの外だったら隠す
  if ($(".js-outside-region")[0]) {
    const $outsideRegion = $(".js-outside-region");
    const videoContentOuterH =  $outsideRegion.height();
    const videoContentInnerH = $outsideRegion.find(".js-video-list-content").height();
    $outsideRegion.find(".js-btn-outside-region").attr("data-height",`{\"outer\":${videoContentOuterH},\"inner\":${videoContentInnerH}}`);

    $(".js-btn-outside-region").on("click", function() {
      const dataHeight = $.parseJSON($(this).attr("data-height"));
      const $outer = $(this).parent(".js-outside-region");
      if($(this).hasClass("open")) {
        $outer.animate( { height: dataHeight.outer});
      } else {
        $outer.animate( { height: dataHeight.inner});
      }
      $(this).toggleClass("open");
      $outer.toggleClass("open");
    });


  }

});
