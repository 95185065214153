var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.id > 0
    ? _c("div", { staticClass: "root", class: [_vm.sp ? "" : "pc"] }, [
        _vm.is_manga && _vm.is_buy
          ? _c("div", [
              _c(
                "a",
                {
                  staticClass: "mal-btn large ga-click ga-impression",
                  class: [_vm.sp ? "sp secondary outline dark" : "pc manga"],
                  attrs: {
                    href: _vm.link,
                    target: "_blank",
                    "data-ga-click-type": _vm.ga_type,
                    "data-ga-impression-type": _vm.ga_type,
                    "data-ga-click-param": _vm.gaParam(),
                    "data-ga-impression-param": _vm.gaParam()
                  },
                  on: { click: _vm.linkClick }
                },
                [
                  _c("i", { staticClass: "fa-solid fa-cart-shopping mr8" }),
                  _vm._v("Buy on Amazon")
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.is_manga && _vm.is_wish
          ? _c(
              "div",
              { staticStyle: { padding: "0 1px", "text-align": "center" } },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "manga mal-btn primary outline large w100 ga-click ga-impression",
                    class: [_vm.is_wishlist ? "secondary pressed" : ""],
                    attrs: {
                      disabled: _vm.is_wishlist,
                      "data-ga-click-type": "buy_manga_wish_manga_title",
                      "data-ga-impression-type": "buy_manga_wish_manga_title",
                      "data-ga-click-param": _vm.gaParam(),
                      "data-ga-impression-param": _vm.gaParam()
                    },
                    on: {
                      click: function($event) {
                        return _vm.addWishList()
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa-solid fa-star-shooting mr8 ga-click",
                      attrs: {
                        "data-ga-click-type": "buy_manga_wish_manga_title",
                        "data-ga-click-param": _vm.gaParam()
                      }
                    }),
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.is_wishlist
                            ? "We'll ask the publisher!"
                            : "Wish for English release"
                        ) +
                        "\n    "
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.is_anime && _vm.is_buy
          ? _c("div", [
              _c(
                "a",
                {
                  staticClass:
                    "mal-btn large secondary outline dark ga-click ga-impression",
                  attrs: {
                    href: _vm.link,
                    target: "_blank",
                    "data-ga-click-type": _vm.ga_type,
                    "data-ga-impression-type": _vm.ga_type,
                    "data-ga-click-param": _vm.gaParam(),
                    "data-ga-impression-param": _vm.gaParam()
                  },
                  on: { click: _vm.linkClick }
                },
                [
                  _c("i", { staticClass: "fa-solid fa-cart-shopping mr8" }),
                  _vm._v("Buy Manga on Amazon")
                ]
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }