<!--
Braintree で PayPal の Checkout ボタンを表示するコンポーネント
Note: コレ使うには以下のスクリプトをグローバルにロードしておく必要があります
- Braintree の client.min.js
- Braintree の paypal-checkout.min.js
- PayPal の checkout.js
-->

<template>
  <div class="paypal-button-container-outer">
    <i v-if="!isRendered" class="fa-solid fa-spinner fa-spin fa-2x"></i>
    <div ref="paypal-button-container">
      <!-- ここに Checkout ボタンがインジェクトされます -->
    </div>
    <div role="alert">
      {{ error }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script type="text/babel">
  let paypalCheckout = null

  export default {
    props: {
      authorizedCallback: Function,
    },
    data() {
      return {
        isRendered: false,
        error: null,
      }
    },
    async mounted() {
      if (!paypalCheckout) {
        const token = document.querySelector(`meta[name="braintree-token"]`).content
        try {
          // paypalCheckout クライアントの初期化
          const client = await braintree.client.create({authorization: token})
          paypalCheckout = await braintree.paypalCheckout.create({client})
        } catch (e) {
          console.error(e)
          this.error = "An error occurred during the initialization."
          return
        }
      }

      if (paypalCheckout) {
        // Checkout ボタンのレンダリング
        paypal.Button.render({
          env: document.querySelector(`meta[name="braintree-env"]`).content,
          payment: () => {
            this.error = null
            return paypalCheckout.createPayment({
              flow: "vault",
              billingAgreementDescription: "MyAnimeList payment agreement",
              enableShippingAddress: false,
            })
          },
          onAuthorize: async (data, actions) => {
            try {
              const {nonce} = await paypalCheckout.tokenizePayment(data)
              await this.authorizedCallback(nonce)
            } catch (e) {
              this.error = "An error occurred."
            }
          },
          onRender: () => {
            this.isRendered = true
          },
        }, this.$refs["paypal-button-container"])
      }
    },
  }
</script>
