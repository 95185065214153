import * as types from "./mutation-types"
import {purchase} from "../../../../common/modules/purchase"
import {NAMESPACE as TAX_NAMESPACE} from "../../../../common/stores/tax_calculation_modal"

export default {
  async [types.PURCHASE]({state, getters}) {
    await purchase({
      itemType: state.itemType,
      itemIds: state.checkedItemIds,
      itemTotalAmount: getters.totalPrice,
      selectedCountry: state[TAX_NAMESPACE].selectedCountry
    })
  },
}
