const $ = window.$;
const sVue = window.sVue;
import CsrfProtector from "../../../../common/modules/CsrfProtector"

$(() => {

  if($(".episode-edit-staff-per-episode")[0]) {
    /* eslint-disable camelcase */
    const formEl = "#staffForm"
    const episodeAnimeStaffList = new sVue({ // eslint-disable-line no-unused-vars
      el: formEl,
      data: {
        keyword: "",
        staffs: $("#staffForm").data("json"),
        pulldown: false,
        hasNoResults: false,
        initialData: null
      },
      created: function () {
        this.initialData = this.generateSubmissionData();
        CsrfProtector.injectTokenToForm(formEl)
      },
      computed: {
        filteredStaffs() {
          const self = this
          const searchRegex = new RegExp(self.keyword, "i")
          return self.staffs.filter((staff) => {
            return searchRegex.test(staff.name)
          })
        }
      },
      methods: {
        getStaffById: function (id) {
          for (let i = 0, len = this.staffs.length; i < len; i++) {
            if (this.staffs[i].id === id) {
              return this.staffs[i];
            }
          }
        },
        addStaff: function (id) {
          this.getStaffById(id).is_added = true;
          this.keyword = "";
          this.pulldown = false;
        },
        deleteStaff: function (id) {
          this.getStaffById(id).is_added = false;
        },
        deleteAllStaffs: function () {
          this.staffs.forEach(function (element) {
            element.is_added = false;
          });
        },
        submitStaffs: function (e) {
          const data = this.generateSubmissionData();

          // レビューページでなければ変更点があるかどうかチェックする
          if (!$("#adminReview").exists()) {
            if (!this.checkModified(data)) {
              e.preventDefault();
              alert("Nothing has been changed!");
              return;
            }
          }

          $("input[name=staffs]").val(JSON.stringify(data));
        },
        generateSubmissionData: function () {
          const data = [];
          this.staffs.forEach(function (staff) {
            if (staff.is_added) {
              const roles = [];
              staff.roles.forEach(function (role) {
                if (role.is_added) {
                  roles.push({role_id: role.role_id});
                }
              });
              data.push({
                id          : staff.id,
                episode_id  : staff.episode_id,
                roles       : roles
              });
            }
          });
          return data;
        },
        checkModified: function (data) {
          // 変更があるかチェック
          if (data.length !== this.initialData.length) {
            return true;
          }
          for (let i = 0; i < data.length; i++) {
            const a = data[i], b = this.initialData[i];
            if (a.id !== b.id || a.episode_id !== b.episode_id) {
              return true;
            }
            if (a.roles.length !== b.roles.length) {
              return true;
            }
            for (let j = 0; j < a.roles.length; j++) {
              const c = a.roles[j], d = b.roles[j];
              if (c.role_id !== d.role_id) {
                return true;
              }
            }
          }
          return false;
        }
      }
    });

    // show/hide Voice Actor
    $(document).on("click", ".js-data-actor", function(e) {
      e.stopPropagation();
      const $block = $(this).next(".js-edit-character-list-block");
      if ($(this).hasClass("on")) {
        $(this).removeClass("on");
        $block.hide();
      } else {
        $(".js-data-role").removeClass("on");
        $(".js-edit-character-list-block").hide();
        $(this).addClass("on");
        $block.css({bottom:(-1 * ($block.height() - 1))}).show();
      }
    });
  }
});
