/**
 * data 属性から store を生成する
 *
 * @param el
 */
import {createBook} from "../../modules/book"
import {getCurrency} from "../../modules/currency"
import store from "../../../common/stores/all_books/index"

export function createStore(el) {
  // 必須
  const items = JSON.parse(el.getAttribute("data-items")).map(i => createBook(i))
  const itemType = Number(el.getAttribute("data-type"))

  const currency = getCurrency(JSON.parse(el.getAttribute("data-currency"))) || null

  // TODO chapter のみ?
  const loginPath = el.getAttribute("data-loginPath") || ""
  const loadItemsPath = el.getAttribute("data-loadItemsPath") || ""
  const numTotalItems = Number(el.getAttribute("data-num-total-items")) || 0
  const numItemsPerPage = Number(el.getAttribute("data-num-items-per-page")) || 0

  return store({
    itemType,
    items,
    loginPath,
    currency,
    loadItemsPath,
    numTotalItems,
    numItemsPerPage,
  })
}

