<template>
  <div class="root">
    <div class="manga" v-if="is_manga">
      <a class="affiliate__buy ga-click ga-impression"
         :data-ga-click-type="ga_type"
         :data-ga-impression-type="ga_type"
         :data-ga-click-param="gaParam()"
         :data-ga-impression-param="gaParam()"
         v-if="is_buy" :href="link" target="_blank"><i class="fa-solid fa-cart-shopping mr4"></i>
        Buy on Amazon</a>
      <a class="affiliate__wish ga-click ga-impression"
         :data-ga-click-type="ga_type"
         :data-ga-impression-type="ga_type"
         :data-ga-click-param="gaParam()"
         :data-ga-impression-param="gaParam()"
         href="javascript:void(0)" @click="addWishList()"
         v-if="is_wish" v-bind:class="[is_wishlist ?  'added' : '']">
        <i class="fa-solid fa-star-shooting mr4"></i>
        {{ is_wishlist ? "We'll ask the publisher!" : "Wish for English release" }}
      </a>
    </div>
    <div class="anime" v-if="is_anime && is_buy">
      <a class="affiliate__buy ga-click ga-impression"
         :data-ga-click-type="ga_type"
         :data-ga-impression-type="ga_type"
         :data-ga-click-param="gaParam()"
         :data-ga-impression-param="gaParam()"
         :href="link" target="_blank"><i class="fa-solid fa-cart-shopping mr4"></i>
        Buy manga on Amazon</a>
    </div>
  </div>
</template>

<script>
import AffiliateAmazon from "./AffiliateAmazon";

export default {
  name: "AffiliateAmazonLink",
  props: {
    type: String,
    id: Number,
    link: String,
    image_url: String,
    title: String,
    volume: String,
    is_wish: Boolean,
    is_buy: Boolean,
    ga_type: String,
  },
  mounted: function () {
    this.is_wishlist = this.affiliate.isWishList(this.id)
  },
  methods: {
    addWishList: function () {
      if (!this.is_wishlist) {
        this.affiliate.addWishList(this.id)
        this.is_wishlist = true;
      }
    },
    gaParam: function () {
      return (this.is_manga ? 'mid' : 'aid') + ':' + this.id;
    }
  },
  data() {
    return {
      is_manga: this.type === 'manga',
      is_anime: this.type === 'anime',
      is_wishlist: false,
      affiliate: AffiliateAmazon(this.$cookie)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../../../../css/_variables.scss";

.root {
  margin: 0.5rem 0 0;

  a {
    font-weight: bold;
  }

  .affiliate__buy {
    color: $color-pc-text-323232 !important;
  }

  .added {
    color: $color-pc-text-888888;
    pointer-events: none;
    animation: fadein .7s forwards;
  }

  .affiliate__with {
    color: $color-pc-text-2e51a2 !important;
  }
}

</style>
