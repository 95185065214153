const $ = window.$;

import GtmJsEvent from "../../common/modules/gtm/jsEvent";

// プロフィールカードのデザインがPC/SPで異なるためいったん別々に実装する
// あとから共通化するのが望ましい
$(function () {
  const PROFILE_CARD_SHOW_TIMEOUT = 500;
  const PROFILE_CARD_HIDE_TIMEOUT = 300;
  const CARD_HTML = `<div class="mal-profile-card">
    <div class="mal-profile-card-container">
      <div class="signature"></div>
      <div class="main">
        <div class="avatar">
          <a class="link ga-click" data-ga-click-type="profilecard-icon"><img class="avatar-icon" src="/images/kaomoji_mal_white.png" /></a>
          <div class="title"></div>
        </div>
        <div class="content">
          <div class="user">
            <div class="item">
              <div class="username ga-click" data-ga-click-type="profilecard-icon"><a class="link"></a></div>
              <div class="registered"></div>
              <div class="posts"></div>
            </div>
            <div class="item">
              <div class="button"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>`;

  jQuery.fn.extend({
    profileCard: function () {
      const $target = $(this);
      const username = $target.attr('rel');
      if (!username) return;

      // GA4インプレッションイベントを送信する
      GtmJsEvent.impression('forum-profilecard', `user:${username}`);

      const $exists = $(`div.mal-profile-card[rel=${username}]`);
      // プロフィールカードが読込済の場合は表示して終了
      if ($exists.length > 0) {
        return $exists.showProfileCard($target);
      }
      // プロフィールカードを新規読込
      $.ajax({
        type: 'GET',
        url: `/profile/${username}/cardinfo`,
        cache: false,
        dataType: 'json',
        timeout: 10000,
        success : function (data) {
          if (!data.result) {
            console.log('Error occured.', data.error.message, data.error.code);
            return;
          }
          const $card = $(CARD_HTML);
          $card.attr('data-id', data.id);
          $card.attr('rel', data.name);
          $('.avatar .link', $card).attr('href', data.url);
          $('.content .username .link', $card).attr('href', data.url);
          $('.content .username .link', $card).text(data.name);
          $('.content .registered', $card).text(data.registered_at);
          if (data.avatar) {
            $('.main .avatar .avatar-icon', $card).attr('src', data.avatar);
          }
          if (data.sectitle) {
            $('.main .avatar .title', $card).append($(`<div class="mal-badge mal-bg-${data.secstr}"></div>`).text(data.sectitle));
          }
          if (data.title) {
            $('.main .avatar .title', $card).append($(`<div class="mal-badge"></div>`).text(data.title));
          }
          if (data.posts) {
            $('.main .content .user .posts', $card).text(data.posts.toLocaleString());
          } else {
            $('.main .content .user .posts', $card).remove();
          }
          if (data.self) {
            $('.main .content .user .item .button', $card).append(`<a href="/editprofile.php?go=forumoptions" class="mal-btn primary ga-click" data-ga-click-type="profilecard-edit-profile">Edit Profile</a>`);
          } else {
            if (data.friend_status == 'friend') {
              $('.main .content .user .item .button', $card).append(`<a class="mal-btn secondary outline disabled mal-btn-friend-request">Friend</a>`);
            } else if (data.friend_status == 'pending') {
              $('.main .content .user .item .button', $card).append(`<a class="mal-btn secondary outline disabled mal-btn-friend-request">Pending Approval</a>`);
            } else if (data.friend_request != 1) {
              $('.main .content .user .item .button', $card).append(`<a class="mal-btn secondary outline disabled mal-btn-friend-request">Add Friend</a>`);
            } else {
              $('.main .content .user .item .button', $card).append(`<a href="/myfriends.php?go=add&id=${data.id}" class="mal-btn primary mal-btn-friend-request ga-click" data-ga-click-type="profilecard-add-friend">Add Friend</a>`);
            }
          }
          $card.appendTo('body');
          $card.showProfileCard($target);
        },
      });
    },
    showProfileCard: function ($element) {
      const $card = $(this);
      const $window = $(window);

      if (window.MAL.profcard_shown) {
        // 同じエレメントに再度ホバーした場合は何もしない
        if ($element.is(window.MAL.profcard_shown)) {
          return;
        }
        // 違うエレメントにホバーした場合は隠す
        $('div.mal-profile-card.show').hideProfileCard();
      }
      // 表示中のユーザ名を設定する
      window.MAL.profcard_shown = $element;

      // 表示位置を計算する
      const cardpos = { left: false, right: false, top: false, bottom: false };
      const anchor_point = $element.offset().top + $element.outerHeight();
      const window_bottom = $(window).scrollTop() + $(window).height();
      // 上下表示を決定する
      if (anchor_point + $card.outerHeight() > window_bottom - 16) {
        // 上表示
        cardpos.top = $element.offset().top - $card.outerHeight() - 8;
      } else {
        // 下表示
        cardpos.top = $element.offset().top + $element.outerHeight() + 8;
      }
      // 左右寄せを決定する
      if ($element.offset().left + $card.outerWidth() >= $window.innerWidth()) {
        // 右寄せ（右側に8px余白を設定して寄せる）
        cardpos.right = $window.innerWidth() - 8;
      } else {
        // 左寄せ（左側をエレメントに揃える）
        cardpos.left = $element.offset().left;
      }
      $card.css(cardpos);
      $card.addClass('show');
      $card.off('mouseout.hide-profile-card');
      $card.on('mouseout.hide-profile-card', function () {
        window.MAL.profcard_hide_timer = setTimeout(function () {
          $card.hideProfileCard();
        }, PROFILE_CARD_HIDE_TIMEOUT);
      });
      return $card;
    },
    hideProfileCard: function () {
      const $card = $(this);
      const username = $card.attr('rel');
      $card.removeClass('show');
      window.MAL.profcard_shown = null;
      clearProfileCardTimer();
    },
  });

  // マウスオーバーでカード表示
  $('body').on('mouseover', '.js-mal-profile-popup', function () {
    clearProfileCardTimer();
    const $target = $(this);
    window.MAL.profcard_show_timer = setTimeout(function () {
      $target.profileCard();
    }, PROFILE_CARD_SHOW_TIMEOUT);
  });
  // マウスアウトでカード除去
  $('body').on('mouseout', '.js-mal-profile-popup', function () {
    clearProfileCardTimer();
    const $target = $(this);
    const username = $target.attr('rel');
    window.MAL.profcard_hide_timer = setTimeout(function () {
      $('.mal-profile-card.show').each(function () {
        $(this).hideProfileCard();
      });
    }, PROFILE_CARD_HIDE_TIMEOUT);
  });
  // カード上にマウス移動した場合はカードの表示切替タイマーをクリアする
  $('body').on('mouseover', '.mal-profile-card', function () {
    clearProfileCardTimer();
  });
});

function clearProfileCardTimer() {
  if (window.MAL.profcard_hide_timer) {
    clearTimeout(window.MAL.profcard_hide_timer);
    window.MAL.profcard_hide_timer = undefined;
  }
  if (window.MAL.profcard_show_timer) {
    clearTimeout(window.MAL.profcard_show_timer);
    window.MAL.profcard_show_timer = undefined;
  }
}
