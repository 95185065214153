import Cookies from "js-cookie"
import {d} from "../../../../../../js/common/modules/store/DRM"
import {getCurrency} from "../../../../../components/manga_store/common/modules/currency"
import {appendTax} from "../../../../../components/manga_store/common/modules/tax"
import {createBook} from "../../../../../components/manga_store/common/modules/book"
import * as cookieNames from "./cookie-names"
import * as types from "./mutation-types"
import uuid from "uuid/v4"

const SVue = window.sVue

export default {
  [types.INITIALIZE](state, {initialState, banned, storeUrl, paymentSettings, termsHtml, supportUrl}) {

    if (initialState.is_preview) {
      state.isPreview = true
      state.loginUrl = initialState.login_url
    } else {
      state.isPreview = false
      state.renewTokenUrl = initialState.renew_token_url
    }

    state.isFree = initialState.is_free

    state.bookmarkUrl = initialState.bookmark_url

    state.isAdShown = initialState.is_ad_shown

    state.currentTimestamp = initialState.current_timestamp

    state.banned = {
      isBanned: banned.isBanned,
      storeUrl: storeUrl,
    }

    state.currentPage = initialState.current_page
    state.numTotalPages = initialState.book.num_pages
    state.currency = getCurrency(initialState.currency)

    state.book = {
      id: initialState.book.id,
      title: initialState.book.title,
      numberingText: initialState.book.numbering_text,
      synopsis: initialState.book.synopsis,
      coverImageBaseUrl: initialState.book.cover_image_base_url,
      storeUrl: storeUrl,
      onSaleAt: initialState.book.on_sale_at,
      closeSaleAt: initialState.book.close_sale_at,
    }

    state.customButton = {
      type: initialState.custom_button.type,
      text: initialState.custom_button.text,
      backgroundColor: initialState.custom_button.background_color,
      color: initialState.custom_button.color,
      backgroundImage: initialState.custom_button.background_image,
      url: initialState.custom_button.url,
    }

    state.customFinish = {
      url: initialState.custom_finish.url,
      scroll: initialState.custom_finish.scroll,
      productId: initialState.custom_finish.product_id,
      useFreeManga: initialState.custom_finish.use_free_manga,
    }

    state.manuscript = {
      imageBaseUrl: initialState.manuscript.image_base_url,
      filenames: initialState.manuscript.filenames,
      queryParamsPart: initialState.manuscript.query_params_part,
      aspectRatio: initialState.manuscript.aspect_ratio,
      isLeftFirst: initialState.manuscript.is_left_first,
      pageProgressionDirection: initialState.manuscript.page_progression_direction,
    }

    state.genres = initialState.genres
    state.authors = initialState.authors

    if (initialState.next_book) {
      state.nextBook = createBook(initialState.next_book)
    }

    // 画像の初期化
    for (let i = 1; i <= state.numTotalPages; i++) {
      const img = new Image()

      SVue.set(state.images, i, {
        page: i,
        url: `${state.manuscript.imageBaseUrl}/${state.manuscript.filenames[i - 1]}`,
        isLoaded: false,
        imageObject: img,
        responseTime: null,
      })
    }

    // Cookie から初期状態を取得
    // 見開きかどうか
    const facingPage = Cookies.get(cookieNames.IS_FACING_PAGE)
    state.isFacingPage = (facingPage === undefined || facingPage === "1")

    // ページ送りの方向
    let pageDirection = undefined
    let defaultPageDirection = state.manuscript.pageProgressionDirection
    if(pageDirection === undefined){
        pageDirection = defaultPageDirection
    }
    state.pageDirection = Number(pageDirection || 1)//デフォルトは1:右から左へページ送り
    //Cookies.set(cookieNames.PAGE_DIRECTION, state.pageDirection, {expires: 3650})//クッキーに保存しない仕様に変更
    
    //左から右へページ送りの場合は注意書を表示する
    if(pageDirection < 0){
        state.modals.showLefttorightModal = true
    }

    // 分析周りの初期化
    state.analytics = {
      logUrl: initialState.log_url,
      sessionId: uuid(),
      i: 0,
      openedTime: Date.now(),
    }

    // Buy Buttonの表示
    if (initialState.is_preview) {
      state.showBuyButton = true
    }

    // Paymentの各種設定情報
    state.payment = {
      defaultPaymentMethod: paymentSettings.defaultPaymentMethod,
      initialPaymentMethod: paymentSettings.initialPaymentMethod,
      registeredCard: paymentSettings.registeredCard ? {
        type: paymentSettings.registeredCard.type,
        digits: paymentSettings.registeredCard.digits,
        isExpired: paymentSettings.registeredCard.is_expired,
        isUsedForMalSupporter: paymentSettings.registeredCard.is_used_for_mal_supporter,
      } : null,
      braintreeRegistrationDatetime: paymentSettings.braintreeRegistrationDatetime,
    }
    state.termsHtml = termsHtml
    state.supportUrl = supportUrl
  },

  /**
   * チュートリアルを表示
   */
  [types.SHOW_TUTORIAL](state, {isNotShown}) {
    state.modals.showTutorialModal = isNotShown
  },

  /**
   * 見開きと単一表示を切り替える
   */
  [types.CHANGE_FACING_TYPE](state, {isFacingPage}) {
    state.isFacingPage = isFacingPage
  },

  /**
   * カーソルがホバーしているかどうか切り替える
   */
  [types.CHANGE_CANVAS_HOVER_STATE](state, {isHover}) {
    state.isHoverCanvas = isHover
  },

  /**
   * ズーム比率を変更する
   */
  [types.CHANGE_ZOOM](state, {zoomScale}) {
    if (zoomScale >= 1 && zoomScale <= 2) {
      state.zoomScale = zoomScale
    }
  },

  /**
   * 画像へのリクエストを送信したことを保存
   */
  [types.REQUESTED_IMAGE](state, {page}) {
    state.images[page].isRequested = true
  },

  /**
   * 取得した画像データをもとに画像を準備
   */
  [types.SETUP_IMAGE](state, {page, contentType, data, responseTime}) {
    // 難読化を解除して blob 作成
    const blob = new Blob([d(data)], {type: contentType})

    // 画像をセットアップ
    // 非同期処理はデバッグで追いづらくなるので Mutation 内に書きたくないが、これが一番シンプルに書けるので妥協...
    state.images[page].imageObject.onload = () => {
      state.images[page].isLoaded = true
      state.newestLoadedImagePage = page
    }
    state.images[page].imageObject.src = window.URL.createObjectURL(blob)
    state.images[page].responseTime = responseTime
  },

  /**
   * 1ページずつ移動する
   */
  [types.MOVE_PAGE](state, {dir}) {
    const move = dir > 0 ? 1 : -1

    let page
    if (!state.isFacingPage) {
      page = state.currentPage + move
    } else {
      // 見開きの場合はまず2ページ進める
      page = state.currentPage + move * 2

      // 右に進む場合は次のページの左ページ、左に進む場合は次のページの右ページに合わせる
      // 左右のページの番号は左始まりかどうかで変わることに注意
      const leftPage = state.manuscript.isLeftFirst ? page + 1 - (page + 2) % 2 : page + (page + 2) % 2
      const rightPage = leftPage - 1
      page = move < 0 ? leftPage : rightPage
    }

    if (page < 1) {
      // No Page モーダルを出す
      state.modals.showNopageModal = true
    } else if (page > state.numTotalPages) {
      // 読了モーダルを出す
      state.modals.showFinishModal = true
    } else {
      if (state.modals.showFinishModal) {
        // 読了モーダルが出ているたら閉じるだけ
        state.modals.showFinishModal = false
      } else {
        state.modals.showNopageModal = false
        state.currentPage = page
      }
    }
  },

  /**
   * 指定ページにジャンプする
   */
  [types.JUMP_PAGE](state, {page}) {
    // 移動できる範囲であれば移動
    if (page >= 1 && page <= state.numTotalPages) {
      state.currentPage = page
    }
  },

  /**
   * 進む方向を変更
   */
  [types.CHANGE_DIRECTION](state) {
    state.pageDirection = state.pageDirection * -1
    //Cookies.set(cookieNames.PAGE_DIRECTION, state.pageDirection, {expires: 3650})//クッキーに保存しない仕様に変更
  },

  /**
   * 署名付き URL のパラメータを更新する
   */
  [types.RENEW_TOKEN](state, {json}) {
    state.manuscript.queryParamsPart = json.query_params_part
  },

  /**
   * 税金をセットする
   */
  [types.SET_TAX](state, taxes) {
    state.nextBook = appendTax(state.nextBook, taxes)
  },

  /**
   * 購入後にフラグを更新する
   */
  [types.PURCHASED](state) {
    state.nextBook.isPossessed = true
  },

  /**
   * 分析ログ出力時に呼ぶ
   */
  [types.LOG](state) {
    state.analytics.i ++
  },

    /**
   * defaultPaymentMethod の設定
   */
  [types.SET_DEFAULT_PAYMENT_METHOD](state, type) {
    state.payment.defaultPaymentMethod = type
  },
  

  /**
   * Modal の表示非表示系
   */
  [types.SHOW_PAYMENT_MODAL](state) {
    state.modals.showPaymentModal = true
  },
  [types.HIDE_PAYMENT_MODAL](state) {
    state.modals.showPaymentModal = false
  },
  [types.HIDE_FINISH_MODAL](state) {
    state.modals.showFinishModal = false
  },
  [types.SHOW_NOPAGE_MODAL](state) {
    state.modals.showNopageModal = true
  },
  [types.HIDE_NOPAGE_MODAL](state) {
    state.modals.showNopageModal = false
  },
  [types.SHOW_STORY_MODAL](state) {
    state.modals.showStoryModal = true
  },
  [types.HIDE_STORY_MODAL](state) {
    state.modals.showStoryModal = false
  },
  [types.SHOW_TUTORIAL_MODAL](state) {
    state.modals.showTutorialModal = true
  },
  [types.HIDE_TUTORIAL_MODAL](state) {
    state.modals.showTutorialModal = false
  },
  [types.SHOW_LEFTTORIGHT_MODAL](state) {
    state.modals.showLefttorightModal = true
  },
  [types.HIDE_LEFTTORIGHT_MODAL](state) {
    state.modals.showLefttorightModal = false
  },
}
