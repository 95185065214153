const _ = window._;

/**
 * TODO: testかく
 * @param {string} str 変換対象文字鉄
 */
export function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * TODO: testかく
 * @param {string} str 変換対象文字鉄
 * @param {string|RegExp} separator 切る文字
 */
export function pascalize(str, separator = "_") {
  return _.map(str.split(separator), ucfirst).join("");
}

export function number_format(str) {
  return (new Intl.NumberFormat('en-US')).format(str);
}

export function word_replies(val) {
  return (val <= 1) ? 'reply' : 'replies';
}
