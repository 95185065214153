"use strict";

const $ = window.$;
const _ = window._;

import Modal from "./modal";

module.exports = {
  plusOneVoteTimeout: null,
  plusOneLastDialog: null,
  plusOneLastAjax: null,
  clearVoteDialog: function() {
    if (this.plusOneVoteTimeout) {
      clearTimeout(this.plusOneVoteTimeout);
      this.plusOneVoteTimeout = null;
    }
    if (this.plusOneLastAjax) {
      this.plusOneLastAjax.abort();
      this.plusOneLastAjax = null;
    }
  },
  showAnimeVoteDialog: function(animeId, episodeNum) {
    this.clearVoteDialog();
    this.plusOneLastDialog = `anime:${animeId}:${episodeNum}`;
    this.plusOneVoteTimeout = setTimeout(function() {
      // Get anime info for episode vote
      this.plusOneLastAjax = $.ajax({
        url: "/includes/ajax.inc.php?t=91",
        type: "POST",
        data: {anime_id:animeId, episode_num:episodeNum},
        datatype: "json",
        timeout: 10000,
        cache: false,
        success: function(data) {
          this.plusOneVoteTimeout = null;
          this.plusOneLastAjax = null;
          // Skip if the next request has already started
          if (this.plusOneLastDialog !== `anime:${animeId}:${episodeNum}`) {
            return;
          }
          const dataJson = JSON.parse(data);
          // Display vote dialog
          if (dataJson.topic_id) {
            Modal.generate();
            Modal.buildVoteDialog(
              dataJson.anime_id,
              dataJson.episode_num,
              dataJson.anime_title,
              dataJson.topic_id,
              dataJson.topic_type,
              true
            );
            Modal.show();
            return;
          }
          // Display topic create dialog
          if (dataJson.error && dataJson.error.code == 31) {
            Modal.generate();
            Modal.buildVoteCreateDialog(
              dataJson.anime_id,
              dataJson.episode_num,
              dataJson.anime_title,
              1
            );
            Modal.show();
          }
        }.bind(this)
      });
    }.bind(this), 500);
  },
  showMangaVoteDialog: function(mangaId, chapterNum) {
    this.clearVoteDialog();
    this.plusOneLastDialog = `manga:${mangaId}:${chapterNum}`;
    this.plusOneVoteTimeout = setTimeout(function() {
      // Get anime info for episode vote
      this.plusOneLastAjax = $.ajax({
        url: "/includes/ajax.inc.php?t=92",
        type: "POST",
        data: {manga_id:mangaId, chapter_num:chapterNum},
        datatype: "json",
        timeout: 10000,
        cache: false,
        success: function(data) {
          this.plusOneVoteTimeout = null;
          this.plusOneLastAjax = null;
          // Skip if the next request has already started
          if (this.plusOneLastDialog !== `manga:${mangaId}:${chapterNum}`) {
            return;
          }
          const dataJson = JSON.parse(data);
          // Display vote dialog
          if (dataJson.topic_id) {
            Modal.generate();
            Modal.buildVoteDialog(
              dataJson.manga_id,
              dataJson.chapter_num,
              dataJson.manga_title,
              dataJson.topic_id,
              dataJson.topic_type,
              true
            );
            Modal.show();
            return;
          }
          // Display topic create dialog
          if (dataJson.error && dataJson.error.code == 31) {
            Modal.generate();
            Modal.buildVoteCreateDialog(
              dataJson.manga_id,
              dataJson.chapter_num,
              dataJson.manga_title,
              2
            );
            Modal.show();
          }
        }.bind(this)
      });
    }.bind(this), 500);
  }
};
