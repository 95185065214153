const sVue = window.sVue
import {
  CommonOption,
  DateRangeOption,
  SeasonOption,
  SortOption,
} from "../../modules/components/ListAdvancedOptions"
import GtmJsEvent from "../../../common/modules/gtm/jsEvent";
import Modal from "../../../common/modules/modal";
import PlusOne from "../../../common/modules/plusone";

const $ = window.$
const _ = window._

$(() => {
  // legacy styleのとき
  if (!$("body").hasClass("ownlist") || !$(".list-table").exists()) {
    // Vote用のfunctionを準備する
    window.MAL.VoteFunc = {
      showAnimeVoteDialog: PlusOne.showAnimeVoteDialog,
      showMangaVoteDialog: PlusOne.showMangaVoteDialog,
      clearVoteDialog: PlusOne.clearVoteDialog,
    };
    // その他は何もしない
    return;
  }

  window.MAL.SNSFunc.initializeFacebook();

  // status, tag などのクエリ情報を取得
  const queryData = $("body").data("query");
  const canEdit   = $("body").data("owner");
  const ownerId   = $("body").data("owner-id");
  const ownerName = $("body").data("owner-name");
  const workType  = $("body").data("work") === "anime" ? "anime" : "manga";

  console.log(queryData);

  // Advanced OptionのVue要素
  let vmAdvanced = null;

  // リストの読み込みが全て終わったか
  let isLoadedAllItems = false;
  // 現在のoffset
  let currentOffset = 0;

  const itemQueue = [];
  let loadingTimerId;
  const vmMain = new sVue({
    el: ".list-table",
    data: {
      canEdit: canEdit,
      items: [],
      broadcasts: [],
      classObject: {
        "edit-disabled": !canEdit,
      },
    },
    methods: {
      loadItems (newItems) {
        // オフセットを増やす
        currentOffset += newItems.length;

        _.map(newItems, (item) => {
          // PHPから渡ってくるデータが数値型となる場合があるので、ここで強制的に文字列に変換
          item.tags = String(item.tags);

          // 各アイテムに編集中かどうかのフラグを持たせる
          item.isScoreInEdit = false;
          item.isTagInEdit = false;
          item.isNoteInEdit = false;
          if (workType === "anime") {
            item.isEpisodeInEdit = false;
          } else {
            item.isChapterInEdit = false;
            item.isVolumeInEdit = false;
          }
        });

        // キューに追加
        Array.prototype.push.apply(itemQueue, newItems);

        // setIntervalが終わっていた場合は新規に開始
        if (!loadingTimerId) {
          const _load = () => {
            // bindしてDOMを作成する
            sVue.set(this, "items", this.items.concat(itemQueue.splice(0, 150)));

            // キューが空になったらタイマーを止める
            if (itemQueue.length === 0) {
              clearTimeout(loadingTimerId);
              loadingTimerId = null;
            }
          };
          loadingTimerId = setInterval(_load, 150);

          // 1回は即時実行
          _load();
        }
      },
      showMore (item) {
        const id = workType === "anime" ? item.anime_id : item.manga_id;
        const $dom = $(`#more-${id}`);
        if ($dom.is(":visible")) {
          $dom.hide();
        } else {
          const $content = $dom.find(".more-content");
          if (!$content.find("table").exists()) {
            $.post("/includes/ajax-no-auth.inc.php?t=6", {
              color: 1,
              id   : id,
              memId: ownerId,
              type : workType
            }, function (data__safe) {
              $dom.find(".more-content").html(data__safe.html);
              $dom.show();
            }, "json");
          } else {
            $dom.show();
          }
        }
      },
      editScore (item) {
        item.isScoreInEdit = false;
        item.score = parseInt(item.score);
        editItem(item, {score: item.score});
      },
      editEpisode (item) {
        item.isEpisodeInEdit = false;

        // 負数の場合は0に
        item.num_watched_episodes = Math.max(parseInt(item.num_watched_episodes), 0);

        // アニメ自体のエピソード数を超える場合
        if (item.anime_num_episodes > 0 && item.anime_num_episodes < item.num_watched_episodes) {
          item.num_watched_episodes = parseInt(item.anime_num_episodes);
        }

        doEditEpisodeProcedure(item);
      },
      editChapter (item) {
        item.isChapterInEdit = false;

        // 負数の場合は0に
        item.num_read_chapters = Math.max(parseInt(item.num_read_chapters), 0);

        // マンガ自体のチャプター数を超える場合
        if (item.manga_num_chapters > 0 && item.manga_num_chapters < item.num_read_chapters) {
          item.num_read_chapters = parseInt(item.manga_num_chapters);
        }

        doEditChapterProcedure(item);
      },
      editVolume (item) {
        item.isVolumeInEdit = false;

        // 負数の場合は0に
        item.num_read_volumes = Math.max(parseInt(item.num_read_volumes), 0);

        // マンガ自体のボリューム数を超える場合
        if (item.manga_num_volumes > 0 && item.manga_num_volumes < item.num_read_volumes) {
          item.num_read_volumes = parseInt(item.manga_num_volumes);
        }

        editItem(item, {num_read_volumes: item.num_read_volumes});
      },
      editTag (item) {
        item.isTagInEdit = false;
        editItem(item, {tags: item.tags});
      },
      incrementEpisode (item) {
        item.num_watched_episodes += 1;
        this.editEpisode(item);
      },
      incrementChapter (item) {
        item.num_read_chapters += 1;
        this.editChapter(item);
      },
      incrementVolume (item) {
        item.num_read_volumes += 1;
        this.editVolume(item);
      },
      generateTagPath (tag) {
        const query = {status: queryData.status, tag: tag};
        if (queryData.preview) {
          query.preview = queryData.preview;
        }
        return `?${$.param(query)}`;
      },
      generateProducerPath (producerId) {
        return `/anime/producer/${producerId}`;
      },
      generateSeasonPath (year, season) {
        return `/anime/season/${year}/${season.toLowerCase()}`;
      },
      generateMagazinePath (magazineId) {
        return `/manga/magazine/${magazineId}`;
      },
      getBroadcast (item) {
        if (workType !== "anime") {
          return null;
        }
        return this.broadcasts[item.anime_id];
      },
      canShowBroadcast (item) {
        const broadcast = this.getBroadcast(item);
        return (broadcast != null && broadcast.count != null && broadcast.count.available > 0);
      },
      showBroadcast (title, subtitle, data) {
        Modal.generate();
        Modal.buildBroadcastDialog(title, subtitle, data);
        Modal.show();
      },
      getIsCompleted (item) {
        if (workType === "anime") {
          return (item.status === 2 && item.is_rewatching !== 1);
        } else {
          return (item.status === 2 && item.is_rereading !== 1);
        }
      },
      showEditableNote (item, event) {
        const currentSelector = ".notes-" + (workType === "anime" ? item.anime_id : item.manga_id);
        if (event.target.closest(`input,a,${currentSelector}`) !== event.currentTarget) {
          // イベントの伝播経路にBBCodeで挿入されたinputタグ・aタグが含まれていた場合は処理をストップ
          return;
        }
        item.isNoteInEdit = true
      },
      inputEditableNote(event) {
        adjustHeight(event.currentTarget);
      },
      editNote (item) {
        const workId = workType === "anime" ? item.anime_id : item.manga_id;
        item.isNoteInEdit = false;
        item.notes = 'Saving...';
        requestEditConvert(
          workId,
          item.status,
          { comments: item.editable_notes }
        ).done((data) => {
          if (data.item && typeof data.item.notes !== 'undefined') {
            item.notes = data.item.notes;
          }
          if (typeof data.sns_data !== 'undefined') {
            window.MAL.SNSFunc.postListUpdates(data.sns_data, workType, workId);
          }
        });
        // 計測イベントを送信
        const sendParam = (workType === "anime" ? 'aid:' : 'mid:') + workId;
        GtmJsEvent.send(`notes-edit-${workType}list`, sendParam);
      },
      getStatusClass (item) {
        switch (parseInt(item.status)) {
          case 1:
            return workType === "anime" ? "watching" : "reading";
          case 2:
            return "completed";
          case 3:
            return "onhold";
          case 4:
            return "dropped";
          case 6:
            return workType === "anime" ? "plantowatch" : "plantoread";
        }
      },
      getContentStatusText (item) {
        if (workType === "anime") {
          if (item.anime_airing_status === 1) {
            return "Airing";
          } else if (item.anime_airing_status === 3) {
            return "Not Yet Aired";
          }
        } else {
          if (item.manga_publishing_status === 1) {
            return "Publishing";
          } else if (item.manga_publishing_status === 3) {
            return "Not Yet Published";
          } else if (item.manga_publishing_status === 4) {
            return "On Hiatus";
          }
        }
      },
      getParsedTags (item) {
        // タグをパースして配列として返す
        // app/Service/Shared/OwnList.php#getParsedTagsの処理に相当
        return _.filter(_.map(item.tags.split(","), (tag) => {
          return tag.trim();
        }), (tag) => {
          return tag !== "";
        });
      },
      editTransition(el) {
        el.focus()
      },
      editResizeTransition (el) {
        adjustHeight(el);
        el.focus();
      },
    },
    created() {
      $(".list-item").show();
      this.broadcasts = $(".list-table").data("broadcasts");
      this.loadItems($(".list-table").data("items"));
    },
  });

  const closeAdvancedOptions = () => {
    $("#advanced-options").hide()
    $("#fancybox-overlay").hide()
  }

  const showAdvancedOptions = () => {
    if (vmAdvanced) {
      $("#advanced-options").show();
    } else {
      // AdvancedOptionsを出すために必要な情報を取ってくる
      const url = `/ownlist/${workType}/advanced_options.json`;
      $.get(url, (data) => {
        // 年月日の配列を生成
        const years  = _.map(_.range(new Date().getFullYear(), data.min_year - 1, -1), (y) => {
          return {value: y, text: y};
        });
        const months = _.map(_.range(1, 13), (m) => {
          return {value: m, text: m};
        });
        const days   = _.map(_.range(1, 32), (d) => {
          return {value: d, text: d};
        });
        years.unshift({value: 0, text: "-"});
        months.unshift({value: 0, text: "-"});
        days.unshift({value: 0, text: "-"});

        // animeとmangaで分岐してオプションのデータを生成
        let modelData;
        if (workType === "anime") {
          modelData = {
            title       : new CommonOption("", null, "s", queryData),
            airingStatus: new CommonOption(0, data.airing_status, "airing_status", queryData),
            producer    : new CommonOption(0, data.producer, "producer", queryData),
            airedDate   : new DateRangeOption({year: years, month: months, day: days}, "aired", queryData),
            season      : new SeasonOption({year: years, season: data.seasons}, queryData),
            sortFirst   : new SortOption(data.sort, "order", queryData),
            sortSecond  : new SortOption(data.sort, "order2", queryData),
          };
        } else {
          modelData = {
            title           : new CommonOption("", null, "s", queryData),
            publishingStatus: new CommonOption(0, data.publishing_status, "publishing_status", queryData),
            magazine        : new CommonOption(0, data.magazine, "magazine", queryData),
            publishedDate   : new DateRangeOption({year : years, month: months, day: days}, "published", queryData),
            sortFirst       : new SortOption(data.sort, "order", queryData),
            sortSecond      : new SortOption(data.sort, "order2", queryData),
          };
        }

        // vueのインスタンスを生成
        vmAdvanced = new sVue({
          el     : "#advanced-options",
          data   : modelData,
          methods: {
            applyFilter () {
              location.href = `?${$.param(this.createQuery())}`;
            },
            clearFilter () {
              for (const d in this.$data) {
                this.$data[d].resetToDefault();
              }
            },
            createQuery () {
              let query = {};
              for (const d in this.$data) {
                query = this.$data[d].toGETParam(query);
              }

              // もしソートのみだったらステータスを付与
              if (_.without(Object.keys(query), "order", "order2").length === 0) {
                query.status = queryData.status;
              }

              // previewを追加
              if (queryData.hasOwnProperty("preview")) {
                query.preview = queryData.preview;
              }

              return query;
            },
            closeAdvancedOptions() {
              closeAdvancedOptions()
            },
          },
          created () {
            $("#advanced-options").show();
          }
        });
      });
    }
  }

  initializeEvents();
  function initializeEvents () {

    // スクロールに追従させるDOM
    const $menuStatus = $("#status-menu");
    let menuStatusV = $menuStatus.offset().top;

    // カバー画像が読み込まれた時に、ヘッダーのoffsetを更新する
    $("#cover-image").on("load", () => {
      menuStatusV = $menuStatus.offset().top;
    });

    // ステータスメニューのフローティング
    $(window).on("scroll", () => {
      const winV = $(window).scrollTop();
      if (winV > menuStatusV) {
        $menuStatus.addClass("fixed");
      } else {
        $menuStatus.removeClass("fixed");
      }

      // ページの下に行ったら追加ロード。
      // 一番下にたどり着いた時の式は
      //   $(window).scrollTop() == $(document).height() - window.innerHeight
      // となるが、ある程度下の方までスクロールしたらロードを開始して欲しいので、
      // windowの高さの半分の猶予を持たせて以下のような式にしている。
      if ($(window).scrollTop() > $(document).height() - window.innerHeight * 1.5) {
        loadMore();
      }
    });

    // Add-Editボタンで新規ウィンドウオープン
    $("table.list-table, .list-menu-float").on("mouseenter", ".List_LightBox", function () {
      $(this).fancybox({
        width         : 980,
        height        : "85%",
        autoScale     : true,
        autoDimensions: true,
        transitionIn  : "none",
        transitionOut : "none",
        type          : "iframe" /*,
        "onComplete"       : function() {
          // This is necessary for the Virool player (so that it can find an out-of-iframe anchor)
          const viroolDiv = "<div id=\"virool\"></div>"
          $("#fancybox-inner").append(viroolDiv)
        } */
      });
    });

    $("#header-menu-button").on("click", () => {
      $("#header-menu-dropdown").toggle();
    });

    $("#advanced-options-button").on("click", () => {
      showAdvancedOptions();
      const $overlay =  $("#fancybox-overlay");
      $overlay.show().css({"opacity": 0.3,"background-color": "#666666"});
      $overlay.on("click", closeAdvancedOptions);
    });


    $("#search-button").on("click", () => {
      $("#search-box").toggleClass("open").find("input").focus();
    });

    $("#search-box input").on("keydown", function (e) {
      if (e.which === 13) {
        const text = $(this).val();
        if (text !== "") {
          const query = {s: text};
          if (queryData.preview) {
            query.preview = queryData.preview;
          }
          location.href = `?${$.param(query)}`;
        }
      }
    }).on("focusout", () => {
      $("#search-box").removeClass("open");
    });

    $("#show-stats-button").on("click", () => {
      const $dom = $(".list-stats");
      if (!$dom.is(":visible")) {
        $dom.slideDown(100);
      } else {
        $dom.slideUp(100);
      }
    });

    // インジケータを隠す
    $("#loading-spinner").hide();
  }

  /**
   * リストを追加でロードする
   */
  function loadMore () {
    // ロードが完了していない場合かつタグ指定がされていない場合に追加ロード
    // (タグ絞り込みは現状全件取得のため)
    if (!$("#loading-spinner").is(":visible") && !isLoadedAllItems && !queryData.tag) {
      // インジケータを表示
      $("#loading-spinner").show();

      // クエリ準備
      const query = _.assign({
        offset: currentOffset
      }, queryData);

      // リストロード
      $.ajax({
        method  : "GET",
        url     : `${ownerName}/load.json`,
        data    : query,
        dataType: "json",
        success (data) {
          if (data.length === 0) {
            isLoadedAllItems = true;
          } else {
            vmMain.loadItems(data);
          }
        },
        complete () {
          // インジケータを消す
          $("#loading-spinner").hide();
        }
      });
    }
  }

  /**
   * 編集する
   */
  function editItem (item, updates, callback) {
    const url = `/ownlist/${workType}/edit.json`;
    const data = updates;
    if (workType === "anime") {
      updates.anime_id = parseInt(item.anime_id);
    } else {
      updates.manga_id = parseInt(item.manga_id);
    }
    updates.status = parseInt(item.status);

    $.post(url, JSON.stringify(data), (data) => {
      const id = workType === "anime" ? updates.anime_id : updates.manga_id;
      window.MAL.SNSFunc.postListUpdates(data, workType, id, {
        onComplete: callback
      });
    });
  }

  /**
   * edit_convertへAjaxリクエスト
   */
  function requestEditConvert (workId, status, optionParam) {
    const param = Object.assign({}, optionParam);
    if (workType === "anime") {
      param.anime_id = parseInt(workId);
    } else {
      param.manga_id = parseInt(workId);
    }
    param.status = parseInt(status);

    return $.ajax({
      type: "POST",
      url: `/ownlist/${workType}/edit_convert.json`,
      data: JSON.stringify(param),
      dataType: "json",
      contentType: "application/json",
    });
  }

  function doEditEpisodeProcedure (item) {
    const updates = {
      num_watched_episodes: item.num_watched_episodes
    };

    const doEdit = () => {
      editItem(item, updates, () => {
        setTimeout(() => {
          askToDiscuss(item.num_watched_episodes, item.anime_id, 0);
        }, 400);
      });
    };

    if (item.num_watched_episodes === item.anime_num_episodes) {
      if (item.status === 2) {
        fancyConfirm("Do you want to set this show as finished rewatching?", "Yes, I am done Rewatching", "Not Finished Rewatching", (result) => {
          if (result) {
            doneRewatching(item.anime_id).always(() => {
              doEdit();
            });
          } else {
            doEdit();
          }
        });
      } else if (item.anime_airing_status === 2) {
        fancyConfirm("Do you want to set this entry as completed?", "Set as Completed", "Do not set as Completed", (result) => {
          if (result) {
            markAnimeComplete(item.anime_id).always(() => {
              // ユーザがCompletedにセットしたので、アップデートする情報もステータスを直してやる
              item.status = updates.status = 2;
              doEdit();
            });
          } else {
            doEdit();
          }
        });
      }
    } else if (item.status === 3 || item.status === 6) {
      fancyConfirm("Move entry to watching?", "Set as Watching", "Do not Move", (result) => {
        if (result) {
          markWatching(item.anime_id).always(function () {
            // ユーザがWatchingにセットしたので、アップデートする情報もステータスを直してやる
            item.status = updates.status = 1;
            doEdit();
          })
        } else {
          doEdit();
        }
      });
    } else {
      doEdit();
    }
  }

  function doEditChapterProcedure (item) {
    const updates = {
      num_read_chapters: item.num_read_chapters
    };

    const doEdit = () => {
      editItem(item, updates, () => {
        setTimeout(() => {
          askToDiscuss(item.num_read_chapters, item.manga_id, item.id);
        }, 400);
      });
    };

    if (item.num_read_chapters === item.manga_num_chapters) {
      if (item.status === 2) {
        fancyConfirm("Do you want to set this series as finished rereading?", "Yes, I am done Rereading", "Not Finished", (result) => {
          if (result) {
            doneRereading(item.manga_id).always(() => {
              doEdit();
            });
          } else {
            doEdit();
          }
        });
      } else {
        fancyConfirm("Do you want to set this entry as completed?", "Set as Completed", "Do not set as Completed", (result) => {
          if (result) {
            markMangaComplete(item.manga_id).always(() => {
              // ユーザがCompletedにセットしたので、アップデートする情報もステータスを直してやる
              item.status = updates.status = 2;
              doEdit();
            });
          } else {
            doEdit();
          }
        });
      }
    } else if (item.status === 3 || item.status === 6) {
      fancyConfirm("Move entry to reading?", "Set as Reading", "Do not Move", (result) => {
        if (result) {
          manga_markReading(item.id).always(function (){
            // ユーザがReadingにセットしたので、アップデートする情報もステータスを直してやる
            item.status = updates.status = 1;
            doEdit();
          })
        } else {
          doEdit();
        }
      });
    } else {
      doEdit();
    }
  }

  function fancyConfirm (message, yesText, noText, callback) {
    let result = false;
    $.fancybox({
      content       : `<div style="width: 300px;font-family: verdana, arial; font-size: 11px; text-align: center;">${message}<div style="margin-top: 5px;"><input type="button" id="fancybox-confirm-yes-button" value="${yesText}">&nbsp;&nbsp;<input type="button" id="fancybox-confirm-no-button" value="${noText}"></div></div>`,
      autoScale     : true,
      onComplete() {
        $("#fancybox-confirm-yes-button").on("click", () => {
          result = true;
          $.fancybox.close();
        });
        $("#fancybox-confirm-no-button").on("click", () => {
          result = false;
          $.fancybox.close();
        });
      },
      onClosed() {
        callback(result);
      }
    });
  }

  // 以下legacyからコピーしてきたメソッド
  function doneRewatching (animeId) {
    return $.post("/includes/ajax.inc.php?t=59", {aid: animeId});
  }

  function doneRereading (mangaId) {
    return $.post("/includes/ajax.inc.php?t=81", {mid: mangaId});
  }

  function markAnimeComplete (animeId) {
    return $.post("/includes/ajax.inc.php?t=18", {aid: animeId});
  }

  function markMangaComplete (mangaId) {
    return $.post("/includes/ajax.inc.php?t=54", {mid: mangaId});
  }

  function markWatching(list_entry_id)
  {
    return $.post("/includes/ajax.inc.php?t=21", {eid:list_entry_id});
  }

  function manga_markReading(list_entry_id)
  {
    return $.post("/includes/ajax.inc.php?t=57", {id:list_entry_id});
  }

  function askToDiscuss (num, id, listId) {
    if (workType === "anime") {
      PlusOne.showAnimeVoteDialog(id, num);
    } else {
      PlusOne.showMangaVoteDialog(id, num);
    }
  }

  function adjustHeight (el) {
    el.style.height = '';
    let newHeight = el.scrollHeight;
    const computedStyle = window.getComputedStyle(el);
    if (computedStyle.boxSizing === 'border-box') {
      newHeight += (Number.parseInt(computedStyle.borderTopWidth, 10) + Number.parseInt(computedStyle.borderBottomWidth, 10));
    } else { // content-box
      newHeight -= (Number.parseInt(computedStyle.paddingTop, 10) + Number.parseInt(computedStyle.paddingBottom, 10));
    }
    el.style.height = `${newHeight}px`;
  }

  // /includes/ajax.inc.php?t=53 でかえってくるHTMLに(onClickで)必要とされる関数だが、モダーンリストにないからここで追加。
  // Legacyのlist.jsから:
  window.notthisseries = listId => {
    $.post("/includes/ajax.inc.php?t=53", {id: listId}, () => {
      $.fancybox.close()
    })
  }
  window.dontAsk = () => {
    $.post("/includes/ajax.inc.php?t=51", {y: 1}, () => {
      $.fancybox.close()
    })
  }
  window.manga_notthisseries = listId => {
    $.post("/includes/ajax.inc.php?t=56", {id: listId}, () => {
      $.fancybox.close()
    })
  }
  window.manga_dontAsk = () => {
    $.post("/includes/ajax.inc.php?t=71", {y: 1}, () => {
      $.fancybox.close()
    })
  }
});
