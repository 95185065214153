const $ = window.$
const _ = window._

import Editor from "../../../common/modules/editor";

$(() => {

  $(".js-profile-reply-comment").on("click", (e) => {
    e.preventDefault()
    const commentId = $(e.currentTarget).data("comment")
    const userId = $(e.currentTarget).data("user-id")
    replyComment(commentId, userId)
  })

  $(".js-profile-user-comment").off("click").on("click", (e) => {
    $(".js-profile-user-comment").prop("disabled", true);
    e.preventDefault()
    const profileMemId = $("input[name=profileMemId]").val()
    const profileUsername = $("input[name=profileUsername]").val()
    const comText = $("textarea[name=commentText]").val()
    $.post("/addcomment.php", {
      commentSubmit: 1,
      profileMemId: profileMemId,
      profileUsername: profileUsername,
      commentText: comText,
      area: 2
    }).done((data) => {
      $(".js-profile-user-comment").prop("disabled", false);
      const response = JSON.parse(data)
      if (response.error) {
        if (response.error == 'banned') {
          location.reload();
          return;
        }
        const $commentForm = $(".comment-form")
        if ($commentForm.children(".error-message").exists()) {
          $commentForm.children(".error-message").val(response.message)
        } else {
          const compiled = _.template(`<div class="error-message"><%- errorMessage %></div>`)
          $commentForm.append($(compiled({errorMessage: response.message})))
        }
        return
      }
      window.location.href = `/profile/${profileUsername}#lastcomment`
      window.location.reload(true)
    })
  })

  $(".js-profile-delete-comment").on("click", (e) => {
    e.preventDefault()
    const commentId = $(e.currentTarget).data("comment")
    deleteComment(commentId)
  })

  $(".js-profile-edit-comment").on("click", (e) => {
    e.preventDefault()
    const commentId = $(e.currentTarget).data("comment")
    editComment(commentId)
  })

  const replyComment = (comId, profileMemId) => {
    const $reply = $(`#reply${comId}`)
    if ($reply.exists() && $reply.is(":visible")) {
      $reply.hide()
      return
    }
    // コメントができるかどうかを確認するリクエストをpostで投げる
    $.post("/addcomment.php", {
      canComment: 1,
      profileMemId: profileMemId,
      area: 2
    }).done((errorMessage__safe) => {
      if (errorMessage__safe) {
        $reply.hide()
        $(`#com${comId}`).append(errorMessage__safe)
      } else {
        showReplyForm(comId, profileMemId, "")
      }
    })
  }

  const showReplyForm = (comId, profileMemId, text) => {
    // 全てのreplyフォームを一旦隠す
    $("div[id^=reply]").hide()
    let replyForm
    if ($(`#reply${comId}`).exists()) {
      replyForm = $(`#reply${comId}`)
      $(`#comreply${comId}`).val(text)
      replyForm.show()
    } else {
      const compiled = _.template(`
<div id="reply<%- comId %>" class="spaceit">
  <textarea id="comreply<%- comId %>" rows="15" class="textarea bbcode-message-editor"><%- text %></textarea>
  <div class="spaceit">
    <input type="button" value="Submit Reply" class="inputButton btn-form-submit fs12 pt4 pb4 btn-profile-comment-reply js-btn-profile-comment-reply" data-comment="<%- comId %>" data-user-id="<%- profileMemId %>">
  </div>
</div>
       `)
      replyForm = $(compiled({comId: comId, profileMemId: profileMemId, text: text}))
      $(`#com${comId}`).append(replyForm)
      Editor.attach(`#comreply${comId}`)
      Editor.focus(`#comreply${comId}`)
      replyForm.ready(() => {
        $(".js-btn-profile-comment-reply").on("click", (e) => {
          e.preventDefault()
          const commentId = $(e.currentTarget).data("comment")
          const userId = $(e.currentTarget).data("user-id")
          submitReplyComment(commentId, userId)
        })
      })
    }
  }

  const submitReplyComment = (comId, profileMemId) => {
    const $comArea = $(`#com${comId}`)
    if ($comArea.children(".loading").exists()) {
      $comArea.children(".loading").show()
    } else {
      $comArea.append(`<img class="loading" src="/images/xmlhttp-loader.gif" />`)
    }

    const comText = $(`#comreply${comId}`).val()
    $(`#reply${comId}`).hide()

    $.post("/addcomment.php", {
      commentSubmit: 1,
      profileMemId: profileMemId,
      commentText: comText,
      area: 2
    }).done((data) => {
      $(".loading").remove()
      const response = JSON.parse(data)
      if (response.error) {
        if (response.error == 'banned') {
          location.reload();
          return;
        }
        showReplyForm(comId, profileMemId, comText)
        if ($comArea.children(".error-message").exists()) {
          $comArea.children(".error-message").val(response.message)
        } else {
          const compiled = _.template(`<div class="error-message"><%- errorMessage %></div>`)
          $comArea.append($(compiled({errorMessage: response.message})))
        }
        return
      }

      $(".error-message").remove()
      $(`#reply${comId}`).remove()
      const compiled = _.template(`
<div id="com<%- commentId %>" class="spaceit">
    <div class="spaceit"><strong><a href="/profile/<%- userName %>"><%- userName %></a></strong> | <small><%- createdAt %></small></div>
    <div id="comtext<%- commentId %>"><%= text %></div>
    <div class="spaceit"><a href="#" class="js-profile-edit-comment" data-comment="<%- commentId %>" title="Edit Comment">Edit</a></div>
</div>
      `)
      const $repliedComment = $(compiled({
        commentId: response.commentId,
        userName: response.userName,
        createdAt: response.createdAt,
        text: response.text
      }))
      $comArea.append($repliedComment)
      $($repliedComment).ready(() => {
        $(".js-profile-edit-comment").on("click", (e) => {
          e.preventDefault()
          const commentId = $(e.currentTarget).data("comment")
          editComment(commentId)
        })
      })
    })
  }

  let openedEdit, lastEditData

  const editComment = (comId) => {
    if (openedEdit === comId) {
      $(`#comtext${comId}`).html(lastEditData)
      openedEdit = false
      lastEditData = false
      return false
    }

    lastEditData = $(`#comtext${comId}`).html()
    openedEdit = comId

    $.post("/includes/ajax.inc.php?t=73", {id: comId}).done((data__safe) => {
      const compiled = _.template(`
<textarea id="newcomtext<%- comId %>" rows="15" class="textarea bbcode-message-editor"><%= data__safe %></textarea>
<div class="spaceit">
  <input type="button" value="Edit Reply" data-comment="<%- comId %>" class="inputButton js-btn-profile-comment-edit">
</div>
       `)
      const editForm = $(compiled({comId: comId, data__safe: data__safe}))
      $(`#comtext${comId}`).html(editForm)
      Editor.attach(`#newcomtext${comId}`)
      Editor.focus(`#newcomtext${comId}`)
      editForm.ready(() => {
        $(".js-btn-profile-comment-edit").on("click", (e) => {
          e.preventDefault()
          const commentId = $(e.currentTarget).data("comment")
          submitEdit(commentId)
        })
      })
    })
  }

  const submitEdit = (comId) => {
    const comText = $(`#newcomtext${comId}`).val()
    $(`#comtext${comId}`).html(`<img src="/images/xmlhttp-loader.gif" />`)
    $.post("/includes/ajax.inc.php?t=74", {
      id: comId,
      text: comText
    }).done((data) => {
      const response = JSON.parse(data);
      if (response.error) {
        if (response.error == 'banned') {
          location.reload();
          return;
        }
      }
      $(`#comtext${comId}`).html(response.message);
      openedEdit = false
      lastEditData = false
    })
  }

  const deleteComment = (comId) => {
    if (!confirm("Are you sure you want to delete this comment?")) {
      return;
    }
    $(`#com${comId}`).html(`<img src="/images/xmlhttp-loader.gif" />`)
    $.post("/includes/ajax.inc.php?t=78", {
      id: comId
    }).done((data) => {
      $(`#comBox${comId}`).hide("slow")
    })
  }

})
