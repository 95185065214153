const $ = window.$;
const _ = window._;

const timeStamp = Date();

$(function () {
    $(".page-forum .forum-watch").on("click", function() {
      const topic_id = $(this).data("id");
      $(`.watch-state-icon[data-topic-id=${topic_id}]`).addClass('fa-spinner fa-spin').removeClass('fa-circle');
      $.ajax({
        type: "POST",
        url: `/forum/${topic_id}/toggle-watch`,
        cache: false,
        data: {},
        dataType: "json",
        timeout: 10000,
        success: function (data) {
          $(`.watch-state-icon[data-topic-id=${topic_id}]`).removeClass('fa-spinner fa-spin').addClass('fa-circle-minus');
          if (data.error) {
            alert(data.error.message);
            return;
          }
          /* Watched */
          if (data.watched) {
            $(`.watch-state-icon[data-topic-id=${topic_id}]`)
              .removeClass('fa-spinner fa-spin')
              .addClass('fa-circle watched')
              .attr('title', 'You are watching this topic');
            return;
          }
          /* Unwatched */
          $(`.watch-state-icon[data-topic-id=${topic_id}]`)
            .removeClass('fa-spinner fa-spin watched')
            .addClass('fa-circle')
            .attr('title', 'You are not watching this topic');
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          $(`.watch-state-icon[data-topic-id=${topic_id}]`).removeClass('fa-spinner fa-spin').addClass('fa-circle-minus');
        },
      });
    });

    $(".page-forum .forum-ignore").on("click", function() {
      const topic_id   = $(this).data("id");
      $(`.ignore-state-icon[data-topic-id=${topic_id}]`).addClass('fa-spinner fa-spin').removeClass('fa-circle-minus');
      $.ajax({
        type: "POST",
        url: `/forum/${topic_id}/toggle-ignore`,
        cache: false,
        data: {},
        dataType: "json",
        timeout: 10000,
        success: function (data) {
          $(`.ignore-state-icon[data-topic-id=${topic_id}]`).removeClass('fa-spinner fa-spin').addClass('fa-circle-minus');
          if (data.error) {
            alert(data.error.message);
            return;
          }
          /* Ignored */
          if (data.ignored) {
            $(`tr[data-topic-id=${topic_id}]`).hide();
            return;
          }
          /* Not Ignored */
          $(`tr[data-topic-id=${topic_id}]`).show();
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          $(`.ignore-state-icon[data-topic-id=${topic_id}]`).removeClass('fa-spinner fa-spin').addClass('fa-circle-minus');
        },
      });
    });
});
