"use strict";

const $ = window.$;
const _ = window._;

import Toast from "./modules/toast";

$(function () {
  $(document).on('click', 'div.codetext button.copycode', function () {
    const $button = $(this);
    const $textarea = $button.siblings('pre');
    if ($textarea.length < 1) return;
    navigator.clipboard.writeText($textarea.text());
    Toast.add('Text copied.', 5000, 'primary');
  });
});
