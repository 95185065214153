const $ = window.$;
const _ = window._;

function addAnimeEntry(animeId, info, options = {}) {
  options = _.assign({
    onSuccess() {},
    onFail() {},
    onSNSPostSuccess() {},
    onSNSPostFail() {},
  }, options);

  $.post("/ownlist/anime/add.json", JSON.stringify({
    anime_id            : parseInt(animeId),
    status              : parseInt(info.status),
    score               : parseInt(info.score),
    num_watched_episodes: parseInt(info.episodes) || 0,
  }), function (data) {
    options.onSuccess(data);
    if (info.side !== "sp") {
      window.MAL.SNSFunc.postListUpdates(data, "anime", animeId, {
        onSuccess: options.onSNSPostSuccess,
        onError  : options.onSNSPostFail,
      });
    }
  })
  .fail((xhr) => {
    options.onFail(xhr.responseJSON.errors[0].message);
  });
}

function updateAnimeEntry(animeId, info, options = {}) {
  options = _.assign({
    onSuccess() {},
    onFail() {},
    onSNSPostSuccess() {},
    onSNSPostFail() {},
  }, options);

  $.post("/ownlist/anime/edit.json", JSON.stringify({
    anime_id            : parseInt(animeId),
    status              : parseInt(info.status),
    score               : parseInt(info.score),
    num_watched_episodes: parseInt(info.episodes) || 0,
  }), function (data) {
    options.onSuccess(data);
    if (info.side !== "sp") {
      window.MAL.SNSFunc.postListUpdates(data, "anime", animeId, {
        onSuccess: options.onSNSPostSuccess,
        onError  : options.onSNSPostFail,
      });
    }
  })
  .fail((xhr) => {
    options.onFail(xhr.responseJSON.errors[0].message);
  });
}

function addMangaEntry(mangaId, info, options = {}) {
  options = _.assign({
    onSuccess() {},
    onFail() {},
    onSNSPostSuccess() {},
    onSNSPostFail() {},
  }, options);

  $.post("/ownlist/manga/add.json", JSON.stringify({
    manga_id         : parseInt(mangaId),
    status           : parseInt(info.status),
    score            : parseInt(info.score),
    num_read_volumes : parseInt(info.volumes) || 0,
    num_read_chapters: parseInt(info.chapters) || 0,
  }), function (data) {
    options.onSuccess(data);
    if (info.side !== "sp") {
      window.MAL.SNSFunc.postListUpdates(data, "manga", parseInt(mangaId), {
        onSuccess: options.onSNSPostSuccess,
        onError  : options.onSNSPostFail,
      });
    }
  })
  .fail((xhr) => {
    options.onFail(xhr.responseJSON.errors[0].message);
  });
}

function updateMangaEntry(mangaId, info, options = {}) {
  options = _.assign({
    onSuccess() {},
    onFail() {},
    onSNSPostSuccess() {},
    onSNSPostFail() {},
  }, options);

  $.post("/ownlist/manga/edit.json", JSON.stringify({
    manga_id         : parseInt(mangaId),
    status           : parseInt(info.status),
    score            : parseInt(info.score),
    num_read_volumes : parseInt(info.volumes) || 0,
    num_read_chapters: parseInt(info.chapters) || 0,
  }), function (data) {
    options.onSuccess(data);
    if (info.side !== "sp") {
      window.MAL.SNSFunc.postListUpdates(data, "manga", parseInt(mangaId), {
        onSuccess: options.onSNSPostSuccess,
        onError  : options.onSNSPostFail,
      });
    }
  })
  .fail((xhr) => {
    options.onFail(xhr.responseJSON.errors[0].message);
  });
}

module.exports = {
  addAnimeEntry,
  updateAnimeEntry,
  addMangaEntry,
  updateMangaEntry,
};
