import PurchaseBulkButton from "./PurchaseBulkButton.vue"
import PreviewButton from "./PreviewButton.vue"
import store from "./store/index"
import * as types from "./store/mutation-types"
import {getCurrency} from "../../../common/modules/currency"

const $ = window.$
const SVue = window.sVue

$(() => {
  // buy button
  Array.from(document.querySelectorAll(".v-manga-store-purchase-bulk-button")).forEach(el => {
    const firstItem = JSON.parse(el.getAttribute("data-first-item"))
    const items     = JSON.parse(el.getAttribute("data-items"))
    const itemType  = JSON.parse(el.getAttribute("data-item-type"))
    const loginPath = el.getAttribute("data-loginPath")
    const currency  = getCurrency(JSON.parse(el.getAttribute("data-currency")))
    const bookshelfComicPath = el.getAttribute("data-bookshelf-comic-path")
    const paymentSettings = JSON.parse(el.getAttribute("data-settings"))
    const termsHtml = el.getAttribute("data-terms-html")
    const supportUrl = el.getAttribute("data-support-url")

    new SVue({
      el,
      store: store(),
      render: h => h(PurchaseBulkButton),
      created() {
        this.$store.commit(types.INITIALIZE, {firstItem, items, itemType, loginPath, currency, bookshelfComicPath, paymentSettings, termsHtml, supportUrl})
      },
    })
  })

  // preview button
  Array.from(document.querySelectorAll(".v-manga-store-first-preview-button")).forEach(el => {
    const firstItem = JSON.parse(el.getAttribute("data-first-item"))

    new SVue({
      el,
      store: store(),
      render: h => h(PreviewButton),
      created() {
        this.$store.commit(types.INITIALIZE, { firstItem })
      },
    })
  })
})
