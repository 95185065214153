import Vuex from "vuex"
import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
import taxStore, {NAMESPACE as TAX_NAMESPACE} from "../../../../../components/manga_store/common/stores/tax_calculation_modal"

const state = {
  // ユーザのban情報
  banned: {
    isBanned: false,
    storeUrl: "",
  },

  // 書籍の基本情報
  book: {
    id: null,
    title: "",
    numberingText: "",
    synopsis: "",
    coverImageBaseUrl: "",
    onSaleAt: "",
    closeSaleAt: ""
  },

  // ジャンル
  genres: [],

  /**
   * 著者
   * authors: {
   *   [Role Name]: Array
   * }
   */
  authors: null,

  // 原稿データの情報
  manuscript: {
    imageBaseUrl: null,
    filenames: [],
    queryParamsPart: null,
    aspectRatio: null,
    isLeftFirst: true
  },

  /**
   * 次巻の書籍情報
   * nextBook: {
   *   title: String,
   *   numberingText: String,
   *   url: String,
   *   viewerUrl: String,
   *   previewUrl: String,
   *   thumbnail: String,
   *   price: String,
   *   salePrice: String,
   *   isPossessed: Boolean,
   *   onSaleAt: String,
   *   closeSaleAt: String,
   * },
  */
  nextBook: null,

  // ユーザの通貨情報
  currency: null,

  // 非ログイン時であればログインするための URL
  loginUrl: null,

  // 読んだページをブックマークするための URL
  bookmarkUrl: null,

  // 署名付き URL のパラメータを再発行するための URL
  renewTokenUrl: null,

  // 試し読みかどうか
  isPreview: false,

  // 無料コンテンツかどうか
  isFree: false,
  
  // 広告表示するかどうか
  isAdShown: false,

  // 現在のタイムスタンプ
  currentTimestamp: null,

  // 無料コンテンツの左上カスタムボタン
  customButton: {
    type: null,
    text: "",
    backgroundColor: null,
    color: null,
    backgroundImage: null,
    url: null,
  },

  // 無料コンテンツの巻末表示
  customFinish: {
    url: null,
    scroll: null,
    productId: null,
    useFreeManga: null,
  },

  // 現在のページ
  currentPage: null,

  // 総ページ数
  numTotalPages: null,

  // 見開きかどうか
  isFacingPage: true,

  // ズーム率
  zoomScale: 1,

  // ページの進行方向
  pageDirection: 1,

  /**
   * 画像データ(動的に読み込まれる)
   * images: {
   *   [page]: {
   *     page: Number,
   *     url: String,
   *     isLoaded: Boolean,
   *     responseTime: Number,
   *     imageObject: Object,
   *   },
   *   ...
   * }
   */
  images: {},

  // 各種モーダルの表示ステータス
  modals: {
    showPaymentModal: false,
    showFinishModal: false,
    showNopageModal: false,
    showStoryModal: false,
    showTutorialModal: false,
    showLefttorightModal: false,
  },

  // キャンバス上にマウスがあるかどうか
  isHoverCanvas: false,

  // 一番最後にロードが完了した画像のページ数で更新される値
  newestLoadedImagePage: null,

  // 分析用のパラメータ
  analytics: {
    // ログの送信先 URL
    logUrl: null,

    // Viewer のセッション ID
    sessionId: null,

    // ログのインデックス
    i: 0,

    // Viewer を開いた時間
    openedTime: null,
  },

  // Paymentの各種設定情報
  payment: {
    defaultPaymentMethod: null,
    initialPaymentMethod: null,
    registeredCard: null,
    braintreeRegistrationDatetime: null,
  },
  termsHtml: null,
  supportUrl: null,
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    [TAX_NAMESPACE]: taxStore(),
  }
})
