/**
 * 通貨を扱う
 */
export class Currency {
  constructor({name, symbol, smallest_unit_ratio}) {
    this.name = name
    this.symbol = symbol
    this.smallestUnitRatio = smallest_unit_ratio
  }

  /**
   * 記号と金額と通貨名をユーザ表示用の文字列にフォーマットする
   */
  formatAll(amount) {
    return this.formatSymbolAndPrice(amount) + " " + this.name
  }

  /**
   * 記号と金額をユーザ表示用の文字列にフォーマットする
   */
  formatSymbolAndPrice(amount) {
    return this.symbol + this.formatPrice(amount)
  }

  /**
   * 金額をユーザ表示用の文字列にフォーマットする
   */
  formatPrice(amount) {
    return (amount / this.smallestUnitRatio).toFixed(2)
  }
}

export function getCurrency(currencyInfo) {
  if (!currencyInfo) {
    return null
  }
  return new Currency(currencyInfo)
}
