var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "button",
      class: { "is-mobile": _vm.isMobile, "is-disable": _vm.isDisable },
      style: _vm.buttonStyle,
      attrs: { target: "_blank" },
      on: { click: _vm.click }
    },
    [_vm._v("Read")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }