<template>
  <div class="root">
    <transition name="modal">
      <div v-show="show" class="modal-content">
        <div class="modal-mask"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <payment-settings-modal
             class="modal-payment-method"
              :default-payment-method = "payment.defaultPaymentMethod"
              :initial-payment-method = "payment.initialPaymentMethod"
              :registered-card = "payment.registeredCard"
              :braintree-registration-datetime = "payment.braintreeRegistrationDatetime"
              :terms-html="termsHtml"
              :support-url="supportUrl"
              @registerdPaymentMethod="registerdPaymentMethod"
            ></payment-settings-modal>
            <a class="modal-close" @click="$emit('hidePaymentModal')">Close</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  @import "../../../../../../css/_variables.scss";
  .modal-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
  }
  .modal-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color-pc-bg-000000, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    font-family: Verdana,Arial;
    position: relative;
    width: 700px;
    margin: 0 auto;
    padding: 20px 40px;
    box-sizing: border-box;
    background-color: $color-pc-bg-ffffff;
    color: $color-pc-text-353535;
    border-radius: 4px;
    transition: all .3s ease;
    max-height: 100vh;
    overflow-y: scroll;
  }
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    content: '';
    display: block;
    width: 29px;
    height: 29px;
    background: url(/images/manga_store/viewer/pc/modal_close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
    cursor: pointer;
  }

  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .dark-mode {
    @import "../../../../../../css/colors_dark";

    .modal-mask {
      background-color: rgba($color-pc-bg-000000, .5);
    }
    .modal-container {
      background-color: $color-pc-bg-ffffff;
      color: $color-pc-text-353535;
    }
  }
</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"
  import * as consts from "../../common/constants"
  import PaymentSettingsModal from "../../../../../components/payments/PaymentSettingsModal.vue"

  export default {
    components: {
      PaymentSettingsModal,
    },
    props: {
      show: Boolean,
      payment: Object,
      termsHtml: String,
      supportUrl: String,
    },
    watch: {
      show() {
        if (this.show) {
          this.$store.dispatch(types.LOG, {
            type: consts.LOG_TYPE_PAYMENT_MODAL
          })
        }
      }
    },
    methods: {
      registerdPaymentMethod() {
        this.$emit("registerdPaymentMethod")
      },
    }
  }
</script>
