const $ = window.$;
let hoverinfo_stack;

$(document).ready(function () {
  $("a.hoverinfo_trigger")
    .css('position', 'relative')
    .hoverIntent({
      sensitivity: 1, // number = sensitivity threshold (must be 1 or higher)
      interval   : 0, // number = milliseconds for onMouseOver polling interval
      over       : showInfo, // function = onMouseOver callback (required)
      timeout    : 100, // number = milliseconds delay before onMouseOut
      out        : hideInfo // function = onMouseOut callback (required)
    });

  $("div.hoverinfo")
    .hoverIntent({
      sensitivity: 1, // number = sensitivity threshold (must be 1 or higher)
      interval   : 0, // number = milliseconds for onMouseOver polling interval
      over       : holdHover, // function = onMouseOver callback (required)
      timeout    : 100, // number = milliseconds delay before onMouseOut
      out        : hideInfoHover // function = onMouseOut callback (required)
    });

  function showInfo() {
    // if there is already a bubble up, remove it (Just hide object)
    if (hoverinfo_stack) {
      $(hoverinfo_stack).css("display", "none");
    }
    $(this).css({display: "inline-block"});
    const btnImgWidth  = $(this).outerWidth();
    const btnImgHeight = $(this).outerHeight();
    const offsetWidth  = 20;
    //remove Class {

    const area  = $(this).attr("id");
    const info  = $(this).attr("rel");
    const sInfo = $(info).attr("rel");

    $(info).addClass("left");
    $(info).addClass("right");
    $(info).addClass("top");
    $(info).addClass("bottom");

    const sType = sInfo.substring(0, 1);
    const sID   = sInfo.substring(1);

    const pos = $(this).position();

    const curleft = pos.left;
    const curtop  = pos.top;

    //init position
    let newleft = curleft + btnImgWidth + offsetWidth;
    let newtop  = curtop + 0;

    $(info).attr("class", "hoverinfo");

    const totalWidth   = $(window).width();
    const totalHeight  = window.innerHeight;// browser height
    const scrollOffset = $(window).scrollTop();

    //adjust position
    if ((newleft + 370) > totalWidth) {
      newleft = curleft - 370 - offsetWidth;
      $(info).addClass("right");
    } else {
      $(info).addClass("left");
    }

    if ((newtop + 270 + 100) >= (totalHeight + scrollOffset)) {
      newtop = curtop - 270 + btnImgHeight;
      $(info).addClass("bottom");
    } else {
      $(info).addClass("top");
    }

    //set position
    $(info).css("left", `${newleft}px`);
    $(info).css("top", `${newtop}px`);

    $(info).css("display", "block");

    let url;
    if (sType == "a")
      url = `/anime/${sID}/hover`;
    else
      url = `/manga/${sID}/hover`;

    // 既に他の hoverinfo が表示されている場合は非表示にする
    $(".hoverinfo").each(function() {
      let hover_info_id = "#" + $(this).attr("id");
      if (hover_info_id !== info) {
        $(hover_info_id).css("display", "none");
      }
    });
    $(info).css("display", "block");

    if (!$(info).data('loaded')) {
      $(info).html("<div class=\"hoverinfo-contaniner\"></div>");
      $(`${info} div`).html('<br/><br/>Loading...');

      $.get(url, function (data__safe) {
        const obj = $(`${info} div`);
        obj.html(`<span></span>${data__safe}`);
        //$(info).css("display", "block");
        hoverinfo_stack = info;
        $(info).data('loaded', true)
      });
    }

  }

  function hideInfo() {
    const info  = $(this).attr("rel");
    const mydiv = $(info);
    if(!mydiv.hasClass('hold-hoverinfo')) {
      mydiv.css("display", "none");
    };
  }

  function holdHover() {
    const infoId  = $(this).attr("id");
    const mydiv = $(`#${infoId}`);

    mydiv.addClass('hold-hoverinfo');
  }

  function hideInfoHover() {
    const infoId  = $(this).attr("id");
    const mydiv = $(`#${infoId}`);

    mydiv.removeClass('hold-hoverinfo');
    mydiv.css("display", "none");
  }
});
