const $ = window.$
const _ = window._

const validGender = [0, 1, 2, 3];

$(() => {
  $('.personal-profile-edit').on("submit", (event) => {
    const selectedGender = Number($('select[name="gender"]').val())
    if (!validGender.includes(selectedGender)) {
      event.preventDefault()
      alert("Please select one of the options of the My Gender section.")
    }
  })
})
