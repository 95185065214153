const $ = window.$;

$(function() {

  //.Columnists
  if($(".columnists-navi-list")[0]){
    $(".columnists-navi-list li").on("click",function(){
      $(".columnists-navi-list li").removeClass("on");
      $(this).addClass("on");

      const id = $(this).data("id");
      if(id == "all"){
        $(".columnists-block-set").show();
      }else{
        $(".columnists-block-set").hide();
        $(`#columnists-block-set-${id}`).show();
      }
    });
  }

  $(".menu-tags-group .btn-category").click(function () {
    const id = $(this).data("id");
    const pos = $(`#${id}`).offset().top;
    $("html,body").animate({ scrollTop: (pos-70) });
    return false;
  });

});
