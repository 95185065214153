"use strict";

import Cookies from "js-cookie";
const $ = window.$;
const _ = window._;

const LOGGING_PATH = "/static/logging.html";

// アクション名の指定ミス防止の為ここに列挙
/*eslint-disable camelcase*/
const ACTIONS = {
  promotion_video    : true,
  click_follow_button: true,
  adblock            : true,
  bcs                : true, // block copy siteの略
  homescreen_prompt  : true,
  daisuki_dragonball : true,
  right_stuf         : true,
  amazon             : true,
  cdjapan            : true,
};
/*eslint-enable*/

const browserId = Cookies.get("MALHLOGSESSID");

module.exports = {
  actionLog: function(action, payload = {}) {
    if (!(action in ACTIONS)) {
      throw `#{action} is nonavailable action name`;
    }

    payload._browser_id = browserId; //eslint-disable-line no-underscore-dangle, camelcase
    payload._action = action; //eslint-disable-line no-underscore-dangle
    return $.ajax(
      {
        url: LOGGING_PATH,
        data: payload,
        cache: false
      }
    );
  },
  actionList: _.keys(ACTIONS)
};
