const $ = window.$;
const _ = window._;

/**
 * AdvancedOptionの基本形
 */
class CommonOption {

  constructor (defaultData, defaultOptions, queryName, query) {
    // データ
    this.default = this.current = defaultData;

    // 選択肢があるならここに
    this.options = defaultOptions;

    // GETのパラメータ名
    this.queryName = queryName;

    this.fromGETParam(query);
  }

  /**
   * GETのパラーメータからデータをセット
   *
   * @param query
   */
  fromGETParam (query) {
    if (query[this.queryName]) {
      this.current = query[this.queryName];
    }
  }

  /**
   * データからGETのパラメータをセット
   *
   * @param query
   * @returns {*}
   */
  toGETParam (query) {
    if (this.current) {
      query[this.queryName] = this.current;
    }
    return query;
  }

  /**
   * 初期設定に戻す
   */
  resetToDefault () {
    this.current = this.default;
  }
}

/**
 * "何年から年々まで" という形式のオプション
 */
class DateRangeOption /* extends CommonOption */ {

  constructor (defaultOptions, querySuffix, query) {
    this.default = this.current = {
      from: {year: 0, month: 0, day: 0},
      to  : {year: 0, month: 0, day: 0},
    };
    this.options = defaultOptions;
    this.querySuffix = querySuffix;

    this.fromGETParam(query);
  }

  fromGETParam (query) {
    _.each(["from", "to"], (range) => {
      _.each(["year", "month", "day"], (date) => {
        const d = query[`${this.querySuffix}_${range}_${date}`];
        if (d) {
          this.current[range][date] = d;
        }
      })
    });
  }

  toGETParam (query) {
    _.each(["from", "to"], (range) => {
      _.every(["year", "month", "day"], (date) => {
        if (this.current[range][date]) {
          query[`${this.querySuffix}_${range}_${date}`] = this.current[range][date];
          return true;
        }
        return false;
      })
    });
    return query;
  }

  resetToDefault () {
    _.each(["from", "to"], (range) => {
      _.each(["year", "month", "day"], (date) => {
        this.current[range][date] = 0;
      })
    });
  }
}

/**
 * シーズン
 */
class SeasonOption /* extends CommonOption */ {

  constructor (defaultOptions, query) {
    this.default = this.current = {year: 0, season: "winter"};
    this.options = defaultOptions;

    this.fromGETParam(query);
  }

  fromGETParam (query) {
    if (query.season_year) {
      this.current.year = query.season_year;
    }
    if (query.season) {
      this.current.season = query.season;
    }
  }

  toGETParam (query) {
    if (this.current.year && this.current.season) {
      query.season_year = this.current.year;
      query.season = this.current.season;
    }
    return query;
  }

  resetToDefault () {
    this.current.year = 0;
    this.current.season = "winter";
  }
}

/**
 * ソート
 */
class SortOption /* extends CommonOption */ {

  /**
   * @param defaultOptions array [ {value: "Title", asc: 1, desc: 10 }, .... }
   * @param queryName
   * @param query
   */
  constructor (defaultOptions, queryName, query) {
    this.default = this.current = {value: 0, dir: "asc"};
    this.options = defaultOptions;
    this.queryName = queryName;

    this.fromGETParam(query);
  }

  /**
   * this.current.column と this.current.dir からorderIDを取得
   * @return int
   */
  getOrderId () {
    const option = _.find(this.options, (option) => {
      return option.value == this.current.value;
    });
    if (!option) {
      return 0;
    } else {
      return option[this.current.dir];
    }
  }

  fromGETParam (query) {
    if (query[this.queryName]) {
      const id     = query[this.queryName];
      const option = _.find(this.options, (option) => {
        return option.asc == id || option.desc == id;
      });
      this.current.value = option.value;
      if (option.asc == id) {
        this.current.dir = "asc";
      } else {
        this.current.dir = "desc";
      }
    }
  }

  toGETParam (query) {
    const id = this.getOrderId();
    if (id) {
      query[this.queryName] = id;
    }
    return query;
  }

  resetToDefault () {
    this.current.value = 0;
    this.current.dir = "asc";
  }
}

module.exports = {
  CommonOption   : CommonOption,
  DateRangeOption: DateRangeOption,
  SeasonOption   : SeasonOption,
  SortOption     : SortOption,
};
