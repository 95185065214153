"use struct";

export function login(path, param, dataLayer){
  if ( path.match("login.php") && document.getElementById('ga_goodresult') ) {
    dataLayer.push({
        'trackPageview': path.concat(param, "&c-ms-mode=2"),
        'member_title': '2-2-1.メールからの会員認証ページ',
        'event': 'loadready-login'
    });
  }
}
