<template>
  <a class="button" target="_blank"
    @click="click"
  >Read</a>
</template>

<style lang="scss" scoped>
  a {
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    line-height: 40px;
    width: 150px;
    padding: 0 10px;
    border: 2px solid #2e51a2;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-weight: 700;
    &:hover {
      background-color: rgba(#2e51a2, 0.2);
      text-decoration: none;
    }
  }
</style>

<script type="text/babel">
  export default {
    methods: {
      click() {
        this.$emit('read')
      }
    },
  }
</script>
