const $ = window.$

$(() => {
  /*
  NDA とかできたらこっち使う
  const check1 = document.querySelector(".admin-mods-agreement .js-mods-agreement-policy#check1")
  const check2 = document.querySelector(".admin-mods-agreement .js-mods-agreement-policy#check2")
  const modAgreementButton = document.querySelector(".admin-mods-agreement .js-btn-form-submit")

  const listener = () => {
    modAgreementButton.disabled = !check1.checked || !check2.checked
  }
  check1.addEventListener("change", listener)
  check2.addEventListener("change", listener)
  */

  const check1 = document.querySelector(".admin-mods-agreement .js-mods-agreement-policy#check1")
  const modAgreementButton = document.querySelector(".admin-mods-agreement .js-btn-form-submit")

  const listener = () => {
    modAgreementButton.disabled = !check1.checked
  }
  if (check1) {
    check1.addEventListener("change", listener)
  }
})
