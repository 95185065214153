var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showConfirmationModal,
                expression: "showConfirmationModal"
              }
            ],
            staticClass: "modal-content"
          },
          [
            _c("div", { staticClass: "modal-mask", on: { click: _vm.cancel } }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-wrapper" }, [
              _c("div", { staticClass: "modal-container" }, [
                _c("h3", { staticClass: "modal-title" }, [
                  _c("i", { staticClass: "fas fa-gift" }),
                  _vm._v("Purchase Gift")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-inner" }, [
                  _c("div", [
                    _c("dl", [
                      _c("dt", [_vm._v("Purchase Detail")]),
                      _vm._v(" "),
                      _c("dd", [
                        _c("span", { staticClass: "item-name" }, [
                          _vm._v(_vm._s(_vm.gift.name))
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "price" }, [
                          _vm._v(
                            _vm._s(_vm.gift.price) +
                              " " +
                              _vm._s(_vm.gift.currency)
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("dt", [_vm._v("Pay For It With")]),
                      _vm._v(" "),
                      _c(
                        "dd",
                        [
                          _vm.paymentMethod.defaultPaymentMethod === "stripe"
                            ? [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "card-info creditcard-information-registered",
                                    attrs: {
                                      "data-type":
                                        _vm.paymentMethod.registeredCard.type
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                      XXXX-XXXX-XXXX-" +
                                        _vm._s(
                                          _vm.paymentMethod.registeredCard
                                            .digits
                                        ) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            : [
                                _vm._v(
                                  "\n                    PayPal\n                  "
                                )
                              ],
                          _vm._v(" "),
                          !_vm.isRequesting
                            ? [
                                _c(
                                  "a",
                                  {
                                    staticClass: "card-edit-link ga-click",
                                    attrs: {
                                      "data-ga-click-type":
                                        "membership-payment-link-edit",
                                      "data-ga-click-param":
                                        "product:" + _vm.gift.gift_id,
                                      href: "javascript: void(0)"
                                    },
                                    on: { click: _vm.editPayment }
                                  },
                                  [_vm._v("Edit")]
                                )
                              ]
                            : [
                                _c("span", { staticClass: "card-edit-link" }, [
                                  _vm._v("Edit")
                                ])
                              ]
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.error,
                            expression: "error"
                          }
                        ],
                        staticClass: "modal-error"
                      },
                      [_vm._v(_vm._s(_vm.error))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-footer" },
                      [
                        !_vm.isRequesting
                          ? [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "mal-btn secondary outline large ga-click",
                                  attrs: {
                                    type: "button",
                                    "data-ga-click-type":
                                      "membership-payment-button-back",
                                    "data-ga-click-param":
                                      "product:" + _vm.gift.gift_id
                                  },
                                  on: { click: _vm.cancel }
                                },
                                [
                                  _c(
                                    "b",
                                    {
                                      attrs: {
                                        "data-ga-click-type":
                                          "membership-payment-button-back",
                                        "data-ga-click-param":
                                          "product:" + _vm.gift.gift_id
                                      }
                                    },
                                    [_vm._v("Back")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "mal-btn primary large",
                                  attrs: { type: "button" },
                                  on: { click: _vm.purchase }
                                },
                                [_c("b", [_vm._v("Purchase")])]
                              )
                            ]
                          : [
                              _c("span", {
                                staticClass: "modal-button-loading"
                              })
                            ]
                      ],
                      2
                    )
                  ])
                ])
              ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }