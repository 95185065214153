var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: { href: _vm.previewUrl, target: "_blank" },
      on: { mouseover: _vm.mouseover, mouseleave: _vm.mouseleave }
    },
    [_vm._v(_vm._s(_vm.buttonText))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }