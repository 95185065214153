
const axios = window.axios

export async function purchase({itemType, itemIds, itemTotalAmount, selectedCountry}) {
  try {
    await axios.post(
      "/store/purchase",
      {
        item_type: itemType,
        item_ids: itemIds,
        item_total_amount: itemTotalAmount,
        selected_country: selectedCountry
      }
    )
  } catch (e) {
    if (!e.response) {
      // network error
      e.message = "A network error has occurred. Please try again."
      throw e
    } else if (e.response && e.response.status === 401) {
      window.location.href = e.response.data.redirect
      throw new Error()
    } else if (e.response && e.response.status === 400) {
      e.name = "BillingCountryError"
      if (e.response.data.errors[0] && e.response.data.errors[0].message) {
        e.message = e.response.data.errors[0].message
      }
      throw e
    } else {
      throw new Error(e.response.data.errors[0].message)
    }
  }
}
