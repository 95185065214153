<template>
    <a class="purchase-button" @click="click">{{ text }}</a>
</template>

<style lang="scss" scoped>
  @import "../../../../../css/variables";

  a.purchase-button {
    border: 2px solid $color-bg-store-purchase-button;
    background-color: $color-bg-store-purchase-button;
    display: inline-block;
    box-sizing: border-box;
    min-width: 180px;
    padding: 0 8px;
    border-radius: 4px;
    text-align: center;
    color: $color-white;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    font-family: Avenir,lucida grande,tahoma,verdana,arial,sans-serif;
    text-decoration: none;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    opacity: 1;
    cursor: pointer;
    &:hover {
      color: $color-white;
      text-decoration: none;
      opacity: 0.7;
    }
  }
</style>

<script type="text/babel">
  export default {
    props: {
      text: String,
    },
    methods: {
      click() {
        this.$emit('purchase')
      }
    }
  }
</script>
