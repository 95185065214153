import GtmJsEvent from "../../../common/modules/gtm/jsEvent";
import Modal from "../../../common/modules/modal";

const $ = window.$;

$(function() {
  if ($(".js-history-updates-item")[0]) {
    const historyUpdates = {
      requestingIds: {},
      showNotesText: ($targetItem) => {
        $targetItem.find(".js-history-updates-show-notes").addClass("hidden")
        $targetItem.find(".js-history-updates-notes-text").removeClass("hidden")
        historyUpdates.showReadMore($targetItem)
      },
      hideNotesText: ($targetItem) => {
        $targetItem.find(".js-history-updates-show-notes").removeClass("hidden")
        $targetItem.find(".js-history-updates-notes-text").addClass("hidden")
        $targetItem.find(".js-history-updates-read-more").addClass("hidden")
      },
      showMoreText: ($targetItem) => {
        $targetItem.find(".js-history-updates-read-more").addClass("hidden")
        $targetItem.find(".js-history-updates-notes-text").removeClass("show-less")
      },
      showReadMore: ($targetItem) => {
        $targetItem.each((index, element) => {
          const $element = $(element);
          const $notesTextInner = $element.find(".js-history-updates-notes-text-inner")
          if ($notesTextInner && $notesTextInner.height() > $element.find(".js-history-updates-notes-text").height()) {
            $element.find(".js-history-updates-read-more").removeClass("hidden")
          }
        })
      },
      showMuteFriendBtn: ($targetItem) => {
        $targetItem.find(".js-history-updates-mutefriend").removeClass("hidden")
        $targetItem.find(".js-history-updates-unmute").addClass("hidden")
      },
      hideMuteFriendBtn: ($targetItem) => {
        $targetItem.find(".js-history-updates-mutefriend").addClass("hidden")
        $targetItem.find(".js-history-updates-unmute").removeClass("hidden")
      },
      requestsMuteNotes: (id, isMuteNotes) => {
        return $.ajax({
          url: "/friends/api/mute_notes.json",
          data: JSON.stringify({
            "user_id": id,
            "is_mute_notes": isMuteNotes
          }),
          contentType: "application/json",
          type: "POST",
        }).catch((jqXHR) => {
          if (jqXHR.status === 401) {
            window.location.href = jqXHR.responseJSON.redirect;
          }
          return jqXHR;
        });
      }
    }
    $(".js-history-updates-show-notes").on("click", function() {
      historyUpdates.showNotesText($(this).closest(".js-history-updates-item"))
    })
    $(".js-history-updates-read-more").on("click", function() {
      historyUpdates.showMoreText($(this).closest(".js-history-updates-item"))
    });
    $(".js-history-updates-comment").on("click", function() {
      const $targetItem = $(this).closest(".js-history-updates-item")
      Modal.generate()
      Modal.buildProfileCommentDialog(
        $targetItem.attr("data-user-id"),
        $targetItem.attr("data-user-name"),
        $targetItem.attr("data-work-title"),
        $targetItem.attr("data-work-url"),
        $targetItem.attr("data-status"),
        $targetItem.attr("data-notes"),
        () => {
          $(this).html('<i class="fas fa-check"></i>Sent').addClass("sent")
          GtmJsEvent.send("notes-sent-comment-history")
        }
      )
      Modal.show()
    });
    $(".js-history-updates-mutefriend").on("click", function() {
      const userId = $(this).closest(".js-history-updates-item").attr("data-user-id")
      if (historyUpdates.requestingIds[userId]) {
        return;
      }
      historyUpdates.requestingIds[userId] = true;
      historyUpdates.requestsMuteNotes(parseInt(userId), true).then(() => {
        const $targetItems = $(`.js-history-updates-item[data-user-id="${userId}"]`)
        historyUpdates.hideNotesText($targetItems)
        historyUpdates.hideMuteFriendBtn($targetItems)
      }).always(() => {
        historyUpdates.requestingIds[userId] = false;
      });
    });
    $(".js-history-updates-unmute").on("click", function() {
      const userId = $(this).closest(".js-history-updates-item").attr("data-user-id")
      if (historyUpdates.requestingIds[userId]) {
        return;
      }
      historyUpdates.requestingIds[userId] = true;
      historyUpdates.requestsMuteNotes(parseInt(userId), false).then(() => {
        const $targetItems = $(`.js-history-updates-item[data-user-id="${userId}"]`)
        historyUpdates.showMuteFriendBtn($targetItems)
        if ($(".js-history-updates").data("setting-show-notes")) {
          historyUpdates.showNotesText($targetItems)
        }
      }).always(() => {
        historyUpdates.requestingIds[userId] = false;
      });
    });
    historyUpdates.showReadMore($(".js-history-updates-item"));
  }
})
