const $ = window.$;

$(function () {

  if ($(".js-btn-sns-setting")[0]) {
    $(".js-btn-sns-setting").on("click", function() {
      $(`.js-${$(this).attr("id")}`).toggle();
      $(this).find(".js-btn-sns-setting-text").toggle();
    });
  }

  //switch
  if ($(".js-sns-setting-switch")[0]) {
    $(".js-sns-setting-switch").on("click", function() {
      //view
      const $self = $(this);

      const permission = $(this).hasClass("on") ? "disable" :"enable";
      const param = $(this).data("json");

      if (param.sns === "twitter") {
        let twitterEvent;
        switch (param.event) {
          case "started_watching_anime":
            twitterEvent = ".js-twitter-started_watching_anime-prefix";
            break;
          case "completed_watching_anime":
            twitterEvent = ".js-twitter-completed_watching_anime-prefix";
            break;
          case "watched_anime_episode":
            twitterEvent = ".js-twitter-watched_anime_episode-prefix";
            break;
          case "started_reading_manga":
            twitterEvent = ".js-twitter-started_reading_manga-prefix";
            break;
          case "completed_reading_manga":
            twitterEvent = ".js-twitter-completed_reading_manga-prefix";
            break;
          case "read_manga_chap_vol":
            twitterEvent = ".js-twitter-read_manga_chap_vol-prefix";
            break;
        }
        var $twitterEvent = $(twitterEvent);
        $twitterEvent.toggle(permission === "enable");
      }

      if (param.sns === "twitter") {
        $self.toggleClass("on")
        const data = {
          event: param.event,
          permission: permission
        }

        // set error handler
        window.MAL.SNSFunc.setPermitTwitterOnError(() => {
          // errorのときは表示をoffに戻す
          $self.toggleClass("on")
          $twitterEvent.toggle(false)
        })

        // ユーザのクリック動作に対して同期的に window を開かないと、ブラウザにブロックされてしまう
        // 本来はすでに認可されている場合は window を開く必要がないが、非同期的に window.open をすることができないので、
        // 最初に window を開いておいて、認可が不要であればすぐに閉じ、認可が必要であれば認可URLに遷移させるようにしている
        const left = (screen.width - 600) / 2
        const top  = (screen.height - 350) / 2
        const authWindow = window.open(
          "about:blank",
          "popup",
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=600, height=350, top=${top}, left=${left}`
        )

        $.ajax({
          url: `/ownlist/permission/twitter?${$.param(data)}`,
          type: "PUT",
          dataType: "json",
          success() {
            authWindow.close()
          },
          error(res) {
            switch (res.status) {
              case 401:
                // Twitter側での認可が必要なので、認可ページに遷移させる
                authWindow.location = res.responseJSON.errors[0].message
                break
              default:
                window.MAL.SNSFunc.permitTwitter(false, res.responseJSON.errors[0].message)
                break
            }
          }
        })
      }

    });

  }


  //Twitterのcommentのtext
  if ($(".js-btn-sns-prefix")[0]) {
    $(".js-btn-sns-prefix").on("click", function() {
      const url    = "/sns/twitter/message.json";
      const event  = $(this).data("event");
      let
        $body    = $(`#twitter-message-${event}`)
      , $error   = $(`#js-twitter-error-msg-${event}`)
      , $success = $(`#js-twitter-success-msg-${event}`)
      ;

      const data = {
        event: event,
        body: $body.val()
      };

      $.ajax({
        url: url,
        type: "PUT",
        data: JSON.stringify(data),
        dataType: "json",
        contentType: "application/json",
        cache: false,
        timeout: 10000,
        beforeSend: function () {
          $(this).prop("disabled", true);
          $body.prop("disabled", true);
          $error.hide();
          $success.hide();
        },
      })
      .always(function () {
        $(this).prop("disabled", false);
        $body.prop("disabled", false);
      })
      .done(function (data) {
        $success.text("Updated successfully.");
        $success.fadeIn().delay(3000).fadeOut("slow");
      })
      .fail(function (xhr, status) {
        let msg;
        if (xhr.status===400) {
          msg=xhr.responseJSON.errors[0].message;
        } else if (xhr.status===401) {
          msg="Login required.";
        } else {
          msg="Unknown errors occurred.";
          console.error(xhr.responseJSON);
        }
        $error.text(msg);
        $error.fadeIn();
      })
      ;
    });
  }

  //Default Setting
  if ($(".js-btn-sns-default-setting")[0]) {
    $(".js-btn-sns-default-setting").on("click", function() {
      const work_type = $(this).attr("id"); // anime or manga
      const data = {
        work_type: work_type,
        post_type: $(`input[name=\"default-setting-${work_type}\"]:checked`).val()
      };
      const $success = $(`#js-default-post-type-${work_type}-success`);
      const $error = $(`#js-default-post-type-${work_type}-error`);

      $.ajax({
        url: "/ownlist/default_post_type/save.json",
        type: "POST",
        data: JSON.stringify(data),
        dataType: "json",
        cache: false,
        timeout: 10000,
        success: function(data) {
          $success.text("Updated successfully.");
          $success.fadeIn().delay(3000).fadeOut("slow");
        }
      })
      .fail(function (data) {
        $error.text(data.responseJSON.errors[0].message);
        $error.fadeIn().delay(3000).fadeOut("slow");
      });
    });
  }

});
