import * as types from "./mutation-types"

const SVue = window.sVue

/* eslint-disable no-console */
export default {
  [types.INITIALIZE](state, {gift, termsHtml, supportUrl}) {
    state.gift = gift
    state.termsHtml = termsHtml
    state.supportUrl = supportUrl
  },

  [types.CHECK_PAYMENT_SETTING](state, paymentSetting) {
    state.showLoadingModal = false
    state.payment.defaultPaymentMethod = paymentSetting.default_payment_method
    if (!paymentSetting.default_payment_method) {
      state.payment.initialPaymentMethod = "paypal"
    }
    if (paymentSetting.registered_card) {
      SVue.set(state.payment, "registeredCard", {
        digits: paymentSetting.registered_card.digits,
        type: paymentSetting.registered_card.type,
        isExpired: paymentSetting.registered_card.is_expired,
        isUsedForMalSupporter: paymentSetting.registered_card.is_used_for_mal_supporter,
      })
    } else {
      SVue.set(state.payment, "registeredCard", null)
    }
  },

  /**
   * MAL Support 購入
   * @param state
   */
  [types.PURCHASE](state) {
  },

  [types.LOADING_MODAL](state, flag) {
    state.showLoadingModal = flag
  },

  [types.CONFIRMATION_MODAL](state, flag) {
    state.showConfirmationModal = flag
  },
  [types.FINISH_MODAL](state, flag) {
    state.showFinishModal = flag
  },

  /**
   * Modal の表示非表示系
   */
  [types.SHOW_PAYMENT_MODAL](state) {
    state.modals.showPaymentModal = true
  },
  [types.HIDE_PAYMENT_MODAL](state) {
    state.modals.showPaymentModal = false
  },
}
