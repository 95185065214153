<template>
  <div class="item" :style="{ width : width ? ( width + 'px') : 'auto' }">
    <a :href="urlWithLocation(item.url, location)">
      <div class="cover-wrap">
        <span v-lazy:background-image="coverImage" class="cover-image"></span>
      </div>
      <recommendation-gauge
        v-if="isRecommendation === true"
        :score="item.score"
      ></recommendation-gauge>
      <div class="title-wrap" :style="{ width : width ? ( (width - 20) + 'px') : 'auto' }">
        <div class="title" ref="ellipsis">{{ item.title }}</div>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
  $title-color : #333;
  $price-color : #d93f32;
  $price-sale-color : #FF2851;
  $title-line-height : 14px;

  /* Style */
  .item {
    display: table-cell;
    text-align: center;
    padding: 0 10px;
    font-family: Verdana,Arial;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    a {
      text-decoration: none;
    }
  }
  .cover-wrap {
    position: relative;
    width: 100%;
    height: 140px;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 4px;
  }
  .cover-image {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 140px;
    width: 95px;
    box-sizing: border-box;
    vertical-align: bottom;
    transition-duration: .3s;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    opacity: 1;
    &:hover {
      opacity: 0.8;
    }
  }
  .cover-image[lazy=loading] {
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    .cover-image {
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
    }
  }
  .title-wrap {
    margin: 7px 0;
  }
  .title {
    display: inline-block;
    width: 100%;
    color: $title-color;
    font-size: 12px;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    line-height: $title-line-height;
    overflow: hidden;
    max-height: $title-line-height * 2;
  }
</style>

<script type="text/babel">
  import * as book from "../../common/modules/book"
  import RecommendationGauge from "../atoms/RecommendationGauge.vue"

  export default {
    components: {
      RecommendationGauge,
    },
    mounted() {
      let elementEllipsis = $(this.$refs.ellipsis)
      let wordEllipsis = elementEllipsis.html()
      let cloneEllipsis = elementEllipsis.clone()
      const heightEllipsis = elementEllipsis.height()

      cloneEllipsis.css({
        'opacity'   : '0',
        'position'  : 'absolute',
        'overflow'  : 'visible',
        'max-height': 'none',
        'height'    : 'auto'
      })
      .width(elementEllipsis.width())
      .height('auto')

      elementEllipsis.after(cloneEllipsis)

      while((wordEllipsis.length > 0) && (cloneEllipsis.height() > heightEllipsis)) {
        wordEllipsis = wordEllipsis.substr(0, wordEllipsis.length - 1)
        cloneEllipsis.html(wordEllipsis + '...')
      }

      elementEllipsis.html(cloneEllipsis.html())
      cloneEllipsis.remove()
    },
    props: {
      /**
       * @param {String} item.title
       * @param {String} item.url
       * @param {String} item.thumbnail
       */
      item : Object,
      width: Number,
      location: String,
      isRecommendation: Boolean,
    },
    computed: {
      coverImage() {
        const imageSize = (window.devicePixelRatio > 1) ? book.COVER_IMAGE_SIZE_M_2X : book.COVER_IMAGE_SIZE_M
        return {
          src: book.generateCoverImageUrl(this.item.coverImageBaseUrl, imageSize),
          loading: "/images/xmlhttp-loader.gif?v=171101"
        }
      }
    },
  }
</script>
