const axios = window.axios
const _ = window._
const CURRENT_TUTORIAL_STEP_ID = window.MAL.CURRENT_TUTORIAL_STEP_ID
const USER_NAME = window.MAL.USER_NAME

window.addEventListener("DOMContentLoaded", () => {

  // Get by template
  const step = CURRENT_TUTORIAL_STEP_ID

  // Tutorial messages and anchors
  const tutorial = [
    {
      anchor: "/topanime.php",
      message: "First, add anime to your list"
    },
    {
      anchor: `/animelist/${USER_NAME}`,
      message: "Next, check your anime list"
    },
    {
      anchor: "/anime/season",
      message: "Let’s check out the latest anime!"
    }
  ]

  const insertTutorial = () => {
    // .initialize-tutorial--disabled があったら実行させない
    if (document.querySelector(".initialize-tutorial--disabled") !== null) {
      return
    }
    // Create DOM contents
    const DOM = document.createElement("div")
    DOM.className = "initialize-tutorial"
    if (document.querySelector(".list-notice-import-statement") !== null) {
      DOM.className += " warning"
      DOM.innerHTML = _.template(`
      <div class="initialize-tutorial__message">
        <i class="fa-solid fa-circle-exclamation" aria-hidden="true"></i> Warning: The @import statement is only available when viewing your own list. URL statements only work for *.jpg, *.gif, *.png images.
      </div>
      `)()
    } else {
      DOM.innerHTML = _.template(`
    <div class="initialize-tutorial__message">
      <i class="fa-regular fa-lightbulb" aria-hidden="true"></i> Hint: <a href="<%- anchor %>"><%- message %></a> (<%- step %>/3)
    </div>
    <div class="initialize-tutorial__close">
      <i class="fa-solid fa-xmark" aria-hidden="true"></i>
    </div>
    `)({anchor: tutorial[step - 1].anchor, message: tutorial[step - 1].message, step: step})
    }

    // Create Style Tag
    /* チュートリアルは全ページ同じスタイルをあてたい
     * しかし、`/ownlist/:username`には共通のCSSが当たらない
     * そこで CSS in JS することにした
     */
    const STYLE = document.createElement("style")
    STYLE.innerHTML =
    `
    .initialize-tutorial {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-height: 80px;
      padding: 12px;
      font-size: 12px;
      line-height: 1.3;
      background-color: #fffefa;
      border-left: 1px solid #faebcc;
      border-right: 1px solid #faebcc;
      border-bottom: 1px solid #faebcc;
      box-sizing: border-box;
      opacity: 1.0;
      transition: all 0.45s ease;
    }

    .initialize-tutorial.warning {
      color: #a94442;
      background-color: #f2dede;
      border-left: 1px solid #ebcccc;
      border-right: 1px solid #ebcccc;
      border-bottom: 1px solid #ebcccc;
    }

    .initialize-tutorial--is-hidden {
      overflow: hidden;
      opacity: 0;
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    .initialize-tutorial a {
      color: #8a6d3b;
    }
    
    .initialize-tutorial a:hover {
      text-decoration: underline;
    }
    
    .initialize-tutorial a:visited {
      color: #8a6d3b;
    }
    
    .initialize-tutorial__close {
      cursor: pointer;
    }
    `
    
    // Insert DOM contents & Style tag
    if (document.querySelector("body.ownlist")) {
      const bodyTag = document.querySelector("body")
      document.querySelector("body.ownlist").insertBefore(DOM, bodyTag.firstChild)
      document.querySelector("head").appendChild(STYLE)
      
    } else {
      document.querySelector("#contentWrapper > div:nth-child(1) > .h1").parentNode.appendChild(DOM)
      document.querySelector("head").appendChild(STYLE)
    }
  }
  
  const skipTutorial = () => {
    
    // Clickable
    const $tutorial = document.querySelector('.initialize-tutorial')
    const $closeBtn = document.querySelector('.initialize-tutorial__close')
    
    // Skip
    $closeBtn.addEventListener("click", (e) => {
      axios.delete(`/users/me/tutorials/${step}.json`)
        .catch(err => {
          console.error("[ERR]", err)
        }
      )
      $tutorial.classList.add("initialize-tutorial--is-hidden")
    }, false)

    // No tutorials

  }
  
  const isClassicList = () => {
    const isListPage = document.querySelector('.ownlist')
    const isClassicPage = document.querySelector('.list-table') ? false : true
    
    if (isListPage && isClassicPage) {
      return true;
    } else {
      return false;
    }
  }

  if (step && !isClassicList()) {
    insertTutorial()
    skipTutorial()
  }
})
