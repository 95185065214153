import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  fromEventPattern,
  merge,
  Observable,
  Subject,
  take
} from "rxjs";

if ('customElements' in window) {
  const MAL_TOOLTIP_ARROW_MARGIN = 14;
  const MAL_TOOLTIP_AREA_MARGIN = 12;
  const MAL_TOOLTIP_POSITION_TOP = 'top';
  const MAL_TOOLTIP_POSITION_RIGHT = 'right';
  const MAL_TOOLTIP_POSITION_BOTTOM = 'bottom';
  const MAL_TOOLTIP_POSITION_LEFT = 'left';
  customElements.define('mal-tooltip', class extends HTMLElement {
    constructor() {
      super();
      this.disconnectFn = [];
      // console.log('Constructed', this);
      const shadow = this.attachShadow({mode: 'open'});
      shadow.innerHTML = `
        <style>
          .header {
            font-weight: bold;
            margin: 0;
            text-align: left;
            margin-right: 2em;
            white-space: nowrap;
          }
          .header + div {
            margin-top: .5em;
            margin-right: 1.5em;
            white-space: nowrap;
          }
          ul {
            padding-inline-start: 2em;
          }
        </style>
        <div part="tooltip">
          <span class="close" part="icon"></span>
          <p class="header">Tooltip Header</p>
          <div>
            <slot></slot>
          </div>
          <div part="bg-arrow"></div>
          <div part="arrow"></div>
        </div>
      `;

      this.isShown$ = new BehaviorSubject(false);
      this.animationState$ = new Subject();

      //DOM内部で変更があった場合イベント通知して、再描画を動かす
      fromEventPattern((handler) => {
        const observer = new MutationObserver(() => {
          this.dispatchEvent(new CustomEvent('mal-tooltip-mutation-event'))
        })
        observer.observe(this.shadowRoot.querySelector('div > div'), {childList: true, attributes: true, subtree: true}) //DOMの変更を検知する。例えばアニメーションでstyleが変わる時など
        return () => observer.disconnect();
      })

      //画面内に表示されたらアニメーションを開始する
      this.isIntersecting$ = fromEventPattern((handler) => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            handler(entry.isIntersecting);
            this.dispatchEvent(new CustomEvent('mal-tooltip-intersection-event', {
              isIntersecting: entry.isIntersecting
            }));
          })
        }, {
          rootMargin: '0px',
          threshold: 0.5
        });
        observer.observe(this.shadowRoot.querySelector('div > div'));
        return () => observer.disconnect();
      })

      //show/hideの制御
      const animationTrigger$ = combineLatest([this.isIntersecting$, this.isShown$]);
      const animationSubscribe = animationTrigger$.subscribe(([isIntersecting, isShown]) => {
        if (isIntersecting && isShown) {
          this.animationState$.next('show');
        } else if (isIntersecting) {
          this.animationState$.next('close');
        }
      })

      const animationStateSubscription = this.animationState$.pipe(distinctUntilChanged()).subscribe((state) => {
        if (state === 'show') {
          this.style.animationDelay = this.getAttribute('delay');
          this.setAttribute('animation', 'show-' + this.position);
          fromEvent(this, 'animationend').pipe(take(1)).subscribe(() => {
            this.style.animationDelay = null;
          });
        } else if (state === 'close') {
          this.setAttribute('animation', 'close');
        }
      })

      this.disconnectFn.push(() => {
        animationSubscribe.unsubscribe();
        animationStateSubscription.unsubscribe();
      })

      this.shadowRoot.querySelector('.close').addEventListener('click', (ev) => {
        ev.stopImmediatePropagation();
        this.close();
      });
    }

    show() {
      this.isShown$.next(true);
    }

    hide() {
      this.isShown$.next(false);
    }

    close() {
      this.animationState$.next('close');
      fromEvent(this, 'animationend').pipe(take(1)).subscribe(() => {
        if (this.parentNode) {
          this.parentNode.removeChild(this); //今の所再度表示はしないので、DOMを消滅させる
        }
      });
    }

    adjustArrow(px = 0, position = null) {
      if (position) {
        this.setAttribute('position', position);
      }
      const arrows = [this.shadowRoot.querySelector('[part=arrow]'), this.shadowRoot.querySelector('[part=bg-arrow]')];
      if (px === 0) {
        arrows.forEach(dom => dom.style.cssText = '');
      } else {
        switch (this.getAttribute('position')) {
          case MAL_TOOLTIP_POSITION_TOP:
          case MAL_TOOLTIP_POSITION_BOTTOM:
            arrows.forEach(dom => dom.style.cssText = `left: calc(50% + ${px}px) !important`);
            break;
          case MAL_TOOLTIP_POSITION_LEFT:
          case MAL_TOOLTIP_POSITION_RIGHT:
            arrows.forEach(dom => dom.style.cssText = `top: calc(50% + ${px}px) !important`);
            break;
        }
      }
    }

    /**
     * Runs each time the element is appended to or moved in the DOM
     */
    connectedCallback() {
      // console.log('connected!', this);
      this.position = this.hasAttribute('position') ? this.getAttribute('position') : MAL_TOOLTIP_POSITION_BOTTOM;
      if (this.hasAttribute('header')) {
        this.shadowRoot.querySelector('.header').innerText = this.getAttribute('header');
      }

      this.show();
    }

    /**
     * Runs when the element is removed from the DOM
     */
    disconnectedCallback() {
      // console.log('disconnected', this);
      this.disconnectFn.forEach(fn => fn());
    }
  });


  class MalTooltipPluginElement {
    constructor(element) {
      this.$el = $(element);
      this.defaults = {
        'position': 'left', //矢印の向き(top, right, bottom, left)
        'header': '', //ヘッダーの文字
        'body': 'Default Body', //本文。htmlで要素を指定しても良いし、documentを渡しても良い(querySelectorなどで)
        'safe_area': '#content', //表示して良いエリア、広告などに被らないようにデフォルトで#contentを指定する。指定したsafe_areaにtooltipを表示する場合は無視する
        'delay': 0, //表示遅延
        'css': {}, //表示した後cssで調整を行いたい場合は指定する。$.css()が実行される
        'closeOnEvent': null, //tooltipを生やした要素に、このイベントが発火した場合はtooltipを削除する
        'debug': false
      };
    }

    init(options) {
      this.settings = $.extend(true, this.defaults, options);
      this.$layer = this.createLayer();
      this.tooltip = this.createTooltip(this.settings);
      this.$safe_area = (this.settings.safe_area && $(this.settings.safe_area).has(this.$el).length > 0) ? $(this.settings.safe_area) : null;
      this.$layer.append(this.tooltip);
      document.body.appendChild(this.$layer.get(0));
      this.adjust(this.tooltip);
      this.tooltip.show();
      if (this.settings.closeOnEvent) {
        this.$el.on(this.settings.closeOnEvent, () => {
          this.tooltip.close();
        })
      }
      if (this.settings.debug) {
        console.log(this.$layer);
        window.tooltip = this.tooltip;
        window.plg = this;
      }
    };

    adjust(tooltip) {
      const $shadow = $(tooltip.shadowRoot)
      const $tooltipDiv = $shadow.find('div');
      const width = $tooltipDiv.outerWidth(true);
      const height = $tooltipDiv.outerHeight(true);
      let {top, left} = this.$el.offset();
      const elWidth = this.$el.outerWidth(); //padding + border
      const elHeight = this.$el.outerHeight();
      if (this.settings.debug) {
        console.log({width, height, top, left, elWidth, elHeight});
      }
      if (this.$el.is(':visible')) {
        this.tooltip.show();
      } else {
        this.tooltip.hide();//要素が消えている場合は、計算上ポジションが0になるのでここで処理を止める
        return;
      }
      //ポジションに合わせた表示箇所の調整
      switch (this.settings.position) {
        case MAL_TOOLTIP_POSITION_RIGHT:
          top -= (height - elHeight) / 2;
          left -= width + MAL_TOOLTIP_ARROW_MARGIN;
          break;
        case MAL_TOOLTIP_POSITION_LEFT:
          top -= (height - elHeight) / 2;
          left += elWidth + MAL_TOOLTIP_ARROW_MARGIN;
          break;
        case MAL_TOOLTIP_POSITION_BOTTOM:
          top -= height + MAL_TOOLTIP_ARROW_MARGIN;
          left -= (width - elWidth) / 2;
          break;
        case MAL_TOOLTIP_POSITION_TOP:
          top += elHeight + MAL_TOOLTIP_ARROW_MARGIN;
          left -= (width - elWidth) / 2;
          break;
        case "auto":
        //TODO: position autoの場合は自動で行う。blockなら下に、inlineなら右に出す

        default:
          break;
      }
      if (this.settings.debug) {
        console.log("fixed", {top, left});
      }

      //safe areaは被ってはいけないエリア
      //例えば広告枠などには被らないように制御を入れる
      const MAX_AREA_TOP = (!!this.$safe_area ? this.$safe_area.offset().top : 0) + MAL_TOOLTIP_AREA_MARGIN;
      const MAX_AREA_BOTTOM = (!!this.$safe_area ? this.$safe_area.offset().top + this.$safe_area.outerHeight(true) : window.innerHeight) - MAL_TOOLTIP_AREA_MARGIN;
      const MAX_AREA_LEFT = (!!this.$safe_area ? this.$safe_area.offset().left : 0) + MAL_TOOLTIP_AREA_MARGIN;
      const MAX_AREA_RIGHT = (!!this.$safe_area ? this.$safe_area.offset().left + this.$safe_area.outerWidth(true) : window.innerWidth) - MAL_TOOLTIP_AREA_MARGIN;
      let diff_left = 0;
      //画面外考慮
      if (top < MAX_AREA_TOP) {
        top = MAX_AREA_TOP;
      }
      if (left < MAX_AREA_LEFT) {
        diff_left = left - MAX_AREA_LEFT;
        left = MAX_AREA_LEFT;
      }
      if (MAX_AREA_BOTTOM < (height + top)) {
        //上向き設定だけど、下に余裕がなくてtooltipが入り切らない場合
        //また矢印を下に自動変更した時に上の領域に被らない場合
        if (this.settings.position === MAL_TOOLTIP_POSITION_TOP && MAX_AREA_TOP < (MAX_AREA_BOTTOM - height - MAL_TOOLTIP_ARROW_MARGIN)) {
          this.changePosition(MAL_TOOLTIP_POSITION_BOTTOM)
        } else {
          top = MAX_AREA_BOTTOM - height - MAL_TOOLTIP_ARROW_MARGIN;
        }
      }
      if (MAX_AREA_RIGHT < (width + left)) {
        diff_left = (width + left) - MAX_AREA_RIGHT;
        left = MAX_AREA_RIGHT - width;
      }
      if (this.settings.debug) {
        console.log("adjust", {top, left, diff_left, MAX_AREA_TOP, MAX_AREA_BOTTOM, MAX_AREA_LEFT, MAX_AREA_RIGHT});
      }
      this.$layer.css({top, left});
      tooltip.adjustArrow(diff_left);
    }

    changePosition(position) {
      this.tooltip.adjustArrow(0, position);
      this.settings.position = position;
    }

    createTooltip(settings) {
      const $tooltip = $(`<mal-tooltip position="${settings.position}" header="${settings.header}" delay="${settings.delay}"></mal-tooltip>`);
      $tooltip.css(settings.css)
      if (settings.body instanceof HTMLElement) {
        $tooltip.append($(settings.body).show())
      } else {
        $tooltip.append(settings.body);
      }
      const tooltip = $tooltip.get(0);
      const mutationSubscribe = merge(
        fromEvent(window, 'resize'),
        fromEvent(window, 'load'),
        this.resizeObservable(document.querySelector('body > div')),
        fromEvent(tooltip, 'mal-tooltip-mutation-event'),
      ).pipe(
        debounceTime(30)//30fpsで1frameごとにresize処理するように
      ).subscribe((e) => this.adjust(tooltip));
      //tooltipの削除に合わせて、layerも消すようにする
      tooltip.disconnectFn.push(() => {
        mutationSubscribe.unsubscribe();
        this.destroy();
      })
      return tooltip;
    }

    createLayer() {
      return $('<div class="mal-tooltip-layer"></div>')
    }

    resizeObservable(elm) {
      return new Observable(function subscribe(subscriber) {
        const ro = new ResizeObserver(entries => {
          subscriber.next(entries);
        });
        ro.observe(elm);
        return function unsubscribe() {
          ro.disconnect()
        };
      })
    }

    destroy() {
      this.$layer.remove();
    }
  }

  //jQuery Pluginで対応する
  (($) => {
    $.fn.malTooltip = function (options) {
      if (typeof options === 'object' || !options) {
        this.each((index, elm) => (new MalTooltipPluginElement(elm)).init(options));
      } else {
        $.error('Method ' + options + ' does not exist on jQuery.malTooltip');
      }
    };
  })(jQuery);
}
