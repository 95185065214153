const $ = window.$;

$(function () {

  if ($("body.page-advertising")[0]) {

    $(".js-spec-sample-button").on("click", function() {
      if (!$(this).hasClass("on")) {
        const id = $(this).attr("id");
        $(".js-spec-sample-button").removeClass("on");
        $(`.js-spec-sample-button#${id}`).addClass("on");
        $(".js-ad-spec-sample").fadeOut();
        $(`.js-ad-spec-sample.${id}` ).fadeIn();
      }
    });

    $(".inputtext.required").on("keyup",function () {
      let chkBtn = false;
      const arry = $(".inputtext.required").map(function(){
        return $(this).val();
      }).get();
      // check requirement
      for (let i = 0; i < arry.length; i++) {
        if (!arry[i]) {
          chkBtn = false;
          break;
        } else {
          chkBtn = true;
        }
      }
      // submit button
      if (chkBtn === true) {
        $("#ad-submit-button").prop("type","submit").removeClass("off");
      } else {
        $("#ad-submit-button").prop("type","button").addClass("off");
      }
    });

    const advertisingMenuActive = function(id) {
      $("#ad_menu a").removeClass("on");
      $(`#menu_${id}`).addClass("on");
    }

    var advertisingMenuFixed = function(obj) {
      const advertisingAnchors = obj;
      const posH = $(window).scrollTop();
      const offset = 111;
      const $adMenu = $("#ad_menu");
      const $adMenuLang = $(".ad_menu_lang");
      if(posH > offset) {
        $adMenu.addClass("fixed");
        $adMenuLang.addClass("fixed");
      }

      if(posH < offset) {
        $adMenu.removeClass("fixed");
        $adMenuLang.removeClass("fixed");
      }

      if(posH > advertisingAnchors.about - offset && posH < advertisingAnchors.user - offset ){
        advertisingMenuActive("about");
      }else if(posH > advertisingAnchors.user - offset && posH < advertisingAnchors.ad - offset ){
        advertisingMenuActive("user");
      }else if(posH > advertisingAnchors.ad - offset && posH < advertisingAnchors.self - offset ){
        advertisingMenuActive("ad");
      }else if(posH > advertisingAnchors.self - offset && posH < advertisingAnchors.contact - offset ){
        advertisingMenuActive("self");
      }else if(posH > advertisingAnchors.contact - offset){
        advertisingMenuActive("contact");
      }else if(posH < advertisingAnchors.about - offset){
        $adMenu.find("a").removeClass("on");
      }
    }

    var advertisingAnchors = {
      about: $("#about").offset().top,
      user: $("#user").offset().top,
      ad: $("#ad").offset().top,
      self: $("#self").offset().top,
      contact: $("#contact").offset().top
    };

    $(".js-jump-to-section").on("click", function() {
      $("#ad_menu a").removeClass("on");
      const href = $(this).attr("href");
      const id = href.split("#")[1];
      const posV = $(`#${id}`).offset().top;
      $("html,body").animate({scrollTop: (posV - 70)});
      return false;
    });


    $(".js-spec-sample-button").click(function() {
      if (!$(this).hasClass("on")) {
        const id = $(this).attr("id");
        $(".js-spec-sample-button").removeClass("on");
        $(`.js-spec-sample-button#${id}`).addClass("on");
        $(".js-ad-spec-sample").fadeOut();
        $(`.js-ad-spec-sample.${id}` ).fadeIn();
      }
    });

  }

  // mediaKitのいち
  const mediaKitPos = function() {
    const mediaKitV = $("#media_kit").offset().top;
    $("html,body").animate({scrollTop: (mediaKitV - 70)});
  }

  $(window).on("load", function(){
    const adsURLhash = window.location.hash;
    if (adsURLhash.indexOf("#media_kit") > -1) {
      mediaKitPos();
    }
  });

  $(window).on("load scroll resize", function(){
    if ($("body.page-advertising")[0]) {
      advertisingMenuFixed(advertisingAnchors);
    }
  });

});
