var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _vm.isShown
          ? _c("div", { staticClass: "modal-content" }, [
              _c("div", {
                staticClass: "modal-mask",
                on: { click: _vm.cancel }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "modal-wrapper" }, [
                _c("div", { staticClass: "modal-container" }, [
                  _c("div", { staticClass: "text" }, [
                    _c("b", [_vm._v("Thanks for choosing this manga.")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n              This content can only be viewed by the first " +
                          _vm._s(_vm.item.accessLimit) +
                          " customers."
                      ),
                      _c("br"),
                      _vm._v(
                        "\n              Please try again next time.\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-button-wrap" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.error,
                              expression: "error"
                            }
                          ],
                          staticClass: "modal-error"
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.error))])]
                      ),
                      _vm._v(" "),
                      !_vm.isRequesting && _vm.item.saleBookId
                        ? [
                            _c(
                              "a",
                              {
                                staticClass: "modal-select-button",
                                on: { click: _vm.purchase }
                              },
                              [_vm._v("Purchase to continue reading")]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    { staticClass: "modal-close", on: { click: _vm.cancel } },
                    [_vm._v("Close")]
                  )
                ])
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("loading-modal", { attrs: { "is-shown": _vm.showLoadingModal } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }