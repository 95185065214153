var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show"
              }
            ],
            staticClass: "modal-content",
            style: { height: "100%" }
          },
          [
            _c("div", { staticClass: "modal-mask" }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-wrapper" }, [
              _c("div", { staticClass: "modal-container" }, [
                _c("p", { staticClass: "modal-head" }, [
                  _vm._v("Your payment method was successfully registered.")
                ]),
                _vm._v(" "),
                _vm.defaultPaymentMethod === "stripe"
                  ? _c(
                      "div",
                      {
                        staticClass: "creditcard-information-registered",
                        attrs: { "data-type": _vm.registeredCard.type }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "XXXX-XXXX-XXXX-" +
                              _vm._s(_vm.registeredCard.digits)
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.defaultPaymentMethod === "paypal"
                  ? _c(
                      "div",
                      { staticClass: "paypal-information-registered" },
                      [
                        _vm._v(
                          "\n            You registered your PayPal account at " +
                            _vm._s(_vm.braintreeRegistrationDatetime) +
                            ".\n          "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "modal-button-submit",
                    on: {
                      click: function($event) {
                        return _vm.$emit("proceedToCheckout")
                      }
                    }
                  },
                  [_vm._v("Proceed to Checkout")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "modal-close", on: { click: _vm.hide } },
                  [_vm._v("Close")]
                )
              ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }