"use strict";

const $ = window.$;
const _ = window._;

import Modal from "./modal";

// pageshowが発火済かを判定するための変数設定
window.MAL.pageshow_fired = false;
$(window).on('pageshow',function(){
  setTimeout(function(){
    window.MAL.pageshow_fired = true;
  }, 500);
});

module.exports = {
  initialized: false,
  sourceWarned: false,
  configurations: {
    autofocus: false,
    format: 'bbcode',
    style: '/css/sceditor.inner.css',
    plugins: 'dragdroptext',
    toolbar: 'bold,italic,underline,strike|size,colorpick|center,right|bulletlist,orderedlist|code,quote,spoiler|image,link,youtube',
    charset: 'utf-8',
    width: '100%',
    emoticonsEnabled: false,
    resizeMaxHeight: -1,
    resizeMinHeight: 100,
    resizeWidth: false,
    startInSourceMode: true,
    autoUpdate: true,
    /* ドラッグ＆ドロップを許容するための処理 */
    dragdroptext: {
      allowedTypes: ['image/jpeg', 'image/png', 'image/gif'],
      handleFiles: function (files, $container, editor) {
        $container.removeClass('dnd');
        $container.removeClass('notallowed');
        // 画像以外のファイルがドロップされた
        if (files.error.length > 0) {
          Modal.alert('Sorry, this file is not a valid file format (must be JPG, GIF, or PNG).');
          return false;
        }
        // ドロップ対象ファイルが存在しない
        if (files.success.length == 0) {
          return false;
        }
        // 複数ファイルがドロップされた
        if (files.success.length > 1) {
          Modal.alert('Only one image can be added at a time.');
          return false;
        }
        // 画像ファイルのサイズが規定以上だった
        if (files.success[0].size > 2*1024*1024) {
          Modal.alert('Your picture is too big, please make it smaller than or equal to 2MB.');
          return false;
        }
        const $toolbar = $container.find('div.sceditor-toolbar');
        const $bar_group = $('<div class="sceditor-group"></div>');
        const $bar_outer = $('<div class="mal-progress sceditor-indicator"></div>');
        const $bar_inner = $('<div class="mal-progress-bar primary bar"></div>');
        $bar_outer.append($bar_inner);
        $bar_group.append($bar_outer);
        $toolbar.append($bar_group);

        const form = new FormData();
        form.append('image', files.success[0]);
        form.append('source', location.href);
        $.ajax({
          url:'/upload/image',
          type:'post',
          data: form,
          async: true,
          cache: false,
          processData: false,
          contentType: false,
          dataType: 'json',
          timeout: 10000,
          xhr : function () {
            const XHR = $.ajaxSettings.xhr();
            if (XHR.upload) {
              XHR.upload.addEventListener('progress', function (e) {
                const progVal = parseInt(e.loaded / e.total * 100);
                $bar_inner.css('width', `${progVal}%`);
              }, false);
            }
            return XHR;
          },
          success : function (data) {
            $bar_group.remove();
            if (!data.result) {
              Modal.alert(data.error.message, data.error.code);
              return;
            }
            editor.insert(`[img]${data.path}[/img]`);
          },
          error : function () {
            $bar_group.remove();
            Modal.alert('There was an error uploading, please try again.');
          },
        });
      },
      handleDragEnter: function (files, $container) {
        $container.addClass('dnd');
        if (files.error.length > 0 || files.success.length > 1) {
          $container.addClass('notallowed');
          return;
        }
        $container.removeClass('notallowed');
      },
      handleDragLeave: function ($container) {
        $container.removeClass('dnd');
        $container.removeClass('notallowed');
      },
    },
  },
  initialize: function() {
    if (this.initialized || typeof sceditor === 'undefined') {
      return this;
    }
    sceditor.insertCustomText = function (instance, startText, selected, endText) {
      let caret = instance.sourceEditorCaret();
      instance.insertText(startText + selected + endText);
      instance.sourceEditorCaret({
        start: caret.start + startText.length + selected.length,
        end: caret.start + startText.length + selected.length
      });
    }
    sceditor.fixFontSize = function (size) {
      size = parseInt(size);
      if (!size || isNaN(size)) return 100;
      if (size < 20) return 20;
      if (size > 200) return 200;
      return size;
    }
    sceditor.formats.bbcode.set('spoiler', {
      allowsEmpty: true,
      breakAfter: false,
      breakBefore: false,
      isInline: false,
      format: function(element, content) {
        var desc = '';
        var $elm = $(element);
        var $button = $elm.children('button').first();
        if($button.length === 1 || $elm.data('desc')) {
          desc = $button.text() || $elm.data('desc');
          $button.remove();
          content = this.elementToBbcode(element);
          if (desc == 'spoiler') {
            desc = '';
          } else if(desc.charAt(0) != '=') {
            desc = '=' + desc;
            $elm.data('desc', desc);
          }
          $elm.prepend($button);
        }
        return '[spoiler' + desc + ']' + content + '[/spoiler]';
      },
      html: function (token, attrs, content) {
        var data = '';
        var desc = attrs.defaultattr;
        if (!desc) {
          desc = 'spoiler';
        }
        content = '<button>' + desc + '</button>' + content;
        if (attrs.defaultattr) {
          data += ' data-desc="' + sceditor.escapeEntities(attrs.defaultattr) + '"';
        }
        return '<blockquote' + data + ' class="spoiler">' + content + '</blockquote>';
      }
    });
    sceditor.formats.bbcode.set('quote', {
      breakAfter: false,
      breakBefore: false,
      isInline: false,
      format: function (element, content) {
        var cite;
        var button;
        var aside;
        var children = element.children;
        var $elm = $(element);

        // 子要素を取得
        for (var i=0; i<children.length; i++) {
          if (sceditor.dom.is(children[i], 'cite')) {
            cite = children[i];
          }
          if (sceditor.dom.is(children[i], 'button')) {
            button = children[i];
          }
          if (sceditor.dom.is(children[i], 'aside')) {
            aside = children[i];
          }
        }

        // Spoilerタグ判定
        if ($elm.hasClass('spoiler')) {
          var desc = '';
          if (button || $elm.data('desc')) {
            desc = $elm.data('desc') || $(button).text();
            if (button) button.remove();
            if (desc == 'spoiler') {
              desc = '';
            } else if(desc.charAt(0) != '=') {
              desc = '=' + desc;
              $elm.data('desc', desc);
            }
            content = this.elementToBbcode(element);
            $elm.prepend(button);
          }
          return '[spoiler' + desc + ']' + content + '[/spoiler]';
        }

        var author = '';
        if (cite || $elm.data('author')) {
          author = $elm.data('author') || $(cite).text();
          $elm.data('author', author);
          if (cite) cite.remove();
          author = '=' + author;
          content = this.elementToBbcode(element);
        }

        var message = '';
        if (aside || $elm.data('message')) {
          message = $elm.data('message') || $(aside).text();
          $elm.data('message', message);
          if (aside) aside.remove();
          message = ' message=' + message;
          content = this.elementToBbcode(element);
        }

        if (cite) {
          $elm.prepend(cite);
        }
        if (aside) {
          $elm.prepend(aside);
        }
        return '[quote' + author + message + ']' + content + '[/quote]';
      },
      html: function (token, attrs, content) {
        var data = '';
        if (attrs.message) {
          content = '<aside>' + sceditor.escapeEntities(attrs.message) +
            '</aside>' + content;
          data += ' data-message="' + attrs.message + '"';
        }
        if (attrs.defaultattr) {
          content = '<cite>' + sceditor.escapeEntities(attrs.defaultattr) +
            '</cite>' + content;
          data += ' data-desc="' + attrs.defaultattr + '"';
        }
        return '<blockquote' + data + '>' + content + '</blockquote>';
      }
    });
    sceditor.formats.bbcode.set('left', {
      format: '{0}',
      html: '{0}'
    });
    sceditor.formats.bbcode.set('ul', {
      format: '[list]{0}[/list]',
      html: '<ul>{0}</ul>'
    });
    sceditor.formats.bbcode.set('ol', {
      format: '[list=1]{0}[/list]',
      html: '<ol>{0}</ol>'
    });
    sceditor.formats.bbcode.set('list', {
      isInline: false,
      format: '[list=1]{0}[/list]',
      html: function(token, attrs, content) {
        if (attrs.defaultattr == '1') {
          return '<ol>' + content + '</ol>'
        }
        return '<ul>' + content + '</ul>';
      }
    });
    sceditor.formats.bbcode.set('li', {
      tags: {
        li: null
      },
      isInline: false,
      closedBy: ['/ul', '/ol', '/list', '*', 'li'],
      format: '[*]{0}',
      html: '<li>{0}</li>'
    });
    sceditor.formats.bbcode.set('*', {
      isInline: false,
      excludeClosing: true,
      closedBy: ['/ul', '/ol', '/list', '*', 'li'],
      html: '<li>{0}</li>'
    });
    sceditor.formats.bbcode.set('size', {
      format: function(element, content) {
        var $elm = $(element);
        var size = sceditor.fixFontSize($elm.data('size'));
        if (size == 100) return content;
        return `[size=${size}]${content}[/size]`;
      },
      html: function(token, attrs, content) {
        var size = sceditor.fixFontSize(attrs.defaultattr);
        if (size == 100) return content;
        return `<font data-size="${size}" style="font-size:${size}%">${content}</font>`;
      }
    });
    sceditor.formats.bbcode.set('yt', {
      allowsEmpty: true,
      tags: {
        iframe: {
          'data-youtube-id': null
        }
      },
      format: function (element, content) {
        var dataid = element.getAttribute('data-youtube-id');
        return dataid ? `[yt]${dataid}[/yt]` : content;
      },
      html: '<iframe width="560" height="315" frameborder="0" ' +
        'src="https://www.youtube-nocookie.com/embed/{0}?wmode=opaque" ' +
        'data-youtube-id="{0}" allowfullscreen></iframe>'
    });
    sceditor.formats.bbcode.set('font', {
      quoteType: sceditor.BBCodeParser.QuoteType.auto,
    });
    sceditor.formats.bbcode.set('img', {
      quoteType: sceditor.BBCodeParser.QuoteType.auto,
      format: function (element, content) {
        var width, height,
          attribs = '',
          style = function (name) {
            return element.style ? element.style[name] : null;
          };
          var $elm = $(element);

        width = $elm.attr('width') || style('width');
        height = $elm.attr('height') || style('height');

        // only add width and height if one is specified
        if ((element.complete && (width || height)) ||
          (width && height)) {

          attribs = '='
            + sceditor.escapeEntities(width, true)
            + 'x'
            + sceditor.escapeEntities(height, true);
        }

        if ($elm.attr('alt')) {
          attribs += ' alt="' + sceditor.escapeEntities($elm.attr('alt'), true) + '"';
        }
        if ($elm.attr('title')) {
          attribs += ' title="' + sceditor.escapeEntities($elm.attr('title'), true) + '"';
        }
        if ($elm.attr('align') == 'left' ||
          $elm.attr('align') == 'center' ||
          $elm.attr('align') == 'right') {
          attribs += ' align="' + sceditor.escapeEntities($elm.attr('align'), true) + '"';
        }

        return '[img' + attribs + ']' + $elm.attr('src') + '[/img]';
      },
      html: function (token, attrs, content) {
        var  undef, width, height, match, alt, title, align,
          attribs = '';

        // handle [img width=340 height=240]url[/img]
        width = attrs.width;
        height = attrs.height;
        alt = attrs.alt;
        title = attrs.title;
        align = attrs.align;

        // handle [img=340x240]url[/img]
        if (attrs.defaultattr) {
          match = attrs.defaultattr.split(/x/i);

          width = match[0];
          height = (match.length === 2 ? match[1] : match[0]);
        }

        if (width !== undef) {
          attribs += ' width="' + sceditor.escapeEntities(width, true) + '"';
        }

        if (height !== undef) {
          attribs += ' height="' + sceditor.escapeEntities(height, true) + '"';
        }

        if (alt !== undef) {
          attribs += ' alt="' + sceditor.escapeEntities(alt, true) + '"';
        }

        if (title !== undef) {
          attribs += ' title="' + sceditor.escapeEntities(title, true) + '"';
        }

        if (align == 'left' || align == 'center' || align == 'right') {
          attribs += ' align="' + sceditor.escapeEntities(align, true) + '"';
        }

        return '<img' + attribs +
          ' src="' + sceditor.escapeUriScheme(content) + '" />';
      }
    });
    sceditor.formats.bbcode.set('hr', {
      format: function(element, content) {
        var $elm = $(element);
        var color = $elm.attr('color');
        var size = $elm.attr('size');
        var attribs = '';
        if (color) {
          attribs += `=${color}`;
        }
        if (size) {
          attribs += ` size=${size}`;
        }
        return `[hr${attribs}]`;
      },
      html: function(token, attrs, content) {
        var color = attrs.defaultattr;
        var size = attrs.size;
        var attribs = '';
        if (color) {
          attribs += ` color="${color}" noshade`
        }
        if (size) {
          attribs += ` size="${size}"`
        }
        return `<hr${attribs} />`;
      }
    });
    sceditor.formats.bbcode.set('pre', {
			tags: {
				pre: null
			},
      format: '[pre]{0}[/pre]',
      html: '<pre>{0}</pre>'
    });
    sceditor.command.set('left', {
      txtExec: null
    });
    sceditor.command.set('size', {
      _dropDown: function (editor, caller, callback) {
        var  $content = $('<div />');
        var clicked = function (event) {
          var size = $(this).data('size');
          callback(size);
          editor.closeDropDown(true);
          event.preventDefault();
        }
        for (var i=25; i<=200; i+=25) {
          var $size = $(`<a>${i}%</a>`);
          $size.addClass('sceditor-fontsize-option');
          $size.attr('href', '#');
          $size.attr('data-size', i);
          $size.on('click', clicked);
          $size.appendTo($content);
        }
        editor.createDropDown(caller, 'fontsize-picker', $content[0]);
      },
      exec: function (caller) {
        var editor = this;
        sceditor.command.get('size')._dropDown(
          editor,
          caller,
          function (fontSize) {
            editor.execCommand('fontsize', fontSize);
            var $body = $(editor.getBody());
            $body.find('font[size]')
              .removeAttr("size")
              .attr("data-size", fontSize)
              .css("font-size", fontSize+"%");
          }
        );
      }
    });
    sceditor.command.set('bulletlist', {
      txtExec: function (caller, selected) {
        sceditor.insertCustomText(
          this,
          '[list]\n[*]',
          selected.split(/\r?\n/).join('\n[*]'),
          '\n[/list]'
        );
      }
    });
    sceditor.command.set('orderedlist', {
      txtExec: function (caller, selected) {
        sceditor.insertCustomText(
          this,
          '[list=1]\n[*]',
          selected.split(/\r?\n/).join('\n[*]'),
          '\n[/list]'
        );
      }
    });
    sceditor.command.set('spoiler', {
      exec: function (caller) {
        var parentNode = this.getRangeHelper().getFirstBlockParent();
        if ($(parentNode).is('blockquote')) {
          return false;
        }
        this.wysiwygEditorInsertHtml('<blockquote class="spoiler">', '</blockquote>');
        $(this.getBody()).find('blockquote.spoiler').each(function(){
          if ($(this).find('button').length == 0) {
            $(this).prepend('<button>spoiler</button>');
          }
        })
      },
      txtExec: ['[spoiler]', '[/spoiler]'],
      tooltip: 'Insert a spoiler'
    });
    sceditor.command.set('youtube', {
      txtExec: function (caller) {
        var editor = this;

        sceditor.command.get('youtube')._dropDown(
          editor,
          caller,
          function (id) {
            editor.insertText('[yt]' + id + '[/yt]');
          }
        );
      }
    });
    sceditor.command.set('colorpick', {
      _dropDown: function (editor, caller) {
        // カラーピッカーが初期化されていない場合は追加
        if ($('input.bbcode-message-color-picker').length == 0) {
          $('<input type="color" class="bbcode-message-color-picker" />')
            .css({
              'position': 'absolute',
              'opacity': 0,
              'width': 0,
              'height': 0
            })
            .appendTo('body')
            .val('#ff0000');
        }
        const holder = $('input.bbcode-message-color-picker');
        holder.css({
          'top': ($(caller).offset().top + 24) + 'px',
          'left': ($(caller).offset().left + 12) + 'px'
        });
        holder.trigger('click');
        holder.off('change').on('change', function() {
          var color = $('input.bbcode-message-color-picker').val();
          if (editor.inSourceMode()) {
            editor.insertText(
              '[color=' + color + ']',
              '[/color]'
            );
          } else {
            editor.execCommand('forecolor', color);
          }
        });
      },
      exec: function(caller){
        sceditor.command.get('colorpick')._dropDown(this, caller)
      },
      txtExec: function(caller){
        sceditor.command.get('colorpick')._dropDown(this, caller)
      },
      tooltip: 'Font Color'
    });
    sceditor.command.set('source', {
      exec: function(caller){
        this.sourceMode(true);
      },
      txtExec: function(caller){
        this.sourceMode(true);
      },
    });
    window.MAL.editor = this;
    this.initialized = true;
    return this;
  },
  /* エディタをプリロードする（手動呼び出しは任意、アタッチ時に自動呼び出しされる） */
  preload: function(target) {
    this.initialize();
    const $target = this.jquerize(target);
    // アウターラッパーを追加する
    if ($target.closest('div.sceditor-outer').length > 0) {
      return;
    }
    $target.wrap('<div class="sceditor-outer"><div class="mal-tab-item active edit"></div></div>');
    const $parent = $target.closest('div.sceditor-outer');
    $parent.data('parse-mention', $target.data('parse-mention'));
    $parent.prepend(`<ul class="mal-tabs sceditor-tabs">
        <li><a class="item js-sceditor-tab-edit active">Edit</a></li>
        <li><a class="item js-sceditor-tab-preview">Preview</a></li>
      </ul>`);
    $parent.append('<div class="mal-tab-item preview"><div class="preview-content"></div></div>');
  },
  /* エディタをアタッチする */
  attach: function(targets, depth) {
    const editor = this;
    if (!window.MAL.pageshow_fired) {
      // 再帰処理は20回（10sec）まで
      if (++depth > 20) return;
      setTimeout(function(){
        editor.attach(targets, depth);
      }, 500);
      return;
    }
    this.initialize();
    const $targets = this.jquerize(targets);

    $targets.each(function(){
      const $target = $(this);
      // 初期化済の場合
      if ($target.attr('data-editor-attached')) return;
      // アウターラッパーを追加する
      editor.preload($target);
      // SCEditorがロードされた環境のみ
      if (!$('body').hasClass('sp') && typeof sceditor !== 'undefined') {
        $target.sceditor(editor.configurations);
      }
      $target.attr('data-editor-attached', true);
    });
    // ダークモードCSSを適用する
    if($('html.dark-mode').length > 0 && $('.sceditor-container iframe').length > 0) {
      $('.sceditor-container iframe').each(function(i, iframeElement) {
        const iframeDocumentElement = iframeElement.contentWindow.document;
        const linkElement = document.createElement('link')
        linkElement.setAttribute('rel', 'stylesheet')
        linkElement.setAttribute('type', 'text/css')
        linkElement.setAttribute('href', '/css/sceditor.inner.dark.css')
        iframeDocumentElement.querySelector('head').append(linkElement);
      });
    }
  },
  /* エディタをデタッチする */
  detach: function(target) {
    const $target = this.jquerize(target);
    const $editor = this.instance($target);
    const $parent = $target.closest('div.sceditor-outer');
    // 初期化されていない場合
    if (!$target.attr('data-editor-attached')) return;
    // アウターラッパーを削除する
    if ($parent.length > 0) {
      $('ul.mal-tabs', $parent).remove();
      $('div.mal-tab-item.preview', $parent).remove();
      $target.unwrap('div.mal-tab-item');
      $target.unwrap('div.sceditor-outer');
    }
    // SCEditorを解除する
    if ($editor) {
      $editor.destroy();
    }
    $target.attr('data-editor-attached', false);
  },
  focus: function(target) {
    const $target = this.jquerize(target);
    const $editor = this.instance(target);
    const $parent = $target.closest('div.sceditor-outer');
    if ($parent.length > 0) {
      // タブを編集モードに切り替える
      $parent.find('a.item.js-sceditor-tab-edit').trigger('click');
    }
    if ($editor) {
      $editor.focus();
      return;
    }
    $target.focus();
  },
  val: function(target, text) {
    const $editor = this.instance(target);
    if ($editor) {
      $editor.val(text);
      return;
    }
    const $target = this.jquerize(target);
    if ($target.is('textarea')) $target.val(text);
  },
  instance: function(target) {
    if (typeof sceditor === 'undefined') {
      return;
    }
    const $instance = (this.jquerize(target)).sceditor('instance');
    return (typeof $instance !== 'undefined') ? $instance : false;
  },
  jquerize: function(target) {
    return (target instanceof jQuery) ? target : $(target);
  },
};

// エディタプレビュー用
$(function () {
  $('body').on('click', '.js-sceditor-tab-edit', function () {
    const $current = $(this);
    if ($current.hasClass('active')) return;
    const $tabs = $(this).closest('ul.mal-tabs');
    $tabs.siblings('div.mal-tab-item.edit').addClass('active');
    $tabs.siblings('div.mal-tab-item.preview').removeClass('active');
    $tabs.find('a.item.js-sceditor-tab-edit').addClass('active');
    $tabs.find('a.item.js-sceditor-tab-preview').removeClass('active');
  });

  $('body').on('click', '.js-sceditor-tab-preview', function () {
    const $current = $(this);
    if ($current.hasClass('active') || $current.hasClass('loading')) return;
    const $tabs = $(this).closest('ul.mal-tabs');
    const $textarea = $tabs.siblings('div.mal-tab-item.edit').children('textarea');
    const $parent = $tabs.closest('div.sceditor-outer');
    if ($textarea.val() == '') return;
    $current.addClass('loading');
    $current.append('<i class="fa-solid fa-spinner fa-spin fa-fw ml4 loading"></i>');

    $.ajax({
      type    : 'POST',
      url     : '/bbcode/preview',
      cache   : false,
      data    : {
        text: $textarea.val(),
        parseMention: $parent.data('parse-mention')
      },
      dataType: 'json',
      timeout: 10000,
      success : function (data) {
        if (!data.result) {
          Modal.alert(data.error.message, data.error.code);
          return;
        }
        $tabs.siblings('div.mal-tab-item.edit').removeClass('active');
        $tabs.siblings('div.mal-tab-item.preview').addClass('active');
        $tabs.find('a.item.js-sceditor-tab-edit').removeClass('active');
        $tabs.find('a.item.js-sceditor-tab-preview').addClass('active');
        $tabs.siblings('div.mal-tab-item.preview').children('div.preview-content').html(data.html);
        $current.removeClass('loading');
        $current.find('.loading').remove();
      },
      error : function (XMLHttpRequest, textStatus, errorThrown) {
        Modal.alert('There was an error posting, please try again.');
        $current.removeClass('loading');
        $current.find('.loading').remove();
      },
    });
  });
});
