/**
 * all.js(や他のentry_js)は遅延実行されます。
 * この中でjqueryやlodashを使う場合はheader.jsで定義されたグローバル変数を参照してください。
 */
import appendQuery from "append-query"

// Polyfills
require("es6-promise").polyfill()
require("array.prototype.find").shim()

// Vue standalone build
window.sVue = require("vue/dist/vue.common.js")
window.sVue.mixin({
  delimiters: ["${", "}"],
  methods: {
    urlWithLocation(url, location) {
      return location ? appendQuery(url, {_location: location}) : url
    }
  },
})

import Vuex from "vuex"
window.sVue.use(Vuex)
// window.sVue.config.debug = true;

import VueCookie from "vue-cookie"
window.sVue.use(VueCookie)

// Vue Lazyload
import VueLazyLoad from "vue-lazyload"
window.sVue.use(VueLazyLoad, {
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1
  }
})

// PWA
require("../../common/pwa")

// botfilter (利用停止)
// require("../../common/botfilter")

// URLから内部パラメータをとりのぞく
require("../../common/removeInternalParams")

// recaptcha
require("../../common/recaptcha")

// 共通的な処理
require("./general")

// URLを埋め込みコンテンツによしなに置き換える
import setupUrl2Embed from "../../common/url2embed"
setupUrl2Embed()

// SocialButtonポップアップ
require("./socialButton")

// インクリメンタルサーチ
require("./search")

// ヘッダのnotification
require("./header-notification")

// チュートリアル
require("./tutorial")

// 写真のFancybox
require("./picture")

// プロフィールカード
require("./profilecard")

// スクロール固定
require("./scrollfix")

// マウスホバーでアニメ・マンガの情報を出す
require("./hover")

// lazyload
require("./lazyload")

// RightStuf
require("./right_stuf")

// adblock検出
require("blockadblock")

// swiper
require("./swiper")

// GTM event
require("../../common/gtm")

require('./tooltip')

//custom component
require("../../common/custom-components/mal-score-selector")
