<template>
  <a class="button" target="_blank"
    :href="url"
    :class="{'is-mobile': isMobile, 'is-border': isBorder, 'is-disable': isDisable}"
    :style="buttonStyle"
  >Preview</a>
</template>

<style lang="scss" scoped>
  $base-color       : #2E51A2;
  $white-color      : #fff;
  $disable-color    : #bdbdbd;
  .button {
    display: block;
    text-align: center;
    border-radius: 4px;
    font-weight: bold;
    vertical-align: middle;
    box-sizing: border-box;
    cursor: pointer;
    padding-bottom: 1px;
    font-family: Verdana,Arial;
    border: 2px solid $base-color;
    background-color: $base-color;
    color: $white-color;
    text-decoration: none;
    &:visited {
      color: $white-color;
    }
    &:hover {
      color: $white-color;
      background-color: #5874b5;//opacity 0.8の非透過
    }
    &.is-mobile {
      font-family: Avenir,"lucida grande",tahoma,verdana,arial,sans-serif;
    }
    &.isBorder {
      background-color: $white-color;
      color: $base-color;
    }
    &.is-disable {
      color: $disable-color;
      border-color:  $disable-color;
    }
  }
</style>

<script type="text/babel">
  export default {
    props: {
      url: String,
      isDisable: Boolean,
      isMobile: Boolean,
      width: Number,
      height: Number,
      isBorder: Boolean,
      isSmall: Boolean,
    },
    computed: {
      buttonStyle() {
        // マンガ詳細画面のボタン用
        if (this.isSmall) {
          return {
            width          : this.width ? `${this.width}px` : '100%',
            height         : this.height ? `${this.height}px` : 'auto',
            lineHeight     : this.height ? `${this.height - (this.isMobile ? 1 : 2 ) }px` : 'nomal',
            fontSize       : '10px',
            fontWeight     : 'normal',
            borderRadius   : '2px',
            borderWidth    : '1px',
            textDecoration : 'none',
          }
        }

        let font_size
        if (this.isMobile) {
          font_size = (this.height >= 26) ? '14px' : '10px'
        } else {
          font_size = this.height >= 42 ? '16px' : ( (this.height < 24 || this.width < 105) ? '12px' : '14px')
        }
        return {
          width        : this.width ? `${this.width}px` : '100%',
          height       : this.height ? `${this.height}px` : 'auto',
          lineHeight   : this.height ? `${this.height - (this.isMobile ? 2 : 3 ) }px` : 'nomal',
          fontSize     : font_size,
        }
      },
    },
  }
</script>
