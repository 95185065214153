const Vue = window.sVue
import * as types from "./mutation-types"
import {createBook} from "../../modules/book"
import {appendTax} from "../../modules/tax"

export default {
  /**
   * item は state に含まれていること
   */
  [types.SET_TAX](state, {taxes, item}) {
    item = appendTax(item, taxes)
  },

  [types.MOVE_PAGE](state, page) {
    state.currentPage = page
  },

  [types.LOAD_MORE_START](state, id) {
    state.isLoadingItems = true
  },

  [types.ADD_ITEMS](state, {items, page}) {
    Vue.set(state.pageToItems, page, items.map(i => createBook(i)))
    state.isLoadingItems = false
  },

  [types.PURCHASE_START](state) {
    state.isPurchasing = true
  },

  [types.PURCHASED](state, item) {
    item.isPossessed = true
    state.isPurchasing = false
  },

  [types.PURCHASE_FAILED](state, id) {
    state.isPurchasing = false
  },
}
