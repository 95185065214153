const $ = window.$;

$(() => {

  $("a.js-link-scroll[href^='#']").on("click", (event) => {
    const $currentTarget = event.currentTarget;
    $("a.js-link-scroll").removeClass("on");
    $($currentTarget).addClass("on");
    const hash = $currentTarget.hash;
    const posV = $(hash).offset().top;
    $("html,body").animate({scrollTop: posV - 10});
    return false;
  });

  $("a.js-link-scroll[href^='#']:first-of-type").addClass("on");

});
