<template>
  <div class="membership-payment">
    <section class="payment-method">
      <div class="form-table border-bottom">
        <div class="title alignLeft fw-b">Payment Method</div>
        <div class="data clearfix"></div>
      </div>
      <div class="form-table blank">
        <div class="title blank"></div>
        <div class="clearfix">
          <ul class="payment-method-select-container">
            <li class="po-r">
              <input v-model="paymentMethod" class="payment-radio" type="radio" value="paypal" id="paypal">
              <label class="mt8" for="paypal">PayPal</label>
              <span v-if="defaultPaymentMethod === 'paypal'" class="selected-payment-methods">Currently Selected</span>
            </li>
            <li class="po-r">
              <input v-model="paymentMethod" class="payment-radio" type="radio" value="stripe" id="stripe">
              <label class="mt8" for="stripe">Credit Card</label>
              <span v-if="defaultPaymentMethod === 'stripe'" class="selected-payment-methods">Currently Selected</span>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="payment-information">
      <div class="form-table border-bottom mt24">
        <div class="title alignLeft fw-b">Payment Information</div>
        <div class="data clearfix"></div>
      </div>
      <div class="form-table mb24">
        <div class="title blank"></div>
        <div class="data clearfix">
          <div v-if="isError" class="badresult mt8 mb8 ml8 mr8">
            <div v-if="errorMessage" class="badresult-text">{{ errorMessage }}</div>
            <div v-else class="badresult-text fs14 lh16">
              There was an error when registering your payment information.<br>
              Please try again in a few minutes. If this problem persists, please contact us
              <a :href="supportUrl">here</a>.
            </div>
          </div>

          <div v-if="paymentMethod === 'stripe'">

            <div v-if="registeredCard && !isCardEditMode">
              <!-- カードが MAL Supporter の方で登録済みの場合はメッセージを出す -->
              <div
                v-if="registeredCard.isUsedForMalSupporter"
                class="creditcard-information-registered-text"
              >Currently the following card is in use of MAL Supporter</div>
              <div class="creditcard-information-registered" :data-type="registeredCard.type">
                XXXX-XXXX-XXXX-{{ registeredCard.digits }}
              </div>
              <div class="creditcard-information-registered-meta">
                <span>Expires: {{ registeredCard.expiryDate }}</span>
              </div>
              <div class="payment-edit-creditcard-button-area clearfix">
                <button @click="isCardEditMode = true" class="payment-edit-creditcard-button-edit btn-edit">Edit</button>
                <div class="payment-edit-creditcard-delete-wrap">
                  <span class="payment-edit-creditcard-delete-text">Delete your registered card information :</span>
                  <button @click="confirmDeleteCard(paymentMethod)" class="btn-edit">Delete</button>
                </div>
              </div>
            </div>
            <stripe v-else
               ref="stripe"
               :is-block-ad-block="isBlockAdBlock"
            ></stripe>

            <div v-if="registeredCard && isCardEditMode" class="payment-edit-creditcard-button-area clearfix">
              <!-- カード情報を変更する -->
              <button
                v-if="isCardEditMode"
                @click="updateStripeCard"
                class="payment-edit-creditcard-button-change btn-edit"
                :disabled="isRequesting"
              >Change credit card information</button>

              <button @click="isCardEditMode = false" class="payment-edit-creditcard-button-cancel btn-edit ">Cancel</button>
            </div>

            <div v-if="showAgreement && !isCardEditMode" class="textPaymentPolicyContainer">
              <agreement
                :termsHtml="termsHtml"
                :checked-agreement.sync="checkedAgreement"
              ></agreement>
            </div>

            <div class="payment-submit-creditcard-button-container">
              <!-- カードを新規登録する -->
              <button
                v-if="!registeredCard"
                @click="registerStripeCard"
                class="btn-form-submit"
                :disabled="(showAgreement && !checkedAgreement) || isRequesting">Register this card</button>

              <!-- デフォルト決済手段を登録済みのカードにする -->
              <button
                v-if="registeredCard && !isCardEditMode && defaultPaymentMethod !== 'stripe'"
                @click="updatePaymentMethod"
                class="payment-submit-creditcard-button btn-form-submit"
                :disabled="(showAgreement && !checkedAgreement) || isRequesting">Make this card my default payment method</button>
            </div>
          </div>
          <div v-if="paymentMethod === 'paypal'">

            <div v-if="showAgreement"
               :class="{textPaymentPolicyContainer: braintreeRegistrationDatetime}">
              <agreement
                :termsHtml="termsHtml"
                :checked-agreement.sync="checkedAgreement"
              ></agreement>
            </div>

            <div v-if="braintreeRegistrationDatetime" class="paypal-information-registered">
              You registered your PayPal account at {{ braintreeRegistrationDatetime }}.
            </div>
            <braintree-pay-pal
              v-else-if="!showAgreement || (showAgreement && checkedAgreement)"
              :authorized-callback="paypalCallback"
            ></braintree-pay-pal>

            <div class="payment-edit-creditcard-button-area mt16 pt8 clearfix"
                 v-if="braintreeRegistrationDatetime">
              <div class="payment-edit-paypal-delete-wrap fl-r">
                <span class="payment-edit-creditcard-delete-text">Delete your registered PayPal information :</span>
                <button @click="confirmDeleteCard(paymentMethod)" class="btn-edit">Delete</button>
              </div>
            </div>

            <button
              v-if="braintreeRegistrationDatetime && defaultPaymentMethod !== 'paypal'"
              @click="updatePaymentMethod"
              class="btn-form-submit"
              :disabled="(showAgreement && !checkedAgreement) || isRequesting">Make PayPal my default payment method</button>
          </div>
        </div>
      </div>
    </section>

    <registerd-payment-method-modal
      :show="showRegisteredPaymentMethodModal"
      :registeredCard="registeredCard"
      :braintree-registration-datetime="braintreeRegistrationDatetime"
      :default-payment-method="defaultPaymentMethod"
      @close="close"
      @proceedToCheckout="proceedToCheckout"
    >
    </registerd-payment-method-modal>

    <loading-modal
      :is-shown="showLoadingModal"
    ></loading-modal>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script type="text/babel">
  import Stripe from "./components/Stripe.vue"
  import BraintreePayPal from "./components/BraintreePayPal.vue"
  import Agreement from "./components/Agreement.vue"
  import LoadingModal from "./components/LoadingModal.vue"
  import RegisterdPaymentMethodModal from "../manga_store/sp/molecules/RegisterdPaymentMethodModal.vue"

  const axios = window.axios

  export default {
    mounted() {
      // checking BlockAddBlock
      window.blockAdBlock.on(true, () => {
        this.isBlockAdBlock = true
      })
      //storeからの決済はスマホのみ。
      if (this.isMobile) {
        this.checkReferer("store_referer", "/store/")
      }
      //membershipからはPCも戻させる
      this.checkReferer("membership_referer", "/account/membership/payment")
    },
    components: {
      Stripe,
      BraintreePayPal,
      Agreement,
      LoadingModal,
      RegisterdPaymentMethodModal,
    },
    props: {
      flash: Object,
      defaultPaymentMethod: String,
      initialPaymentMethod: String,
      registeredCard: Object,
      braintreeRegistrationDatetime: String,
      supportUrl: String,
      termsHtml: String,
      isMobile: Boolean,
    },
    data() {
      return {
        paymentMethod: this.initialPaymentMethod,
        checkedAgreement: false,
        showLoadingModal: false,
        isCardEditMode: false,
        isRequesting: false,
        isError: false,
        errorMessage: null,
        isBlockAdBlock: false,
        isRegisterdPaymentMethod: false,
        showRegisteredPaymentMethodModal: false,
        isReferer: false,
        refererUrl: null
      }
    },
    computed: {
      showAgreement() {
        // デフォルト決済手段が登録されていない時に出す
        return !this.defaultPaymentMethod
      },
    },
    methods: {

      /**
       * カードを新規に登録し、デフォルト決済手段に設定する
       */
      async registerStripeCard() {
        this.isRequesting = true
        this.showLoadingModal = true

        try {
          const token = await this.$refs.stripe.fetchToken()
          if (!token) {
            this.isRequesting = false
            this.showLoadingModal = false
            return
          }

          const response = await axios.post("/payment/register/stripe", {token})
          window.location.href = response.data.redirect
        } catch (error) {
          this.isError = true
          if (error.response.data.redirect) {
            window.location.href = error.response.data.redirect
          } else {
            this.errorMessage = error.response.data.message
            if (!this.errorMessage && error.response.data.errors) {
              this.errorMessage = error.response.data.errors[0].message
            }
            this.isRequesting = false
            this.showLoadingModal = false
          }
        }
      },

      /**
       * カード情報を更新し、デフォルト決済手段に設定する
       */
      async updateStripeCard() {
        this.isRequesting = true
        this.showLoadingModal = true

        try {
          const token = await this.$refs.stripe.fetchToken()
          if (!token) {
            this.isRequesting = false
            this.showLoadingModal = false
            return
          }

          const response = await axios.put("/payment/card", {token})
          window.location.href = response.data.redirect
        } catch (error) {
          this.isError = true
          if (error.response.data.redirect) {
            window.location.href = error.response.data.redirect
          } else {
            this.errorMessage = error.response.data.message
            if (!this.errorMessage && error.response.data.errors) {
              this.errorMessage = error.response.data.errors[0].message
            }
            this.isRequesting = false
            this.showLoadingModal = false
          }
        }
      },

      /**
       * カード情報を削除前の確認
       */
      confirmDeleteCard(agent) {
        if (window.confirm(`Are you sure to delete your payment information ?`)) {
          this.deleteCard(agent)
        }
      },

      /**
       * カード情報を削除する
       */

      async deleteCard(agent) {
        this.showLoadingModal = true

        try {
          const response = await axios.delete(`/payment/customer/${agent}`)
          this.deleteSuccessfullyRegisteredCookie()
          window.location.href = response.data.redirect
        } catch (error) {
          this.isError = true
          this.errorMessage = error.response.data.message
        } finally {
          this.isRequesting = false
          this.showLoadingModal = false
        }
      },

      /**
       * PayPal のダイアログで認証された時に呼ばれるコールバック
       * PayPal の情報を新規に登録し、デフォルト決済手段に設定する
       */
      async paypalCallback(nonce) {
        this.isRequesting = true
        this.showLoadingModal = true

        try {
          const response = await axios.post("/payment/register/paypal", {token: nonce})
          window.location.href = response.data.redirect
        } catch (error) {
          this.isError = true
          this.errorMessage = error.response.data.message
          if (!this.errorMessage && error.response.data.errors) {
            this.errorMessage = error.response.data.errors[0].message
          }
          this.isRequesting = false
          this.showLoadingModal = false
        }
      },

      /**
       * 既に登録済みの決済手段を、デフォルトの決済手段として設定する
       */
      async updatePaymentMethod() {
        this.isRequesting = true
        this.showLoadingModal = true

        try {
          const response = await axios.put(`/payment/register/${this.paymentMethod}`)
          window.location.href = response.data.redirect
        } catch (error) {
          this.isError = true
          this.errorMessage = error.response.data.message
          if (!this.errorMessage && error.response.data.errors) {
            this.errorMessage = error.response.data.errors[0].message
          }
          this.isRequesting = false
          this.showLoadingModal = false
        }
      },

      /**
       * 決済途中に、決済情報が登録されておらず設定画面に移動した時に、決済画面に戻すための仕組み
       * 決済方法を登録した後に、「途中の決済がありますが戻りますか？」と通知するかどうかを判定、フラグに書き込みを行う
       * @param cookieKey 戻すURLを格納しているCookie Key
       * @param checkRefererPath refererに保存されているURLに指定したパスが含まれているかどうかの検証を行う
       */
      checkReferer(cookieKey, checkRefererPath) {
        // get cookie for mobile
        const referer = this.$cookie.get(cookieKey)
        if (!referer) {
          return
        }
        this.refererUrl = decodeURIComponent(referer)
        this.isReferer = this.refererUrl.includes(checkRefererPath)
        if (this.flash) { //なんらかの操作がされていないなら何もしない
          const successfullyRegistered = !!(this.flash.successfully_registered || this.flash.successfully_updated_agent)
          // registered, update
          if (successfullyRegistered && this.isReferer) {
            this.showRegisteredPaymentMethodModal = this.isReferer
          }
          // delete, edit the card number
          if (this.flash.successfully_delete_customer || this.flash.successfully_updated_card) {
            this.$cookie.delete(cookieKey)
          }
        }
      },

      close() {
        this.hideRegisteredPaymentMethodModal()
        this.deleteSuccessfullyRegisteredCookie()
      },
      setSuccessfullyRegisteredCookie() {
        this.$cookie.set("successfully_registered", true, 1)
      },
      deleteSuccessfullyRegisteredCookie() {
        this.$cookie.delete("successfully_registered")
      },
      hideRegisteredPaymentMethodModal() {
        this.showRegisteredPaymentMethodModal = false
      },
      proceedToCheckout() {
        this.setSuccessfullyRegisteredCookie()
        location.href = this.refererUrl
      }
    }
  }
</script>
