var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root for-sp" },
    [
      _c("transition", { attrs: { name: "overlay" } }, [
        _vm.isShown
          ? _c("div", { staticClass: "modal-content-overlay" })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "modal" } }, [
        _vm.isShown
          ? _c(
              "div",
              {
                staticClass: "modal-content",
                attrs: { id: "gdpr-modal-bottom" }
              },
              [
                _c("div", { staticClass: "modal-wrapper" }, [
                  _c("div", { staticClass: "modal-container" }, [
                    _c("div", { staticClass: "text fs-s" }, [
                      _c("p", [
                        _vm._v(
                          "\n              We use cookies as described in our "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/about/cookie_policy",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Cookie Policy")]
                        ),
                        _vm._v(
                          '. By continuing to use our site or clicking "OK", you consent to our use of cookies.\n            '
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "button-wrapper" }, [
                      _c("button", { on: { click: _vm.close } }, [
                        _vm._v("OK")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text fs-s" }, [
                        _c(
                          "a",
                          {
                            staticClass: "click-text",
                            on: { click: _vm.showUpdates }
                          },
                          [
                            _vm._v("Terms and privacy policy updates"),
                            _c("i", {
                              staticClass:
                                "fas fa-caret-down modal-fa-caret-down ml-b va-m fs-l"
                            })
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "policy-updates" }, [
                      _c("div", { staticClass: "text fs-s" }, [
                        _c("p", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/about/terms_of_use",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Terms of Use Agreement:")]
                          ),
                          _vm._v(
                            " updated effective December 12, 2022\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/about/privacy_policy",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Privacy Policy:")]
                          ),
                          _vm._v(
                            " updated effective February 27, 2023\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/about/cookie_policy",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Cookie Policy:")]
                          ),
                          _vm._v(
                            " updated effective October 22, 2021\n              "
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }