var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-payment-policy-container" }, [
    _c("div", {
      staticClass: "text-payment-policy",
      domProps: { innerHTML: _vm._s(_vm.termsHtml) }
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.checked,
          expression: "checked"
        }
      ],
      class: { checked: _vm.checked },
      attrs: { type: "checkbox", id: "agreement" },
      domProps: {
        checked: Array.isArray(_vm.checked)
          ? _vm._i(_vm.checked, null) > -1
          : _vm.checked
      },
      on: {
        change: function($event) {
          var $$a = _vm.checked,
            $$el = $event.target,
            $$c = $$el.checked ? true : false
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v)
            if ($$el.checked) {
              $$i < 0 && (_vm.checked = $$a.concat([$$v]))
            } else {
              $$i > -1 &&
                (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
            }
          } else {
            _vm.checked = $$c
          }
        }
      }
    }),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "btn-supporter-policy mt12",
        attrs: { tabindex: "7", for: "agreement" }
      },
      [_vm._v("\n    I have read and agree to the Terms of Use Agreement.\n  ")]
    ),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "text-payment-notice" }, [
      _c("li", [_vm._v("All payments are non-refundable.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }