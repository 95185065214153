const $ = window.$;
const _ = window._;

$(function() {
    const enabledButton = $('.js-clubroom_enabled_button');
    const isChecked = enabledButton.hasClass("checked");
    const isDisabled = enabledButton.hasClass("disabled");

    if(isChecked && !isDisabled) {
        $('#app_setting').show();
    }

    if(!isChecked && !isDisabled) {
      enabledButton.on("click", function () {
        $('#app_setting').slideToggle();
        enabledButton.toggleClass("checked");
        if (!$('input[name="clubroom_enabled"]').prop("checked")) {
          $(".js-clubroom-settings-button input").prop("checked", true);
        } else {
          $(".js-clubroom-settings-button input").prop("checked", false);
        }
      });
    }

  $('.form-club-submit, .form-club-user-comment').on('submit', function (event) {
    const $this = $(this)
    if ($this.data('submitted') === true) {
      event.preventDefault()
      return
    }
    $this.data('submitted', true)
  })

  // Club banner
  var current_unixtime = $('body').data('time');
  var element = document.querySelector(".club-search-form-search");

  if (current_unixtime && element) {
    var banners = [
      {
        html: '<div class="club-banner" style="display:block; text-align:center;"><a href="https://myanimelist.net/forum/?topicid=2114449"><img class="ga-click ga-impression" data-ga-click-type="paradox_live_badge_event" data-ga-impression-type="paradox_live_badge_event" src="https://cdn.myanimelist.net/resources/misc/20230905_paradox_live_club.gif" width="720" height="90" alt="Paradox Live Badge Event"></a></div>',
        start: 1693897200,
        end: 1699772400
      },
      {
        html: '<div class="club-banner" style="display:block; text-align:center;"><a href="https://myanimelist.net/forum/?topicid=2116998"><img class="ga-click ga-impression" data-ga-click-type="club_top_bunkasai_open" data-ga-impression-type="club_top_bunkasai_open" src="https://cdn.myanimelist.net/resources/misc/2023/20230918_club_top_bunkasai.gif" width="720" height="90" alt="FESTIVAL OPEN NOW"></a></div>',
        start: 1695020400,
        end: 1695625200
      },
    ];

    // Filter available banners
    var available_banners = banners.filter(function(banner) {return banner.start <= current_unixtime && current_unixtime < banner.end});

    if (available_banners.length > 0 ) {
      var random_num = Math.floor(Math.random() * available_banners.length);
      element.insertAdjacentHTML('afterend', available_banners[random_num].html);
    }
  }

});
