import PurchaseSingleButton from "./PurchaseSingleButton.vue"
import PreviewButton from "./PreviewButton.vue"
import store from "./store/index"
import * as types from "./store/mutation-types"
import {getCurrency} from "../../../common/modules/currency"

const $ = window.$
const SVue = window.sVue

$(() => {

  // buy button
  Array.from(document.querySelectorAll(".v-manga-store-purchase-single-button")).forEach(el => {
    const item      = JSON.parse(el.getAttribute("data-item"))
    const currency  = getCurrency(JSON.parse(el.getAttribute("data-currency")))
    const loginPath = JSON.parse(el.getAttribute("data-loginPath"))
    const paymentSettings = JSON.parse(el.getAttribute("data-settings"))
    const termsHtml = el.getAttribute("data-terms-html")
    const supportUrl = el.getAttribute("data-support-url")

    new SVue({
      el,
      store: store(),
      render: h => h(PurchaseSingleButton),
      created() {
        this.$store.commit(types.INITIALIZE, {item, loginPath, currency, paymentSettings, termsHtml, supportUrl})
      },
    })
  })

  // preview button
  Array.from(document.querySelectorAll(".v-manga-store-preview-button")).forEach(el => {
    const item = JSON.parse(el.getAttribute("data-item"))

    new SVue({
      el,
      store: store(),
      render: h => h(PreviewButton),
      created() {
        this.$store.commit(types.INITIALIZE, { item })
      },
    })
  })

})
