<template>
  <div class="root">
    <transition name="modal">
      <div v-show="isShown" class="modal-content">
        <div class="modal-mask" @click="cancel"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <div class="modal-title">{{ title }}</div>
              <div class="modal-table">
                <div class="modal-price">
                  {{ currency.symbol }}<span>{{ currency.formatPrice(totalPriceWithoutTax) }}</span>{{ currency.name }}
                  <span class="tax">(Excl. Tax)</span>
                </div>
                <div><a class="modal-cancel-button margin-l10" @click="cancel">Cancel</a></div>
                <div><a class="modal-buy-button margin-l10" :class="{'is-disable': checkedIdsNum == 0}" @click="proceed">Buy</a></div>
              </div>
              <div class="modal-checkbox-wrap">
                <span v-if="checkedIdsNum === 0">Check all</span>
                <span v-if="checkedIdsNum > 0">Uncheck all</span>
                <div class="checkbox-wrap checkbox-s-wrap margin-l10">
                  <span class="checkbox" :class="checkAllClass" @click="toggleAll"></span>
                </div>
              </div>
            </div>
            <div class="modal-list">
              <div>
                <div class="modal-list-item" v-for="item in items" :class="{ 'is-hold' : item.isPossessed }">
                  <div>
                    <img width="46" height="auto" class="modal-list-cover margin-r10"
                      v-lazy="coverImage(item.coverImageBaseUrl)"
                      :class="{'disabled': item.isPossessed}">
                  </div>
                  <div class="modal-list-info">
                    <div class="modal-list-volume"
                         :class="{ 'disabled': item.isPossessed, 'icon-new-item': !item.isPossessed && item.isNew }"
                    >{{ item.numberingText }}</div>
                    <div class="modal-list-price" v-show="!item.isPossessed">
                      <template v-if="item.salePrice >= 0">
                        <span class="is-sale">{{ currency.formatSymbolAndPrice(item.price) }}</span>
                        Sale <span class="modal-price-sale">{{ currency.formatSymbolAndPrice(item.salePrice) }}</span>
                      </template>
                      <template v-else>
                        {{ currency.formatSymbolAndPrice(item.price) }}
                      </template>
                    </div>
                  </div>
                  <div>
                    <div class="checkbox-wrap margin-l10">
                      <input type="checkbox" :disabled="item.isPossessed || item.isFree" v-model="checkedIds" :value="item.id" class="volumeCheckbox">
                      <span class="checkbox"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="modal-close" @click="cancel">Close</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  @import "../../../../../css/variables";

  $text-price-color:$color-pc-text-c0392b;
  $text-volume-color:$color-pc-text-7b7d82;
  $button-payment-color:$color-bg-store-purchase-button;
  $button-cancel-color:$color-pc-text-2e51a2;
  $button-disable-color:$color-pc-text-999999;
  $border-color:$color-pc-border-ebebeb;
  $border-light-color:$color-pc-border-e5e5e5;
  $border-cover-color:$color-pc-border-e1e7f5;
  $header-height:147px;
  $modal-height:600px;
  $modal-height-s:400px;
  $modal-width:606px;
  $modal-side-padding:40px;
  img {
    vertical-align: bottom;
  }
  .modal-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
  }
  .modal-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color-pc-bg-000000, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    font-family: Verdana,Arial;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    width: $modal-width;
    height: $modal-height;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: $color-pc-bg-ffffff;
    color: $color-pc-text-353535;
    border-radius: 4px;
    transition: all .3s ease;
    @media screen and (max-height: $modal-height) {
      height: $modal-height-s;
    }
  }
  .modal-header {
    display: block;
    border-bottom: 1px solid $border-light-color;
  }
  .modal-title {
    width: 100%;
    box-sizing: border-box;
    padding: 18px $modal-side-padding;
    line-height: 18px;
    font-size: 16px;
    font-weight: 700;
    word-wrap: break-word;
  }
  .modal-price {
    font-weight: 700;
    font-size: 16px;
    flex: 1;
    .tax {
      color: $color-pc-text-323232;
      font-size: 10px;
      font-weight: 300;
    }
    span {
      font-size: 30px;
    }
  }
  .modal-table {
    display: flex;
    padding: 0 $modal-side-padding 20px;
    width: 100%;
    box-sizing: border-box;
  }
  %button {
    font-weight: 700;
    font-size: 14px;
    width: 118px;
    padding: 7px 0;
    display: block;
    cursor: pointer;
    text-align: center;
    line-height: 16px;
    border-radius: 3px;
    &.is-disable {
      cursor: auto;
      color: $button-disable-color;
      border: 2px solid $button-disable-color;
    }
  }
  .modal-cancel-button {
    color: $button-cancel-color;
    border: 2px solid $button-cancel-color;
    @extend %button;
    &:hover {
      color: $button-cancel-color;
      background-color: $color-pc-bg-e1e7f5;
      text-decoration: none;
    }
  }
  .modal-buy-button {
    background-color: $button-payment-color;
    color: $color-white;
    border: 2px solid $button-payment-color;
    @extend %button;
    text-decoration: none;
    opacity: 1;
    &:hover {
      color: $color-white;
      text-decoration: none;
      opacity: 0.7;
    }
    &.is-disable {
      background-color: $color-pc-text-ffffff;
      &:hover {
        color: $button-disable-color;
        border: 2px solid $button-disable-color;
        opacity: 1;
      }
    }
  }
  .modal-checkbox-wrap {
    line-height: 37px;
    height: 37px;
    padding-bottom: 3px;
    display: block;
    text-align: center;
    border-top: 1px solid $border-color;
    label {
      font-size: 12px;
    }
  }
  .modal-list {
    flex: 1;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .modal-list-item {
    margin: 0;
    padding: 10px $modal-side-padding;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid $border-light-color;
    &:last-child{
      border-bottom: none;
    }
    &.is-hold{
      color: $color-pc-text-bababa;
    }
  }
  .modal-list-cover {
    box-sizing: border-box;
    border: 1px solid  $border-cover-color;
    &.disabled {
      opacity: .3;
    }
  }
  .modal-list-info {
    width: 435px;
    display: flex;
    flex-direction: column;
  }
  .modal-list-volume {
    font-size: 12px;
    font-weight: 700;
    flex: 1;
    &.disabled {
      opacity: .3;
    }
    .is-hold &{
      color: $color-pc-text-bababa;
    }
    &.icon-new-item {
      position: relative;
      padding-left: 36px;
      &:after {
        position: absolute;
        top: 1px;
        left: 0;
        background-color: $color-pc-bg-c0392b;
        display: inline-block;
        padding: 2px 4px;
        border-radius: 2px;
        color: $color-pc-text-ffffff;
        font-size: 10px;
        font-weight: 300;
        line-height: 1.0;
        content: "New";
      }
    }
  }
  .modal-list-price {
    font-size: 14px;
    color: $text-price-color;
    .is-sale {
      color: $color-pc-text-787878;
      font-size: 12px;
      font-weight: 300;
      text-decoration: line-through;
    }
    .modal-price-sale {
      font-weight: 700;
    }
  }
  .checkbox-wrap {
    position: relative;
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    &.checkbox-s-wrap{
      width: 20px;
      height: 20px;
    }
  }
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border: none;
    display: block;
    z-index: 1;
    cursor: pointer;
  }
  .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-pc-text-ffffff;
    border: 1px solid $color-pc-border-bababa;
    border-radius: 2px;
    display: block;
    vertical-align: middle;
    background-size: contain;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &.is-checked,
    input[type="checkbox"]:checked + & {
      background-image: url(/images/manga_store/pc/checkbox_on@2x.png?v=161115001);
    }
    &.is-mixed {
      background-image: url(/images/manga_store/pc/checkbox_mixed@2x.png?v=161115001);
    }
    input[type="checkbox"]:disabled + & {
      background-image: none;
      background-color: rgba($color-pc-bg-e5e5e5,0.3);
      border: 1px solid $color-pc-border-ebebeb;
      border-radius: 2px;
    }
  }
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    content: '';
    display: block;
    width: 29px;
    height: 29px;
    background: url(/images/manga_store/pc/modal_close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
    cursor: pointer;
  }
  .margin-l10 {
    margin-left: 10px;
  }
  .margin-r10 {
    margin-right: 10px;
  }
  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .dark-mode {
    @import "../../../../../css/colors_dark";
  
    $text-price-color:$color-pc-text-c0392b;
    $text-volume-color:$color-pc-text-7b7d82;
    $button-payment-color:$color-bg-store-purchase-button;
    $button-cancel-color:$color-pc-text-2e51a2;
    $button-disable-color:$color-pc-text-999999;
    $border-color:$color-pc-border-ebebeb;
    $border-light-color:$color-pc-border-e5e5e5;
    $border-cover-color:$color-pc-border-e1e7f5;
    $header-height:147px;
    $modal-height:600px;
    $modal-height-s:400px;
    $modal-width:606px;
    $modal-side-padding:40px;

    .modal-mask {
      background-color: rgba($color-pc-bg-000000, .5);
    }
    .modal-container {
      width: $modal-width;
      height: $modal-height;
      background-color: $color-pc-bg-ffffff;
      color: $color-pc-text-353535;
      @media screen and (max-height: $modal-height) {
        height: $modal-height-s;
      }
    }
    .modal-header {
      border-bottom: 1px solid $border-light-color;
    }
    .modal-title {
      padding: 18px $modal-side-padding;
    }
    .modal-price {
      .tax {
        color: $color-pc-text-323232;
      }
      }
    %button {
      &.is-disable {
        cursor: auto;
        color: $button-disable-color;
        border: 2px solid $button-disable-color;
      }
    }
    .modal-cancel-button {
      color: $button-cancel-color;
      border: 2px solid $button-cancel-color;
      @extend %button;
      &:hover {
        color: $button-cancel-color;
        background-color: $color-pc-bg-e1e7f5;
        text-decoration: none;
      }
    }
    .modal-buy-button {
      background-color: $button-payment-color;
      color: $color-white;
      border: 2px solid $button-payment-color;
      @extend %button;
      text-decoration: none;
      opacity: 1;
      &:hover {
        color: $color-white;
        text-decoration: none;
        opacity: 0.7;
      }
      &.is-disable {
        background-color: $color-pc-text-ffffff;
        &:hover {
          color: $button-disable-color;
          border: 2px solid $button-disable-color;
          opacity: 1;
        }
      }
    }
    .modal-checkbox-wrap {
      border-top: 1px solid $border-color;
    }
    .modal-list-item {
      padding: 10px $modal-side-padding;
      border-bottom: 1px solid $border-light-color;
      &.is-hold{
        color: $color-pc-text-bababa;
      }
    }
    .modal-list-cover {
      border: 1px solid  $border-cover-color;
    }
    .modal-list-volume {
      .is-hold &{
        color: $color-pc-text-bababa;
      }
      &.icon-new-item {
        &:after {
          background-color: $color-pc-bg-c0392b;
          color: $color-pc-text-ffffff;
          content: "New";
        }
      }
    }
    .modal-list-price {
      color: $text-price-color;
      .is-sale {
        color: $color-pc-text-787878;
      }
    }
    .checkbox {
      background-color: $color-pc-text-ffffff;
      border: 1px solid $color-pc-border-bababa;
      cursor: pointer;
      input[type="checkbox"]:disabled + & {
        background-image: none;
        background-color: rgba($color-pc-bg-e5e5e5,0.3);
        border: 1px solid $color-pc-border-ebebeb;
        border-radius: 2px;
      }
    }
  }
</style>

<script type="text/babel">
  import * as types from "../organisms/purchase_bulk_button/store/mutation-types"
  import * as book from "../../common/modules/book"

  export default {
    props: {
      isShown: Boolean,
      title: String,
      items: Array,
      currency: Object,
      purchasableItems: Array,
      checkedItemIds: Array,
      totalPriceWithoutTax: Number,
      isPurchased: Boolean,
    },
    data() {
      return {
        checkedIds: this.checkedItemIds,
      }
    },
    watch: {
      checkedIds() {
        this.$emit('updateCheckedItemIds', this.checkedIds)
      }
    },
    computed: {
      checkAllClass() {
        return {
          "is-mixed": this.checkedIds.length > 0 && this.checkedIds.length < this.purchasableItems.length,
          "is-checked": this.checkedIds.length === this.purchasableItems.length
        }
      },
      checkedIdsNum() {
        if (this.isPurchased) {
          this.checkedIds = this.checkedItemIds
          this.$emit('updateIsPurchased', false)
        }
        return this.checkedIds.length
      }
    },
    methods: {
      coverImage(coverImageBaseUrl) {
        const imageSize = (window.devicePixelRatio > 1) ? book.COVER_IMAGE_SIZE_S_2X : book.COVER_IMAGE_SIZE_S
        return {
          src: book.generateCoverImageUrl(coverImageBaseUrl, imageSize),
          loading: "/images/xmlhttp-loader.gif?v=171101"
        }
      },
      toggleAll() {
        if (this.checkedIds.length === 0) {
          this.checkedIds = this.purchasableItems.map(i => i.id)
        } else {
          this.checkedIds = []
        }
      },
      proceed() {
        if (this.checkedIds.length > 0) {
          this.$emit('proceed')
        }
      },
      cancel() {
        this.$emit('cancel')
      },
    },
  }
</script>
