const $ = window.$;

//詳細ページのpicture表示
$(document).ready(function () {
  $(".js-picture-gallery").fancybox({
    titleFormat: function(title) { 
      return $(".js-picture-gallery[href=\"" + this.href + "\"]").parent().find(".caption").html()
    },
    titlePosition: "over"
  })
})

