"use struct";

export function register(path, dataLayer){
  if ( path.match("register.php") && document.getElementById('ga_goodresult') ) {
    dataLayer.push({
        'trackPageview': '/register.php?from=%2F&c-ms-mode=2',
        'member_title': '2-2-1.会員情報入力完了',
        'event': 'loadready-register'
    });
  }
}
