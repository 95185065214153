import GtmJsEvent from "../../../../common/modules/gtm/jsEvent";

const $ = window.$;
const axios = window.axios

const Cookies = require("js-cookie");
const advancedKey = "anime_update_advanced";

$(function () {
  if ($("body").hasClass("ownlist_anime_update")) {
    const oldNotes = $("#add_anime_comments").val();

    function toggleAdvanced (show) {
      $("#show-advanced-button").toggle(!show);
      $("#hide-advanced-button").toggle(show);
      $("table.advanced").toggle(show);
      $("#top-submit-buttons").toggle(show);
    }
    $("#advanced-button").on("click", function (e) {
      const showAdvanced = $("#show-advanced-button:visible").exists();
      toggleAdvanced(showAdvanced);
      axios.post("/cookie/set", {key: advancedKey, value: showAdvanced ? 1 : 0, expires: 365});
    });
    if (Cookies.get(advancedKey) == 1) {
      toggleAdvanced(true);
    } else {
      toggleAdvanced(false);
    }

    if ($("#add_anime_status").val() == 2) {
      // completedだったらrewatchingのチェックボックスを表示
      $("#rewatching_span").show();
    }

    // ajaxで返ってくるHTMLのためにグローバルにメソッドを定義しておく
    // ajaxのエンドポイントをリファクタしたら消す
    window.chooseAnime = function (aid, eps, status) {
      $("#anime_id").val(aid);
      $("#anime_num_episodes").val(eps);
      $("#anime_airing_status").val(status);

      $("#search_result").hide();
      $("#search_field").hide();
      $("#totalEpisodes").text(eps);
      $("#selected_field").show().html(_.template(`<strong><%- title %></strong> <small><a href="javascript:void(0);" onclick="cancelSelection();">Cancel</a></small>`)({title: document.getElementById(`maAnimeTitle${aid}`).innerText}));
    };
    window.cancelSelection = function () {
      $("#selected_field").hide();
      $("#search_result").html("");
      $("#search_field").show();
    };

    function enableEndDate (isEnable) {
      if (isEnable === null) {
        // トグル動作
        const change = $("#unknown_end").prop("checked");
        $("#add_anime_finish_date_month").prop("disabled", change);
        $("#add_anime_finish_date_year").prop("disabled", change);
        $("#add_anime_finish_date_day").prop("disabled", change);
        $("#unknown_end").prop("checked", change);
      } else {
        $("#add_anime_finish_date_month").prop("disabled", !isEnable);
        $("#add_anime_finish_date_year").prop("disabled", !isEnable);
        $("#add_anime_finish_date_day").prop("disabled", !isEnable);
        $("#unknown_end").prop("checked", !isEnable);
      }
    }

    function enableStartDate (isEnable) {
      if (isEnable === null) {
        // トグル動作
        const change = $("#unknown_start").prop("checked");
        $("#add_anime_start_date_month").prop("disabled", change);
        $("#add_anime_start_date_year").prop("disabled", change);
        $("#add_anime_start_date_day").prop("disabled", change);
        $("#unknown_start").prop("checked", change);
      } else {
        $("#add_anime_start_date_month").prop("disabled", !isEnable);
        $("#add_anime_start_date_year").prop("disabled", !isEnable);
        $("#add_anime_start_date_day").prop("disabled", !isEnable);
        $("#unknown_start").prop("checked", !isEnable);
      }
    }

    function setToday (isStart) {
      const dateVar = new Date();
      const year  = dateVar.getFullYear();
      const month   = dateVar.getMonth() + 1;
      const day   = dateVar.getDate();

      if (isStart) {
        if (!$("#add_anime_start_date_month").prop("disabled")) {
          $("#add_anime_start_date_month").val(month);
          $("#add_anime_start_date_year").val(year);
          $("#add_anime_start_date_day").val(day);
        }
      } else {
        if (!$("#add_anime_finish_date_month").prop("disabled")) {
          $("#add_anime_finish_date_month").val(month);
          $("#add_anime_finish_date_year").val(year);
          $("#add_anime_finish_date_day").val(day);
        }
      }
    }

    function searchAnime () {
      $("#search_result").show().html("<div class=\"normal_header\">Search Results</div><img src=\"/images/xmlhttp-loader.gif\" />");
      const searchString = document.getElementById("queryTitle").value;

      $.get(`/includes/masearch.inc.php?b=${searchString}`, function (data__safe) {
        $("#search_result").show().html(data__safe);
      });
    }

    // submitボタン
    $(".main_submit").on("click", function () {
      $(this).prop('disabled',true);
      if (!$("#anime_id").val()) {
        alert("You did not select an anime title from the Anime Database.");
        $(this).prop('disabled',false);
        return;
      }

      if ($("#add_anime_status").val() == 0) {
        alert("You must select a status (watching, completed, etc...) for this series.");
        $(this).prop('disabled',false);
        return;
      }

      if ($("#add_anime_comments").val() !== oldNotes) {
        // 計測イベントを送信
        const sendParam = 'aid:' + $("#anime_id").val();
        GtmJsEvent.send("notes-submit-animelist-update", sendParam);
      }

      $("#main-form").submit();
    });

    // deleteボタン
    $(".delete_submit").on("click", function () {
      if (!confirm("Are you sure you want to delete this series?")) {
        return;
      }
      $("#delete-form").submit();
    });

    // 検索枠でエンターをキャッチ
    $("#queryTitle").on("keypress", function (e) {
      const key = e.which;
      if (key === 13) {
        searchAnime();
        return false;
      }
    });
    $("#search_submit").on("click", searchAnime);

    // +ボタン
    $("#increment_episode").on("click", function () {
      const val = parseInt($("#add_anime_num_watched_episodes").val()) || 0;
      $("#add_anime_num_watched_episodes").val(val + 1);
    });

    // Unknown Dateボタン
    $("#unknown_start").on("click", function () {
      enableStartDate(null);
    });
    $("#unknown_end").on("click", function () {
      enableEndDate(null);
    });

    // Insert Todayボタン
    $("#start_date_insert_today").on("click", function () {
      setToday(true);
    });
    $("#end_date_insert_today").on("click", function () {
      setToday(false);
    });

    // Re-watchingチェックボックス
    $("#add_anime_is_rewatching").on("click", function () {
      const checked = $(this).prop("checked");
      if (checked) {
        $("#add_anime_num_watched_episodes").val(1);
      } else {
        $("#add_anime_num_watched_episodes").val($("#totalEpisodes").html());
      }
    });

    // ストレージドロップダウン
    $("#add_anime_storage_type").on("change", function () {
      const $storageObj      = $("#storage_div");
      const $storageDescriptionObj = $("#storage_description");
      const storageVal       = $("#add_anime_storage_type").val();
      const storageValue       = $("#add_anime_storage_value");

      if (storageVal == 2) {
        // page load
        $storageObj.show();
        $storageDescriptionObj.html("DVD's");
        storageValue.focus();
      } else if (storageVal == 5) {
        // VHS
        $storageObj.show();
        $storageDescriptionObj.html("VHS tapes");
        storageValue.focus();
      } else if (storageVal == 4) {
        // selected retail
        $storageObj.show();
        $storageDescriptionObj.html("retail DVD's");
        storageValue.focus();
      } else if ((storageVal == 6) || (storageVal == 1) || storageVal == 7) {
        // selected HD or external HD or NAS
        $storageObj.show();
        $storageDescriptionObj.html("drive space (GB)");
        storageValue.focus();
      } else if (storageVal == 8) {
        // Blu-ray
        $storageObj.show();
        $storageDescriptionObj.html("Blu-ray's");
        storageValue.focus();
      } else {
        $storageObj.hide();
      }
    });

    // ステータスドロップダウン
    $("#add_anime_status").on("change", function () {
      const animeId      = $("#anime_id").val();
      const status       = $("#add_anime_status").val();
      const airingStatus = $("#anime_airing_status").val();
      const episodes     = $("#anime_num_episodes").val();
      const previewDate  = $("#anime_preview_date").val();

      if (!animeId) {
        if ((status == 2) || (status == 1)) {
          alert("You haven't chosen an anime and thus you may not choose Watching or Completed yet.");
          $("#add_anime_status").val(null);
          return false;
        }
      }

      if (airingStatus == 1) {
        // still airing
        if (status == 2) {
          alert("This anime is still airing, you cannot mark it as completed.");
          $("#add_anime_status").val(0);
          return false;
        }
      }
      else if (airingStatus == 3) {
        // Preview Dateが過ぎていた場合、Complete以外に変更可能。
        if (previewDate && new Date(previewDate) <= new Date()) {
          if (status == 2) {
            alert("This anime is still airing, you cannot mark it as completed.");
            $("#add_anime_status").val(6);
            return false;
          }
        } else {
          if (status != 6) {
            alert("This anime has not aired yet, you cannot mark it as anything but Plan to Watch");
            $("#add_anime_status").val(6);
            return false;
          }
        }
      }

      if (status != 2) {
        // Completed以外
        if (status == 6) {
          enableStartDate(false);
        }
        enableEndDate(false);
        $("#rewatching_span").hide();
      } else {
        // Completed
        if (animeId != 0) {
          $("#add_anime_num_watched_episodes").val(episodes);
        }
        enableStartDate(true);
        enableEndDate(true);
        $("#rewatching_span").show();
      }
    });

    // Storageのイベントを発火して、DOMの状態を更新する
    $("#add_anime_storage_type").trigger("change");

    // StatusがCompletedならrewatchingのチェックボックスを表示
    if ($("#add_anime_status").val() == 2) {
      $("#rewatching_span").show();
    }
  }
});
