"use strict";

const $ = window.$;
const _ = window._;

module.exports = {
  initiailze: function() {
    if ($('div.mal-tipmenu').length == 0) {
      $('<div class="mal-tipmenu"></div>').appendTo('body').hide();
    }
  },
  add: function(data) {
    $('.mal-tipmenu').append(data);
  },
  width: function() {
    return $('.mal-tipmenu').innerWidth();
  },
  height: function() {
    return $('.mal-tipmenu').innerHeight();
  },
  position: function(top, left) {
    if (top < 0) {
      top = 0;
    }
    if (left < 0) {
      left = 0;
    }
    if (left > $(window).width() - this.width()) {
      left = $(window).width() - this.width();
    }
    const tipmenu = $('.mal-tipmenu');
    tipmenu.css('top', top);
    tipmenu.css('left', left);
  },
  show: function() {
    $('.mal-tipmenu').show();
  },
  hide: function() {
    $('.mal-tipmenu').hide();
  },
};
