const $ = window.$
const _ = window._
const sVue = window.sVue

const VERSION = 1

let isComponentInitialized = false
function initializeComponents() {
  if (isComponentInitialized) {
    return
  }
  isComponentInitialized = true

  _.each(["anime", "manga", "character", "person","company", "club", "user", "news", "featured", "forum", "separator"], (type) => {
    sVue.component(`incremental-result-item-${type}`, {
      template: $(`#incremental-result-item-${type}`).text(),
      props: ["item", "url", "focus"],
      computed: {
        mediaTypeWithStartYear() {
          if (this.item.payload.start_year > 0) {
            return `${this.item.payload.media_type}, ${this.item.payload.start_year}`
          } else {
            return this.item.payload.media_type
          }
        },
      },
    })
  })
}

/**
 * Vueを初期化する
 *
 * @param {String} element -  検索ボックスやサジェストのDIVが入った親の要素のセレクタ
 * @param {Object} options
 * @param {Object} options.resultPosition - サジェスト結果のDIVの位置や幅(left, top, width)
 * @param {Function} options.generateItemPageUrl - アイテムオブジェクトを受け取って、遷移先URLを生成する。
 *                                                 指定しなければitem.urlになる
 * @param {Function} options.generateResultPageParams - 検索結果ページのURLを生成するメソッド
 * @param {String} options.type - 検索のタイプ
 * }
 * @returns {*}
 */
function initializeVueModel(element, options) {
  initializeComponents()

  // サジェスト結果の位置、サイズ調整
  $(element).find(".incrementalSearchResultList").css({
    left: options.resultPosition.left,
    top: options.resultPosition.top,
    width: options.resultPosition.width,
  })

  // v-model=keywordになっているのでrendering前に削除しないとエラーになる
  const $kw = $(`${element} input[type='text'][name='${options.query}']`)
  const keyword = $kw.val() || ""
  $kw.removeAttr("value")

  let currentAjaxRequest;
  let requestTimer;

  const vmIncrementalSearch = new sVue({
    el: element,
    data: {
      keyword: keyword,
      items: [],
      isFocused: false,
      selection: -1,
      isRequesting: false,
      isRequested: false,
      type: options.type ? options.type : "anime",
      isShowDropDownMenu: false, // for SP
    },
    watch: {
      keyword(val) {
        // 選択している項目をリセット
        this.selection = -1

        // キーワードが空なら項目をリセットして現在発行しているリクエストをabort
        if (val === "") {
          this.abortRequest()
          this.items = []
          return
        }
        this.executeRequest()
      },
      type() {
        if (this.keyword.length > 0) {
          this.selection = -1
          this.items = []
          this.executeRequest()
        }
      },
    },
    methods: {
      abortRequest() {
        // 現在発行しているリクエストをabort
        if (requestTimer) {
          clearTimeout(requestTimer);
          requestTimer = null;
        }
        if (currentAjaxRequest &&
          currentAjaxRequest.readyState > 0 &&
          currentAjaxRequest.readyState < 4) {
          currentAjaxRequest.abort();
          currentAjaxRequest = null;
        }
        // リクエスト中の判定用（後方互換性のため）
        this.isRequesting = false;
        this.isRequested = false;
      },
      executeRequest() {
        // 前リクエストを中断しておく
        this.abortRequest();
        // インクメンタルサーチ可否を確認する
        if (!this.canIncrementalSearch) {
          return;
        }
        // リクエスト中の判定用（後方互換性のため）
        this.isRequesting = true;
        requestTimer = setTimeout(function(){
          currentAjaxRequest = $.ajax({
            url: "/search/prefix.json",
            type: "GET",
            data: {
              type: this.type,
              keyword: this.keyword,
              v: VERSION,
            },
            dataType: "json",
            cache: true,
            timeout: 10000,
          }).done((data) => {
            let items = []
            const categories = data.categories
            _.each(categories, (category) => {
              // もしカテゴリのタイプがモデルと違ったらseparatorを入れる。
              // 基本的にAllでの検索時用の処理になる
              if (category.type !== vmIncrementalSearch.type) {
                items.push({
                  type: "separator",
                  name: category.type,
                })
              }
              items = items.concat(category.items)
            });
            vmIncrementalSearch.items = items;
          }).always(()=>{
            // リクエスト中の判定用（後方互換性のため）
            this.isRequesting = false;
            this.isRequested = true;
          });
        }.bind(this), 500);
      },
      resolveComponent(item) {
        return `incremental-result-item-${item.type}`
      },
      /**
       * 上下キーで選択を移動させる
       * @param {int} dir - -1で上、+1で下
       */
      moveSelection(dir) {
        // selectionは -1 ~ (items.length-1) までの値をとる。
        // selection=-1 の時に dir=-1 なら selection=(items.length-1) になり、
        // selection=(items.length-1) の時に dir=1 なら selection=-1 になる。
        // separatorは選択しないので、skipされる
        const rot = (this.items.length + 1)
        let nextIndex = this.selection
        do {
          nextIndex = (nextIndex + dir + 1 + rot) % rot - 1
        } while (nextIndex !== -1 && this.items[nextIndex].type === "separator")

        this.selection = nextIndex
      },
      /**
       * サジェスト結果のアイテムのURLを生成するメソッド
       * @param item
       * @returns {String}
       */
      generateItemPageUrl(item) {
        if (item.type === "separator") {
          return ""
        }

        if (options.generateItemPageUrl) {
          // オプションで自由に生成関数を指定することも可能
          return options.generateItemPageUrl(item)
        } else {
          // 効果測定のために末尾に検索文字列を追加する
          return `${item.url + (item.url.indexOf("?") !== -1 ? "&" : "?")}q=${encodeURIComponent(this.keyword)}&cat=${encodeURIComponent(item.type)}`
        }
      },
      // アイテムが選択されている場合はtrueを返す
      // 今のところPMの送信先を検索する箇所で使用されている戻り値
      jump() {
        this.abortRequest()
        if (this.selection !== -1) {
          // 何らかのアイテムが選択されていたら、その個別ページにジャンプ
          location.href = this.generateItemPageUrl(this.items[this.selection])
          return true
        } else if (this.canSearch) {
          // 検索結果ページにジャンプ
          location.href = this.resultPageUrl
        }
        return false
      },
      toggleDropDownMenu() {
        this.isShowDropDownMenu = !this.isShowDropDownMenu
      },
      selectType(type) {
        this.type = type
        this.toggleDropDownMenu()
      },
    },
    computed: {
      showResult() {
        return this.isFocused && (this.showViewAllLink || this.items.length > 0)
      },
      showViewAllLink() {
        return this.canSearch && this.canIncrementalSearch
      },
      showNoResponse() {
        return this.isRequested && this.items.length < 1
      },
      canSearch() {
        // 日本語だったら１文字、英語だったら３文字以上から検索可能
        const bytesInUTF8 = encodeURIComponent(this.keyword).replace(/%../g, "x").length
        return bytesInUTF8 >= 3
      },
      resultPageUrl() {
        // focusがあたった時にoptions.generateResultPageParamsを再評価するために
        // 本来不要であるがif文を入れてisFocusedを評価して、依存関係を明示的に作っている。
        if (this.isFocused || true) { // eslint-disable-line no-constant-condition
          const urls = {
            all: "/search/all?",
            anime: "/anime.php?",
            manga: "/manga.php?",
            character: "/character.php?",
            person: "/people.php?",
            store: "/store/search?",
            club: "/clubs.php?",
            user: "/users.php?",
            news: "/news/search?",
            featured: "/featured/search?",
            forum: "/forum/search?",
            company: "/company?"
          }
          return urls[this.type] + options.generateResultPageParams(this.type, this.keyword)
        }
      },
      changeTypeToName() {
        const name = {
          "anime"     : "Anime",
          "manga"     : "Manga",
          "store"     : "Manga Store",
          "person"    : "People",
          "character" : "Characters",
          "user"      : "User",
          "company"   : "Companies"
        }
        return name[this.type]
      },
      canIncrementalSearch() {
        // マンガストアの検索はまだインクリメンタル検索できない
        return this.type !== "store"
      },
      getType() {
        return this.type
      },
    }
  })

  return vmIncrementalSearch
}

module.exports = {
  initializeVueModel: initializeVueModel
}
