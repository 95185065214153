import RelatedBookButton from "./RelatedBookButton.vue"
import {createBook} from "../../../common/modules/book"

const $ = window.$
const SVue = window.sVue

$(() => {

  Array.from(document.querySelectorAll(".v-manga-store-related-book-button")).forEach(el => {
    const item = JSON.parse(el.getAttribute("data-item"))

    new SVue({
      el,
      data: {
        item: {},
      },
      render(h) {
        return h(RelatedBookButton, {
          props: {
            item: this.item,
          }
        })
      },
      created() {
        this.item = createBook(item)
      },
    })
  })

})
