var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("button-sample", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !_vm.item.isPossessed &&
              _vm.item.isPreviewable &&
              !_vm.item.isFree,
            expression:
              "!item.isPossessed && item.isPreviewable && !item.isFree"
          }
        ],
        staticClass: "button related-button-sample",
        attrs: {
          url: _vm.urlWithLocation(_vm.item.previewUrl, _vm.location),
          "is-mobile": false,
          height: 20,
          width: 62,
          "is-border": true,
          "is-small": true
        }
      }),
      _vm._v(" "),
      _c("button-read", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.item.isPossessed || _vm.item.isFree,
            expression: "item.isPossessed || item.isFree"
          }
        ],
        staticClass: "button related-button-read",
        attrs: {
          "is-mobile": false,
          height: 20,
          width: 62,
          "is-border": true,
          "is-small": true
        },
        on: { read: _vm.read }
      }),
      _vm._v(" "),
      _c("button-detail", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !_vm.item.isPossessed &&
              !_vm.item.isPreviewable &&
              !_vm.item.isFree,
            expression:
              "!item.isPossessed && !item.isPreviewable && !item.isFree"
          }
        ],
        staticClass: "button related-button-detail",
        attrs: {
          url: _vm.urlWithLocation(_vm.item.url, _vm.location),
          "is-mobile": false,
          height: 20,
          width: 62,
          "is-border": true,
          "is-small": true
        }
      }),
      _vm._v(" "),
      _c("access-limit-modal", {
        attrs: {
          "is-shown": _vm.showAccessLimitModal,
          item: _vm.saleItem,
          "show-loading-modal": _vm.showLoadingModal
        },
        on: { purchase: _vm.moveToPurchase, cancel: _vm.cancel }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }