const $ = window.$;
const sVue = window.sVue;

$(function() {

  //vue.js
  if($("#news-submit-file-list")[0]){
    const app = new sVue({
      el: "#news-submit-file-list",
      data: {
        images: [],
        thumbnail_id: -1,
      },
      created() {
        const json = $("#news-submit-file-list").data("json");
        if(json){
          this.images = json["images"];
          this.thumbnail_id = json["thumbnail_id"];
        }
      },
      methods: {
        getThumbnailId() {
          return this.thumbnail_id;
        },
        getImages(){
          return this.images;
        },
        addImage(data){
          this.images.unshift(data);
        },
        deleteImage(n) {
          this.images.splice(n, 1);
        },
        selectObj(obj){
          const range = document.createRange();
          range.selectNodeContents(obj.target);
          const sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        },
        fancybox(obj) {
          $(".large-image-box").remove();

          $("body").append("<div class=\"large-image-box\"><img src=\"\"><p class=\"btn-close\">X</p></div>");
          const imageURL = obj.target.dataset.name;
          const imgPreloader = new Image();
          imgPreloader.onload = function() {
            $(".large-image-box").children("img").attr({src:imageURL});
            const imageDimention = {};
            const imgW = $(".large-image-box img").width();
            const imgH = $(".large-image-box img").height();
            if(imgW>imgH){//ヨコ位置
              imageDimention.width = (imgW > 600) ? 600 : imgW;
              imageDimention.height = imageDimention.width * imgH / imgW;
            }else{//タテ位置
              imageDimention.height = (imgH > 400) ? 400 : imgH;
              imageDimention.width = imageDimention.height * imgW / imgH;
            }

            const scrollHight = $(window).scrollTop();
            const offsetHeight = (window.innerHeight - imageDimention.height - 24) / 2;
            const offsetWidth = (window.innerWidth - imageDimention.width - 24) / 2;
            const style = {
              top: (scrollHight + offsetHeight),
              left: offsetWidth
            };

            $(".large-image-box").css({top:style.top,left:style.left});
            $(".large-image-box img").css({width:imageDimention.width,height:imageDimention.height});

            $(document).on("click",function(){
              $(".large-image-box").remove();
              $(document).off("click");
            });
          };
          imgPreloader.src=imageURL;

          $(".large-image-box").on("click",function(e){
            e.stopPropagation();
          });

          $(".large-image-box .btn-close").on("click",function(){
            $(".large-image-box").remove();
            $(document).off("click");
          });
        }
      }
    });
    //-----------------------
    // upload image
    //-----------------------
    $(".btn-input-news-image").on("click", function() {
      const type = $(this).data("id");
      $("#state-message-images").html("");
      $("#news-file-upload").addClass("loading");

      const $uploadFile = (type === "file") ? $("#news-image-upload-by-file") : $("#news-image-upload-by-url");
      const fd = new FormData();

      if (type === "file") {
        //fileの時
        fd.append("form[image][file]", $uploadFile.get(0).files[0]);
      } else {
        //URLの時
        fd.append("form[image][url]", $uploadFile.val());
      }

      $.ajax({
        url: "/news/images.json",
        type: "POST",
        data: fd,
        dataType: "json",
        processData: false,
        contentType: false,
        success: function(data) {
          $("#news-file-upload").removeClass("loading");
          const addData = {
            file_id : data.image.id,
            filename: data.image.url,
          };
          app.addImage(addData);
          if (type === "file") {
            //fileの時
            $uploadFile.replaceWith($uploadFile.clone()); //fileのformを空にする
          } else {
            //URLの時
            $uploadFile.val("");
          }
        },
        error: function(jqXHR) {
          const errorMessages = $.parseJSON(jqXHR.responseText).errors;
          let errorMessage = "";
          for(let i=0;i<errorMessages.length;i++){
            errorMessage += `${errorMessages[i].message}<br>`;
          }
          $("#news-file-upload").removeClass("loading");
          $("#state-message-images").html(_.template(`<span class="badresult-text pt8 di-ib"><%- msg %></span>`)({msg: errorMessage})).show();
        },
      });
    });
  }

  if($(".news-file-list-table-scroll")[0]){
    if (navigator.userAgent.indexOf("Mac") > 0){
      $(".news-file-list-table-scroll").width(750);
    }
  }

});
