var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "membership-payment" },
    [
      _c("section", { staticClass: "payment-method" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "form-table blank" }, [
          _c("div", { staticClass: "title blank" }),
          _vm._v(" "),
          _c("div", { staticClass: "clearfix" }, [
            _c("ul", { staticClass: "payment-method-select-container" }, [
              _c("li", { staticClass: "po-r" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.paymentMethod,
                      expression: "paymentMethod"
                    }
                  ],
                  staticClass: "payment-radio",
                  attrs: { type: "radio", value: "paypal", id: "paypal" },
                  domProps: { checked: _vm._q(_vm.paymentMethod, "paypal") },
                  on: {
                    change: function($event) {
                      _vm.paymentMethod = "paypal"
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { staticClass: "mt8", attrs: { for: "paypal" } }, [
                  _vm._v("PayPal")
                ]),
                _vm._v(" "),
                _vm.defaultPaymentMethod === "paypal"
                  ? _c("span", { staticClass: "selected-payment-methods" }, [
                      _vm._v("Currently Selected")
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "po-r" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.paymentMethod,
                      expression: "paymentMethod"
                    }
                  ],
                  staticClass: "payment-radio",
                  attrs: { type: "radio", value: "stripe", id: "stripe" },
                  domProps: { checked: _vm._q(_vm.paymentMethod, "stripe") },
                  on: {
                    change: function($event) {
                      _vm.paymentMethod = "stripe"
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { staticClass: "mt8", attrs: { for: "stripe" } }, [
                  _vm._v("Credit Card")
                ]),
                _vm._v(" "),
                _vm.defaultPaymentMethod === "stripe"
                  ? _c("span", { staticClass: "selected-payment-methods" }, [
                      _vm._v("Currently Selected")
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "payment-information" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "form-table mb24" }, [
          _c("div", { staticClass: "title blank" }),
          _vm._v(" "),
          _c("div", { staticClass: "data clearfix" }, [
            _vm.isError
              ? _c("div", { staticClass: "badresult mt8 mb8 ml8 mr8" }, [
                  _vm.errorMessage
                    ? _c("div", { staticClass: "badresult-text" }, [
                        _vm._v(_vm._s(_vm.errorMessage))
                      ])
                    : _c("div", { staticClass: "badresult-text fs14 lh16" }, [
                        _vm._v(
                          "\n            There was an error when registering your payment information."
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Please try again in a few minutes. If this problem persists, please contact us\n            "
                        ),
                        _c("a", { attrs: { href: _vm.supportUrl } }, [
                          _vm._v("here")
                        ]),
                        _vm._v(".\n          ")
                      ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.paymentMethod === "stripe"
              ? _c(
                  "div",
                  [
                    _vm.registeredCard && !_vm.isCardEditMode
                      ? _c("div", [
                          _vm.registeredCard.isUsedForMalSupporter
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "creditcard-information-registered-text"
                                },
                                [
                                  _vm._v(
                                    "Currently the following card is in use of MAL Supporter"
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "creditcard-information-registered",
                              attrs: { "data-type": _vm.registeredCard.type }
                            },
                            [
                              _vm._v(
                                "\n              XXXX-XXXX-XXXX-" +
                                  _vm._s(_vm.registeredCard.digits) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "creditcard-information-registered-meta"
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Expires: " +
                                    _vm._s(_vm.registeredCard.expiryDate)
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "payment-edit-creditcard-button-area clearfix"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "payment-edit-creditcard-button-edit btn-edit",
                                  on: {
                                    click: function($event) {
                                      _vm.isCardEditMode = true
                                    }
                                  }
                                },
                                [_vm._v("Edit")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "payment-edit-creditcard-delete-wrap"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "payment-edit-creditcard-delete-text"
                                    },
                                    [
                                      _vm._v(
                                        "Delete your registered card information :"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-edit",
                                      on: {
                                        click: function($event) {
                                          return _vm.confirmDeleteCard(
                                            _vm.paymentMethod
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Delete")]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      : _c("stripe", {
                          ref: "stripe",
                          attrs: { "is-block-ad-block": _vm.isBlockAdBlock }
                        }),
                    _vm._v(" "),
                    _vm.registeredCard && _vm.isCardEditMode
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "payment-edit-creditcard-button-area clearfix"
                          },
                          [
                            _vm.isCardEditMode
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "payment-edit-creditcard-button-change btn-edit",
                                    attrs: { disabled: _vm.isRequesting },
                                    on: { click: _vm.updateStripeCard }
                                  },
                                  [_vm._v("Change credit card information")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "payment-edit-creditcard-button-cancel btn-edit ",
                                on: {
                                  click: function($event) {
                                    _vm.isCardEditMode = false
                                  }
                                }
                              },
                              [_vm._v("Cancel")]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showAgreement && !_vm.isCardEditMode
                      ? _c(
                          "div",
                          { staticClass: "textPaymentPolicyContainer" },
                          [
                            _c("agreement", {
                              attrs: {
                                termsHtml: _vm.termsHtml,
                                "checked-agreement": _vm.checkedAgreement
                              },
                              on: {
                                "update:checkedAgreement": function($event) {
                                  _vm.checkedAgreement = $event
                                },
                                "update:checked-agreement": function($event) {
                                  _vm.checkedAgreement = $event
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "payment-submit-creditcard-button-container"
                      },
                      [
                        !_vm.registeredCard
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-form-submit",
                                attrs: {
                                  disabled:
                                    (_vm.showAgreement &&
                                      !_vm.checkedAgreement) ||
                                    _vm.isRequesting
                                },
                                on: { click: _vm.registerStripeCard }
                              },
                              [_vm._v("Register this card")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.registeredCard &&
                        !_vm.isCardEditMode &&
                        _vm.defaultPaymentMethod !== "stripe"
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "payment-submit-creditcard-button btn-form-submit",
                                attrs: {
                                  disabled:
                                    (_vm.showAgreement &&
                                      !_vm.checkedAgreement) ||
                                    _vm.isRequesting
                                },
                                on: { click: _vm.updatePaymentMethod }
                              },
                              [
                                _vm._v(
                                  "Make this card my default payment method"
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.paymentMethod === "paypal"
              ? _c(
                  "div",
                  [
                    _vm.showAgreement
                      ? _c(
                          "div",
                          {
                            class: {
                              textPaymentPolicyContainer:
                                _vm.braintreeRegistrationDatetime
                            }
                          },
                          [
                            _c("agreement", {
                              attrs: {
                                termsHtml: _vm.termsHtml,
                                "checked-agreement": _vm.checkedAgreement
                              },
                              on: {
                                "update:checkedAgreement": function($event) {
                                  _vm.checkedAgreement = $event
                                },
                                "update:checked-agreement": function($event) {
                                  _vm.checkedAgreement = $event
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.braintreeRegistrationDatetime
                      ? _c(
                          "div",
                          { staticClass: "paypal-information-registered" },
                          [
                            _vm._v(
                              "\n            You registered your PayPal account at " +
                                _vm._s(_vm.braintreeRegistrationDatetime) +
                                ".\n          "
                            )
                          ]
                        )
                      : !_vm.showAgreement ||
                        (_vm.showAgreement && _vm.checkedAgreement)
                      ? _c("braintree-pay-pal", {
                          attrs: { "authorized-callback": _vm.paypalCallback }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.braintreeRegistrationDatetime
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "payment-edit-creditcard-button-area mt16 pt8 clearfix"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "payment-edit-paypal-delete-wrap fl-r"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "payment-edit-creditcard-delete-text"
                                  },
                                  [
                                    _vm._v(
                                      "Delete your registered PayPal information :"
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-edit",
                                    on: {
                                      click: function($event) {
                                        return _vm.confirmDeleteCard(
                                          _vm.paymentMethod
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Delete")]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.braintreeRegistrationDatetime &&
                    _vm.defaultPaymentMethod !== "paypal"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn-form-submit",
                            attrs: {
                              disabled:
                                (_vm.showAgreement && !_vm.checkedAgreement) ||
                                _vm.isRequesting
                            },
                            on: { click: _vm.updatePaymentMethod }
                          },
                          [_vm._v("Make PayPal my default payment method")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("registerd-payment-method-modal", {
        attrs: {
          show: _vm.showRegisteredPaymentMethodModal,
          registeredCard: _vm.registeredCard,
          "braintree-registration-datetime": _vm.braintreeRegistrationDatetime,
          "default-payment-method": _vm.defaultPaymentMethod
        },
        on: { close: _vm.close, proceedToCheckout: _vm.proceedToCheckout }
      }),
      _vm._v(" "),
      _c("loading-modal", { attrs: { "is-shown": _vm.showLoadingModal } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-table border-bottom" }, [
      _c("div", { staticClass: "title alignLeft fw-b" }, [
        _vm._v("Payment Method")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "data clearfix" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-table border-bottom mt24" }, [
      _c("div", { staticClass: "title alignLeft fw-b" }, [
        _vm._v("Payment Information")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "data clearfix" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }