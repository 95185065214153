const $ = window.$
const SVue = window.sVue

import PaymentSettings from "../../../../vue/components/payments/PaymentSettings.vue"

$(() => {
  if ($("#v-payment-settings").length > 0) {
    const $el = $("#v-payment-settings")

    const settings = $el.data("settings")
    const flash = settings.flash
    const defaultPaymentMethod = settings.defaultPaymentMethod
    const initialPaymentMethod = settings.initialPaymentMethod
    const registeredCard = settings.registeredCard ? {
      type: settings.registeredCard.type,
      digits: settings.registeredCard.digits,
      expiryDate: settings.registeredCard.expiry_date,
      isExpired: settings.registeredCard.is_expired,
      isUsedForMalSupporter: settings.registeredCard.is_used_for_mal_supporter,
    } : null
    const braintreeRegistrationDatetime = settings.braintreeRegistrationDatetime

    const termsHtml = $el.data("terms-html")
    const supportUrl = $el.data("support-url")
    const isMobile = $el.data("mobile")


    new SVue({
      el: "#v-payment-settings",
      render: h => h(PaymentSettings, {
        props: {
          flash,
          defaultPaymentMethod,
          initialPaymentMethod,
          registeredCard,
          braintreeRegistrationDatetime,
          termsHtml,
          supportUrl,
          isMobile,
        }
      }),
    })
  }
})

