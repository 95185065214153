const $ = window.$
const sVue = window.sVue

$(() => {
  if ($("#vWhitelistReview").exists()) {
    const reviewEl = "#vWhitelistReview"
    const reviewData = $(reviewEl).data("review")
    reviewData.sending = false
    reviewData.msg = undefined
    reviewData.isError = undefined

    const vReview = new sVue({
      el: reviewEl,
      data: reviewData,
      methods: {
        review(status) {
          reviewWhitelist(status, () => {
            this.isError = false
            this.msg = "Successfully updated!"
            this.inWhite = status==="white"
            this.canReview = false
          })
        },
        beforeSend() {
          this.sending = true
          this.msg = undefined
        },
        afterSend() {
          this.sending = false
        },
        showErrorMsg(err) {
          this.isError = true
          this.msg = err
        },
      }
    })

    const reviewWhitelist = (status, onSuccess) => {
      $.ajax({
        url: `${location.href}/review.json`,
        method: "PUT",
        data: {review_status: status}, // eslint-disable-line camelcase
        dataType: "json",
        beforeSend: () => {
          vReview.beforeSend()
        }
      })
      .done((data) => {
        onSuccess(data.review_status) // eslint-disable-line camelcase
      })
      .fail((xhr) => {
        vReview.showErrorMsg(xhr.responseJSON.errors[0].message)
      })
      .always(() => {
        vReview.afterSend()
      })
    }
  }

  if ($("#review-date").exists()) {
    $("#review-date").on("change", (e) => {
      const $input = $(e.currentTarget)
      if ($input.val().match(/^20\d{2}-[01]\d-[0-3]\d$/)) {
        location.href = `${$input.data("url")}/${$input.val().replace(/-/g, "/")}`
      }
    })
  }

  if ($("#chk-all").exists()) {
    $("#chk-all").on("change", (e) => {
      const $input = $(e.currentTarget)
      if ($input.prop("checked")) {
        location.href = `${$input.data("url")}/all`
      } else {
        location.href = $input.data("url")
      }
    })
  }

  if ($("#id-search").exists()) {
    $("#id-search").on("submit", (e) => {
      e.preventDefault()
      const $form = $(e.currentTarget)
      const id = $("#id-search-text").val()
      if (id) {
        location.href = `${$form.attr("action")}/${id}`
      }
    })
  }
})
