<template>

  <div class="slider-wrap">
    <div class="slider-container" :style="{ width: sliderContainerWidth + 'px' }">
      <div class="slider" :class="{ 'is-move-left' : isMoveLeft , 'is-move-right' : !isMoveLeft }">
        <transition-group name="slide" tag="div">
          <book
            v-if="!isTypeComic"
            v-for="item in currentItems"
            @accessLimit="accessLimit(item)"
            :key="item.id"
            :item="item"
            :currency="currency"
            :width="sliderWidth"
            :location="options.location"
            :isRecommendation="isRecommendation"
          ></book>
          <comic
            v-if="isTypeComic"
            v-for="item in currentItems"
            :key="item.id"
            :item="item"
            :width="sliderWidth"
            :location="options.location"
            :isRecommendation="isRecommendation"
          ></comic>
        </transition-group>
      </div>
    </div>

    <a class="button-left" :class="{ 'is-disable' : !hasPrevPage }" @click="move(-1)" >left</a>
    <a class="button-right" :class="{ 'is-disable' : !hasNextPage }" @click="move(1)">right</a>

<!-- modal -->
    <access-limit-modal
      :is-shown="showAccessLimitModal"
      :item="saleItem"
      :show-loading-modal="showLoadingModal"
      @purchase="purchase"
      @cancel="cancel"
    ></access-limit-modal>
  </div>

</template>

<style lang="scss" scoped>
  @import "../../../../../../css/variables";

  .slider-wrap {
    width: 100%;
    position: relative;
    padding: 0 0 10px;
    border-bottom: 1px solid $color-pc-border-d8d8d8;
  }
  .slider-container {
    margin: 0 auto;
    overflow: hidden;
    display: block;
  }
  .slider > div {
    display: table;

  }
  .button-left {
    position: absolute;
    left: 0;
    top: 55px;
    display: block;
    width: 30px;
    height: 30px;
    background: url(/images/manga_store/slider/left@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    cursor: pointer;
    text-indent: -99999px;
  }
  .button-right {
    position: absolute;
    right: 0;
    top: 55px;
    display: block;
    width: 30px;
    height: 30px;
    background: url(/images/manga_store/slider/right@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    cursor: pointer;
    text-indent: -99999px;
  }
  .is-disable {
    opacity: 0.3;
    cursor: pointer;
  }
  /* Transition Effect */
  .slide-enter-active,
  .slide-leave-active {
    transition: all .3s ease;
  }
  .slide-enter,
  .slide-leave {
    opacity: 0;
  }
  .is-move-left .slide-enter {
    transform: translateX(-10px);
  }
  .is-move-right .slide-enter {
    transform: translateX(10px);
  }

  .dark-mode {
    @import "../../../../../../css/colors_dark";

    .slider-wrap {
      border-bottom: 1px solid $color-pc-border-d8d8d8;
    }
    .button-left,
    .button-right {
      filter: invert(100%) hue-rotate(180deg);
    }
  }
</style>

<script type="text/babel">
  import * as book from "../../../common/modules/book"
  import Book from "../../molecules/Book.vue"
  import Comic from "../../molecules/Comic.vue"
  import AccessLimitModal from "../../molecules/AccessLimitModal.vue"

  const axios = window.axios

  export default {
    components: {
      Book,
      Comic,
      AccessLimitModal,
    },
    data() {
      return {
        isMoveLeft: false,
        currentPage: 0,
        sliderWidth: 125,
        showAccessLimitModal: false,
        saleItem: null,
        showLoadingModal: false,
      }
    },
    props: {
      items    : Array,
      type     : String,
      currency : Object,
      options  : Object,
    },
    computed: {
      isRecommendation() {
        return this.options.isRecommendation || false
      },
      numItemsPerPage() {
        return this.options.num_items_per_page || 5
      },
      currentItems() {
        return this.items.slice(this.currentPage * this.numItemsPerPage, (this.currentPage + 1) * this.numItemsPerPage)
      },
      hasPrevPage() {
        return this.currentPage > 0;
      },
      hasNextPage() {
        return this.currentPage < Math.ceil(this.items.length / this.numItemsPerPage) - 1;
      },
      sliderContainerWidth() {
        return 125 * this.numItemsPerPage
      },
      isTypeComic() {
        return (this.type === book.TYPE_COMIC) ? true : false
      },
    },
    methods: {
      move(direction) {
        this.isMoveLeft = direction === -1
        if ((this.hasPrevPage && direction < 0) || (this.hasNextPage && direction > 0)) {
          this.currentPage += direction
        }
      },
      purchase() {
        location.href = `/store/manga_volume/${this.saleItem.saleBookId}/${this.saleItem.saleBookTitle}`
      },
      cancel() {
        this.showAccessLimitModal = false
      },
      async accessLimit(item) {
        this.error = null
        this.showLoadingModal = true
        let newWindow = window.open(window.location.href)
        try {
          const res = await axios.get(`/store/manga_volume/${item.id}/access/count`)
          if (res.data.login_path) {
            this.showLoadingModal = false
            window.location.href = res.data.login_path
            newWindow.close()
          } else if (!(res.data.user_access_count > 0) && res.data.total_access_count > item.accessLimit) {
            this.saleItem = item
            this.showLoadingModal = false
            this.showAccessLimitModal = true
            newWindow.close()
          } else {
            this.showLoadingModal = false
            newWindow.location.href = item.viewerUrl
          }
        } catch (e) {
          this.showLoadingModal = false
          // 握りつぶす
        }
      },
    },
  }
</script>
