var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoadingItems
        ? _c("div", [
            _c("i", { staticClass: "fa-solid fa-spinner fa-spin fa-2x fa-fw" })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.currentRows, function(itemRow) {
        return !_vm.isLoadingItems
          ? _c(
              "div",
              { staticClass: "itemColumn" },
              [
                _vm._l(itemRow, function(item) {
                  return _vm.isTypeVolume
                    ? _c("book", {
                        key: item.id,
                        attrs: {
                          item: item,
                          currency: _vm.currency,
                          width: 792 / _vm.numItemsPerRow,
                          "hide-title": true,
                          location: _vm.options.location
                        },
                        on: {
                          accessLimit: function($event) {
                            return _vm.accessLimit(item)
                          }
                        }
                      })
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(itemRow, function(item) {
                  return _vm.isTypeChapter
                    ? _c("chapter", {
                        key: item.id,
                        attrs: {
                          item: item,
                          currency: _vm.currency,
                          "login-path": _vm.loginPath,
                          width: 792 / _vm.numItemsPerRow,
                          "hide-title": true,
                          "is-requesting": _vm.isLoadingItems,
                          "is-lazyload": _vm.isLazyload,
                          location: _vm.options.location
                        },
                        on: {
                          accessLimit: function($event) {
                            return _vm.accessLimit(item)
                          },
                          purchase: function($event) {
                            return _vm.purchase(item)
                          }
                        }
                      })
                    : _vm._e()
                })
              ],
              2
            )
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.numPages > 1
        ? _c("div", { staticClass: "pager-area" }, [
            _c(
              "ul",
              { staticClass: "pager" },
              _vm._l(_vm.numPages, function(i) {
                return _c("li", { staticClass: "pager-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "pager-item-button",
                      class: { selected: i === _vm.currentPage },
                      on: {
                        click: function($event) {
                          return _vm.loadItems(i)
                        }
                      }
                    },
                    [_vm._v(_vm._s(i))]
                  )
                ])
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("tax-calculation-modal"),
      _vm._v(" "),
      _vm.showModal
        ? [
            _c("purchase-single-modal", {
              attrs: {
                "is-shown": _vm.showConfirmationModal,
                "is-requesting": _vm.isPurchasing,
                error: _vm.error,
                item: _vm.modalItem,
                currency: _vm.currency,
                "is-delegated": _vm.isDelegated
              },
              on: {
                confirm: _vm.confirm,
                cancel: function($event) {
                  _vm.showConfirmationModal = false
                }
              }
            }),
            _vm._v(" "),
            _c("purchase-finish-modal", {
              attrs: {
                "is-shown": _vm.showFinishModal,
                "is-single": true,
                title: _vm.modalItem.title,
                numberingText: _vm.modalItem.numberingText
              },
              on: {
                read: _vm.read,
                close: function($event) {
                  _vm.showFinishModal = false
                }
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("access-limit-modal", {
        attrs: {
          "is-shown": _vm.showAccessLimitModal,
          item: _vm.saleItem,
          "show-loading-modal": _vm.showLoadingModal
        },
        on: { purchase: _vm.moveToPurchase, cancel: _vm.cancel }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }