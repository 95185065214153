const $ = window.$
const SVue = window.sVue
const axios = window.axios

import Stripe from "../../../../vue/components/payments/components/Stripe.vue"
import Toast from "../../../common/modules/toast";
import GtmJsEvent from "../../../common/modules/gtm/jsEvent";

$(() => {
  let stripeComponent = null

  // Stripe のカード情報入力フォームコンポーネントをマウント
  if ($("#v-stripe-card-form").exists()) {

    window.blockAdBlock.on(true, () => {
      const paymentErrorMessage = document.querySelector(".membership-adblock-error-message")
      paymentErrorMessage.style.display = "block"
    })

    stripeComponent = new SVue({
      el: "#v-stripe-card-form",
      render: h => h(Stripe, {ref: "form"}),
    })
  }

  // faq の画像
  $(".js-faq-img").fancybox()

  // プラン選択時の表示切り替え
  if ($(".membership .js-btn-select-plan")[0]) {
    const $checkedPlan = $(".membership input[name=plan]:checked")
    let gift = $checkedPlan.next(".js-btn-select-plan").data("gift")
    let planId = $checkedPlan.val()
    $(".membership .js-btn-select-plan").on("click", function () {
      gift = $(this).data("gift")
      planId = $(this).parent().find("input").val()
      if (gift) {
        $(".membership .js-text-payment-notice-gift-text").text(
          `A ${gift.month} month gift is $${gift.price} ${gift.currency} for ${gift.days} days of MAL Supporter.`
        );
        $(".membership .js-text-payment-notice-subscription").hide();
        $(".membership .js-text-payment-notice-gift").show();
        $(".membership-payment").removeClass("payment-subscribe payment-product1 payment-product2").addClass(`payment-product${gift.product_id}`)
        history.replaceState(null, document.title, `${location.origin + location.pathname}?product=${gift.product_id}`)
      } else {
        const text = $(this).find(".js-plan-payment").text();
        const name = $(this).data("name");
        $(".membership .js-text-payment-notice-plan").text(name);
        $(".membership .js-text-payment-notice").text(text);
        $(".membership .js-text-payment-notice-subscription").show();
        $(".membership .js-text-payment-notice-gift").hide();
        $(".membership-payment").addClass("payment-subscribe").removeClass("payment-product1 payment-product2")
        history.replaceState(null, document.title, `${location.origin + location.pathname}?plan=${planId}`)
      }
    })
    $(".js-text-payment-notice-gift-faq").on("click", function () {
      const productId = gift ? gift.product_id : ''
      const supporter = $(this).data("supporter")
      GtmJsEvent.send(`gift-faq-gift${productId}-payment`, `supporter:${supporter}`)
    })
    $(".js-paypal-faq").on("click", function () {
      GtmJsEvent.send("gift-faq-paypal-payment", `planid:${planId}`)
    })
    $(".js-btn-form-submit").on("click", function () {
      GtmJsEvent.send("gift-register-stripe-payment", `planid:${planId}`)
    })
    $(".js-get-agreement-with-paypal").on("click", function () {
      GtmJsEvent.send("gift-register-paypal-payment", `planid:${planId}`)
    })
  }
  if ($(".membership .js-btn-plan")[0]) {
    const $plan = $(".membership .js-btn-plan")
    $plan.on("click", function () {
      const text = $(this).find(".js-plan-payment").text();
      const name = $(this).data("name");
      $(".membership .js-text-payment-notice-plan").text(name);
      $(".membership .js-text-payment-notice").text(text);
    })
  }

  // first time payment 入力項目がうまっているかの確認
  if ($(".membership .js-supporter-policy")[0]) {
    const $membershipPolicy = $(".membership .js-supporter-policy")
    $membershipPolicy.on("click", () => {
      $(".membership .js-btn-form-submit").prop("disabled", !$membershipPolicy.prop("checked"))
    })
  }
  const $badResult = $(".js-badresult")
  const $badResultText = $(".js-badresult-text")
  const $paymentSubmitBlock = $(".js-payment-submit-block")

  if ($(".membership .js-upsert-creditcard").exists()) {
    // register membership
    $(".js-btn-form-submit").on("click", async e => {
      e.preventDefault()
      // submit 連打対策
      $(".membership .js-btn-form-submit").prop("disabled", true)
      const plan = $(`input[name="plan"]:checked`).val()
      const policy = $(".js-supporter-policy").val()
      // hide error message
      $badResult.hide()
      // show ajax loader
      $paymentSubmitBlock.addClass("payment-loader")

      try {
        const requestData = {plan, policy}

        // もしカード情報がまだ登録されていなかったら、カード情報入力フォームが表示されている
        // その場合はカード情報が Stripe に送られて、Stripe からトークンが戻ってくるので、それを使用して会員登録する。
        // カード情報が登録済みの場合は、それを使ってプランを購読するので、トークンは不要
        if (stripeComponent) {
          // Stripe コンポーネントにトークンを取得してもらう
          // Note: カード情報の入力エラーはコンポーネント内でエラー処理を完結してくれるよ
          const token = await stripeComponent.$refs.form.fetchToken()
          if (!token) {
            $(".membership .js-btn-form-submit").prop("disabled", false)
            $paymentSubmitBlock.removeClass("payment-loader")
          }
          requestData.token = token
        }

        const response = await $.ajax({
          url: "/account/membership/payment/stripe",
          method: "POST",
          data: requestData,
          dataType: "json",
        })
        if(response.redirect === undefined) {
          $badResultText.text('Something went wrong. Please try again later.')
          $badResult.show()
          return
        }
        $paymentSubmitBlock.removeClass("payment-loader")
        window.location.href = response.redirect
      } catch (error) {
        showErrorMessage(error)
      }
    })
  }

  // すでにカードが登録されてる場合にplanだけ購読する
  if ($(".membership .js-membership-subscribe-plan").exists()) {
    const $badResult = $(".js-badresult")
    const $paymentSubmitBlock = $(".js-payment-submit-block")
    $badResult.hide()

    const $membershipPolicy = $(".membership .js-supporter-policy")
    $membershipPolicy.on("click", function () {
      if ($membershipPolicy.prop("checked")) {
        $(".membership .js-btn-subscribe-plan").prop("disabled", false)
      } else {
        $(".membership .js-btn-subscribe-plan").prop("disabled", true)
      }
    })
    $(".js-btn-subscribe-plan").on("click", function (e) {
      e.preventDefault()
      // hide error message
      $badResult.hide()
      // show ajax loader
      $paymentSubmitBlock.addClass("payment-loader")
      const plan = $(`input[name="plan"]:checked`).val()
      const policy = $(".js-supporter-policy").val()
      $.ajax({
        url: "/account/membership/payment",
        method: "POST",
        data: {plan: plan, policy: policy},
        dataType: "json",
        success: function (data) {
          // hide ajax loader
          $paymentSubmitBlock.removeClass("payment-loader")
          if(data.redirect === undefined) {
            $badResultText.text('Something went wrong. Please try again later.')
            $badResult.show()
            return
          }
          window.location.href = data.redirect
        },
        error: showErrorMessage
      })
    })
  }

  // update plan
  if ($(".js-plan-submit-button-outer")) {
    const $badResult = $(".js-badresult")
    $badResult.hide()
    $(".js-form-submit-plan").on("click", function () {
      const plan = $(`input[name="plan"]:checked`).val()
      $.ajax({
        url: "/account/membership/plan",
        type: "PUT",
        data: {plan: plan},
        dataType: "json",
        success: function (data) {
          if (data.redirect) {
            window.location.href = data.redirect
          }
        },
        error: function (xhr) {
          const res = $.parseJSON(xhr.responseText)
          $badResult.show()
          if (res.message) {
            $badResultText.text(res.message)
            $badResult.show()
            return
          }
          if (res.errors && res.errors[0].message) {
            $badResultText.text(res.errors[0].message)
            $badResult.show()
            return
          }
          $badResult.show()
        }
      })
      GtmJsEvent.send("gift-update-plan-payment", `planid:${plan}`)
    })
  }

  // suspend membership
  if ($(".about-membership")) {
    const $badResult = $(".js-badresult")
    $badResult.hide()
    $(".js-btn-membership-suspend").on("click", function () {
      $.ajax({
        url: "/account/membership/suspend",
        method: "POST",
        success: function (data) {
          if (data.redirect) {
            window.location.href = data.redirect
          }
        },
        error: function () {
          $badResult.show()
        }
      })
    })
  }

  if ($(".js-cancel-membership")) {
    const $badResult = $(".js-badresult")
    $badResult.hide()
    // reactivate membership
    $(".js-btn-membership-reactivate").on("click", function () {
      $.ajax({
        url: "/account/membership/reactivate",
        method: "POST",
        success: function (data) {
          if (data.redirect) {
            window.location.href = data.redirect
          }
        },
        error: showErrorMessage
      })
    })

    // cancel membership
    $(".js-btn-membership-cancel").on("click", function () {
      const result = confirm("Are you sure to quit MAL Supporter subscription now?")
      if (result === true) {
        $.ajax({
          url: "/account/membership/payment",
          method: "DELETE",
          success: function (data) {
            if(!('redirect' in data)) {
              $badResultText.text('Something went wrong. Please try again later.')
              $badResult.show()
              return
            }
            window.location.href = data.redirect
          },
          error: function () {
            $badResult.show()
          }
        })
      }
    })
  }

  // Status
  if ($(".membership .js-forum-title-edit")[0]) {
    const $forumTitleEdit = $(".membership .js-forum-title-edit")
    $forumTitleEdit.on("click", function () {
      const $forumTitle = $(".membership .js-forum-title")
      $forumTitle.toggleClass("show")
      $forumTitle.find("input[type=\"text\"]:enabled").focus()
    })
  }

  // Edit Status
  if ($(".membership .js-btn-change")[0]) {
    const $btnChange = $(".membership .js-btn-change")
    $btnChange.on("click", function () {
      const $targetID = $(this).data("id")
      const $editTable = $(".membership .js-edit-table")
      const $editTableTarget = $(`.membership .js-edit-table[data-id="${$targetID}"]`)
      const currentPlan = $(".membership .js-edit-table").data("plan")

      if (!$editTableTarget.hasClass("on")) {
        $editTable.removeClass("on")
      }
      $editTableTarget.toggleClass("on")

      //Edit button押したら Card情報は空にする。
      $(".membership .js-edit-table[data-id=\"creditcard\"] .input-text").val("")

      //Edit button押したら Current Planを元に戻す
      $(`.membership .js-edit-table input[name="plan"][value="${currentPlan}"]:radio`).prop("checked", true)
      $(".membership .js-edit-table .js-form-submit-plan").prop("disabled", true)
      $(".membership .js-edit-table .js-plan-submit-button-outer").hide()
    })
  }

  if ($(".membership .js-edit-table input[type=\"number\"].input-text")[0]) {
    $(".membership .js-edit-table input[type=\"number\"].input-text").on("input", function () {
      //maxlength以上に入力させない
      if ($(this).val().length > $(this).attr("maxlength")) {
        $(this).val($(this).val().slice(0, $(this).attr("maxlength")))
      }

      //Submit Buttonを enableにする
      let chkBtn = false
      const arry = $(".membership .js-edit-table input[type=\"number\"].input-text").map(function () {
        return $(this).val()
      }).get()
      // check requirement
      for (let i = 0; i < arry.length; i++) {
        if (!arry[i]) {
          chkBtn = false
          break
        } else {
          chkBtn = true
        }
      }
      if (chkBtn === true) {
        $(".membership .js-edit-table .js-form-submit-card").prop("disabled", false)
      } else {
        $(".membership .js-edit-table .js-form-submit-card").prop("disabled", true)
      }

    })
  }

  // Plan変更のSubmit Buttonを enableにする
  if ($(".membership .js-edit-table input[name=\"plan\"]:radio")[0]) {
    $(".membership .js-edit-table input[name=\"plan\"]:radio").on("change", function () {
      const currentPlan = $(".membership .js-edit-table").data("plan")
      const selectedPlan = parseInt($(this).val())
      const $submit = $(".membership .js-edit-table .js-form-submit-plan")
      const $submitOuter = $(".membership .js-edit-table .js-plan-submit-button-outer")
      if (currentPlan !== selectedPlan) {
        $submitOuter.show()
        $submit.prop("disabled", false)
      } else {
        $submitOuter.hide()
        $submit.prop("disabled", true)
      }

    })
  }

  // Security Code PopUp
  $(".js-btn-about-security-code").on("mouseenter", function () {
    $(this).fancybox({
      width: 560,
      height: 450,
      autoScale: true,
      autoDimensions: true,
      transitionIn: "none",
      transitionOut: "none",
      type: "iframe"
    })
    return false
  })

  // onClick で has_error 時の inputの背景色を消す (focusだと消えすぎる)
  if ($(".membership .form-table .data input.has_error")[0]) {
    const $error = $(".membership .form-table .data input.has_error")
    $error.on("click", function () {
      $(this).removeClass("has_error")
    })
  }

  if ($(".membership .js-membership-gift-copy-link")[0]) {
    $(".membership .js-membership-gift-copy-link").on("click", function () {
      const giftLink = $(this).data("giftLink");
      navigator.clipboard.writeText(giftLink);
      Toast.add('Link copied.', 5000, 'primary');
    });
  }

  if ($(".membership .js-faq-question")[0]) {
    $(".membership .js-faq-question").on("click", function () {
      const faqId = $(this).attr("id")
      GtmJsEvent.send("gift-question-faq", `faqid:${faqId}`)
    })
  }

  /**
   * MALとの通信で発生したエラーを表示する
   * @param xhr
   */
  const showErrorMessage = function (xhr) {
    // hide error message
    $badResult.hide()
    // hide ajax loader
    $paymentSubmitBlock.removeClass("payment-loader")
    try {
      const res = $.parseJSON(xhr.responseText)
      if (res.redirect) {
        window.location.href = res.redirect
        return
      }
      if (
        (res.error !== undefined && res.error === "invalid_request" &&
          res.description !== undefined && res.description === "failed_to_fetch_token")
        ||
        (res.error !== undefined && res.error === "connection_failure")
      ) {
        $badResult.show()
        return
      }
      if (res.message) {
        $badResultText.text(res.message)
        $badResult.show()
        return
      }
      if (res.errors && res.errors[0].message) {
        $badResultText.text(res.errors[0].message)
        $badResult.show()
        return
      }
      $badResultText.text("There was an internal error. Please contact us. Thanks.")
      $badResult.show()
    } catch (e) {
      $badResultText.text("There was an internal error. Please contact us. Thanks.")
      $badResult.show()
    }
  }
})
