"use strict";

const $ = window.$;
const _ = window._;

$(function() {
  jQuery.fn.extend({
    toggleDropdown: function () {
      const $menu = $(this);
      if ($menu.hasClass('show')) {
        return $menu.hideDropdown();
      }
      return $menu.showDropdown();
    },
    showDropdown: function () {
      const $menu = $(this);
      const $button = $menu.siblings('.mal-btn-dropdown');
      $button.addClass('on pressed');
      $menu.addClass('show');
      // ボタンを右寄せ
      if ($menu.hasClass('mal-dropdown-menu-right') || $button.position().left + $menu.innerWidth() >= window.innerWidth) {
        $menu.css({
          top: $button.position().top + $button.outerHeight() + 4,
          left: $button.position().left + $button.outerWidth() - $menu.outerWidth(),
        });
      }
      // ボタンを左寄せ
      else {
        $menu.css({
          top: $button.position().top + $button.outerHeight() + 4,
          left: $button.position().left,
        });
      }
      // クリックイベント
      $(document).on('click.mal.dropdown', function (event) {
        const $target = $(event.target);
        const noreturn = (
          $target.parents('.mal-dropdown-menu').length > 0 &&
          !$target.hasClass('item') &&
          !$target.hasClass('divider')
        );
        // メニュー内ではアイテム以外の項目は閉じない（フォーム対策）
        if (noreturn) {
          return;
        }
        // すべてのドロップダウンを閉じる
        $('.mal-dropdown-menu.show').hideDropdown();
      });
      return $menu;
    },
    hideDropdown: function () {
      const $menu = $(this);
      const $button = $menu.siblings('.mal-btn-dropdown');
      $button.removeClass('on pressed');
      $menu.removeClass('show');
      $(document).off('click.mal.dropdown');
      return $menu;
    },
  });

  $(document).on('click', '.mal-btn-dropdown', function (event) {
    const $button = $(this);
    const $menu = $button.siblings('.mal-dropdown-menu');
    $menu.toggleDropdown();
  });
  // ボタンのフォーカス移動
  $(document).on('keydown', '.mal-btn-dropdown', function (event) {
    const $button = $(this);
    const $menu = $button.siblings('.mal-dropdown-menu.show');
    if ($menu.length == 0) {
      return true;
    }
    // ESCキーで閉じる
    if (event.key == 'Escape') {
      $menu.hideDropdown();
      return false;
    }
    // 下キーで最初の要素
    if (event.key == 'ArrowDown') {
      const $focusable = $menu.find('.item').filter(':visible').filter(':not(.disabled)');
      $focusable.eq(0).focus();
      return false;
    }
    return true;
  });
  // メニューアイテム内のフォーカス移動
  $(document).on('keydown', '.mal-dropdown-menu.show .item', function (event) {
    const $item = $(this);
    const $menu = $item.parents('.mal-dropdown-menu.show');
    // ESCキーで閉じる
    if (event.key == 'Escape') {
      $menu.hideDropdown();
      return false;
    }
    const $button = $menu.siblings('.mal-btn-dropdown');
    const $focusable = $menu.find('.item').filter(':visible').filter(':not(.disabled)');
    const index = $focusable.index($item);
    // 上キーで前の要素
    if (event.key == 'ArrowUp') {
      if (index <= 0) {
        $button.focus();
        return false;
      }
      const $prev = $focusable.eq(index - 1);
      if ($prev.length) {
        $prev.focus();
        return false;
      }
      return true;
    }
    // 下キーで次の要素
    if (event.key == 'ArrowDown') {
      if (index >= $focusable.length) {
        return true;
      }
      const $next = $focusable.eq(index + 1);
      if ($next.length) {
        $next.focus();
        return false;
      }
      return true;
    }
    return true;
  });
});
