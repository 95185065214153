var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "purchase-button-container" },
    [
      _vm.item.isPossessed || _vm.item.isFree
        ? _c("read-button", {
            staticStyle: { "margin-right": "16px" },
            on: { read: _vm.readOrLimit }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.item.isPossessed && !_vm.item.isFree
        ? _c("purchase-button", {
            attrs: { text: "Checkout" },
            on: { purchase: _vm.purchase }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasDefaultPatmentMethod
        ? _c("icon-payment-method", {
            staticStyle: {
              position: "absolute",
              bottom: "0",
              right: "-100px",
              "margin-left": "8px"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("tax-calculation-modal"),
      _vm._v(" "),
      _c("payment-modal", {
        attrs: {
          show: _vm.showPaymentModal,
          payment: _vm.payment,
          "terms-html": _vm.termsHtml,
          "support-url": _vm.supportUrl
        },
        on: {
          registerdPaymentMethod: _vm.registerdPaymentMethod,
          hidePaymentModal: _vm.hidePaymentModal
        }
      }),
      _vm._v(" "),
      _c("purchase-single-modal", {
        attrs: {
          "is-shown": _vm.showConfirmationModal,
          "is-requesting": _vm.isRequesting,
          "is-delegated": _vm.isDelegated,
          error: _vm.error,
          item: _vm.item,
          currency: _vm.currency
        },
        on: {
          confirm: _vm.confirm,
          cancel: function($event) {
            _vm.showConfirmationModal = false
          }
        }
      }),
      _vm._v(" "),
      _c("purchase-finish-modal", {
        attrs: {
          "is-shown": _vm.showFinishModal,
          "is-single": true,
          title: _vm.item.title,
          "numbering-text": _vm.item.numberingText
        },
        on: {
          read: _vm.read,
          close: function($event) {
            _vm.showFinishModal = false
          }
        }
      }),
      _vm._v(" "),
      _c("access-limit-modal", {
        attrs: {
          "is-shown": _vm.showAccessLimitModal,
          item: _vm.saleItem,
          "show-loading-modal": _vm.showLoadingModal
        },
        on: { purchase: _vm.moveToPurchase, cancel: _vm.cancel }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }