var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _vm.isShown
          ? _c("div", { staticClass: "modal-content" }, [
              _c("div", {
                staticClass: "modal-mask",
                on: { click: _vm.cancel }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "modal-wrapper" }, [
                _c("div", { staticClass: "modal-container" }, [
                  _c("span", { staticClass: "modal-message" }, [
                    _vm._v(
                      "\n            Your registered address doesn’t match your access location."
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            Please select your current area of residence in order to continue shopping.\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedAddress,
                            expression: "selectedAddress"
                          }
                        ],
                        attrs: { type: "radio", name: "address", id: "id0" },
                        domProps: {
                          value: _vm.residentialArea.registered,
                          checked: _vm._q(
                            _vm.selectedAddress,
                            _vm.residentialArea.registered
                          )
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedAddress = _vm.residentialArea.registered
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "id0" } }, [
                        _vm._v(_vm._s(_vm.residentialArea.registeredName))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedAddress,
                            expression: "selectedAddress"
                          }
                        ],
                        attrs: { type: "radio", name: "address", id: "id1" },
                        domProps: {
                          value: _vm.residentialArea.current,
                          checked: _vm._q(
                            _vm.selectedAddress,
                            _vm.residentialArea.current
                          )
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedAddress = _vm.residentialArea.current
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "id1" } }, [
                        _vm._v(_vm._s(_vm.residentialArea.currentName))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-button-wrap" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.error,
                              expression: "error"
                            }
                          ],
                          staticClass: "modal-error"
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.error))])]
                      ),
                      _vm._v(" "),
                      !_vm.isRequesting
                        ? [
                            _c(
                              "a",
                              {
                                staticClass: "modal-cancel-button",
                                on: { click: _vm.cancel }
                              },
                              [_vm._v("Cancel")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "modal-select-button",
                                on: { click: _vm.select }
                              },
                              [_vm._v("Select")]
                            )
                          ]
                        : [_c("span", { staticClass: "modal-button-loading" })]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    { staticClass: "modal-close", on: { click: _vm.cancel } },
                    [_vm._v("Close")]
                  )
                ])
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }