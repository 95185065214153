const $ = window.$;

$(function() {

  const setTopRankingColWidth = function(selector) {
    var selector = selector;
    let widest = 0;
    $.each($(selector), function() {
      const newWidth = $(this).width() + 2;
      widest = (widest < newWidth) ? newWidth : widest;
    });
    $(selector).width(widest);
  }

  if ($(".js-top-ranking-score-col")[0]) {
    setTopRankingColWidth(".js-top-ranking-score-col");
  }
  if ($(".js-top-ranking-your-score-col")[0]) {
    setTopRankingColWidth(".js-top-ranking-your-score-col");
  }

});
