<template>
  <div class="root" v-if="id > 0" v-bind:class="[sp ? '': 'pc']">
    <div v-if="is_manga && is_buy">
      <a class="mal-btn large ga-click ga-impression"
         @click="linkClick" v-bind:class="[sp ? 'sp secondary outline dark' :'pc manga']"
         :href="link"
         target="_blank"
         :data-ga-click-type="ga_type"
         :data-ga-impression-type="ga_type"
         :data-ga-click-param="gaParam()"
         :data-ga-impression-param="gaParam()">
        <i class="fa-solid fa-cart-shopping mr8"></i>Buy on Amazon</a>
    </div>
    <div v-if="is_manga && is_wish" style="padding: 0 1px;text-align: center">
      <button @click="addWishList()" class="manga mal-btn primary outline large w100 ga-click ga-impression"
              v-bind:class="[is_wishlist ?  'secondary pressed' : '']" v-bind:disabled="is_wishlist"
              data-ga-click-type="buy_manga_wish_manga_title"
              data-ga-impression-type="buy_manga_wish_manga_title"
              :data-ga-click-param="gaParam()"
              :data-ga-impression-param="gaParam()">
        <i class="fa-solid fa-star-shooting mr8 ga-click"
           data-ga-click-type="buy_manga_wish_manga_title"
           :data-ga-click-param="gaParam()"></i>
        {{ is_wishlist ? "We'll ask the publisher!" : "Wish for English release" }}
      </button>
    </div>
    <div v-if="is_anime && is_buy">
      <a class="mal-btn large secondary outline dark ga-click ga-impression" @click="linkClick" :href="link" target="_blank"
         :data-ga-click-type="ga_type"
         :data-ga-impression-type="ga_type"
         :data-ga-click-param="gaParam()"
         :data-ga-impression-param="gaParam()">
        <i class="fa-solid fa-cart-shopping mr8"></i>Buy Manga on Amazon</a>
    </div>
  </div>
</template>

<script>


import AffiliateAmazon from "./AffiliateAmazon";

export default {
  name: "AffiliateAmazonButton",
  props: {
    type: String,
    id: Number,
    link: String,
    image_url: String,
    title: String,
    volume: String,
    is_wish: Boolean,
    is_buy: Boolean,
    sp: Boolean,
    ga_type: String
  },
  mounted: function () {
    this.is_wishlist = this.affiliate.isWishList(this.id)
  },
  methods: {
    linkClick: function () {
      this.$emit('click-link');
    },
    addWishList: function () {
      if (!this.is_wishlist) {
        this.affiliate.addWishList(this.id)
        this.is_wishlist = true;
        setTimeout(this.linkClick, 500)
      }
    },
    gaType: function () {
      return 'buy_manga_' + (this.is_buy ? 'amazon' : 'wish') + '_' + (this.is_manga ? 'manga' : 'anime') + '_title';
    },
    gaParam: function () {
      return (this.is_manga ? 'mid' : 'aid') + ':' + this.id;
    }
  },
  data() {
    return {
      is_manga: this.type === 'manga',
      is_anime: this.type === 'anime',
      is_wishlist: false,
      affiliate: AffiliateAmazon(this.$cookie)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../../../../css/_variables.scss";

.root.pc {
  margin: 0.5rem 0;
}

a {
  margin: 0;
  display: block;
  text-align: center;

  &.mal-btn.dark {
    border: $color-pc-bg-5d5d5d 1px solid;
    color: $color-pc-text-323232;

    &:hover {
      border-color: rgba($color-pc-bg-5d5d5d, 0.8);
    }
  }

  &.mal-btn.manga {
    background-color: $color-pc-bg-4f74c8;
    border: $color-pc-bg-4f74c8 1px solid;
    border-radius: 4px;
    color: #fff !important;
    cursor: pointer;

    &:hover {
      background-color: rgba($color-pc-bg-4f74c8, 0.8);
      border-color: rgba($color-pc-bg-4f74c8, 0.8);
      color: $color-pc-text-ffffff;
    }
  }
}

button {
  margin: 0;
}
</style>
