const $ = window.$;
import Toast from "../../../common/modules/toast";
import Modal from "../../../common/modules/modal";

$(function(){
  $('#colorselector').on('change', function(){
    $('#colortester').removeClass(function(index, className){ return (className.match(/\bmal-\S+/g) || []).join(' ') });
    $('#colortester').addClass($(this).val());
  }).trigger('change');
  $(".js-mal-toast-test").on("click", function(){
    Toast.add('Toast is displayed for 5 seconds.', 5000, $(this).attr('rel'));
  });
  $(".js-mal-modal-test-1").on("click", function(){
    Modal.generate();
    Modal.buildCommonDialog('Modal Test', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.');
    Modal.show();
  });
  $(".js-mal-modal-test-2").on("click", function(){
    Modal.confirm({
      title: 'Confirm',
      message: 'Are you sure you want to hogehoge?',
      confirm: function() { alert('confirmed'); },
      cancel: function() { alert('cancelled'); }
    });
  });
  $(".js-mal-modal-test-3").on("click", function(){
    Modal.confirm({
      title: 'Confirm',
      message: 'Are you sure you want to hogehoge?',
      confirm: {
        text: 'Yes',
        action: function() { alert('confirmed'); }
      },
      cancel: {
        text: 'No',
        action: function() { alert('cancelled'); }
      }
    });
  });
  $(".js-mal-modal-test-4").on("click", function(){
    Modal.alert("test line 1\ntest line 2\ntest line 3");
  });
});
