import Modal from "../../../common/modules/modal"
import ReviewModule from "../../../common/modules/review"
import GtmJsEvent from "../../../common/modules/gtm/jsEvent";

const $ = window.$
const _ = window._
const MAL = window.MAL

$(() => {
  // Reviewのread more, show lessのボタン
  if ($(".js-toggle-review-button").exists()) {
    $(".js-toggle-review-button").on("click", (e) => {
      const id    = $(e.currentTarget).data("id")
      const $text = $(`#review${id}`)
      $text.toggle()
      $(e.currentTarget).html($text.is(":visible") ? "show less" : "read more")
      $(e.currentTarget).data("ga-click-type", $text.is(":visible") ? "review-show-less" : "review-read-more")
    })
  }

  // review redirect
  // 特定のURLパラメータを引き回し、opt によって上書きする
  const redirectReview = function(opt, href = "") {
    const params = new URLSearchParams(window.location.search)
    for (const key of params.keys()) {
      if (!['t', 'sort', 'filter_check', 'filter_hide', 'preliminary', 'spoiler', 'friends_only'].includes(key)) {
        params.delete(key)
      }
    }
    for (const key in opt) {
      params.set(key, opt[key])
    }
    const url = href ? href : window.location.pathname
    window.location.href = url + "?" + params.toString()
  }

  // sort & filter
  if ($(".js-reviews-horiznav-nav-sort-block").exists()) {
    // Sort ボタン
    if ($(".js-review-sort-order-block").exists() && $(".js-btn-show-sort").exists()) {
      const $sortButton = $(".js-btn-show-sort")
      const $sortListBlock = $(".js-review-sort-order-block")
      $sortButton.on("click", () => {
        $sortButton.toggleClass("on")
        $sortListBlock.toggleClass("show")
        if ($sortListBlock.hasClass("show")) {
          setTimeout(function () {
            $(document).on("click", function () {
              $sortButton.removeClass("on")
              $sortListBlock.removeClass("show")
              $(document).off("click")
              return false
            });
          }, 0);
        }
      })
      $(".js-review-sort-order-block").on("click", function (e) {
        e.stopPropagation()
      });

      // ソートリストの初期配置を設定する
      $sortListBlock.css({
        left: $sortButton.position().left + $sortButton.width() - $sortListBlock.width() + 3,
        top: $sortButton.height() + 8
      })

      // Sort List 内のボタン
      $sortListBlock.find(".js-reviews-btn-sort-order:not(.disabled)").on("click", (e) => {
        $(".js-review-sort-order-block .js-ajax-loader").show()
        $(".btn-sort-order").removeClass("selected")
        $(e.currentTarget).addClass("selected")
        const type = $(e.currentTarget).attr("data-type")
        redirectReview({sort: type})
      })

      // Close ボタン
      $sortListBlock.find(".js-btn-close").on("click", function(){
        $sortButton.removeClass("on")
        $sortListBlock.removeClass("show")
        $(document).off("click")
      })
    }

    // get filter ids
    const getFilterIds = function() {
      const checkId = [];
      const hideId = [];
      $(".js-reviews-btn-filter").each(function(){
        if ($(this).hasClass("crossed")) {
          hideId.push(parseInt($(this).data("id")))
        } else if ($(this).hasClass("selected")) {
          checkId.push(parseInt($(this).data("id")))
        }
      });
      return {
        check_ids: checkId,
        hide_ids: hideId
      };
    }

    // Filter ボタン
    const saveFilterIds = JSON.stringify(getFilterIds());
    const $filterButton = $(".js-btn-show-filter")
    const $filterListBlock = $(".js-review-filter-block")
    $filterButton.on("click", () => {
      $filterButton.toggleClass("on")
      $filterListBlock.toggleClass("show")
      if ($filterListBlock.hasClass("show")) {
        setTimeout(function(){
          $(document).on("click", function () {
            const filters = getFilterIds()
            const currentFilterIds = JSON.stringify(filters)
            if (saveFilterIds != currentFilterIds) {
              $(".js-review-filter-block .js-ajax-loader").show()
              // Filter項目が確定したタイミングでイベントを送信する
              GtmJsEvent.send('review-filter', 'filter:' + currentFilterIds)
              redirectReview({filter_check: filters.check_ids.join(","), filter_hide: filters.hide_ids.join(",")})
            } else {
              $filterButton.removeClass("on")
              $filterListBlock.removeClass("show")
              $(document).off("click")
            }
            return false;
          });
        }, 0);
      }
    })
    $(".js-review-filter-block").on("click", function(e){
      e.stopPropagation();
    });

    // フィルターリストの初期配置を設定する
    $filterListBlock.css({
      left: $filterButton.position().left + $filterButton.width() - $filterListBlock.width() + 3,
      top: $filterButton.height() + 8
    })

    $filterListBlock.find(".js-btn-close").on("click", function(){
      const filters = getFilterIds()
      const currentFilterIds = JSON.stringify(filters)
      if (saveFilterIds != currentFilterIds) {
        $(".js-review-filter-block .js-ajax-loader").show()
        // Filter項目が確定したタイミングでイベントを送信する
        GtmJsEvent.send('review-filter', 'filter:' + currentFilterIds)
        redirectReview({filter_check: filters.check_ids.join(","), filter_hide: filters.hide_ids.join(",")})
      } else {
        $filterButton.removeClass("on")
        $filterListBlock.removeClass("show")
        $(document).off("click")
      }
    })

    // Preliminary のチェックボックス
    $(".js-reviews-chk-preliminary").on("click", (e) => {
      const checked = $(e.currentTarget).hasClass("on") ? 1 : 0
      redirectReview({preliminary: (checked ? "off" : "on")})
    })

    // Spoiler のチェックボックス
    $(".js-reviews-chk-spoiler").on("click", (e) => {
      const checked = $(e.currentTarget).hasClass("on") ? 1 : 0
      redirectReview({spoiler: (checked ? "off" : "on")})
    })

    // My Friends only のチェックボックス
    $(".js-reviews-chk-friends").on("click", (e) => {
      const checked = $(e.currentTarget).hasClass("on") ? 1 : 0
      redirectReview({friends_only: (checked ? "off" : "on")})
    })

    // filter button
    // none => selected => crossed => none => ...
    const changeFiltered = function() {
      const rfb = $(".js-review-filter-block .js-reviews-btn-filter")
      $(".js-btn-show-filter").toggleClass("filtered", rfb.hasClass("selected") || rfb.hasClass("crossed"))
    }
    changeFiltered();
    $(".js-reviews-btn-filter").on("click", (e) => {
      const t = e.currentTarget
      if ($(t).hasClass("crossed")) {
        $(t).removeClass("crossed")
        $(t).children(".fa-xmark").hide()
      } else if ($(t).hasClass("selected")) {
        $(t).removeClass("selected")
        $(t).children(".fa-check").hide()
        $(t).addClass("crossed")
        $(t).children(".fa-xmark").show()
      } else {
        $(t).addClass("selected")
        $(t).children(".fa-check").show()
      }
      changeFiltered()
    })

    // Clear All button
    $(".js-clear-all-btn").on("click", function() {
      $(this).siblings(".selected").removeClass("selected")
      $(this).siblings().children(".fa-check").hide()
      $(this).siblings(".crossed").removeClass("crossed")
      $(this).siblings().children(".fa-xmark").hide()
      changeFiltered()
    });
  }

  if ($(".js-review-element").exists()) {
    // popup menu
    if ($(".js-btn-popup").exists()) {
      $(".js-btn-popup").on("click", function(e){
        e.preventDefault()
        const p = $(this).next(".js-menu-block")
        const flag = p.is(':hidden')
        $(".js-menu-block").hide()
        p.toggle(flag)
      });
    }

    // Read more & Show less
    if ($(".js-readmore").exists()) {
      $(".js-readmore").on("click", function(e){
        e.preventDefault()
        const p = $(this).parent()
        p.hide()
        p.parent().find(".js-showless").parent().show()
        const el = $(this).parents(".js-review-element")
        el.find(".js-hidden").show()
        el.find(".js-visible").hide()
      });
      $(".js-showless").on("click", function(e){
        e.preventDefault()
        const p = $(this).parent()
        p.hide()
        p.parent().find(".js-readmore").parent().show()
        const el = $(this).parents(".js-review-element")
        el.find(".js-hidden").hide()
        el.find(".js-visible").show()
      });
    }

    // Reactions show all
    if ($(".js-btn-showall").exists()) {
      $(".js-btn-showall").on("click", function(e){
        e.preventDefault()
        $(this).hide()
        $(".js-btn-reaction", $(this).parent()).fadeIn(200)
      });
    }

    // Reaction button
    const GIVE_FEEDBACK = '<a href="#" class="ga-click js-give-feedback" data-ga-click-type="review-give-feedback">Give Feedback!</a>'
    let requestTimer;
    const clickGiveFeedback = function(e) {
      e.preventDefault()
      const p = $(e.currentTarget).parent().parent()
      p.find(".js-readmore").parent().hide()
      p.find(".js-showless").parent().show()
      const el = p.parents(".js-review-element")
      el.find(".js-hidden").show()
      el.find(".js-visible").hide()
    }
    $(".js-btn-reaction").on("click", (e) => {
      clearTimeout(requestTimer)
      $(".js-btn-reaction").addClass("disabled")
      const t = $(e.currentTarget)
      const r = t.parents(".js-review-element")
      const sel = t.hasClass("selected")
      const num = parseInt(t.find(".num").html()) + (sel ? -1 : 1)
      if (sel) {
        t.removeClass("selected")
      } else {
        t.siblings(".selected").each(function(i, elem) {
          $(elem).removeClass("selected")
          const num2 = parseInt($(elem).find(".num").html()) - 1
          $(elem).find(".num").html(num2 < 0 ? 0 : num2)
        });
        t.addClass("selected")
        r.find(".js-btn-showall").hide()
        r.find(".js-btn-reaction").show()
        if (t.data("more") == true) {
          r.find(".js-more_reviews").slideDown()
        }
      }
      t.find(".num").html(num < 0 ? 0 : num)

      const data = {
        id: t.data("id"),
        val: sel ? 0 : t.data("val")
      }
      $.post("/includes/ajax.inc.php?t=72", data, (res) => {
        if (res.redirect) {
          window.location.href = res.redirect
        } else {
          let div = '';
          if (res.num > 0) {
            for (const id of res.icon) {
              div += '<img src="' + MAL.REACTIONS_IMAGE_PATH + MAL.REACTIONS_EMOTICON[id] + '">'
            }
            div += '<span class="num js-num">' + res.num + '</span>'
          } else {
            div = GIVE_FEEDBACK
          }
          r.find(".js-icon-reaction").html(div)
          $(".js-votes-modal").data("id", "")
          if (res.num > 0) {
            $(".js-icon-reaction .js-num", r).on("click", clickNum)
          } else {
            $(".js-give-feedback", r).on("click", clickGiveFeedback)
          }
        }
      })
      requestTimer = setTimeout(function(){
        $(".js-btn-reaction").removeClass("disabled");
      }, 300)
    })
    $(".js-review-element").each(function(e) {
      const res = $(this).data("reactions")
      let div = '';
      if (res.num > 0) {
        for (const id of res.icon) {
          div += '<img src="' + MAL.REACTIONS_IMAGE_PATH + MAL.REACTIONS_EMOTICON[id] + '">'
        }
        div += '<span class="num js-num">' + res.num + '</span>'
      } else {
        div = GIVE_FEEDBACK
      }
      $(this).find(".js-icon-reaction").html(div)
      $(".js-give-feedback", this).on("click", clickGiveFeedback)
      if (res.count) {
        let id = 1
        for (const num of res.count) {
          $(this).find(".js-btn-reaction[data-val=" + id + "] .num").html(num)
          id++
        }
      }
      $(".js-btn-reaction", this).each(function() {
        if ($(this).hasClass("selected") && parseInt($(".num", this).html()) === 0) {
          $(".num", this).html("1")
        }
      })
    });

    // Votes list
    const clickNum = function(e) {
      const $self = $(e.currentTarget).parents(".js-icon-reaction")
      const pagename = $self.data("pagename")
      const reviewId = $self.data("id")
      ReviewModule.generateVotesModal('#content', 'pc', pagename)
      ReviewModule.openVotesModal(reviewId)
      window.dataLayer.push({'event':'ga-js-event','ga-js-event-type':'review-votes-modal-open-pc-' + pagename})
      return false
    }
    $(".js-icon-reaction .js-num").on("click", clickNum)

    // label button
    $(".js-btn-label").on("click", function(e){
      const id = $(this).data("id")
      const href = $(this).data("href")
      redirectReview({filter_check: id, filter_hide: ""}, href)
    });
  }

  // Write a Review
  if ($(".js-write_a_review").exists()) {

    // radio box
    $(".js-radio-box input[type=radio]").on("click", function(){
      changeRadioBox(this)
    });
    const changeRadioBox = function(e){
      $(".js-radio-box").removeClass("on")
      $(e).parents(".js-radio-box").addClass("on")
    }
    changeRadioBox($(".js-radio-box input[type=radio]:checked"))

    // auto resize textarea (minimum 300px)
    $(document).on("input", ".js-auto-resize", function(){
      if ($(this).outerHeight() > this.scrollHeight) {
        $(this).height(300)
      }
      if ($(this).outerHeight() < this.scrollHeight) {
        const paddingHeight = $(this).outerHeight() - $(this).height();
        $(this).height(this.scrollHeight - paddingHeight)
      }
    });

    // Overall Rating
    $(".js-scores .score").on("click", function(){
      $(".js-scores .score").removeClass("selected")
      $(this).addClass("selected")
      const id = $(this).data("id")
      $("#frmreview_overall_score").val(id)
      $(".js-scores .js-score-message").html('<i class="fas fa-circle-check"></i><span class="js-score-id">' + id + '</span> - <span class="js-score-str">' + $(this).data("str") + '</span>')
    });
    $(".js-scores .score.selected").click();

    // anime episodes alert (TV 3 eps, other 1 eps)
    $("#myinfo_watchedeps").on("change", function(){
      const threshold = $('#myinfo_media_type').val() == 1 ? 3 : 1;
      const v = parseInt($(this).val())
      const f = v < threshold
      $('#review_message').toggle(f)
      $('#review_text').prop("disabled", f)
      $("#seeneps").val(v)
    }).trigger("change");

    // manga chapters alert
    $("#myinfo_chapters").on("change", function(){
      const v = parseInt($(this).val())
      const f = v < 1
      $('#review_message').toggle(f)
      $('#review_text').prop("disabled", f)
      $("#seeneps").val(v)
    }).trigger("change");

    // disable textarea
    $("#review_text").prop("disabled", $("#review_message").is(":visible"))
  }
  if ($(".js-write_a_review").exists()) {
    $(".js-btn-preview").on("click", function(e) {
      const $targetItem = $(this).closest(".js-write_a_review");
      const workType = $("input[name='review_type']").val();
      const feeling = $("input[name=frm_review_feelings]:checked").val();
      let feelingTag = "";
      const spoiler = $("input[name=frm_review_is_spoiler]:checked").val();
      let spoilerTag = "";
      if (feeling !== undefined) {
        if (feeling === "1") {
          feelingTag = '<div class="tag recommended"><i class="fas fa-star"></i>Recommended</div>';
        } else if (feeling === "2") {
          feelingTag = '<div class="tag mixed-feelings"><i class="fas fa-star-half-stroke"></i>Mixed Feelings</div>';
        } else if (feeling === "3") {
          feelingTag = '<div class="tag not-recommended"><i class="far fa-star"></i>Not Recommended</div>';
        }
      }
      let preliminaryTag = "";
      if ($('div').hasClass("user-status-block")) {
        if ($("#myinfo_status").data("class") !== "completed") {
          if (workType === 'anime') {
            const v = parseInt($("#myinfo_watchedeps").val());
            const total = $("#curEps").text();
            preliminaryTag = '<div id="preliminary_tag" class="tag preliminary">Preliminary<span>(' + v + '/' + total + ' eps)</span>'
          } else {
            const v = parseInt($("#myinfo_chapters").val());
            const total = $("#totalChaps").text();
            preliminaryTag = '<div id="preliminary_tag" class="tag preliminary">Preliminary<span>(' + v + '/' + total + ' eps)</span>'
          }
        }
      }
      if (spoiler === "1") {
        spoilerTag = '<div class="tag spoiler">Spoiler</div>';
      }
      Modal.generate();
      Modal.buildReviewPreviewDialog(
        $targetItem.attr("data-user-name"),
        $targetItem.attr("data-user-img"),
        $targetItem.attr("data-img-width"),
        nl2br($("#review_text").val()),
        feelingTag,
        preliminaryTag,
        spoilerTag,
      );
      Modal.show();
    })
  }

  // Christmas Layout
  if ($(".page-common.christmas").exists()) {
    $(".review-element .bottom-navi").children("div").on("click", function() {
      let icon_path = "/images/event/2022_christmas/icn_gift_forum.gif?v=" + Date.now();
      const $fa_gift = $(this).siblings(".gift").find('.fa-gift');
      $fa_gift.css("visibility", "hidden");
      $fa_gift.before('<i class="christmas-icn-gift-forum"></i>');
      $fa_gift.siblings('.christmas-icn-gift-forum').css({
        "position": "absolute",
        "background-image": `url("${icon_path}")`,
        "background-position": "left top",
        "background-repeat": "no-repeat",
        "background-size": "40px auto",
        "display": "inline",
        "width": "40px",
        "height": "25px",
        "content": "''",
        "margin-top": "-5.5px",
        "margin-left": "-13px",
      });
      setTimeout(function() {
        $fa_gift.css("visibility", "visible");
        $fa_gift.siblings('.christmas-icn-gift-forum').remove();
      }, 5000);
    });
  }

})

function nl2br(str) {
  str = str.replace(/\r\n/g, "<br />");
  str = str.replace(/(\n|\r)/g, "<br />");
  return str;
}
