const $ = window.$;
const _ = window._;
const sVue = window.sVue;
import {DEFAULT_URL2EMBED_OPTIONS} from "../../../common/url2embed";

$(function () {

  const draft         = {
    form: $("#featured-draft-form")
  };
  draft.submitURL = draft.form.data("url");
  draft.requestType = draft.form.data("post") === "edit" ? "PUT" : "POST";

//vue.js
  if ($("#news-feature-file-list")[0]) {
    sVue.filter("escape", function (val) {
      return val.replace(/[ !"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, "\\$&");
    });
    sVue.filter("default", function (val, defaultVal) {
      return val ? val : defaultVal;
    });

    var app = new sVue({
      el      : "#news-feature-file-list",
      data    : {
        images         : [],
        thumbnail_index: -1
      },
      created : function () {
        const json = $("#news-feature-file-list").data("json");
        if (json) {
          this.images = json;
          for (let i = 0, len = this.images.length; i < len; i++) {
            if (this.images[i].is_thumbnail) {
              this.thumbnail_index = i;
              break;
            }
          }
        }
      },
      computed: {},
      methods : {
        getThumbnailIndex: function () {
          return this.thumbnail_index;
        },
        getImages        : function () {
          return this.images;
        },
        addImage         : function (data) {
          this.images.unshift(data);

          // thumnailが選択されている場合のみ
          if (this.thumbnail_index >= 0) {
            // DOMが更新されてから出ないと、正しく計算出来ない
            this.$nextTick(function () {
              this.thumbnail_index++;
            });
          }
        },
        deleteImage      : function (n) {
          if (this.thumbnail_index == n) {
            this.thumbnail_index = -1;
          }
          this.images.splice(n, 1);
        },
        selectObj        : function (obj) {
          const range = document.createRange();
          range.selectNodeContents(obj.target);
          const sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        },
        fancybox         : function (obj) {
          $(".large-image-box").remove();

          $("body").append("<div class=\"large-image-box\"><img src=\"\"><p class=\"btn-close\">X</p></div>");
          const imageURL        = obj.target.dataset.name;
          const imgPreloader    = new Image();
          imgPreloader.onload = function () {
            $(".large-image-box").children("img").attr({src: imageURL});
            const imageDimention = {};
            const imgW           = $(".large-image-box img").width();
            const imgH           = $(".large-image-box img").height();
            if (imgW > imgH) {//ヨコ位置
              imageDimention.width = (imgW > 600) ? 600 : imgW;
              imageDimention.height = imageDimention.width * imgH / imgW;
            } else {//タテ位置
              imageDimention.height = (imgH > 400) ? 400 : imgH;
              imageDimention.width = imageDimention.height * imgW / imgH;
            }

            const scrollHight  = $(window).scrollTop();
            const offsetHeight = (window.innerHeight - imageDimention.height - 24) / 2;
            const offsetWidth  = (window.innerWidth - imageDimention.width - 24) / 2;
            const style        = {
              top : (scrollHight + offsetHeight),
              left: offsetWidth
            };

            $(".large-image-box").css({top: style.top, left: style.left});
            $(".large-image-box img").css({width: imageDimention.width, height: imageDimention.height});

            $(document).on("click", function () {
              $(".large-image-box").remove();
              $(document).off("click");
            });
          };
          imgPreloader.src = imageURL;

          $(".large-image-box").on("click", function (e) {
            e.stopPropagation();
          });

          $(".large-image-box .btn-close").on("click", function () {
            $(".large-image-box").remove();
            $(document).off("click");
          });

        }
      }
    });
  }

  //-----------------------
  // upload image
  //-----------------------
  $(".btn-input-feature-image").on("click", function () {
    const type = $(this).data("id");
    $("#state-message-images").html("");
    $("#feature-file-upload").addClass("loading");

    const $uploadFile = (type === "file") ? $("#feature-image-upload-by-file") : $("#feature-image-upload-by-url");
    const fd          = new FormData();

    if (type === "file") {
      //fileの時
      fd.append("form[image][file]", $uploadFile.get(0).files[0]);
    } else {
      //URLの時
      fd.append("form[image][url]", $uploadFile.val());
    }

    $.ajax({
      url        : "/featured/images.json",
      type       : "POST",
      data       : fd,
      dataType   : "json",
      processData: false,
      contentType: false,
      success    : function (data) {
        $("#feature-file-upload").removeClass("loading");

        const addData = {
          file_id          : data.image.id,
          filename         : data.image.url,
          thumbnail_file_id: data.thumbnail.id,
          thumbnail        : data.thumbnail.url,
          title            : "",
          align            : "",
          is_thumbnail     : false
        };
        app.addImage(addData);

        if (type === "file") {
          //fileの時
          $uploadFile.replaceWith($uploadFile.clone()); //fileのformを空にする
        } else {
          //URLの時
          $uploadFile.val("");
        }
      },
      error      : function (jqXHR) {
        const errorMessages = $.parseJSON(jqXHR.responseText).errors;
        let errorMessage__safe  = "";
        for (let i = 0; i < errorMessages.length; i++) {
          errorMessage__safe += _.template("<%- msg %><br>")({msg: errorMessages[i].message});
        }
        $("#feature-file-upload").removeClass("loading");
        $("#state-message-images").html(`<span class=\"badresult-text pt8 di-ib\">${errorMessage__safe}</span>`).show();
      },
      complete   : function () {
      }
    });
  });

  if ($(".news-feature-file-list-table-scroll")[0]) {
    if (navigator.userAgent.indexOf("Mac") > 0) {
      $(".news-feature-file-list-table-scroll").width(750);
    }
  }


  //-----------------------
  // text preview
  //-----------------------
  $("#btn-draft-text-preview").on("click", function () {
    const textareaVal = $("#featured-draft-text-textarea #textarea-text").val();
    const $textarea   = $("#featured-draft-text-textarea");
    const $preview    = $("#featured-draft-preview");
    const $previewVal = $("#featured-draft-text-preview");

    $textarea.hide();
    $preview.show();

    $.ajax({
      url     : "/preview/markdown.json",
      type    : "POST",
      data    : {body: textareaVal},
      dataType: "json",
      success : function (data) {
        $previewVal.html(data.body).url2embed(DEFAULT_URL2EMBED_OPTIONS);
      }
    });
  });

  $("#btn-draft-text-edit").on("click", function () {
    $("#featured-draft-preview").hide();
    $("#featured-draft-text-textarea").show();
  });

  $("#featured-draft-text-preview").on("click", function () {
    $("#featured-draft-preview").hide();
    $("#featured-draft-text-textarea").show();
  });

  //-----------------------
  // submit form
  //-----------------------
  $("#btn-submit").on("click", function (e) {
    e.preventDefault();
    $(".state-message").hide();
    const $btnSubmit = $("#btn-submit");
    const $form      = draft.form;

    $btnSubmit.attr("disabled", true);

    const images     = app.getImages();
    const imagesJson = [];
    for (let i = 0; i < images.length; i++) {
      const value = {
        file_id          : parseInt(images[i].file_id),
        thumbnail_file_id: parseInt(images[i].thumbnail_file_id),
        title            : images[i].title,
        is_thumbnail     : app.getThumbnailIndex() == i
      };
      if (images[i].align) {
        value.align = images[i].align;
      }
      imagesJson.push(value);
    }

    const char2int = function (ary) {
      const newAry = [];
      _.each(ary, function (v) {
        newAry.push(parseInt(v));
      });
      return newAry;
    };

    const $formData = {
      images       : imagesJson,
      anime_ids    : $form.find("#animeIds").val() ? char2int($form.find("#animeIds").val().split(",")) : [],
      manga_ids    : $form.find("#mangaIds").val() ? char2int($form.find("#mangaIds").val().split(",")) : [],
      person_ids   : $form.find("#peopleIds").val() ? char2int($form.find("#peopleIds").val().split(",")) : [],
      character_ids: $form.find("#characterIds").val() ? char2int($form.find("#characterIds").val().split(",")) : [],
      tag_ids      : $form.find("#tagIds").val() ? char2int($form.find("#tagIds").val().split(",")) : [],
      is_spoiler   : parseInt($form.find("input[name=\"is_spoiler\"]:checked").val()),
    };

    const $primary      = $form.find("select#primaryRelation option:selected");
    const relation_type = $primary.attr("name");
    if (relation_type) {
      $formData.primary_relation_type = relation_type;
      $formData.primary_relation_id = parseInt($primary.val());
    }

    const dataTitle   = $form.find("#featured-draft-title").val();
    const dataSummary = $form.find("#textarea-summary").val();
    const dataText    = $form.find("#textarea-text").val();

    if (dataTitle) {
      $formData.title = dataTitle;
    }
    if (dataSummary) {
      $formData.summary = dataSummary;
    }
    if (dataText) {
      $formData.body = dataText;
    }

    // for draft
    if ($form.find("input[name=\"status\"]").exists()) {
      $formData.status = $form.find("input[name=\"status\"]:checked").val();
    }
    const $wordLimit = $form.find("input[name=word_limit]:checked");
    if ($wordLimit.exists()) {
      $formData.word_limit = +$wordLimit.val();
    }

    // for admin page
    if ($form.find("input[name=\"article_configuration[is_advertorial]\"]").exists()) {
      $formData.is_advertorial = parseInt($form.find("input[name=\"article_configuration[is_advertorial]\"]:checked").val());
    }
    if ($form.find("input[name=\"article_configuration[on_fbia_feed]\"]").exists()) {
      $formData.on_fbia_feed = parseInt($form.find("input[name=\"article_configuration[on_fbia_feed]\"]:checked").val());
    }
    if ($form.find("input[name=\"article_configuration[status]\"]").exists()) {
      $formData.status = $form.find("input[name=\"article_configuration[status]\"]:checked").val();
    }

    if ($form.find("input[name=\"article_configuration[is_scheduled]\"]").exists()) {
      $formData.scheduled_at = {
        date: {},
        time: {}
      };
      $formData.is_scheduled = parseInt($form.find("input[name=\"article_configuration[is_scheduled]\"]:checked").val());
    }

    _.each([["date", "year"], ["date", "month"], ["date", "day"], ["time", "hour"]], function (key) {
      const att = $form.find(`select[name="article_configuration[scheduled_at][${key[0]}][${key[1]}]"]`);
      if (att.exists()) {
        const val = att.val();
        if (val) {
          $formData.scheduled_at[key[0]][key[1]] = parseInt(val);
        }
      }
    });

    if ($form.find("input[name=\"article_configuration[corner]\"]").exists()) {
      const corner_id_Val   = $form.find("input[name=\"article_configuration[corner]\"]:checked").val();
      $formData.corner_id = parseInt(corner_id_Val);
    }
    if ($form.find("input[name=\"article_configuration[display_order]\"]").exists()) {
      const displayOrder = $form.find("input[name=\"article_configuration[display_order]\"]").val();
      if (displayOrder !== "") {
        $formData.display_order = parseInt(displayOrder);
      }
    }

    $.ajax({
      url     : draft.submitURL,
      type    : draft.requestType,
      data    : JSON.stringify($formData),
      dataType: "json",
      timeout : 10000,
      cache   : false,
      success : function (data) {
        // 新規保存が成功したら以降は編集モード
        if (draft.requestType === "POST") {
          draft.submitURL = `${draft.submitURL}/${data.digest}`;
          draft.requestType = "PUT";
          // ブラウザがpushStateに対応しているかチェック
          if (window.history && window.history.pushState) {
            window.history.pushState(null, null, `${draft.submitURL}/edit`);
          } else {
            location.href = draft.submitURL;
          }
        }
        $("#state-message-finished").html("<span class=\"goodresult-text di-ib pt4\">This document has been saved successfully.</span>").show();
      },
      error   : function (jqXHR) {
        $("#state-message-finished").html("<span class=\"badresult-text di-ib pt4\">Failed to save this draft, because some errors occurred.</span><br>").show();
        const errorMessages = $.parseJSON(jqXHR.responseText).errors;
        for (let i = 0; i < errorMessages.length; i++) {
          const errorProperty = errorMessages[i].property;
          if (errorProperty) {
            if (errorProperty.indexOf("images") > -1) {
              const propertyName = errorProperty.split(".");
              $("#state-message-images-upload").html(_.template(`<span class="badresult-text di-ib pt4"><%- name %> <%- msg %>.</span><br>`)({name: propertyName[1] ? capitalized(propertyName[1]) : "", msg: errorMessages[i].message})).show();
            } else {
              $(`#state-message-${errorProperty}`).html(_.template(`<span class="badresult-text di-ib pt4"><%- msg %></span><br>`)({msg: errorMessages[i].message})).show();
            }
          } else {
            $("#state-message-finished").append(_.template(`<span class="badresult-text di-ib pt4"><%- msg %></span><br>`)({msg: errorMessages[i].message}));
          }
        }
      },
      complete: function () {
        $btnSubmit.attr("disabled", false);
      }
    });

  });

  $("#btn-delete").on("click", function () {
    if (!window.confirm("Are you sure you want to delete this article?")) {
      return;
    }
    $.ajax({
      url     : $(this).data("url"),
      type    : "DELETE",
      dataType: "json",
      timeout : 10000
    }).then(function (data) {
      const url       = data.redirect_to;
      location.href = url;
    }, function (jqXHR) {
      const data = $.parseJSON(jqXHR.responseText);
      $("#state-message-finished").append(_.template(`<span class="badresult-text di-ib pt4"><%- msg %></span><br>`)({msg: data.errors[0].message}));
    });
  });

  function capitalized(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

});
