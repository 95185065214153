var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isBlockAdBlock
      ? _c("div", { staticClass: "payment-adblock-error-message" }, [
          _vm._v(
            "\n    There is a possibility that your adblocker is preventing the credit card entry form from displaying."
          ),
          _c("br"),
          _vm._v(
            "\n    Please disable your adblocker temporarily and reload the page.\n  "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "creditcard-information" }, [
      _c("div", { ref: "card-element", attrs: { id: "card-element" } }),
      _vm._v(" "),
      _c("div", { attrs: { role: "alert" } }, [
        _vm._v("\n      " + _vm._s(_vm.error) + "\n    ")
      ])
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "creditcard-note di-ib" }, [
      _vm._v(
        "\n    Your credit card information will not be seen nor stored on MyAnimeList's servers."
      ),
      _c("br"),
      _vm._v("\n    All payments are processed using "),
      _c(
        "a",
        {
          staticClass: "ga-click",
          attrs: {
            "data-ga-click-type": "membership-payment-link-stripe",
            href: "https://stripe.com/",
            target: "_blank",
            rel: "noopener noreferrer"
          }
        },
        [_vm._v("Stripe")]
      ),
      _vm._v(".\n    Their service is safe, secure and\n    "),
      _c(
        "a",
        {
          staticClass: "ga-click",
          attrs: {
            "data-ga-click-type": "membership-payment-link-stripe_pcidss",
            href: "https://www.pcisecuritystandards.org/pci_security/",
            target: "_blank",
            rel: "noopener noreferrer"
          }
        },
        [_vm._v("PCI DSS")]
      ),
      _vm._v("\n    compliant."),
      _c("br")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }