var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sp
    ? _c("div", { staticClass: "sp pl12 pr12 pt8 pb8" }, [
        _vm.is_buy
          ? _c(
              "div",
              {
                staticClass: "manga-store-preview ga-impression",
                attrs: {
                  "data-ga-impression-type": _vm.ga_type + "_preview",
                  "data-ga-impression-param": _vm.gaParam()
                }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "manga-store-preview-thumb ga-click",
                    attrs: {
                      href: _vm.link,
                      target: "_blank",
                      "data-ga-click-type": _vm.ga_type + "_cover",
                      "data-ga-click-param": _vm.gaParam()
                    }
                  },
                  [_c("img", { attrs: { src: _vm.image_url, alt: _vm.title } })]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "manga-store-preview-info" }, [
                  _c(
                    "a",
                    {
                      staticClass: "di-b ga-click",
                      attrs: {
                        href: _vm.link,
                        target: "_blank",
                        "data-ga-click-type": _vm.ga_type + "_title",
                        "data-ga-click-param": _vm.gaParam()
                      }
                    },
                    [
                      _c("span", { staticClass: "title fw14 fw-b" }, [
                        _vm._v(_vm._s(_vm.title))
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticClass: "volume" }, [
                        _vm._v(_vm._s(_vm.volume))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "mal-btn outline button-preview ga-click",
                      attrs: {
                        href: _vm.link,
                        target: "_blank",
                        "data-ga-click-type": _vm.ga_type + "_preview",
                        "data-ga-click-param": _vm.gaParam()
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.is_manga
                            ? "Preview on Amazon"
                            : "Preview Manga on Amazon"
                        )
                      )
                    ]
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.is_wish
          ? _c(
              "div",
              { staticClass: "ac" },
              [
                _c("affiliate-amazon-button", {
                  attrs: {
                    id: _vm.id,
                    type: _vm.type,
                    is_wish: _vm.is_wish,
                    link: _vm.link,
                    sp: _vm.sp
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    : _c(
        "div",
        {
          staticClass: "manga-store-preview pc ga-impression",
          attrs: {
            "data-ga-impression-type": _vm.ga_type + "_preview",
            "data-ga-impression-param": _vm.gaParam()
          }
        },
        [
          _c(
            "a",
            {
              staticClass: "manga-store-preview-thumb ga-click",
              attrs: {
                href: _vm.link,
                target: "_blank",
                "data-ga-click-type": _vm.ga_type + "_cover",
                "data-ga-click-param": _vm.gaParam()
              }
            },
            [_c("img", { attrs: { src: _vm.image_url, alt: _vm.title } })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "manga-store-preview-info" }, [
            _vm._m(0),
            _c("br"),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "di-ib ga-click",
                attrs: {
                  href: _vm.link,
                  target: "_blank",
                  "data-ga-click-type": _vm.ga_type + "_title",
                  "data-ga-click-param": _vm.gaParam()
                }
              },
              [
                _c("span", { staticClass: "title fw14 fw-b" }, [
                  _vm._v(_vm._s(_vm.title))
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "volume" }, [
                  _vm._v(_vm._s(_vm.volume))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "mal-btn button-preview ga-click",
                attrs: {
                  href: _vm.link,
                  target: "_blank",
                  "data-ga-click-type": _vm.ga_type + "_preview",
                  "data-ga-click-param": _vm.gaParam()
                }
              },
              [_vm._v("Preview on Amazon")]
            )
          ])
        ]
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "manga-store-preview-info-header" }, [
      _c("i", {
        staticClass: "fa-solid fa-cart-shopping",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v("\n      Available on Amazon")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }