<template>
  <div class="root">
    <transition name="modal">
      <div v-show="isShown" class="modal-content">
        <div class="modal-mask" @click="$emit('close')"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <p class="modal-lead">Purchase completed.</p>
            <p class="modal-title">{{ title }}</p>
            <p class="modal-volume">{{ numberingText }}</p>
            <p v-if="isSubscription" class="modal-text">
              Subscriptions are set to auto-renew. You can change the subscription setting from your
              <a href="/store/bookshelf/magazine">Bookshelf</a>.
            </p>
            <div v-if="isSingle">
              <a class="modal-read-button" @click="$emit('read')">Read</a>
              <a class="modal-close-button" @click="$emit('close')"><i class="fa-solid fa-xmark" aria-hidden="true"></i> Close</a>
            </div>
            <div v-else>
              <a class="modal-read-button" @click="$emit('read')">Bookshelf</a>
              <a class="modal-close-button" @click="$emit('close')"><i class="fa-solid fa-xmark" aria-hidden="true"></i> Close</a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
$confirm-color:#ff9600;
  .modal-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
  }
  .modal-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    font-family: Verdana,Arial;
    position: relative;
    width: 267px;
    margin: 0 auto;
    padding: 37px 40px 40px;
    box-sizing: border-box;
    background-color: #fff;
    color: #353535;
    border-radius: 4px;
    transition: all .3s ease;
  }
  .modal-lead {
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    margin: 0;
  }
  .modal-title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin: 12px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }
  .modal-volume {
    color: #686A6F;
    font-size: 14px;
    font-weight: 700;
  }
  .modal-later-button,
  .modal-read-button {
    border: 2px solid #2E51A2;
    color: #2E51A2;
    font-size: 16px;
    text-align: center;
    display: block;
    height: 44px;
    line-height: 40px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: bold;
    margin: 20px 0 0;
    text-decoration: none;
    &:hover {
      color: #2E51A2;
      background-color: #e1e7f5;
      text-decoration: none;
    }
  }
  .modal-text {
    padding: 4px 0;
    font-size: 12px;
    text-align: left;
  }
  .modal-close-button {
    display: block;
    margin-top: 24px;
    text-align: center;
    font-size: 16px;
    color: #7a7a7a;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }


  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    content: '';
    display: block;
    width: 29px;
    height: 29px;
    background: url(/images/manga_store/pc/modal_close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
    cursor: pointer;
  }

  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>

<script type="text/babel">
  export default {
    props: {
      title: String,
      numberingText: String,
      isShown: Boolean,
      isSingle: Boolean,
      isSubscription: Boolean,
    },
  }
</script>
