const $ = window.$;

$(() => {
  if ($("body").hasClass("ownlist_style")) {
    let
      $oldStyle     = $("#old-style")
    , $newStyle     = $("#new-style")
    , $themeSetting = $("#theme-setting")
    , $themeInputs  = $(".js-theme-input")

    // switch old or new
    $("input[name='style_edit[is_new]']:radio").on("change", (event) => {
      if ($(event.currentTarget).val()==="1") {
        $newStyle.show()
        $themeInputs.prop("disabled", false)
        $oldStyle.hide()
      } else {
        $oldStyle.show()
        $newStyle.hide()
        $themeInputs.prop("disabled", true)
      }
    })
    
    // add bg-color to selected theme
    const themeSelectedCheck = function() {
      $("input.js-theme-input[type=radio]").parents(".new-theme-unit").removeClass("selected")
      $("input.js-theme-input[type=radio]:checked").parents(".new-theme-unit").addClass("selected")
    } 
    $("input.js-theme-input[type=radio]").on("change", () => {
      themeSelectedCheck()
    })
    themeSelectedCheck()

  }
})
