import Log from "./modules/log"

const $ = window.$

$(() => {

  if (window.navigator === undefined) {
    return
  }

  const serviceWorker = window.navigator.serviceWorker
  if (serviceWorker === undefined) {
    return
  }

  // 今のところ service-worker.js は空のファイルだが、ホームスクリーン追加機能を動作させるために必要
  serviceWorker.register("/service-worker.js")
    .then((/* r */) => {
      // service worker の登録成功時に呼ばれる
    })
    .catch((/* r */) => {
      // service worker の登録失敗時に呼ばれる
    })

  window.addEventListener("beforeinstallprompt", (e) => {
    // プロンプトのimpressionを取るために
    Log.actionLog("homescreen_prompt", {event: "prompt"})

    // ユーザがプロンプトに対して何らかアクションをすると呼ばれるコールバック
    e.userChoice.then((result) => {
      Log.actionLog("homescreen_prompt", {
        event  : "result",
        outcome: result.outcome  // "dismissed" or "accepted"
      })
    })
  })
})
