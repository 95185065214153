const $ = window.$
const _ = window._
import Modal from "../../../common/modules/modal";

$(function () {
  if ($(".twentieth-anniversary")[0]) {

    if (typeof $.fancybox !== "undefined") {
      $(document).on("click", ".Lightbox_Pic", function(e) {
        e.preventDefault();
        $(this).fancybox({
          autoScale     : true,
          autoDimensions: true,
          transitionIn  : "none",
          transitionOut : "none",
          titleShow     : false
        });
        $(this).trigger("click");
      });
    }

    const render = function (id, params) {
      const template = _.template($(id).html())
      return template(params);
    };

    let voting = false;
    const finished = function () {
      $(".vote_button").not(".disabled2").on("click", function () {
        if (voting) return false;

        if ($(this).hasClass("signup")) {
          location.href = $(this).data('signup_url');
          return false;
        }

        const is_unvote = $(this).hasClass("voted")
        const url = '/polls/' + $(this).data("code") + "/" + $(this).data("question_id") + "/" + $(this).data("option_id") + ".json";

        if (is_unvote && $(this).data("code") == '20th_badge') return false;

        voting = true;
        $(this).html('<img src="/images/xmlhttp-loader-big.gif" style="height:1em;">');
        $.ajax({
          url: url + window.location.search,
          contentType: "application/json",
          type: is_unvote ? "DELETE" : "POST",
          dataType: 'json',
          data: JSON.stringify({
            number: 1
          }),
          success: data => {
            if (is_unvote) {
              $(this).removeClass("voted");
              $(this).text($(this).data('vote_label'));
            } else {
              $(this).addClass("voted");
              $(this).text($(this).data('voted_label'));
            }
            count_voted_members();
          },
          error: error => {
            if (is_unvote) {
              $(this).addClass("voted");
              $(this).text($(this).data('voted_label'));
            } else {
              $(this).removeClass("voted");
              $(this).text($(this).data('vote_label'));
            }
            if(error.status == 400) {
              if (error.responseJSON.errors[0].message == "not login") {
                Modal.alert("Please login and open this page.");
              } else if (error.responseJSON.errors[0].message == "limit per user"){
                Modal.alert("You can apply for up to 5 items");
              }
            }
          },
          complete: data => {
            voting = false;
          }
        });
        return false;
      });
      // $("div.round").show();
    };

    const fetch_items_poll = function () {
      $.ajax({
        url: "/polls/20th_anniv.json" + window.location.search,
        contentType: "application/json",
        type: "GET",
        success: data => {
          console.log(data)
          // sponsorごとにitemsを持つようjsonを整理
          const result = [];
          let currentSponsorName = "";
          data.questions[0].options.forEach(current => {
            const sponsorName = current.data.sponsor.name;
            const item = current.data.item;
            item.option_id = current.id; // option_id
            item.is_voted = current.number; // 投票済みか判定用
            if (currentSponsorName != sponsorName) {
              result.push({
                sponsor: current.data.sponsor,
                items: []
              });
            }
            result.at(-1).items.push(item);
            currentSponsorName = sponsorName;
          });
          console.log(result);

          // template
          let html = render("#options-template", {data: data, options: result});
          $('#options').html(html);

          finished();
        },
        error: error => {
          console.error('error:', error);
          finished();
        },
        complete: data => {
        }
      });
    }
    fetch_items_poll(1);

    const fetch_badge_poll = function () {
      $.ajax({
        url: "/polls/20th_badge.json" + window.location.search,
        contentType: "application/json",
        type: "GET",
        success: data => {
          // template
          let html = render("#badge-template", {data: data});
          $('#badge').html(html);
          finished();
        },
        error: error => {
          console.error('error:', error);
          finished();
        },
        complete: data => {
        }
      });
    }
    fetch_badge_poll(1);

    const count_voted_members = function () {
      $.ajax({
        url: "/polls/count_voted_members/20th_anniv.json" + window.location.search,
        contentType: "application/json",
        type: "GET",
        success: data => {
          console.log(data)
          $('#voted_count').text(data.count.toLocaleString("en-US"));
        },
        error: error => {
          // finished();
        },
        complete: data => {
        }
      });
    }
    count_voted_members();
  }
});
