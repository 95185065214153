var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gauge-outer" }, [
    _c("span", { staticClass: "gauge-inner", style: _vm.gaugeWidth }),
    _vm._v(" "),
    _c("span", { staticClass: "gauge-text" }, [
      _vm._v(_vm._s(_vm.score) + "% matched")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }