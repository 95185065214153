$(function () {
  $('a').each(function(){
    let url = $(this).attr('href');
    if (url && (url.includes('/register.php') || url.includes('/login.php') || url.includes('/sns/register/'))) {
      let clickType = $(this).attr('data-ga-click-type');
      let clickParam = $(this).attr('data-ga-click-param');
      let appendString = '';

      if (url.includes('?')) {
        appendString += '&';
      } else {
        appendString += '?';
      }

      if (clickType) {
        appendString += 'click_type=' + encodeURI(clickType);
        if (clickParam) {
          appendString += '&';
        }
      }
      if (clickParam) {
        appendString += 'click_param=' + encodeURI(clickParam);
      }

      $(this).attr('href', url + appendString);
    }
  });
});
