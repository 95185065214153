"use strict";

const $ = window.$;
const _ = window._;

module.exports = {
  initiailze: function() {
    if ($('div.toast-container').length > 0) {
      // 同時表示数を10件に制限する
      while ($('div.toast-container div.toast').length > 10) {
        $('div.toast-container div.toast').get(0).remove();
      }
      return;
    }
    $('<div class="toast-container"></div>').appendTo('body');
    window.MAL.toast = this;
  },
  add: function(data, timeout, className) {
    this.initiailze();
    const toast = $('<div class="toast"></div>');
    const toast_body = $('<div class="body"></div>');
    const toast_content = $('<div class="content"></div>');
    const toast_buttons = $('<div class="buttons"></div>');
    const toast_close = $('<button class="btn-close"><i class="fa-solid fa-times"></i></button>');
    toast_buttons.append(toast_close);
    toast_body.append(toast_content);
    toast_body.append(toast_buttons);
    toast.append(toast_body);
    $('div.toast-container').append(toast);
    toast_content.html(data);
    if (className) {
      toast.addClass(className);
    }
    // 閉じるボタン
    toast_close.on('click', function() {
      $(this).parents('div.toast').trigger('close');
    });
    // 閉じる際の動作（隠す処理をfadeで行うため1000ms遅延させてからremoveする）
    toast.on('close', function() {
      const toast_remove = function(){ toast.remove(); }
      toast.removeClass('show');
      setTimeout(toast_remove, 1000);
    });
    // 自動で閉じるタイムアウト設定
    if (timeout > 0) {
      const toast_timeout = function(){ toast.trigger('close'); }
      setTimeout(toast_timeout, timeout);
    }
    // 表示開始
    toast.addClass('show');
  },
};
