export const DEFAULT_URL2EMBED_OPTIONS = {
  defaultWidth: 600,
  defaultAspect: 1.78,
  defaultAlign: "center",
  predefined: {
    "embed-left": {align: "left"},
    "embed-right": {align: "right"}
  }
};

export default function setupUrl2Embed() {
  const $ = window.$;
  $(document).ready(function() {
    $(".url2embed").url2embed(DEFAULT_URL2EMBED_OPTIONS);
  });
}
