var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "item",
      style: { width: _vm.width ? _vm.width + "px" : "auto" }
    },
    [
      _c(
        "div",
        {
          staticClass: "cover-wrap",
          class: { iconNew: !_vm.item.isPossessed && _vm.item.isNew },
          on: { mouseover: _vm.mouseOver, mouseout: _vm.mouseOut }
        },
        [
          _vm.isLazyload
            ? _c("a", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy:background-image",
                    value: _vm.coverImage,
                    expression: "coverImage",
                    arg: "background-image"
                  }
                ],
                staticClass: "cover-image",
                class: {
                  hasButton:
                    _vm.item.isPossessed ||
                    _vm.item.isFree ||
                    _vm.item.isPreviewable
                },
                attrs: {
                  href: _vm.isTablet ? _vm.viewerUrlWithLocation : null,
                  target: "_blank"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLazyload
            ? _c("a", {
                staticClass: "cover-image",
                class: {
                  hasButton:
                    _vm.item.isPossessed ||
                    _vm.item.isFree ||
                    _vm.item.isPreviewable
                },
                style: { backgroundImage: "url(" + _vm.coverImage.src + ")" },
                attrs: {
                  href: _vm.isTablet ? _vm.viewerUrlWithLocation : null,
                  target: "_blank"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("button-sample", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  !_vm.item.isPossessed &&
                  _vm.item.isPreviewable &&
                  _vm.isHover &&
                  !_vm.item.isFree,
                expression:
                  "!item.isPossessed && item.isPreviewable && isHover && !item.isFree"
              }
            ],
            staticClass: "button",
            attrs: {
              url: _vm.urlWithLocation(_vm.item.previewUrl, _vm.location),
              "is-mobile": false,
              height: 24,
              width: 72,
              "is-border": true
            }
          }),
          _vm._v(" "),
          _c("button-read", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: (_vm.item.isPossessed || _vm.item.isFree) && _vm.isHover,
                expression: "(item.isPossessed || item.isFree)  && isHover"
              }
            ],
            staticClass: "button",
            attrs: {
              "is-mobile": false,
              height: 24,
              width: 72,
              "is-border": true
            },
            on: { read: _vm.read }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.item.isPossessed || _vm.item.isFree
        ? [
            _vm.hideTitle !== true
              ? _c(
                  "div",
                  {
                    staticClass: "title-wrap",
                    style: { width: _vm.width ? _vm.width - 20 + "px" : "auto" }
                  },
                  [
                    _c("div", { ref: "ellipsis", staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.item.title))
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "volume" }, [
              _vm._v(_vm._s(_vm.item.numberingText))
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.item.isPossessed && !_vm.item.isFree
        ? [
            _vm.hideTitle !== true
              ? _c(
                  "div",
                  {
                    staticClass: "title-wrap",
                    style: { width: _vm.width ? _vm.width - 20 + "px" : "auto" }
                  },
                  [
                    _c("div", { ref: "ellipsis", staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.item.title))
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showPurchaseButton
              ? _c(
                  "div",
                  {
                    staticClass: "volume purchase-button",
                    on: { click: _vm.click }
                  },
                  [
                    _c("i", {
                      staticClass: "fa-solid fa-cart-shopping",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" Buy " + _vm._s(_vm.item.numberingText) + "\n    ")
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.showPurchaseButton
              ? _c("div", { staticClass: "volume" }, [
                  _vm._v(_vm._s(_vm.item.numberingText))
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.item.isPossessed
              ? _c("div", { staticClass: "price-wrap" }, [
                  _c(
                    "span",
                    {
                      staticClass: "price",
                      class: { "is-sale": _vm.item.salePrice >= 0 }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.currency.formatSymbolAndPrice(_vm.item.price)
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.item.salePrice >= 0,
                          expression: "item.salePrice >= 0"
                        }
                      ],
                      staticClass: "price-sale"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.currency.formatSymbolAndPrice(_vm.item.salePrice)
                        )
                      )
                    ]
                  )
                ])
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }