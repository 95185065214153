const $ = window.$;
const _ = window._;
const sVue = window.sVue;
import CsrfProtector from "../../../../common/modules/CsrfProtector"

$(() => {

  if($(".episode-edit-staff")[0]){
    /* eslint-disable camelcase */

    // Episode list
    const formEl = "#staffForm"
    const episodeList = new sVue({
      el: formEl,
      data: {
        allEpisodes: $(formEl).data("json"),
        episodes: [],
        initialData: null,
        keyword: "",
        staffs: $("#episodeStaffList").data("json"),
        entry: {id: null, name: null},
        pulldown: false,
        hasNoResults: false,
        roles: []
      },
      created: function () {
        const self = this, loadOnetime = 30;
        let offset = 0;

        (function loadEpisodes() {
          self.episodes = self.episodes.concat(self.allEpisodes.slice(offset, offset + loadOnetime));
          offset += loadOnetime;
          if (offset <= self.allEpisodes.length) {
            setTimeout(loadEpisodes, 100);
          }
        })();

        const data = $("#episodeRole").data("json");
        if (data) {
          this.roles = data;
        }

        CsrfProtector.injectTokenToForm(formEl)
      },
      computed: {
        filteredStaffs() {
          const self = this
          const searchRegex = new RegExp(self.keyword, "i")
          return self.staffs.filter((staff) => {
            return searchRegex.test(staff.name)
          })
        },
      },
      methods: {
        updateEpisodes: function (data) {
          const role_list    = data.role_list;
          const episode_list = data.episode_list;

          this.allEpisodes.forEach(function (episode) {
            episode.is_added = (episode_list[episode.id].is_added ? true : false);

            const roles = JSON.parse(JSON.stringify(role_list));
            roles.forEach(function (role) {
              if (episode.is_added) {
                role.is_added = _.includes(episode_list[episode.id].role_ids, role.role_id.toString());
              } else {
                role.is_added = false;
              }
            });
            episode.roles = roles;
          });

          this.initialData = this.generateSubmissionData();
        },
        addAll: function () {
          this.allEpisodes.forEach(function (episode) {
            episode.is_added = true;
          });
        },
        deleteAll: function () {
          this.allEpisodes.forEach(function (episode) {
            episode.is_added = false;
          });
        },
        addDefaultRoleId: function (role_id) {
          this.allEpisodes.forEach(function (episode) {
            episode.roles.forEach(function (role) {
              if (role.role_id === role_id) {
                role.is_added = true;
              }
            });
          });
        },
        deleteDefaultRoleId: function (role_id) {
          this.allEpisodes.forEach(function (episode) {
            episode.roles.forEach(function (role) {
              if (role.role_id === role_id) {
                role.is_added = false;
              }
            });
          });
        },
        addByIndices: function (indices) {
          this.allEpisodes.forEach(function (episode) {
            episode.is_added = false;
          });

          for (let i = 0; i < indices.length; i++) {
            this.allEpisodes[indices[i]].is_added = true;
          }
        },
        submitStaffs: function (e) {
          const data = this.generateSubmissionData();
          if (data === null) {
            e.preventDefault();
            alert("Select staff!");
            return;
          }

          // レビューページでなければ変更点があるかどうかチェックする
          if ($("#episodeStaffList").exists()) {
            if (!this.checkModified(data)) {
              e.preventDefault();
              alert("Nothing has been changed!");
              return;
            }
          }

          $("input[name=staffs]").val(JSON.stringify(data));

        },
        generateSubmissionData: function () {
          // reviewページではスタッフリストはない
          let staff_id;
          if ($("#episodeStaffList").exists()) {
            staff_id = this.getStaffId();
            if (!staff_id) {
              return null;
            }
          }
          else {
            staff_id = $("#staffIdInput").val();
          }

          const data = [];
          this.allEpisodes.forEach(function (episode) {
            if (episode.is_added) {
              const roles = [];
              episode.roles.forEach(function (role) {
                if (role.is_added) {
                  roles.push({role_id: role.role_id});
                }
              });
              data.push({
                id         : staff_id,
                episode_id : episode.id,
                roles      : roles
              });
            }
          });
          return data;
        },
        checkModified: function (data) {
          // 変更があるかチェック
          if (data.length !== this.initialData.length) {
            return true;
          }
          for (let i = 0; i < data.length; i++) {
            const a = data[i], b = this.initialData[i];
            if (a.id !== b.id || a.episode_id !== b.episode_id) {
              return true;
            }
            if (a.roles.length !== b.roles.length) {
              return true;
            }
            for (let j = 0; j < a.roles.length; j++) {
              const c = a.roles[j], d = b.roles[j];
              if (c.role_id !== d.role_id) {
                return true;
              }
            }
          }
          return false;
        },
        getStaffById: function (id) {
          for (let i = 0, len = this.staffs.length; i < len; i++) {
            if (this.staffs[i].id === id) {
              return this.staffs[i];
            }
          }
        },
        selectStaff: function (id) {
          this.entry = this.getStaffById(id);
          this.keyword = "";
          this.pulldown = false;

          $.get(`../staff/${id}`, {}, function (value) {
            episodeList.updateRoles(value.role_list);
            episodeList.updateEpisodes(value);
          });
        },
        getStaffId: function () {
          if (this.entry) {
            return this.entry.id;
          } else {
            return false;
          }
        },
        setPrimaryRole: function(role_id) {
          if ($(`.js-checkbox-default.js-va-id-${role_id}`).is(":checked")) {
            episodeList.addDefaultRoleId(role_id);
          } else {
            episodeList.deleteDefaultRoleId(role_id);
          }
        },
        updateRoles: function (role_list) {
          this.roles = role_list;
        },
      }
    });

    //Select Episodes
    $(".js-data-episode-all").on("click", function() {
      if ($(this).prop("checked")) {
        episodeList.addAll();
      } else {
        episodeList.deleteAll();
      }
    });

    // 数値入力でエピソードを選択するときに、エンターキーで反映できるようにする
    $(".js-episode-numbers").on("keypress", function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        submitEpisodeNumbers();
      }
    });

    //submiit Eps Numbers
    $(".js-episode-numbers-submit").on("click", function() {
      submitEpisodeNumbers();
    });

    const submitEpisodeNumbers = () => {
      const num = $(".js-episode-numbers").val().split(",");
      const numAry = [];
      for (let i = 0; i < num.length; i++) {
        if (num[i].indexOf("-") > -1) {
          const numbers = num[i].split("-");
          for (let g = numbers[0]; g <= numbers[1]; g++) {
            numAry.push(parseInt(g) - 1);
          }
        } else {
          numAry.push(parseInt(num[i]) - 1);
        }

        episodeList.addByIndices(numAry);
      }
    }

    // show/hide Voice Actor
    $(document).on("click", ".js-data-actor", function(e) {
      e.stopPropagation();
      const $block = $(this).next(".js-edit-character-list-block");
      if ($(this).hasClass("on")) {
        $(this).removeClass("on");
        $block.hide();
      } else {
        $(".js-data-actor").removeClass("on");
        $(".js-edit-character-list-block").hide();
        $(this).addClass("on");
        $block.css({bottom:(-1 * ($block.height() - 1))}).show();
      }
    });
  }
});
