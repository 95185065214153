var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show"
              }
            ],
            staticClass: "modal-content"
          },
          [
            _c("div", { staticClass: "modal-mask" }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-wrapper" }, [
              _c(
                "div",
                { staticClass: "modal-container" },
                [
                  _c("payment-settings-modal", {
                    staticClass: "modal-payment-method",
                    attrs: {
                      "default-payment-method":
                        _vm.payment.defaultPaymentMethod,
                      "initial-payment-method":
                        _vm.payment.initialPaymentMethod,
                      "registered-card": _vm.payment.registeredCard,
                      "braintree-registration-datetime":
                        _vm.payment.braintreeRegistrationDatetime,
                      "terms-html": _vm.termsHtml,
                      "support-url": _vm.supportUrl
                    },
                    on: { registerdPaymentMethod: _vm.registerdPaymentMethod }
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "modal-close",
                      on: {
                        click: function($event) {
                          return _vm.$emit("hidePaymentModal")
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }